class EventDataBuilder {
  static userUpdated = user => {
    const evtData = {
      displayName: user.displayName,
      userLanguage: user.language,
      publisherId: user.defaultPublisher,
      createdDate: user.created,
      updatedDate: user.updated,
    };
    const accounts = [];
    const accountTypes = ['facebook', 'google', 'twitter'];

    accountTypes.forEach(t => {
      if (user[t]) {
        accounts.push(t);
      }
    });
    if ((user.emails || []).length) {
      accounts.push('email');
    }
    evtData.accountType = accounts.join(',');
    if (user.profileImage) {
      evtData.userProfileImage = user.profileImage.path;
    }
    if (user.defaultPublisher) {
      evtData.userDefaultChannel = user.defaultPublisher;
    }
    return evtData;
  };

  static userMFA = type => ({
    browser: navigator.appVersion,
    MFA_TYPE: type,
  });

  static channelCreated = channel => ({
    channelId: channel.publisherId,
    channelName: channel.name,
    channelGeo: channel.geo,
    channelVertical: channel.vertical,
    channelUrl: channel.urls?.join(',') || channel.url,
    channelLogoUrl: channel.profileImage.path,
    channelCreatorId: channel.admins.length ? channel.admins[0]._id : '',
    channelToken: channel.authToken,
    channelAdmins: (channel.admins || []).map(c => c._id).join(','),
    channelAdminsCount: `${channel.admins.length}`,
    mfaEnabled: `${!!channel.MFA}`,
    defaultEmbedding: 'iframe',
    createdDate: `${channel.created}`,
    updatedDate: `${channel.updated}`,
  });

  static channelUpdated = (channel, canEditChannels) => {
    const evtData = {
      channelId: channel.publisherId || channel._id,
      channelName: channel.name,
      channelUrl: channel.urls?.join(',') || channel.url,
      channelStatus: channel.status,
      channelLogoUrl: channel.profileImage.path,
      mfaEnabled: `${!!channel.MFA}`, // Boolean Only
      channelCreatorId: channel.admins.length ? channel.admins[0].userId : '',
      channelToken: channel.authToken,
      channelAdmins: (channel.admins || []).map(c => c.userId).join(','),
      channelEditors: (channel.editors || []).map(e => e.userId).join(','),
      channelAdminsCount: `${(channel.admins || []).length}`,
      channelEditorsCount: `${(channel.editors || []).length}`,
      defaultEmbedding: (channel.publishSettings || {}).type || 'iframe',
      createdDate: `${channel.created}`,
      updatedDate: `${channel.updated}`,
      channelGeo: channel.geo ? channel.geo : '',
      channelVertical: channel.vertical ? channel.vertical : '',
    };
    if (canEditChannels && channel.campaignSettings.billings.length > 1) {
      evtData.iNativeBillingMethod = channel.campaignSettings.billings[0].billingType;
      evtData.iNativeBillingValue = `${channel.campaignSettings.billings[0].value}`;
      evtData.programmaticBillingMethod = channel.campaignSettings.billings[1].billingType;
      evtData.programmaticBillingValue = `${channel.campaignSettings.billings[1].value}`;
    }
    return evtData;
  };

  static groupUpdated = group => {
    const evtData = {
      groupId: group._id,
      groupName: group.name,
      geoCountryName: group.geo,
      channels: JSON.stringify(group.channels ? group.channels.map(c => ({ channelId: c._id })) : []),
    };
    return evtData;
  };

  static groupCreated = group => {
    const evtData = {
      groupId: group._id,
      groupName: group.name,
      geoCountryName: group.geo,
    };
    return evtData;
  };

  static channelMFA = () => ({
    browser: navigator.appVersion,
  });
}

export default EventDataBuilder;
