import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import EllipsisText from '../../EllipsisText/EllipsisText';
import Checkbox from '../Checkbox/Checkbox';
import SelectSearchField from './SelectSearchField';

import './MultiselectDropdown.scss';

const filterListBySearchName = (list, search) => (
  list.filter(item => item.label?.toLowerCase()?.includes(search))
);

const MultiselectDropdown = ({
  isOpen, anchorEl, handleClose, items = [], includeSearchField, includeSelectAll, className, onSearchChange, name,
}) => {
  const [filteredItems, setFilteredItems] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedDictionary, setSelectedDictionary] = useState({});
  const [numberOfSelected, setNumberOfSelected] = useState();
  // AllDictionary is needed to store all items (including the ones that are hidden due to filter action)
  const [allDictionary, setAllDictionary] = useState({});

  const id = isOpen ? 'multiselect-dropdown' : undefined;

  const setSelectedItems = (list, isAllSelected) => {
    const selectedItems = isAllSelected ? list : list?.filter(item => item.isSelected);
    const alreadySelected = {};
    selectedItems.forEach(selected => { alreadySelected[selected.id] = true; });
    setSelectedDictionary(alreadySelected);
  };

  useEffect(() => {
    if (items?.length) {
      setSelectedItems(items);
      const itemsObject = items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
      setAllDictionary(({ ...allDictionary, ...itemsObject }));
    }
  }, [items]);

  useEffect(() => {
    const selectedNumber = Object.keys(selectedDictionary || {}).filter(key => selectedDictionary[key]).length;
    setNumberOfSelected(selectedNumber);
  }, [selectedDictionary]);

  // Reset checked items (using timeout so the reset will apply after close animation)
  const resetSelectedDictionary = list => {
    setTimeout(() => setSelectedItems(list));
  };

  const onClose = () => {
    handleClose();
    resetSelectedDictionary(items);
  };

  const handleSelect = ({ target }) => {
    if (target.value === 'all') {
      setSelectedItems(target.checked ? items.map(item => ({ ...item, isSelected: true })) : [], target.checked);
    } else {
      setSelectedDictionary({ ...selectedDictionary, [target.value]: target.checked });
    }
  };

  const onApply = () => {
    // Compare checked/unchecked items to the saved items list and pass only the ones who changed.
    const changedItems = [];
    Object.keys(selectedDictionary).forEach(itemId => {
      const currentItem = allDictionary[itemId];
      const hasChangedSelection = !currentItem || currentItem.isSelected !== selectedDictionary[itemId];
      if (hasChangedSelection) {
        changedItems.push({ ...currentItem, id: itemId, isSelected: selectedDictionary[itemId] });
      }
    });

    handleClose(changedItems);
    resetSelectedDictionary(items);
  };

  // For lists that have static list we can avoid passing onSearchChange function as prop and handle the filtering in this component
  const onSearch = value => {
    setIsFiltered(value?.length > 0);

    if (!onSearchChange) {
      setFilteredItems(!value?.length ? undefined : filterListBySearchName(items, value));
    } else {
      onSearchChange(value);
    }
  };

  const renderSelectAll = () => (
    <>
      <div className="list-item-button">
        <Checkbox
          formControlClass="multiselect-item-control"
          labelClass="multiselect-item-label"
          checked={numberOfSelected > 0 && numberOfSelected === items?.length}
          onChange={handleSelect}
          value="all"
          label={<p className="primary-text">Select all</p>}
        />
      </div>
      <Divider classes={{ root: 'divider' }} />
    </>
  );

  const renderList = (isSelectedList = false) => (
    <List className={isSelectedList ? 'selected-list' : 'unselected-list'} disablePadding>
      {(filteredItems || items)?.map(item => {
        const isCurrentSelected = selectedDictionary[item.id];
        const shouldDisplayItem = (isSelectedList && isCurrentSelected) || (!isSelectedList && !isCurrentSelected);
        return shouldDisplayItem && (
          <ListItem key={item.id} classes={{ root: 'list-item-button' }}>
            <Checkbox
              formControlClass="multiselect-item-control"
              labelClass={`multiselect-item-label ${item.secondaryLabel ? 'multiline' : ''}`}
              checked={isSelectedList}
              onChange={handleSelect}
              value={item.id}
              label={(
                <>
                  <EllipsisText className="primary-text" text={item.label} maxWidth="100%" />
                  {item.secondaryLabel && <EllipsisText className="secondary-text" text={item.secondaryLabel} maxWidth="100%" />}
                </>
              )}
            />
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      className={`custom-menu-popper custom-select-menu multiselect-menu-popover ${className}`}
      placement="bottom-start"
      disableAutoFocus
      disableEnforceFocus
    >
      {includeSearchField && <SelectSearchField name={name} onSearch={onSearch} />}
      {includeSelectAll && !isFiltered && renderSelectAll()}

      <div className="options-list">
        {renderList(true)}
        {renderList()}
      </div>

      <div className="multiselect-dropdown-footer">
        <Button
          classes={{ root: 'contained-primary', label: 'button-label' }}
          onClick={onApply}
        >
          Apply
        </Button>
      </div>
    </Popover>
  );
};

MultiselectDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  includeSearchField: PropTypes.bool,
  includeSelectAll: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    secondaryLabel: PropTypes.string,
    isSelected: PropTypes.bool,
  })),
};

MultiselectDropdown.defaultProps = {
  includeSearchField: false,
  includeSelectAll: false,
  anchorEl: null,
  className: '',
  items: [],
  name: '',
};

export default MultiselectDropdown;
