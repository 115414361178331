window.process = {};
/* eslint-disable global-require */
const REACT_APP_ENV = process && (process.env.REACT_APP_ENV || process.env.NODE_ENV);

// eslint-disable-next-line import/no-dynamic-require
export const CONFIG = require(`../config/${REACT_APP_ENV}.env.js`);

export const contactPublicUrl = 'https://apester.com/contact';

export const trialPlanName = 'trial';

export const MAILCHIMP_URL = 'mailchimp.com';

export const SALESFORCE_URL = 'salesforce.com';

export const BillingTypes = {
  revenueShare: 'revenueShare',
  flatRate: 'flatRat',
};

export const CampaignTypes = {
  programmatic: 'programmatic',
};

export const SellerTypes = {
  publisher: 'publisher',
  intermediary: 'intermediary',
  both: 'both',
};

export const adminMembersColumns = [
  { field: 'member', headerName: 'Member' },
  { field: 'status', headerName: 'Status' },
  { field: 'channels', headerName: 'Channels' },
  { field: 'actions', headerName: 'Actions' },
];

export const channelMembersColumns = [
  { field: 'member', headerName: 'Member' },
  { field: 'status', headerName: 'Status' },
  { field: 'permissions', headerName: 'Permissions' },
  { field: 'actions', headerName: 'Actions' },
];

export const channelsColumns = [
  { field: 'channel', headerName: 'Channel' },
  { field: 'plan', headerName: 'Plan' },
  { field: 'revShare', headerName: 'Rev Share' },
  { field: 'members', headerName: 'Members' },
  { field: 'actions', headerName: 'Actions' },
];

export const channelEmbedOptions = [
  { name: "jsEmbed", id: "jsEmbed" },
  { name: "html", id: "html" },
];

export const statusOptions = [
  { name: "active", id: "active" },
  { name: "inactive", id: "inactive" },
  { name: "test", id: "test" },
];

export const taglineSourceOptions = [
  { name: 'Use tags', id: 'tag' },
  { name: 'Use title', id: 'title' },
];

export const defaultFonts = [
  { name: 'Default', id: '' },
  { name: 'Lato', id: 'LatoLatin, Helvetica, sans-serif', provider: 'system' },
  { name: 'Playfair', id: 'Playfair Display, sans-serif', provider: 'google' },
  { name: 'Abril', id: 'Abril Fatface, cursive', provider: 'google' },
  { name: 'Cormorant', id: 'Cormorant Garamond, serif', provider: 'google' },
  { name: 'Oswald', id: 'Oswald, sans-serif', provider: 'google' },
  { name: 'Shrikhand', id: 'Shrikhand, cursive', provider: 'google' },
  { name: 'Anton', id: 'Anton, sans-serif', provider: 'google' },
  { name: 'Raleway', id: 'Raleway, sans-serif', provider: 'google' },
  { name: 'Berkshire Swash', id: 'Berkshire Swash, cursive', provider: 'google' },
  { name: 'Roboto', id: 'Roboto, sans-serif', provider: 'google' },
];

export const UNSUPPORTED_FONTS = ['ploni', 'almoni', 'almoni neue', 'almoni neue black'];

export const qualityList = [
  { name: '100%', id: 100 },
  { name: '90%', id: 90 },
  { name: '80%', id: 80 },
  { name: '70%', id: 70 },
  { name: '60%', id: 60 },
  { name: '50%', id: 50 },
  { name: '40%', id: 40 },
  { name: '30%', id: 30 },
  { name: '20%', id: 20 },
  { name: '10%', id: 10 },
];

export const fallbackSizesOptions = [
  { name: '300x250', id: '300x250', width: '300', height: '250', default: true },
  { name: '640x480', id: '640x480', width: '640', height: '480', default: false },
  { name: '480x480', id: '480x480', width: '480', height: '480', default: false },
];

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
