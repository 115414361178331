import { noop } from 'lodash';
import { useReducer, useEffect, useState } from 'react';
import ApiRequest from '../../../../../../helpers/ApiRequest';
import { CONFIG, MAILCHIMP_URL, SALESFORCE_URL } from '../../../../../../utils/constants';
import { sanitizeUrl, validateUrl } from '../../../../../../utils/url';

const apiRequest = new ApiRequest(`${CONFIG.webhooksBaseUrl}/publisher`);

const INITIAL_STATE = {
  endPointList: [],
};

const COMMON_TRANSFORM_DATA = {
  name: '$$name',
  phone: '$$phone',
  gender: '$$gender',
  address: '$$address',
  city: '$$city',
  zipcode: '$$zipcode',
  company: '$$company',
  unitname: '$$unitname',
  custom1: '$$custom1',
  custom2: '$$custom2',
  url: '$$url',
  ipAddress: '$$ipAddress',
  userAgent: '$$userAgent',
  created: '$$created',
  externalId: '$$externalId',
  checkbox1: '$$checkbox1',
  checkbox2: '$$checkbox2',
};

const MAILCHIMP_TRANSFORM_DATA = {
  status: 'subscribed',
  email_address: '$$email',
  ...COMMON_TRANSFORM_DATA,
  merge_fields: {
    FNAME: '$$firstName',
    LNAME: '$$lastName',
  },
};

const SALESFORCE_TRANSFORM_DATA = {
  email: '$$email',
  firstName: '$$firstName',
  lastName: '$$lastName',
  company: '$$company',
  phone: '$$phone',
};

const DEFAULT_TRANSFORM_DATA = {
  ...SALESFORCE_TRANSFORM_DATA,
  ...COMMON_TRANSFORM_DATA,
};

const getTransormData = URLendPoint => {
  if (URLendPoint.includes(MAILCHIMP_URL)) {
    return MAILCHIMP_TRANSFORM_DATA;
  }

  if (URLendPoint.includes(SALESFORCE_URL)) {
    return SALESFORCE_TRANSFORM_DATA;
  }

  return DEFAULT_TRANSFORM_DATA;
};

// Action Types
const GET_END_POINT_LIST = 'GET_END_POINT_LIST';
const DELETE_END_POINT_FROM_LIST = 'DELETE_END_POINT_FROM_LIST';
const APPEND_NEW_END_POINT_FROM_LIST = 'APPEND_NEW_END_POINT_FROM_LIST';
const UPDATE_EXISTING_END_POINT = 'UPDATE_EXISTING_END_POINT';

// Action Dispatchers
const setEndPointsList = payload => ({
  type: GET_END_POINT_LIST,
  payload,
});
const deleteEndPoint = endPointId => ({
  type: DELETE_END_POINT_FROM_LIST,
  payload: {
    endPointId,
  },
});
const appendNewEndPoint = newEndPoint => ({
  type: APPEND_NEW_END_POINT_FROM_LIST,
  payload: {
    newEndPoint,
  },
});
const updateExistsEndPoint = (transformData, endPointId) => ({
  type: UPDATE_EXISTING_END_POINT,
  payload: {
    transformData,
    endPointId,
  },
});

// Async Actions
export const fetchEndPointsListAction = dispatch => async publisherId => {
  try {
    const { code, payload: endPointList = [] } = await apiRequest.get(`/${publisherId}/webhooks`);
    if (code === 200) {
      dispatch(setEndPointsList(endPointList));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
  }
};
export const updateEndPointAction = dispatch => async (publisherId, updatedEndPointData, endPointId, { onSuccessCallBack = noop, onErrorCallBack = noop }) => {
  try {
    const {
      transformData, useBasicAuth, basicAuthName, basicAuthPassword, useTokenAuth, tokenAuthToken,
    } = updatedEndPointData;

    const basicAuth = useBasicAuth ? { basicAuthName, basicAuthPassword } : {};
    const tokenAuth = useTokenAuth ? { tokenAuthToken } : {};

    const { code } = await apiRequest.put(`/${publisherId}/webhooks/${endPointId}`, {
      data: {
        transformData,
        ...basicAuth,
        ...tokenAuth,
      },
    });
    if (code === 200) {
      dispatch(updateExistsEndPoint(transformData, endPointId));
      onSuccessCallBack();
    }
  } catch (e) {
    onErrorCallBack(e.message);
  }
};

export const testEndPointAction = dispatch => async (publisherId, endPointId, { onSuccessCallBack = noop, onErrorCallBack = noop }) => {
  try {
    const resp = await apiRequest.get(`/${publisherId}/webhooks/${endPointId}/test`);
    if (resp.payload && resp.payload.status) {
      onSuccessCallBack(resp.payload);
    }
  } catch (e) {
    onErrorCallBack(e);
  }
};
export const createNewEndPointAction = dispatch => async (publisherId, newEndPoint, onErrorCallBack = noop) => {
  try {
    const { code, payload } = await apiRequest.post(`/${publisherId}/webhooks`, {
      data: {
        ...newEndPoint,
      },
    });

    if (code === 201) {
      dispatch(appendNewEndPoint(payload));
    }
  } catch (e) {
    onErrorCallBack(e.message);
  }
};
export const deleteEndPointAction = dispatch => async (publisherId, endPointId, onErrorCallBack = noop) => {
  try {
    const { code } = await apiRequest.delete(`/${publisherId}/webhooks/${endPointId}`);

    if (code === 200) {
      dispatch(deleteEndPoint(endPointId));
    }
  } catch (e) {
    onErrorCallBack(e.message);
  }
};

const endPointReducer = (state, { type, payload }) => {
  switch (type) {
  case GET_END_POINT_LIST:
    return {
      ...state,
      endPointList: payload,
    };
  case DELETE_END_POINT_FROM_LIST:
    return {
      ...state,
      endPointList: state.endPointList.filter(ep => ep._id !== payload.endPointId),
    };
  case APPEND_NEW_END_POINT_FROM_LIST:
    return {
      ...state,
      endPointList: [...state.endPointList, payload.newEndPoint],
    };
  case UPDATE_EXISTING_END_POINT:
    state = {
      ...state,
      endPointList: state.endPointList.map(endPoint => {
        if (endPoint._id === payload.endPointId) {
          return {
            ...endPoint,
            transformData: payload.transformData,
          };
        }
        return endPoint;
      }),
    };
    return state;

  default:
    return state;
  }
};

export default function useEndPoint(publisherId) {
  const [state, dispatch] = useReducer(endPointReducer, INITIAL_STATE);
  const [error, setError] = useState('');
  const { endPointList: endPoints } = state;

  useEffect(() => {
    fetchEndPointsListAction(dispatch)(publisherId);
  }, [publisherId]);

  function addEndPointToList(url) {
    let validURLendPoint;
    let errorMsg = '';
    if (validateUrl(url)) {
      validURLendPoint = sanitizeUrl(url);
    } else {
      errorMsg = errorMsg ? `${errorMsg}, '${url}'` : `Invalid URL: '${url}'`;
    }
    if (errorMsg) {
      setError(errorMsg);
    }
    if (validURLendPoint) {
      setError('');
      createNewEndPointAction(dispatch)(
        publisherId,
        {
          event: 'utility_form_submission',
          url: validURLendPoint,
          transformData: getTransormData(validURLendPoint),
        },
        e => setError(e),
      );
    }
  }
  function removeEndPointFromList(endpointId) {
    deleteEndPointAction(dispatch)(publisherId, endpointId, e => setError(e));
  }
  function updateExistsEndPointOnList(updatedEndPointData, endpointId, onSuccessCallBack, onErrorCallBack) {
    updateEndPointAction(dispatch)(publisherId, updatedEndPointData, endpointId, {
      onSuccessCallBack,
      onErrorCallBack,
    });
  }
  function testWebhookEndPoint(endpointId, onSuccessCallBack, onErrorCallBack) {
    testEndPointAction(dispatch)(publisherId, endpointId, {
      onSuccessCallBack,
      onErrorCallBack,
    });
  }
  return [
    endPoints,
    error,
    {
      addEndPointToList,
      removeEndPointFromList,
      updateExistsEndPointOnList,
      testWebhookEndPoint,
    },
  ];
}
