import _ from 'lodash';
import PaymentsProvider from '../../../../helpers/PaymentsProvider';

const starBlue = require('../../../../theme/images/Star-blue.svg');
const starRed = require('../../../../theme/images/Star-red.svg');
const starGrey = require('../../../../theme/images/Star-gray.svg');
const starYellow = require('../../../../theme/images/Star-yellow.svg');

export const plans = {
  PUBLISHER_PRO: 'publisher pro',
  MARKETER_PRO: 'marketer pro',
  ESSENTIAL: 'essential',
  TRIAL: 'trial',
};

const THIRD_PARTY_PAYING_PLANS = ['wix premium'];
const THIRD_PARTY_TRIAL_PLANS = ['wix'];

export const checkThirdPartyPayingPlan = (subscriptionName=plans.TRIAL) => {
  const lowerCase = subscriptionName.toLowerCase();
  return THIRD_PARTY_PAYING_PLANS.includes(lowerCase);
};

export const getPlanImageOrColor = (planName, getColor = false) => {
  switch (planName?.toLowerCase()) {
  case plans.MARKETER_PRO:
    return getColor ? 'rgba(235, 50, 60)' : starRed;
  case plans.PUBLISHER_PRO:
    return getColor ? 'rgba(100, 50, 255)' : starBlue;
  case plans.ESSENTIAL:
    return getColor ? 'rgba(255, 195, 60)' : starYellow;
  default:
    return getColor ? '#c8c8c8' : starGrey;
  }
};

export const getDefaultPlan = availablePlans => (
  availablePlans?.find(p => p.name.toLowerCase() === plans.TRIAL)
);

export const getChannelSubscriptionName = (channelId, subscription) => (
  subscription?.subscriptionEntities?.[0].entityId === channelId
    ? subscription.billingPlan.productSet.display_name
    : plans.TRIAL
);

export const checkIfChannelHasSubscription = (subscription, channel) => {
  const subscriptionEntity = subscription?.subscriptionEntities?.[0];
  if (!subscriptionEntity) {
    return false;
  }
  const { entityId } = subscriptionEntity;
  const lowerCasePlanName = subscription.billingPlan.productSet.name.toLowerCase();
  const isSelectedChannelSubscription = entityId === channel.publisherId;
  const isTrialSubscription = lowerCasePlanName === plans.TRIAL.toLowerCase() || THIRD_PARTY_TRIAL_PLANS.includes(lowerCasePlanName);
  return isSelectedChannelSubscription && !isTrialSubscription;
};

export const setInternalPlan = async ({
  channel, currentSubscription, plan,
}) => {
  const currentSubscriptionId = checkIfChannelHasSubscription(currentSubscription, channel) ? currentSubscription.id : undefined;

  if (currentSubscriptionId) {
    await PaymentsProvider.cancelSubscription(currentSubscriptionId);
  }

  const subscriptionData = {
    billingPlanId: plan.id,
    entityId: channel.publisherId || channel._id,
    token: '',
    subscriptionId: currentSubscriptionId,
  };

  return PaymentsProvider.postSubscription(subscriptionData);
};

export const checkPayingUserId = (userId, channel) => !_.isEmpty(channel) && ((channel.payingUser && channel.payingUser.userId === userId) || !channel.payingUser);

export const getSubscriptionByChannel = (subscriptions, channel) => subscriptions && subscriptions.find(subscription => subscription.subscriptionEntities[0].entityId === channel.publisherId);

export const getBillingFormOrder = (subscriptions, channel) => [
  {
    field: 'address',
    status: 'full',
  },
  {
    field: 'city',
    status: 'break',
  },
  {
    field: 'country',
    status: 'break',
    displayFieldName: 'State',
  },
  {
    field: 'zip',
    status: 'break',
    displayFieldName: 'Zip code',
  },
  {
    field: 'email',
    status: 'full',
    displayFieldName: 'E-mail',
  },
];
