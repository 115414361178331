import React, { Component } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import _ from 'lodash';
import paymentsValidationFields from '../PaymentsValidation/PaymentsValidation';
import PlanModal from '../Modal/Modal';
import StripeElements from '../PlanElement/StripeElements/StripeElements';
import PlanNameElement from '../PlanElement/PlanTitle/PlanElement';
import { newPlanState, UpdatePaymentsState } from './FormState';
import Modal from '../../../../Modal/Modal';
import PlanFeature from '../Modal/PlanFeatures/PlanFeatures';
import { getBillingFormOrder, plans } from '../payments-util';
import EventTracker, { EVENT_TYPE } from '../../../../../helpers/EventTracker';

import '../CreatePlan/CreatePlan.scss';
import './CheckoutForm.scss';

const ERROR_TYPES = {
  CARD_REJECTED: 'card_rejected',
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      error: true,
      formData: undefined,
      enableFormBtn: false,
      modalToRender: 'terms',
    };

    this.termsStyleEditMode = props.isEditMode ? { justifyContent: 'flex-end', marginBottom: '0' } : {};

    this.biliingFormOrder = getBillingFormOrder();
  }

  componentDidMount() {
    const { isEditMode, selectedChannel, plansProp } = this.props;
    const { formData } = this.state;

    if (!isEditMode) {
      EventTracker.track(EVENT_TYPE.PAYMENT_PAGE_VISITED, { channelId: selectedChannel });
    }

    // Set the correct form data per current form state (new/update).
    const currentState = isEditMode ? UpdatePaymentsState : newPlanState;

    this.setState(({ formData: _.cloneDeep(currentState) }), () => {
      if (!isEditMode && formData && !formData.selectedPlan.value && plansProp) {
        this.handleBillingPlanSelection(plansProp[0]);
      }
    });
  }

  handleChangeField = e => {
    const field = e.target.name;
    const { value } = e.target;
    const { formData } = this.state;
    formData[field].value = value;
    this.setState((formData));
    this.checkIfFormIsValid();
  };

  openModal = modalToRender => {
    const { renderModal } = this.props;
    this.setState(({ modalToRender }));
    renderModal();
  };

  handleBillingPlanSelection = selectedPlan => {
    const { formData } = this.state;
    formData.selectedPlan = {
      type: selectedPlan.paymentInterval,
      price: selectedPlan.price,
      value: selectedPlan.id,
      error: formData.selectedPlan.error,
    };
    this.setState(({ formData }));
  };

  handleTermConfirmation = () => {
    const { formData } = this.state;
    const { modalSuccess } = this.props;
    formData.termsConfirm.value = !formData.termsConfirm.value;
    this.setState((formData));
    modalSuccess();
    this.checkIfFormIsValid();
  };

  createStripeToken = () => {
    const { stripe } = this.props;
    const { formData } = this.state;

    return stripe.createToken({
      type: 'card',
      address_city: formData.city.value,
      address_line1: formData.address.value,
      address_zip: formData.zip.value,
      address_country: formData.country.value,
    });
  };

  // move logic of full and brack into form state.
  formInputs = () => {
    const { formData } = this.state;
    const fullFields = this.biliingFormOrder.filter(o => o.status === 'full').map(result => (
      this.renderFormInputs(result.field, result.status, formData, result.displayFieldName)
    ));

    const breakFields = this.biliingFormOrder.filter(o => o.status === 'break').map(result => (
      this.renderFormInputs(result.field, result.status, formData, result.displayFieldName)
    ));

    return (
      <>
        <StripeElements
          onBlur={e => this.validateField(e.target.name)}
          onChange={e => this.handleChangeField(e)}
        />
        <div className="section-title">
          Billing  Information
        </div>
        <div>
          <div style={{ marginTop: '12px' }}>
            {fullFields[0]}
          </div>
          <div className="group-input-container">
            {breakFields}
          </div>
          <div style={{ marginTop: '25px' }}>
            {fullFields[1]}
          </div>
        </div>
      </>
    );
  };

  calculateDiscount = () => {
    const { plans: plansProp } = this.props;
    const prices = plansProp.map(plan => (
      parseInt(this.calculateDisplayPrice(plan.paymentInterval, plan.price))
    )).sort((a, b) => a - b);

    return Math.abs(Math.round((1 - (prices[0] / prices[1])) * 100));
  };

  calculateDisplayPrice = (paymentInterval, planPrice, showTotalPrice) => {
    let displayPrice = planPrice / 100;

    if (paymentInterval === "year" && !showTotalPrice) {
      displayPrice /= 12;
    }

    return displayPrice.toFixed(0).toLocaleString();
  };

  validateField = field => {
    let { formData } = this.state;
    formData = paymentsValidationFields(formData, field);
    this.setState((formData));
    this.checkIfFormIsValid();
  };

  checkIfFormIsValid = () => {
    const { formData } = this.state;
    const enableFormBtn = !formData || !Object.keys(formData).some(field => formData[field].error.value || !formData[field].value || formData[field].value === '');
    this.setState(({ error: enableFormBtn, enableFormBtn }));
  };

  async submit(e) {
    e.preventDefault();
    const { token, error } = await this.createStripeToken();
    if (error) { // Check if stripe return an error from the webhook
      this.setState(({ error }));
      return;
    }

    this.setState(({ error: undefined }));

    const {
      isEditMode, channel, subscriptionId, onSubmit,
    } = this.props;
    const { formData } = this.state;

    const dataToBeSend = {
      token,
      entityId: channel,
      email: formData.email.value,
      address: formData.address.value,
      country: formData.country.value,
      zip: formData.zip.value,
    };

    if (!isEditMode) {
      dataToBeSend.billingPlanId = formData.selectedPlan.value || '';
    } else { // Data for Update payment
      dataToBeSend.subscriptionId = subscriptionId;
    }

    onSubmit(dataToBeSend);
  }

  // Rendering form elements
  renderFormInputs = (formField, status, formData, displayFieldName = formField) => (
    <div className="input-container" key={formField}>
      <input
        type={formField === 'email' ? 'email' : 'text'}
        name={formField}
        value={formData[formField].value}
        className={`${formData[formField].error.value ? 'input-error' : ''}`}
        onBlur={e => this.validateField(e.target.name)}
        onChange={e => this.handleChangeField(e)}
        required
      />
      <span className={`input-title ${formData[formField].error.value ? 'error-alert' : ''}`}>
        {displayFieldName}
      </span>
    </div>
  );

  renderPlans = () => {
    const { plans: plansProp } = this.props;
    const { formData } = this.state;

    return plansProp && plansProp
      .sort((a, b) => {
        if (a.paymentInterval === "year") {
          return -1;
        }
        return 1;
      })
      .map((plan, index) => {
        const isDefaultChecked = index === 0;

        return (
          <label className="pricing" key={plan.id}>
            <span className="pricing-title">
              <div style={{ width: '60px' }}>
                <span>{`${plan.paymentInterval}ly`}</span>
              </div>
              <div style={plan.paymentInterval === 'year' ? { backgroundColor: '#f7f7f7' } : {}} className="pricing-discount-box">
                <span className="title">
                  {plan.paymentInterval === 'year' ? `Pay once. Save ${this.calculateDiscount()}%` : ''}
                </span>
              </div>
              <span style={{ width: '70px' }}>
                $
                <span style={{ fontWeight: 'bold' }}>{this.calculateDisplayPrice(plan.paymentInterval, plan.price)}</span>
                <span style={{ textTransform: 'none' }}>/month</span>
              </span>
            </span>
            <input
              type="radio"
              name="selectedPlan"
              value={plan.id}
              color="red"
              required
              checked={(formData.selectedPlan && formData.selectedPlan.value === plan.id)}
              onChange={e => {
                this.handleBillingPlanSelection(plansProp.find(p => p.id === e.target.value));
              }}
            />
            <span className="checkmark" />
          </label>
        );
      });
  };

  renderFormTitle = () => {
    const { isEditMode } = this.props;

    return !isEditMode && (
      <div className="form-header">
        <div className="plan-name-container">
          <PlanNameElement subscriptionName={plans.ESSENTIAL} />
        </div>
        <div className="plan-note">
          <span>Limited to 100,000 unit views a month.</span>
          <span className="plan-features" onClick={() => this.openModal()}>View plan features</span>
        </div>
      </div>
    );
  };

  renderBillingPlans = () => {
    const { isEditMode } = this.props;
    const { formData } = this.state;

    return (!isEditMode && formData ? (
      <>
        <div className="pricing-selection">
          <div className="section-title">Choose Billing Type</div>
          {this.renderPlans()}
        </div>
        <div className="section-title">Credit card details</div>
      </>
    )
      : <div className="section-title" style={{ marginTop: '0' }}>Credit card details</div>
    );
  };

  renderTotalSection = () => {
    const { isEditMode } = this.props;
    const { formData } = this.state;

    return !isEditMode && (
      <div className="payment-summary">
        <div className="elem">Total price:</div>
        { formData && formData.selectedPlan.price && (
          <div className="price-total">
            <span>$</span>
            <span style={{ fontWeight: 'bold' }}>{this.calculateDisplayPrice(formData.selectedPlan.type, formData.selectedPlan.price, true)}</span>
          </div>
        )}
      </div>
    );
  };

  renderModal = () => {
    const { modalToRender, formData } = this.state;
    const { closeModal } = this.props;

    const modalHeaderTitleStyle = {
      fontFamily: 'LatoLatin, sans-serif',
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '2px',
      color: '#cacaca',
      textTransform: 'uppercase',
    };

    const modalHeaderStyle = {
      display: 'flex',
      borderBottom: 'solid 1px #eeeeee',
      height: '99%',
      alignItems: 'center',
      justifyContent: 'center',
    };

    if (modalToRender === 'terms') {
      return (
        <Modal
          title="TERMS AND CONDITION"
          width="606"
          height="607"
          onClose={closeModal}
          customModalStyle={{ alignItems: 'flex-start' }}
        >
          <PlanModal
            close={closeModal}
            success={this.handleTermConfirmation}
            termsChecked={formData.termsConfirm.value}
          />
        </Modal>
      );
    }

    return (
      <Modal
        modalHeaderTitleStyle={modalHeaderTitleStyle}
        title="PLAN FEATURES"
        width="583"
        height="652"
        modalHeaderStyle={modalHeaderStyle}
        onClose={closeModal}
      >
        <PlanFeature close={closeModal} />
      </Modal>
    );
  };

  renderTermsCondSection = () => {
    const { isEditMode } = this.props;
    const { formData } = this.state;

    return !isEditMode && (
      <div className="plan-term">
        <label className="terms">
          <input
            required
            type="checkbox"
            name="termsConfirm"
            value={formData.termsConfirm.value}
            onChange={e => this.handleTermConfirmation(e)}
            checked={formData.termsConfirm.value}
          />
          <span className="checkmark-box" />
        </label>
        <span className="terms-title">
          I agree to Apester’s
          <span className="open-terms" onClick={() => this.openModal('terms')}> terms and conditions.</span>
        </span>
      </div>
    );
  };

  renderFormError() {
    const { formError } = this.props;
    if (!formError) {
      return undefined;
    }

    let errorMsg;

    switch (formError) {
    case ERROR_TYPES.CARD_REJECTED: {
      errorMsg = 'You’re credit card was declined. Please try a different payment method or contact';
      break;
    }
    default:
      errorMsg = 'An error occurred. please contact';
    }

    return (
      <div>
        {errorMsg}
        <a href="support@apester.com">support@apester.com</a>
      </div>
    );
  }

  render() {
    const { error, enableFormBtn, formData } = this.state;
    const { isEditMode, showModal } = this.props;

    const renderSubmitBtn = (
      <div className="submit-button">
        <button type="submit" disabled={!enableFormBtn}>
          {isEditMode ? 'Update' : 'Purchase'}
        </button>
      </div>
    );
    const formStyleInEditMode = isEditMode ? { padding: '0 30px' } : {};

    return (
      <form style={formStyleInEditMode} onSubmit={this.submit}>
        {this.renderFormTitle()}
        <div>
          {formData && (
            <>
              {this.renderBillingPlans()}
              {this.formInputs()}
              {this.renderTotalSection()}
            </>
          )}

          <div className="submit-section">
            <div className="form-submission-error">
              {this.renderFormError()}
            </div>
          </div>

          <div style={this.termsStyleEditMode} className="purchase-and-terms-container">
            {formData && (
              <>
                {this.renderTermsCondSection()}
                {renderSubmitBtn}
              </>
            )}
          </div>
        </div>

        {error && error.message && (
          <div className="errors-container">
            <div className="error-stripe">{error ? error.message : ''}</div>
          </div>
        )}

        {showModal && this.renderModal()}
      </form>
    );
  }
}

export default <Elements><CheckoutForm /></Elements>;
