import React, { useEffect, useState } from "react";
import { inject, observer } from 'mobx-react';
import { debounce } from "lodash";
import { Field, renderField } from "../../../../utils/formFieldHelper";
import {
  fixedFlatRateValue, fixedRevenueShareValue, isValidRevShareList, getValidRevShareList, updateCampaignBillingValue,
} from "../../../../utils/utils";
import { BillingTypes } from "../../../../utils/constants";
import Section from "../../../Section/Section";
import Form from "../../../Settings/Form/Form";
import { StoresEnum } from "../../../../stores";

import './AdsBilling.scss';

const BILLING_DEBOUNCE_TIME = 200;
const billingTypes = [
  {
    label: 'Publisher Revenue Share',
    getValFunc: fixedRevenueShareValue,
    type: BillingTypes.revenueShare,
  },
  {
    label: 'Flat Rate',
    getValFunc: fixedFlatRateValue,
    type: BillingTypes.flatRate,
  },
];

const getUpdatedBillingSettings = (
  campaignBillings,
  billingType,
  value,
  parseFloat = false,
) => {
  const newBilling = JSON.parse(JSON.stringify(campaignBillings));
  const index = billingType === BillingTypes.revenueShare ? 0 : 1;
  const valueToUpdate = parseFloat ? Number.parseFloat(value) || 0 : value;

  return updateCampaignBillingValue(index, newBilling, valueToUpdate);
};

const AdsBilling = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const { campaignSettings = {} } = channel;
  const [billingValues, setBillingValues] = useState(campaignSettings.billings || []);

  useEffect(() => {
    if (!isValidRevShareList(campaignSettings) && !readOnly) {
      const campaignBillings = getValidRevShareList(campaignSettings);
      setBillingValues(campaignBillings);
      updateChannel({
        ...channel,
        campaignSettings: {
          ...campaignSettings,
          billings: campaignBillings,
        },
      });
    }
  }, [campaignSettings]);

  const handleSubmit = () => {
    updateChannel({
      ...channel,
      campaignSettings: {
        ...campaignSettings,
        billings: billingValues,
      },
    });
  };

  const handleBillingChange = (billingObj, newValue) => {
    const val = billingObj.getValFunc(newValue);
    setBillingValues(getUpdatedBillingSettings(billingValues, BillingTypes[billingObj.type], val));
  };

  const renderFields = () => (
    <>
      {billingTypes.map((billingObj, index) => (
        <Field
          key={billingObj.type}
          name={`billings[${index}].value`}
          type="text"
          readOnly={readOnly}
          component={renderField}
          label={billingObj.label}
          onChange={debounce(newVal => {
            handleBillingChange(billingObj, newVal);
          }, BILLING_DEBOUNCE_TIME)}
        />
      ))}
    </>
  );

  return (
    <Section title="REV SHARE">
      <Form
        onSubmit={handleSubmit}
        className="ads-rev-share-form"
        fields={renderFields()}
        initialValues={{
          ...campaignSettings,
          billings: billingValues,
        }}
        innerTitle="Video ads and Companion ads"
        hideCancelButton
        alwaysEnableApproveButton
        approveLabel="Apply"
        approveClass="outlined-secondary"
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(AdsBilling));
