import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserProvider from '../../../helpers/UserProvider';
import MembersTable from '../MembersTable/MembersTable';
import { adminMembersColumns } from '../../../utils/constants';
import MemberForm from './MemberForm';
import AddMember from './AddMember';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';

const unsetBulkRegistered = (groupId, members) => (
  UserProvider.removeBulkMembersFromGroup(
    groupId,
    members.map(member => member.emails?.[0] || member.secondaryLabel),
  )
);

const unsetBulkInvited = (members, assignedChannels) => {
  const publisherIds = assignedChannels.map(channel => channel._id);
  const invites = members.map(member => ({ userEmail: member.to || member.emails?.[0], publisherIds }));
  return UserProvider.deleteBulkPublisherInvitations(invites);
};

const setBulkMembers = (groupId, members) => (
  UserProvider.addBulkMembersToGroup(
    groupId,
    members.filter(member => member.isSelected).map(member => member.secondaryLabel),
  )
);

const GroupMembersSection = ({ group, onGroupChange }) => {
  const [shouldDisplayMemberForm, setShouldDisplayMemberForm] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { channels: assignedChannels, members: registeredMembers, invited: invitedMembers } = group || {};

  const unassignMembers = async (registered, invited) => {
    const [unsetRegistered, unsetInvited] = await Promise.all([
      unsetBulkRegistered(group._id, registered),
      unsetBulkInvited(invited, assignedChannels),
    ]);

    if (unsetRegistered.success || unsetInvited.success) {
      setSelectedRows([]);
      onGroupChange(unsetRegistered.success || unsetInvited.success);
    }

    if (unsetRegistered.error || unsetInvited.error) {
      return unsetRegistered.error ? unsetRegistered : unsetInvited;
    }

    return { success: true };
  };

  const toggleNewMember = () => {
    setShouldDisplayMemberForm(prev => !prev);
  };

  const handleClose = async updatedList => {
    if (updatedList?.length) {
      // Removing members before adding new ones (instead of using Promise.all) to avoid conflicts on list changes.
      const unsetMembers = await unsetBulkRegistered(group._id, updatedList.filter(member => !member.isSelected));
      const setMembers = await setBulkMembers(group._id, updatedList);

      if (unsetMembers.success || setMembers.success) {
        onGroupChange(true);
      }

      if (unsetMembers.error || setMembers.error) {
        return unsetMembers.error ? unsetMembers : setMembers;
      }
    }

    return { success: true };
  };

  const onSubmitMember = async ({ selectedChannels, to, displayName }) => {
    const response = await UserProvider.sendBulkInvites(
      selectedChannels.map(publisher => ({
        to,
        displayName,
        publisher: publisher.id,
      })),
      false,
    );

    if (response.success) {
      const message = snackbarContent[snackbarTopics.INVITE_EMAIL_SENT](displayName)?.message;
      onGroupChange(true, message);
      toggleNewMember();
    }

    return response;
  };

  const renderMemberForm = () => (
    <MemberForm
      toggleFormDisplay={toggleNewMember}
      groupChannels={assignedChannels}
      onSubmitMember={onSubmitMember}
      assignTo="group"
      includeChannelField
    />
  );

  return shouldDisplayMemberForm ? renderMemberForm() : (
    <>
      <AddMember
        isEditable
        isSuperAdmin
        disableAddAction={Boolean(selectedRows.length)}
        assignedMembers={registeredMembers}
        handleMembersChange={handleClose}
        newMemberHandler={toggleNewMember}
        assignTo="group"
      />

      <MembersTable
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        registeredMembers={registeredMembers}
        invitedMembers={invitedMembers}
        columns={adminMembersColumns}
        unassignMembers={unassignMembers}
        includeChannelsColumn
        isEditable
        assignTo="group"
      />
    </>
  );
};

GroupMembersSection.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  onGroupChange: PropTypes.func.isRequired,
};

export default GroupMembersSection;
