import React from 'react';

import './Modal.scss';

const Modal = ({
  onClose, width, height, children, title, modalHeaderStyle, modalHeaderTitleStyle, customModalStyle, customModalBodyStyle,
}) => {
  const modalStyle = { width: `${width}px`, height: `${height}px`, ...customModalStyle };
  return (
    <div className="modal-container">
      <div
        className="modal"
        style={modalStyle}
      >
        <div className="modal-header" style={modalHeaderStyle}>
          <div className="title-string" style={modalHeaderTitleStyle}>
            {' '}
            {title}
            {' '}
          </div>
          <button className="close-button" onClick={onClose} type="button">
            <i className="ic icon-cross" />
          </button>
        </div>
        <div className="modal-body" style={customModalBodyStyle}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
