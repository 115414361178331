import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import union from 'lodash/union';
import PropTypes from 'prop-types';
import MultiselectField from '../../ApeUI/SelectField/MultiselectField';
import { getUserFullName, isSelfRemovalException, isSingleChannelException } from '../../../utils/utils';
import UserProvider from '../../../helpers/UserProvider';
import useUsers from '../../../hooks/useUsers';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';
import { StoresEnum } from '../../../stores';

import './ChannelsTable.scss';

const MAX_FIELD_WIDTH = 120;

const setBulkMembers = (channelId, members) => (
  UserProvider.addBulkMembersToPublisher(
    channelId,
    members.filter(member => member.isSelected).map(member => member.secondaryLabel),
  )
);

const unsetBulkMembers = (channelId, members) => (
  UserProvider.removeBulkMembersFromPublisher(
    channelId,
    members.filter(member => !member.isSelected).map(member => member.secondaryLabel),
  )
);

const MembersColumn = ({ channel, groupStore: { fetchGroup, group } }) => {
  const [assignedMembers, setAssignedMembers] = useState();
  const [mappedUsers, setMappedUsers] = useState([]);
  const [snackbarCurrentContent, setSnackbarCurrentContent] = useState();
  const [searchName, setSearchName] = useState('');
  const { users, mapUsersToSelectFormat } = useUsers({ searchName, selectedUsers: assignedMembers });

  const handleError = ({ error }, usersList) => {
    if (isSingleChannelException(error)) {
      const failedUserName = usersList.find(user => user.id === error.payload)?.label;
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SINGLE_CHANNEL_EXCEPTION](failedUserName);
      setSnackbarCurrentContent(content);
    }

    if (isSelfRemovalException(error)) {
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SELF_REMOVAL_EXCEPTION]();
      setSnackbarCurrentContent(content);
    }
  };

  const handleClose = async updatedList => {
    setSearchName('');
    if (!updatedList?.length) {
      return;
    }

    const [removedMembers, addedMembers] = await Promise.all([
      unsetBulkMembers(channel._id, updatedList),
      setBulkMembers(channel._id, updatedList),
    ]);

    if (removedMembers.success || addedMembers.success) {
      fetchGroup(group._id);
    }

    if (removedMembers.error) {
      handleError(removedMembers, updatedList);
    }
  };

  useEffect(() => {
    if (channel) {
      setAssignedMembers(union(channel.editors, channel.admins));
    }
  }, [channel]);

  useEffect(() => {
    if (users?.length) {
      setMappedUsers(mapUsersToSelectFormat());
    }
  }, [users]);

  return (
    <>
      <MultiselectField
        handleClose={handleClose}
        items={mappedUsers}
        onSearchChange={setSearchName}
        savedSelectedItems={assignedMembers?.map(getUserFullName)}
        includeSearchField
        className="select-column-field"
        name="members"
        maxFieldWidth={MAX_FIELD_WIDTH}
      />

      <ApeSnackbar
        open={Boolean(snackbarCurrentContent?.message)}
        message={snackbarCurrentContent?.message}
        onRequestClose={() => setSnackbarCurrentContent()}
        iconType={snackbarCurrentContent?.type}
        className="unassign-member-error"
      />
    </>
  );
};

MembersColumn.propTypes = {
  channel: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    editors: PropTypes.arrayOf(PropTypes.shape({
      userId: PropTypes.string.isRequired,
    })).isRequired,
    admins: PropTypes.arrayOf(PropTypes.shape({
      userId: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

export default inject(StoresEnum.GROUP)(observer(MembersColumn));
