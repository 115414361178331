module.exports = {
  adsetPublicUrl: 'https://adset.apester.local.com',
  analyticsBaseUrl: 'https://analytics.apester.local.com',
  authBaseUrl: 'https://authorization.apester.local.com',
  usersBaseUrl: 'https://users.apester.local.com',
  interactionBaseUrl: 'https://interaction.apester.local.com',
  consoleBaseUrl: 'https://localhost:3015',
  editorBaseUrl: 'https://app.apester.local.com',
  portalUrl: 'https://portal.apester.local.com',
  jobsUrl: 'https://jobs-api.apester.local.com',
  paymentsUrl: 'https://payments.apester.local.com',
  wixIntegrationUrl: 'https://wix-integration.apester.local.com',
  planApiUrl: 'https://plans-api.apester.local.com/',
  eventsBaseUrl: 'https://events.apester.com',
  imagesUrl: '//images.apester.com',
  facebookAppId: '1511127292441041',
  dataroomBaseUrl: 'https://vremini.apester.com/v2/',
  playerBaseUrl: 'https://player.apester.local.com/v2/',
  enableHeap: false,
};
