import React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import './Form.scss';

const Form = ({
  className,
  innerTitle,
  onCancel,
  onSubmit,
  initialValues,
  hasFieldArray,
  fields,
  cancelLabel,
  approveLabel,
  disableApproveButton,
  alwaysEnableApproveButton,
  disableCancelButton,
  hideCancelButton,
  hideApproveButton,
  approveClass,
  cancelClass,
  children,
}) => {
  // Needed because on react-final-form submit method is required.
  const submitForm = async data => (onSubmit ? onSubmit(data) : undefined);

  const renderButtons = ({ pristine, submitting, errors }) => (
    <div className="buttons-wrapper">
      {!hideCancelButton && (
        <Button
          size="medium"
          onClick={onCancel}
          classes={{ label: 'button-label' }}
          className={cancelClass}
          disabled={disableCancelButton}
        >
          {cancelLabel}
        </Button>
      )}
      {!hideApproveButton && (
        <Button
          size="medium"
          type="submit"
          disabled={(pristine || submitting || !!Object.keys(errors).length || disableApproveButton) && !alwaysEnableApproveButton}
          classes={{ label: 'button-label' }}
          className={approveClass}
        >
          {approveLabel}
        </Button>
      )}
    </div>
  );

  return (
    <>
      {innerTitle && <h2 className="form-inner-title">{innerTitle}</h2>}
      <ReactFinalForm onSubmit={submitForm} initialValues={initialValues} {...hasFieldArray && { mutators: arrayMutators }}>
        {props => (
          <form className={`create-form ${className}`} onSubmit={props.handleSubmit}>
            {fields && (
              <div className="fields-grid">
                {fields}
              </div>
            )}
            {children}
            {(!hideApproveButton || !hideCancelButton) && renderButtons(props)}
          </form>
        )}
      </ReactFinalForm>
    </>
  );
};

Form.propTypes = {
  fields: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  approveLabel: PropTypes.string,
  innerTitle: PropTypes.string,
  className: PropTypes.string,
  approveClass: PropTypes.string,
  cancelClass: PropTypes.string,
  hasFieldArray: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  hideApproveButton: PropTypes.bool,
  disableApproveButton: PropTypes.bool,
  // TODO: Remove this prop when cancel functionality will be implemented on all form
  disableCancelButton: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  fields: undefined,
  innerTitle: undefined,
  cancelLabel: 'Cancel',
  approveLabel: 'Update',
  className: '',
  approveClass: 'contained-primary',
  cancelClass: 'outlined-secondary',
  hasFieldArray: false,
  disableApproveButton: false,
  disableCancelButton: false,
  hideCancelButton: false,
  hideApproveButton: false,
  onCancel: undefined,
  onSubmit: undefined,
};

export default Form;
