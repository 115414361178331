import ApiRequest from "./ApiRequest";

const constants = require('../utils/constants');

class WebhooksProvider {
  static request = new ApiRequest(constants.CONFIG.webhooksBaseUrl);

  static getWebhooks = async channelId => (
    WebhooksProvider.request.get(`/${channelId}`)
      .then(res => res.payload)
      .catch(err => console.error(err))
  );
}

export default WebhooksProvider;
