import React from 'react';
import './PopupMoveInteractions.scss';
import Intro from './Intro/Intro';
import Migration from './Migration/Migration';
import InteractionsHelper from '../../../../helpers/InteractionsHelper';

const MAX_COUNTER = 20;

class PopupMoveInteractions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMigration: false,
      counter: 0,
      isFinished: false,
      isError: false,
    };
  }

    moveInteractionsToChannel = async interactionsArr => {
      const { channel, interactions } = this.props;
      const { counter } = this.state;

      let tempCounter = interactionsArr.length;
      if (interactions.count === counter) {
        this.setState({ counter: counter + tempCounter });
        setTimeout(() => {
          this.setState({ isFinished: true });
          this.closePopup();
        }, 1000);
        return;
      } else if (tempCounter > MAX_COUNTER) {
        tempCounter = MAX_COUNTER;
      }
      const isSuccess = await InteractionsHelper.postInteractionToChannel(channel.publisherId, interactionsArr.slice(0, tempCounter));
      if (isSuccess) {
        setTimeout(() => {
          this.setState({ counter: counter + tempCounter });
          this.moveInteractionsToChannel(interactionsArr.slice(tempCounter));
        }, 1000);
      } else {
        this.setState({ isError: true });
        this.closePopup();
      }
    };

    closePopup = () => {
      setTimeout(() => {
        const { ocClose } = this.props;
        ocClose();
      }, 1000);
    };

    showMigration = () => {
      const { interactions } = this.props;
      this.setState({ showMigration: true });
      this.moveInteractionsToChannel(interactions.interactionsIds);
    }

    render = () => {
      const { interactions } = this.props;
      const {
        counter, isFinished, isError, showMigration,
      } = this.state;

      return (
        !showMigration
          ? <Intro props={this.props} showMigration={() => this.showMigration()} />
          : <Migration current={counter} total={interactions.count} finishedMigration={isFinished} errorWithMigration={isError} />
      );
    }
}

export default PopupMoveInteractions;
