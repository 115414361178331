import React from 'react';
import { StripeProvider, Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { CONFIG } from '../../../../../utils/constants';
import './CreatePlan.scss';

class CreateSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      formSubmissionFailed: undefined,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { formSubmissionFailed } = this.state;
    if (nextProps.formError !== formSubmissionFailed) {
      this.setState(({ formSubmissionFailed: nextProps.formError }));
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    const { clearError } = this.props;
    clearError();
  }

  renderModal = () => {
    this.setState(({ showModal: true }));
  };

  closeModal = () => {
    this.setState(({ showModal: false }));
  };

  submitTerms = () => {
    this.closeModal();
  };

  render() {
    const {
      isEditMode = false, plans, subscriptionId, selectedChannel, onSubmit, formError,
    } = this.props;
    const { showModal, formSubmissionFailed } = this.state;
    const font = {
      family: 'LatoLatin',
      src: 'url(https://static.apester.com/fonts/lato/LatoLatin-Regular.ttf)',
      style: 'normal',
      weight: 'normal',
    };

    return (
      <div className={`plan-form-container ${isEditMode ? '' : 'box-shadow'}`}>
        {!isEditMode && (
          <div className="plan-form-title">
            <span className="title">your channel payment</span>
          </div>
        )}

        <StripeProvider apiKey={CONFIG.stripePK}>
          <Elements fonts={[font]}>
            <CheckoutForm
              plans={plans}
              isEditMode={isEditMode}
              subscriptionId={subscriptionId || undefined}
              closeModal={this.closeModal}
              modalSuccess={this.submitTerms}
              showModal={showModal}
              renderModal={this.renderModal}
              channel={selectedChannel}
              onSubmit={onSubmit}
              errorMsg={formSubmissionFailed}
              formError={formError}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

export default CreateSubscription;
