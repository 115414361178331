import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MultiselectField from '../../ApeUI/SelectField/MultiselectField';
import UserProvider from '../../../helpers/UserProvider';
import useChannels from '../../../hooks/useChannels';
import { getUserFullName, isSelfRemovalException, isSingleChannelException } from '../../../utils/utils';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';
import { StoresEnum } from '../../../stores';

import './MembersTable.scss';

const MAX_FIELD_WIDTH = 120;

const sendBulkInvites = (member, publishers) => UserProvider.sendBulkInvites(
  publishers.filter(publisher => publisher.isSelected).map(publisher => ({
    to: member.emails[0],
    publisher: publisher.id,
  })),
);

const unsetBulkPublishers = (member, publishers) => (
  UserProvider.removeUserFromBulkPublishers(
    member.userId,
    publishers.filter(publisher => !publisher.isSelected).map(publisher => publisher.id),
  )
);

const deleteBulkInvites = (member, publishers) => UserProvider.deleteBulkPublisherInvitations([{
  userEmail: member.emails[0],
  publisherIds: publishers.filter(publisher => !publisher.isSelected).map(({ id }) => id),
}]);

const ChannelsColumn = ({
  member, relatedChannels, groupStore: { fetchGroup, group }, isDisabled,
}) => {
  const [snackbarCurrentContent, setSnackbarCurrentContent] = useState();
  const [mappedChannels, setMappedChannels] = useState([]);
  const [searchName, setSearchName] = useState('');
  const { channels, mapChannelsToSelectFormat } = useChannels({ searchName, selectedChannels: relatedChannels });

  const handleError = ({ error }) => {
    if (isSingleChannelException(error)) {
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SINGLE_CHANNEL_EXCEPTION](getUserFullName(member));
      setSnackbarCurrentContent(content);
    }

    if (isSelfRemovalException(error)) {
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SELF_REMOVAL_EXCEPTION]();
      setSnackbarCurrentContent(content);
    }
  };

  const handleClose = async updatedList => {
    setSearchName('');
    if (!updatedList?.length) {
      return;
    }

    // Add new channels before removing channels to avoid 'single channel' exception if no longer relevant.
    const addedChannels = await sendBulkInvites(member, updatedList);
    const removedChannels = !member.isPending
      ? await unsetBulkPublishers(member, updatedList)
      : await deleteBulkInvites(member, updatedList);

    if (removedChannels.success || addedChannels.success) {
      fetchGroup(group._id);
    }

    if (removedChannels.error) {
      handleError(removedChannels);
    }
  };

  useEffect(() => {
    setMappedChannels(mapChannelsToSelectFormat());
  }, [channels]);

  return (
    <>
      <MultiselectField
        handleClose={handleClose}
        items={mappedChannels}
        onSearchChange={setSearchName}
        savedSelectedItems={relatedChannels?.map(({ name }) => name)}
        includeSearchField
        className="select-column-field"
        name="channels"
        maxFieldWidth={MAX_FIELD_WIDTH}
        isDisabled={isDisabled}
      />

      <ApeSnackbar
        open={Boolean(snackbarCurrentContent?.message)}
        message={snackbarCurrentContent?.message}
        onRequestClose={() => setSnackbarCurrentContent()}
        iconType={snackbarCurrentContent?.type}
      />
    </>
  );
};

ChannelsColumn.propTypes = {
  member: PropTypes.shape({
    userId: PropTypes.string.isRequired,
  }).isRequired,
};

export default inject(StoresEnum.GROUP)(observer(ChannelsColumn));
