import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Checkbox from '../../ApeUI/Checkbox/Checkbox';

import './TableActionsMenu.scss';

const TableActionsMenu = ({
  name, selectedItemsNumber, isAllSelected, onSelect, actions, isDisabled,
}) => (
  <div className="table-actions-menu">
    <Checkbox
      onChange={onSelect}
      checked={isAllSelected}
      isDisabled={isDisabled}
      indeterminate={!isAllSelected && selectedItemsNumber > 0}
      inputProps={{ 'aria-label': 'select all' }}
      label={selectedItemsNumber > 0 ? `${selectedItemsNumber} ${name} selected` : 'Select All'}
      labelClass="number-of-selected"
    />
    <Fade in={selectedItemsNumber > 0}>
      <div className="action-items-container">
        {actions.map(action => (
          <Button onClick={action.function} key={action.label} startIcon={action.icon}>{action.label}</Button>
        ))}
      </div>
    </Fade>
  </div>
);

TableActionsMenu.propTypes = {
  name: PropTypes.string.isRequired,
  selectedItemsNumber: PropTypes.number.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    function: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  })).isRequired,
};

export default TableActionsMenu;
