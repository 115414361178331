import React, { Component } from 'react';

import { fixedPhoneNumber } from '../../../../utils/utils';
import { getMFABackupCodes } from '../auth-requests';

import ConfirmPhoneNumber from '../MFAAlert/ConfirmPhoneNumber';
import Modal from '../../../Modal/Modal';

import './BackupOptionsAlert.scss';

const generatedCodeItems = (codes = []) => (
  codes.map(value => (
    <li key={value} className="item-code">
      {value}
    </li>
  ))
);

class BackupOptionsAlert extends Component {
  state = {
    page: 0,
    codes: [],
  };

  handleSetupBackupPhoneNumber = values => {
    const { countryCode, phoneNumber } = values;
    const { userStore: { updateUser }, onClose } = this.props;

    updateUser({
      backupPhone: `+${countryCode.replace(/^\D+/g, '')}${fixedPhoneNumber(
        phoneNumber,
      )}`,
    });
    onClose();
  };

  renderInitialScreen = () => (
    <>
      <div> Set Up Backup Options, In Case You Lose Your Phone </div>
      <div className="submit-container">
        <button
          className="general-button black"
          onClick={() => this.setState({ page: 1 })}
          type="button"
        >
          EDIT BACKUP PHONE NUMBER
        </button>
      </div>
      <div className="submit-container">
        <button
          className="general-button black"
          onClick={() => this.setState({ page: 2 })}
          type="button"
        >
          GENERATE SECURITY CODE
        </button>
      </div>
    </>
  );

  renderGenerateCodesScreen = (userId, codes) => (
    <>
      <div className="description-section">
        <div className="description-title">
          Print or download the codes and save them in a safe place. If you
          lose your phone you will be able to login using one of your backup
          codes
        </div>
      </div>
      <div className="submit-container">
        <button
          className="general-button"
          type="button"
          onClick={() => getMFABackupCodes(userId).then(newCodes => this.setState({ codes: newCodes }))}
        >
          GENERATE NEW CODES
        </button>
      </div>
      <div className="codes-container">
        {(codes.length && (
          <ul className="list-code">{generatedCodeItems(codes)}</ul>
        )) || null}
      </div>
    </>
  );

  render() {
    const { userStore: { user }, onClose } = this.props;

    const { page, codes } = this.state;

    return (
      <Modal
        width={606}
        height={482}
        onClose={onClose}
        title="EDIT BACK UP OPTION"
      >
        {page === 0 && this.renderInitialScreen()}

        {page === 1 && (
          <ConfirmPhoneNumber
            onSubmit={this.handleSetupBackupPhoneNumber}
            isBackup
          />
        )}
        {page === 2 && this.renderGenerateCodesScreen(user.userId, codes)}
      </Modal>
    );
  }
}

export default BackupOptionsAlert;
