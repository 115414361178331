import { contactPublicUrl } from "./constants";

const config = require("./constants").CONFIG;

export default {
  templates: `${config.portalUrl}/templates`,
  brandedCampaigns: `${config.campaignBaseUrl}/#/dashboard?`,
  programaticCampaigns: `${
    config.campaignBaseUrl
  }/#/dashboard?type=programmatic&`,
  editProfile: "/",
  manageChannel: `/channels/`,
  createChannel: `/channels/create`,
  manageGroup: `/groups/`,
  createGroup: `/groups/create`,
  payments: `/channels`,
  ads: `${config.adsetPublicUrl}/ads-txt-crawler`,
  help: "https://help.apester.com?",
  logout: config.portalUrl,
  logoutApi: `${config.usersBaseUrl}/logout`,
  contact: `${contactPublicUrl}?`,
  mainSite: "https://apester.com?",
  chromeExtension:
    "https://chrome.google.com/webstore/detail/the-story-generator/jhdiomfoakbpbicfbljgimnkjgipnooj",
  dashboard: `${config.editorBaseUrl}/dashboard?`,
  analytics: `${config.analyticsBaseUrl}`,
  allAdsets: `${config.adsetPublicUrl}`,
  autoPoll: `${config.editorBaseUrl}/auto-poll?`,
  autoQuiz: `${config.editorBaseUrl}/auto-quiz?`,
};
