import React from 'react';
import { inject, observer } from 'mobx-react';
import { Field, renderField, renderToggle } from '../../../../utils/formFieldHelper';
import Form from '../../../Settings/Form/Form';
import Section from '../../../Section/Section';
import { StoresEnum } from '../../../../stores';

const AutoQuizForm = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const renderFields = () => (
    <>
      <Field name="autoQuiz.siteSection" type="text" component={renderField} label="Target or path domain" readOnly={readOnly} allowEmpty />
      <Field name="autoQuiz.enabled" type="checkbox" component={renderToggle} label="Add Auto-Create Quiz" readOnly={readOnly} />
      <Field name="autoQuiz.viewsThreshold" type="number" component={renderField} label="Auto Creation Threshold" readOnly={readOnly} />
    </>
  );

  return (
    <Section title="INSTANT AUTO QUIZ">
      <Form
        initialValues={channel}
        fields={renderFields()}
        onSubmit={updateChannel}
        hideCancelButton
        hideApproveButton={readOnly}
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(AutoQuizForm));
