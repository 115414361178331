import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import Radio from '../../../../ApeUI/Radio/Radio';
import Checkbox from '../../../../ApeUI/Checkbox/Checkbox';
import WebHookAction from './WebHookAction';
import WebHooListItemCell from './WebHooListItemCell';
import WebHookConnectionTester from "./WebHookConnectionTester";
import { renderField as InputField } from '../../../../../utils/formFieldHelper';

const colors = {
  background: '#FFFFFF',
  string: '#e600ac',
  keys: '#4da6ff',
  colon: '#4da6ff',
};
const style = {
  outerBox: {
    margin: '0 auto 20px',
    height: 'auto',
    width: '100%',
  },
  container: {
    height: '150px',
    width: '100%',
    border: '1px solid #cccccc',
  },
  body: {
    fontSize: '14px',
    fontFamily: 'LatoLatin, sans-serif',
    lineHeight: '16px',
  },
};
const WebHookListItemWrapper = ({
  id,
  url,
  testWebhookEndPoint,
  transformData = {},
  onDeleteClick,
  onApplyClick,
  basicAuthName = '',
  basicAuthPassword = '',
  tokenAuthToken = '',
  readOnly,
}) => {
  const [error, setError] = useState('');

  const [onApplyError, setOnApplyError] = useState('');

  const [open, setOpen] = useState(false);
  const [updatedTransformData, setUpdatedTransformData] = useState(transformData);
  const [authName, setAuthName] = useState(basicAuthName);
  const [authPass, setAuthPass] = useState(basicAuthPassword);
  const [authToken, setAuthToken] = useState(tokenAuthToken);
  const BASIC_AUTH = 'basicAuth';
  const TOKEN_AUTH = 'tokenAuth';
  let initAuth = '';
  if (authName || authPass) {
    initAuth = BASIC_AUTH;
  }
  if (authToken) {
    initAuth = TOKEN_AUTH;
  }
  const [isAuth, setIsAuth] = useState(Boolean(initAuth));
  const [selectedAuth, setSelectedAuth] = useState(initAuth);
  const [applyActive, setApplyActive] = useState(false);
  const [testResult, setTestResult] = useState({ code: null, message: '' });
  const [processing, setProcessing] = useState(false);

  const toggleCollapse = () => {
    setOpen(prev => !prev);
  };

  const removeItem = () => {
    onDeleteClick(id);
  };

  const onJSONInputChange = ({ error: inputError, jsObject }) => {
    if (!inputError) {
      setError('');
      setUpdatedTransformData(jsObject);
      setApplyActive(true);
    }
    setError(inputError);
  };

  const changeIsAuth = event => {
    const { checked } = event.target;
    setIsAuth(checked);
    if (checked) {
      setSelectedAuth(BASIC_AUTH); // Basic auth by default
    } else {
      setSelectedAuth('');
    }
  };

  function onWebhookTestFinished(data) {
    setProcessing(false);
    setApplyActive(true);
    if (data.status >= 200 && data.status < 300) {
      setTestResult({ code: data.status, message: `<b>Connection succeeded.</b> Received ${data.status} from remote server.` });
    } else {
      setTestResult({ code: data.status, message: `<b>Connection failed.</b> Received ${data.status} from remote server.` });
    }
  }

  useEffect(() => {
    setApplyActive(true);
  }, [isAuth, authName, authPass, authToken]);

  const handleApplyClick = () => {
    const useBasicAuth = selectedAuth === BASIC_AUTH;
    const useTokenAuth = selectedAuth === TOKEN_AUTH;
    if (useBasicAuth && (!authPass || !authName)) {
      setOnApplyError('Missing required fields!');
      return;
    }
    if (useTokenAuth && !authToken) {
      setOnApplyError('Missing required token!');
      return;
    }
    setOnApplyError('');

    if (!error) {
      onApplyClick(
        {
          transformData: updatedTransformData,
          basicAuthName: authName,
          basicAuthPassword: authPass,
          tokenAuthToken: authToken,
          useBasicAuth,
          useTokenAuth,
        },
        id,
        setApplyActive(false),
        e => setOnApplyError(e),
      );
    }
  };

  const handleTestClick = () => {
    setProcessing(true);
    setTestResult({ code: null, message: '' });
    setApplyActive(false);
    if (!error) {
      testWebhookEndPoint(
        id,
        onWebhookTestFinished,
      );
    }
  };

  const onSelectedAuthChanged = e => {
    setSelectedAuth(e.target.value);
  };

  return (
    <div className="webhook-list-item-wrapper">
      <WebHooListItemCell url={url} removeItem={removeItem} readOnly={readOnly} />
      <div className="payloadWrap">
        <WebHookAction onClick={toggleCollapse} visible={!open} />
        <Collapse in={open} timeout="auto">
          <div className="collapse-content">
            <JSONInput
              id="a_unique_id"
              placeholder={updatedTransformData}
              theme="light_mitsuketa_tribute"
              colors={colors}
              locale={locale}
              style={style}
              reset={false}
              waitAfterKeyPress={1000}
              onChange={onJSONInputChange}
              viewOnly={readOnly}
            />
            <Checkbox
              formControlClass="auth-checkbox-control"
              checked={isAuth}
              onChange={changeIsAuth}
              label="Add authentication"
            />
            <Collapse in={isAuth} timeout="auto">
              <div className="collapse-content">
                <div className="basic-auth-content">
                  <Radio
                    value={BASIC_AUTH}
                    selected={selectedAuth === BASIC_AUTH}
                    label="Basic authentication"
                    onChange={onSelectedAuthChanged}
                  />
                </div>
                {selectedAuth === BASIC_AUTH && (
                  <div className="inputs-fields">
                    <InputField
                      input={{
                        value: authName,
                        name: 'basicAuthName',
                        onChange: e => {
                          setAuthName(e.target.value);
                        },
                      }}
                      type="text"
                      placeholder="Enter Name"
                      label="Name"
                      readOnly={readOnly}
                      meta={{}}
                    />
                    <InputField
                      input={{
                        value: authPass,
                        name: 'basicAuthPassword',
                        onChange: e => {
                          setAuthPass(e.target.value);
                        },
                      }}
                      type="password"
                      placeholder="Enter Password"
                      label="Password"
                      readOnly={readOnly}
                      meta={{}}
                    />
                  </div>
                )}
                <div className="basic-auth-content" style={style.basicAuth}>
                  <Radio
                    value={TOKEN_AUTH}
                    selected={selectedAuth === TOKEN_AUTH}
                    label="Token authentication"
                    onChange={onSelectedAuthChanged}
                  />
                </div>
                <div className="inputs-fields">
                  {selectedAuth === TOKEN_AUTH && (
                    <InputField
                      input={{
                        value: authToken,
                        name: 'tokenAuthToken',
                        onChange: e => {
                          setAuthToken(e.target.value);
                        },
                      }}
                      type="password"
                      placeholder="Enter Token"
                      label="Token"
                      readOnly={readOnly}
                      meta={{}}
                    />
                  )}
                </div>
              </div>
            </Collapse>
            <div className="url-error">
              {onApplyError}
            </div>
            <WebHookConnectionTester
              testWebhookEndPoint={handleTestClick}
              testResult={testResult}
              processing={processing}
            />
            {!readOnly && (
              <div className="submit-button">
                <Button
                  type="button"
                  className="contained-primary"
                  onClick={handleApplyClick}
                  disabled={!applyActive}
                >
                  Apply
                </Button>
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default WebHookListItemWrapper;
