import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { unionBy } from 'lodash';
import UserProvider from '../helpers/UserProvider';
import { getUserFullName } from '../utils/utils';

const isUserIncludedInSearch = (user, searchName) => (
  user.displayName?.includes(searchName) || user.username?.includes(searchName) || user.emails[0]?.includes(searchName)
);

const useUsers = ({
  from = 0, limit = 100, searchName = '', selectedUsers, enabled = true,
}) => {
  const [users, setUsers] = useState();
  const [fetchedUsers, setFetchedUsers] = useState();

  const transform = async response => (
    response?.payload
      ?.filter(user => !user.emails?.[0]?.includes('apester_bot'))
      .map(user => {
        const trimmedDisplayName = user.displayName?.trim();
        return {
          ...user,
          displayName: trimmedDisplayName?.length ? trimmedDisplayName : user.username,
        };
      }) || []
  );

  const { data } = useQuery({
    queryKey: ['search-users', searchName, from, limit],
    enabled,
    queryFn: () => UserProvider.getUsers({ from, limit, searchName }).then(transform),
  });

  const mapUsersToSelectFormat = () => (
    (users || fetchedUsers)?.map(user => ({
      id: user.userId || user._id,
      label: getUserFullName(user, false),
      secondaryLabel: user.emails[0],
      isSelected: user.isSelected,
    })) || []
  );

  useEffect(() => {
    setFetchedUsers(data);
  }, [data]);

  useEffect(() => {
    if (selectedUsers && fetchedUsers) {
      const additionalUsers = selectedUsers
        .filter(user => isUserIncludedInSearch(user, searchName))
        .map(user => ({ ...user, isSelected: true }));

      const joinedLists = [...additionalUsers, ...fetchedUsers];
      setUsers(unionBy(joinedLists, 'userId'));
    }
  }, [selectedUsers, fetchedUsers]);

  return { users: users || fetchedUsers, mapUsersToSelectFormat };
};

export default useUsers;
