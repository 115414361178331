import React from "react";
import PropTypes from "prop-types";

import "./Avatar.scss";

const imageStyle = (size, isHidden) => ({
  width: `${size}px`,
  height: `${size}px`,
  visibility: isHidden ? "hidden" : "visible",
});

const Avatar = props => {
  const { profileImage, avatarSize } = props;
  const { userAvatarChange } = props;
  return (
    <div
      className={`avatar-wrapper ${userAvatarChange ? "editable" : ""} ${
        !profileImage ? "empty" : ""
      }`}
    >
      <img
        className="picture"
        src={profileImage}
        style={imageStyle(avatarSize, !profileImage)}
        alt="avatar"
      />
      {userAvatarChange && (
        <div className="icon-container" style={imageStyle(avatarSize)}>
          <label className="user-avatar-upload">
            <input
              className="input-file"
              type="file"
              id="file"
              name="avatar"
              accept=".jpg, .jpeg, .png, .gif, .bmp"
              onChange={userAvatarChange}
            />
            <span className="ic icon-plus icon" />
          </label>
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  userAvatarChange: PropTypes.func,
  avatarSize: PropTypes.number,
};

Avatar.defaultProps = {
  profileImage: null,
  userAvatarChange: null,
  avatarSize: null,
};

export default Avatar;
