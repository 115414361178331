import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  TableCell, TableRow, IconButton, Menu, MenuItem,
} from '@mui/material';
import {
  BlockRounded, LinkOffRounded, MoreHorizRounded, RefreshRounded,
} from '@mui/icons-material';
import Checkbox from '../../ApeUI/Checkbox/Checkbox';
import Tooltip from '../../ApeUI/Tooltip/Tooltip';
import { popupTopics, popupContent } from '../../ApeUI/Popup/PopupContent';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';
import PaymentsProvider from '../../../helpers/PaymentsProvider';
import UserProvider from '../../../helpers/UserProvider';
import { getChannelDomains } from '../../../utils/url';
import EllipsisText from '../../EllipsisText/EllipsisText';
import MembersColumn from './MembersColumn';
import PlanColumn from './PlanColumn';
import RevShareColumn from './RevShareColumn';
import { StoresEnum } from '../../../stores';

import './ChannelsTable.scss';

const getDomains = (urls = []) => {
  if (!urls[0]?.length) {
    return '';
  }

  return urls.length === 1 ? urls[0] : `${urls[0]} + ${urls.length - 1} ...`;
};

const ChannelRow = ({
  channel, initialChannelPlan, onSelectItem, isRowSelected, isInitiallyBlockable, refetchIsBlockable, groupId, openPopup, routing: { location },
}) => {
  const { pathname } = location;

  const [channelPlan, setChannelPlan] = useState();
  const [channelDomains, setChannelDomains] = useState('');
  const [domainsList, setDomainsList] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBlockAllow, setIsBlockAllow] = useState(false);
  const [snackbarCurrentContent, setSnackbarCurrentContent] = useState();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasMultipleDomains = domainsList?.length > 1;

  const resetTrial = async () => {
    const newSubscription = channelPlan?.id
      ? await PaymentsProvider.resetTrialSubscription(channelPlan.id)
      : await PaymentsProvider.createTrialPlan(channel._id);

    if (newSubscription) {
      setChannelPlan(newSubscription);
      setIsBlockAllow(true);
    }

    handleClose();
  };

  const unassignChannel = async () => UserProvider.removePublisherFromGroup(groupId, channel._id);

  const blockChannel = async () => {
    const newSubscription = await PaymentsProvider.blockSubscription(channelPlan?.id, channel._id);
    if (newSubscription?.id) {
      setChannelPlan(newSubscription);
      const content = snackbarContent[snackbarTopics.CHANNEL_BLOCKED](channel.name);
      setSnackbarCurrentContent(content);
      setIsBlockAllow(false);
    }
    return newSubscription;
  };

  const openUnassignPopup = () => {
    const currentPopupContent = popupContent[popupTopics.UNASSIGN_CHANNEL](channel.name);
    openPopup(currentPopupContent, () => unassignChannel(channel._id));
    handleClose();
  };

  const openBlockPopup = () => {
    const currentPopupContent = popupContent[popupTopics.BLOCK_CHANNEL](channel.name);
    openPopup(currentPopupContent, () => blockChannel());
    handleClose();
  };

  const renderEllipsisText = text => (
    <EllipsisText text={text} maxWidth="170px" />
  );

  const renderNameColumn = () => (
    <>
      <Link to={`${pathname}/channels/${channel._id}`} className="primary">
        {renderEllipsisText(channel.name)}
      </Link>
      <div className="secondary">
        {hasMultipleDomains ? (
          <Tooltip
            className="table-tooltip"
            placement="top"
            title={domainsList?.map(url => <p key={url} className="tooltip-line">{url}</p>)}
          >
            <div>{channelDomains}</div>
          </Tooltip>
        ) : renderEllipsisText(channelDomains)
        }
      </div>
    </>
  );

  const renderActionsColumn = () => (
    <>
      <IconButton className="more-actions-button" onClick={handleClick}>
        <MoreHorizRounded />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} classes={{ list: 'more-actions-menu' }}>
        {(!channelPlan || channelPlan.billingPlan?.name === 'Trial') && (
          <MenuItem onClick={resetTrial} classes={{ root: 'list-item-button' }}>
            <RefreshRounded />
            Reset trial plan
          </MenuItem>
        )}
        <MenuItem onClick={openUnassignPopup} classes={{ root: 'list-item-button' }}>
          <LinkOffRounded />
          Unassign
        </MenuItem>
        {isBlockAllow && (
          <MenuItem onClick={openBlockPopup} classes={{ root: 'list-item-button' }}>
            <BlockRounded />
            Block
          </MenuItem>
        )}
      </Menu>
    </>
  );

  const onChangePlan = newPlan => {
    setChannelPlan(newPlan);
    refetchIsBlockable();
  };

  useEffect(() => {
    setChannelPlan(initialChannelPlan);
  }, [initialChannelPlan]);

  useEffect(() => {
    if (channel) {
      const domains = getChannelDomains(channel);
      setDomainsList(domains);
      const domainsToDisplay = getDomains(domains);
      setChannelDomains(domainsToDisplay);
    }
  }, [channel]);

  useEffect(() => {
    setIsBlockAllow(isInitiallyBlockable);
  }, [isInitiallyBlockable]);

  return channel ? (
    <TableRow hover className="body-row">
      <TableCell padding="checkbox" classes={{ paddingCheckbox: 'checkbox-column' }}>
        <Checkbox onChange={onSelectItem} value={channel._id} checked={isRowSelected} />
      </TableCell>
      <TableCell className="name-column">
        {renderNameColumn()}
      </TableCell>
      <TableCell>
        <PlanColumn channel={channel} channelPlan={channelPlan} onChangePlan={onChangePlan} />
      </TableCell>
      <TableCell className="rev-share-column">
        <RevShareColumn channel={channel} />
      </TableCell>
      <TableCell>
        <MembersColumn channel={channel} />
      </TableCell>
      <TableCell>
        {renderActionsColumn()}
        <ApeSnackbar
          open={Boolean(snackbarCurrentContent?.message)}
          message={snackbarCurrentContent?.message}
          onRequestClose={() => setSnackbarCurrentContent()}
          iconType={snackbarCurrentContent?.type}
        />
      </TableCell>
    </TableRow>
  ) : null;
};

export default inject(StoresEnum.ROUTING)(observer(ChannelRow));
