import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Breadcrumbs, Fade } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';
import EllipsisText from '../EllipsisText/EllipsisText';
import { StoresEnum } from '../../stores';

import './Breadcrumbs.scss';

const ApeBreadcrumbs = ({ routing: { location }, links, maxItems }) => {
  const { pathname } = location;

  const renderEllipsisText = text => (
    <EllipsisText text={text} maxWidth="130px" />
  );

  return (
    <div className="breadcrumbs-container">
      <Fade in={Boolean(links?.length)}>
        <Breadcrumbs maxItems={maxItems} separator={<ChevronRightRounded fontSize="small" />} aria-label="breadcrumb">
          {links?.map(link => (
            <div key={link.label}>
              {pathname !== link.path
                ? <Link className="link-item" to={link.path}>{renderEllipsisText(link.label)}</Link>
                : <span className="active-link">{renderEllipsisText(link.label)}</span>
              }
            </div>
          ))}
        </Breadcrumbs>
      </Fade>
    </div>
  );
};

export default inject(StoresEnum.ROUTING)(observer(ApeBreadcrumbs));
