import { BillingTypes, CampaignTypes, CONFIG as config } from './constants';

const plans = {
  PUBLISHER_PRO: 'Publisher Pro',
};

export const fileTypes = {
  CSV: 'text/csv;charset=utf-8',
  TEXT: 'text/plain;charset=utf-8',
};

export const createPhotoObject = data => ({
  type: 'cdn',
  path: data,
});

export const defaultImageObject = {
  type: 'cdn',
  path: 'user-images/cd/cd07b389d956a109dbd84a349a95ccac.jpg',
};

export const fixedPhoneNumber = newVal => {
  const re = /^[0-9\b]+$/;
  let val = '';
  if (newVal === '' || re.test(newVal)) {
    val = newVal;
  }
  return val;
};

export const getImageUrl = (imageModel, size = 'original') => {
  const sizeMap = {
    original: {
      width: 0,
      height: 0,
    },
    card: {
      width: 500,
      height: 0,
    },
    madeWithThumb: {
      height: 170,
      width: 0,
    },
  };

  const getCdnUrl = () => `{imagesUrl}/insecure/fit/{width}/{height}/ce/0/plain/{path}`
    .replace('{imagesUrl}', config.imagesUrl)
    .replace('{path}', encodeURIComponent(imageModel.path))
    .replace('{width}', sizeMap[size].width)
    .replace('{height}', sizeMap[size].height);

  const getGiphyUrl = () => {
    let mediaUrl = imageModel[size] || imageModel.fixed_height;
    mediaUrl = encodeURIComponent(mediaUrl.replace(/^https?:\/\//i, ''));

    return '{imagesUrl}/ext_ssl/{mediaUrl}/{width}/{height}/{filter}'
      .replace('{imagesUrl}', config.imagesUrl)
      .replace('{mediaUrl}', mediaUrl)
      .replace('{width}', undefined)
      .replace('{height}', undefined)
      .replace('{filter}', undefined);
  };

  const getUnsplashUrl = () => {
    let mediaUrl = imageModel[size] || imageModel.regular;
    mediaUrl = encodeURIComponent(mediaUrl.replace(/^https?:\/\//i, ''));

    return '{imagesUrl}/ext_ssl/{mediaUrl}/{width}/{height}/{filter}'
      .replace('{imagesUrl}', config.imagesUrl)
      .replace('{mediaUrl}', mediaUrl)
      .replace('{width}', sizeMap[size].width)
      .replace('{height}', sizeMap[size].height)
      .replace('{filter}', imageModel.filter || undefined);
  };

  const getFacebookUrl = () => {
    if (!imageModel || !imageModel.accountId) {
      return '';
    }
    return `https://graph.facebook.com/v2.3/${
      imageModel.accountId
    }/picture?type=small`;
  };

  const getYoutubeUrl = () => 'https://img.youtube.com/vi/{id}/hqdefault.jpg'.replace(
    '{id}',
    imageModel.id,
  );

  const getGoogleUrl = () => {
    if (imageModel && imageModel.path) {
      return imageModel.path;
    }
    return imageModel;
  };

  const getUrlByImageType = {
    cdn: getCdnUrl,
    giphy: getGiphyUrl,
    unsplash: getUnsplashUrl,
    youtube: getYoutubeUrl,
    facebook: getFacebookUrl,
    google: getGoogleUrl,
  };

  if (!imageModel) {
    return '';
  }

  return (
    getUrlByImageType[imageModel.type]
    && getUrlByImageType[imageModel.type]()
  );
};

/**
 * hash array of objects by object property.
 * default by index
 *
 * @param {array} arr
 * @param {string} prop
 * @returns hashed array
 */
export const hash = (arr, prop) => {
  const result = {};
  if (arr && arr.length) {
    arr.forEach((item, i) => {
      const hashKey = item[prop] || i;
      result[hashKey] = item;
    });
  }
  return result;
};

/**
 * Utility function that helps extracting safely props
 * out of actio payload.
 * @param {*} propname
 */
export function extractFromActionPayload(action, propname) {
  const payload = { ...action.payload } || {};
  return payload[propname];
}

export function redirect(url) {
  window.location.assign(url);
}

export const copyToClipboard = toCopy => {
  if (toCopy) {
    const textField = document.createElement('textarea');
    textField.value = toCopy;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
};

export const downloadFile = (csv, fileName, type) => {
  const csvBlob = new Blob([csv], { type });
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(csvBlob));
  link.setAttribute('download', fileName);
  link.click();
};

export const isAdmin = user => user?.roles?.includes('admin') ?? false;

export const getUserFullName = (user, allowEmailAsName = true) => {
  if (!user) {
    return '';
  }

  const trimmedDisplayName = user.displayName?.trim();

  return user.firstName || user.lastName
    ? `${user.firstName || ''} ${user.lastName || ''}`
    : (trimmedDisplayName?.length && trimmedDisplayName) || user.username || (allowEmailAsName && user.emails?.[0]) || '';
};

export const hasPublisherProPlan = (subscriptions, channelIds) => (
  subscriptions.some(sub => (
    sub?.billingPlan?.productSet?.name === plans.PUBLISHER_PRO && channelIds.includes(sub.subscriptionEntities[0].entityId)
  ))
);

export const executeRequest = async (type, url, params) => {
  const response = await fetch(url, {
    method: type,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response.json();
};

const handleBulkRestrictionsResponse = (ids, payload) => {
  if (!payload) {
    return ids.reduce((acc, id) => ({ ...acc, [id]: false }), {});
  }

  return Object.keys(payload).reduce((acc, id) => ({ ...acc, [id]: payload[id].allowed }), {});
};

export const getAuthRestriction = async (channelId, feature) => {
  const requestUrl = `${config.authBaseUrl}/channels/${feature}`;
  const isBulk = Array.isArray(channelId);

  try {
    const response = await executeRequest('POST', requestUrl, { channelId });
    const { payload } = response;

    if (isBulk) {
      return handleBulkRestrictionsResponse(channelId, payload);
    }

    if (!payload) {
      return false;
    }

    const isFeatureAllowed = !JSON.stringify(payload).includes('"allowed":false');

    return isFeatureAllowed;
  } catch (e) {
    return isBulk ? handleBulkRestrictionsResponse(channelId) : false; // Fallback block, when an error occurred in auth service.
  }
};

export const getCookie = cname => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const getResponseJson = async url => {
  try {
    const engineRequest = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    });
    const json = await engineRequest.json();
    return json;
  } catch (e) {
    console.log(e);
    return {};
  }
};

const createRouter = async () => {
  const json = await getResponseJson(`${config.interactionBaseUrl}/engine`);

  const layouts = await getResponseJson(`${config.interactionBaseUrl}/engine/${json.payload?.[0].engineId}/layout`);

  const routings = layouts.payload?.reduce((acc, layout) => {
    const layoutLavel = layout.name.toLowerCase();
    switch (layoutLavel) {
    case 'video trivia':
      acc.videoquiz = layout.layoutId;
      return acc;
    case 'multi trivia two':
      acc.quiz = layout.layoutId;
      return acc;
    case 'multi poll two':
      acc.poll = layout.layoutId;
      return acc;
    case 'personality quiz two':
      acc.personality = layout.layoutId;
      return acc;
    case 'contest poll':
      acc.countdown = layout.layoutId;
      return acc;
    default:
      acc[layout.name.toLowerCase().replace(/[\s|-]/g, '')] = layout.layoutId;
      return acc;
    }
  }, {});

  return routings;
};
const routerPromise = createRouter();
export const getHeaderRouter = async (menuOption, sessionId) => {
  const router = await routerPromise;

  return `${config.editorBaseUrl}/editor/new?layoutId=${
    router[menuOption.toLowerCase()]
  }&sId=${sessionId}`;
};

export const executeFunctionWithDelay = async (minTime, func) => {
  const started = Date.now();

  const funcResponse = await func();
  const elapsed = Date.now() - started;
  const delayFurther = minTime - elapsed;
  if (delayFurther > 0) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise(r => setTimeout(r, delayFurther));
  }

  return funcResponse;
};

/**
 * To overcome bugs and inconsistencies with campaigns billing format we should check if it match the expectations.
 */
export const isValidRevShareList = (campaignSettings = {}) => {
  const { billings = [] } = campaignSettings;
  const revenueItems = billings.filter(billing => billing.billingType === BillingTypes.revenueShare);
  const flatItems = billings.filter(billing => billing.billingType === BillingTypes.flatRate);
  const notProgrammatic = billings.filter(billing => billing.campaignType !== CampaignTypes.programmatic);
  return (
    billings.length === 2
    && revenueItems.length === 1
    && flatItems.length === 1
    && billings[0].billingType === BillingTypes.revenueShare
    && !notProgrammatic.length
  );
};

/**
 * Adjust and return valid campaign billing list
 */
export const getValidRevShareList = (campaignSettings = {}) => {
  const { billings = [] } = campaignSettings;
  return [
    {
      campaignType: CampaignTypes.programmatic,
      billingType: BillingTypes.revenueShare,
      value: billings.find(billing => billing.billingType === BillingTypes.revenueShare && billing.value > 0)?.value || 0,
    },
    {
      campaignType: CampaignTypes.programmatic,
      billingType: BillingTypes.flatRate,
      value: billings.find(billing => billing.billingType === BillingTypes.flatRate && billing.value > 0)?.value || 0,
    },
  ];
};

export const updateCampaignBillingValue = (index, billing, newValue) => ([
  {
    ...billing[0],
    value: index === 0 ? newValue : 0,
  },
  {
    ...billing[1],
    value: index === 1 ? newValue : 0,
  },
]);

export const fixedRevenueShareValue = newVal => {
  const re = /^[0-9\b]+$/;
  const MIN_REVENUE_SHARE_VAL = 0;
  const MAX_REVENUE_SHARE_VAL = 100;
  let val = "";
  if (!newVal || re.test(newVal)) {
    if (newVal < MIN_REVENUE_SHARE_VAL) {
      val = MIN_REVENUE_SHARE_VAL;
    } else if (newVal > MAX_REVENUE_SHARE_VAL) {
      val = fixedRevenueShareValue(newVal.substring(0, newVal.length - 1));
    } else {
      val = newVal;
    }
  }
  return val;
};

export const fixedFlatRateValue = newVal => {
  const re = /^\d*\.?\d*$/;
  let val = "";
  if (!newVal || re.test(newVal)) {
    val = newVal;
  } else {
    val = fixedFlatRateValue(newVal.substring(0, newVal.length - 1));
  }
  return val;
};

export const isSingleChannelException = error => error?.message?.includes('has only one channel');

export const isSelfRemovalException = error => error?.message?.includes('Cant remove yourself');

export const isAlreadyExistException = error => error?.message?.includes('already exist');

export const isChannelAdmin = (user, channel) => (
  channel?.admins?.some(channelUser => (channelUser.userId || channelUser._id) === user.userId)
);
