const popupTopics = {
  PLAN_CHANGE: 'plan_change',
  UNASSIGN_CHANNELS: 'unassign_channels',
  UNASSIGN_CHANNEL: 'unassign_channel',
  UNASSIGN_MEMBER: 'unassign_member',
  UNASSIGN_MEMBERS: 'unassign_members',
  BLOCK_CHANNEL: 'block_channel',
  CHANNEL_ALREADY_ASSIGNED: 'channel_already_assigned',
  DELETED_GROUP: 'delete_group',
};

const popupContent = {
  [popupTopics.PLAN_CHANGE]: ({ channelName, currentPlanName, newPlanName }) => ({
    title: 'Change channel plan',
    description: `The plan of the ${channelName} is ${currentPlanName}. Do you want to change it to <b>${newPlanName}</b>?`,
    approve: 'YES',
    cancel: 'NO',
  }),
  [popupTopics.UNASSIGN_CHANNELS]: channelsNumber => ({
    title: 'Unassign channels',
    description: `You are about to unassign <b>${channelsNumber} Channels</b> from the group.`,
    approve: 'Unassign',
    cancel: 'Cancel',
  }),
  [popupTopics.UNASSIGN_CHANNEL]: channelName => ({
    title: 'Unassign channel',
    description: `You are about to unassign <b>${channelName}</b> channel from the group.`,
    approve: 'Unassign',
    cancel: 'Cancel',
  }),
  [popupTopics.UNASSIGN_MEMBERS]: (membersNumber, assignTo = 'group') => ({
    title: 'Unassign members',
    description: `You are about to unassign <b>${membersNumber} Members</b> from the ${assignTo}.`,
    approve: 'Unassign',
    cancel: 'Cancel',
  }),
  [popupTopics.UNASSIGN_MEMBER]: (memberName, assignTo = 'group') => ({
    title: 'Unassign member',
    description: `You are about to unassign <b>${memberName}</b> member from the ${assignTo}.`,
    approve: 'Unassign',
    cancel: 'Cancel',
  }),
  [popupTopics.BLOCK_CHANNEL]: channelName => ({
    title: 'Block channel',
    description: `You are about to block <b>${channelName}</b> channel. Once blocked users will not be able to publish interactions via this channel.`,
    approve: 'Block',
    cancel: 'Cancel',
  }),
  [popupTopics.CHANNEL_ALREADY_ASSIGNED]: channelName => ({
    title: `${channelName} is already assigned`,
    description: `In order to change the group, go to the <b>${channelName}</b> channel page and select the desired group.`,
    approve: 'Go to channel page',
    cancel: 'Cancel',
  }),
  [popupTopics.DELETED_GROUP]: groupName => ({
    title: 'Delete group',
    description: `You are about to delete the ${groupName} group, Once deleted all related channels will be unassigned from this group. You can re-assign them, selecting other one.`,
    approve: 'Delete',
    cancel: 'Cancel',
  }),
};

export { popupTopics, popupContent };
