const fallbackImage = {
  type: 'IMAGE',
  transforms: {
    radians: 0,
  },
  styles: {
    'background-size': 'cover',
  },
  data: {
    publicUrl: 'https://static.apester.com/strip/fallback-image.png',
    path: '',
    type: 'cdn',
  },
  id: 'no_id',
};

const buildImageBackgroundFromVideo = videoId => {
  const defaultImageProps = {
    type: 'IMAGE',
    data: {
      path: videoId,
      type: 'youtubeVideo',
    },
    backgroundPosition: '50% 50%',
    isBackground: true,
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    zIndex: 'initial',
    radians: '0',
    position: 'absolute',
  };

  return defaultImageProps;
};

const extractImageElement = interaction => {
  let selectedElement;

  // Get the slides of the selected interaction (append cover slide if exists)
  const slides = interaction.data.cover ? [interaction.data.cover, ...interaction.data.slides] : interaction.data.slides;

  for (const s of slides) {
    const { elements: slideElements } = s.data;
    selectedElement = slideElements.find(elem => elem.isBackground || elem.type === 'IMAGE' || elem.type === 'VIDEO');

    if (selectedElement) {
      // Element found, break loop
      break;
    }
  }

  // Fallback to default if no appropriate element was found
  selectedElement = selectedElement || fallbackImage;
  if (selectedElement.styles) {
    selectedElement.styles['background-size'] = 'cover';
  }
  // Set image to youtube frame in 0 sec if element type is video
  if (selectedElement.type === 'VIDEO') {
    const youtubeId = selectedElement.data.provider.videoId;
    selectedElement = buildImageBackgroundFromVideo(youtubeId);
    selectedElement.data.publicUrl = `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
  }

  return {
    ...selectedElement,
    // Ovreride styling from interaction slide element
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 0,
  };
};

export default extractImageElement;
