import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import EllipsisText from '../../EllipsisText/EllipsisText';
import SingleSelectDropdown from '../MultiselectDropdown/SingleSelectDropdown';

import './SelectField.scss';

const SelectField = ({
  list,
  activeItem,
  onSelect,
  size,
  className,
  label,
  placeholder,
  includeSearch,
  name,
  popoverClass,
  searchLabel,
  fetchList,
  alignDropdownToFieldWidth,
  maxFieldWidth,
  readOnly,
}) => {
  const [selected, setSelected] = useState();
  const [anchorEl, setAnchorEl] = useState();

  const isOpen = Boolean(anchorEl);

  const renderExpandIcon = () => (isOpen ? <ExpandLessRounded className="custom-select-icon" /> : <ExpandMoreRounded className="custom-select-icon" />);

  const getSelected = () => {
    if (typeof activeItem === 'undefined') {
      return undefined;
    }

    return typeof activeItem === 'string' || typeof activeItem === 'number'
      ? list.find(item => item.id === activeItem || item.name === activeItem)
      : activeItem;
  };

  useEffect(() => {
    setSelected(getSelected());
  }, [activeItem]);

  return (
    <div className={`custom-select single-select ${className} ${size}`}>
      <div className={`custom-select-field ${readOnly ? 'disabled' : ''}`} onClick={event => setAnchorEl(event.currentTarget)}>
        <EllipsisText className={`current-value ${placeholder && !selected ? 'empty' : ''}`} text={selected?.name || placeholder} maxWidth={maxFieldWidth || '100%'} />
        <input name={name} value={selected?.id || ''} readOnly />
        {!readOnly && renderExpandIcon()}
      </div>
      {label && <span className="custom-select-label">{label}</span>}

      {!readOnly && (
        <SingleSelectDropdown
          isOpen={isOpen}
          anchorEl={anchorEl}
          popoverClass={popoverClass}
          alignDropdownToFieldWidth={alignDropdownToFieldWidth}
          onClose={val => {
            if (val !== undefined) {
              onSelect(val);
            }

            setAnchorEl(null);
          }}
          list={list}
          includeSearch={includeSearch}
          fetchList={fetchList}
          searchLabel={searchLabel || name}
          selectedId={selected?.id}
          name={name}
          size={size}
        />
      )}
    </div>
  );
};

SelectField.propTypes = {
  className: PropTypes.string,
  includeSearch: PropTypes.bool,
  alignDropdownToFieldWidth: PropTypes.bool,
  popoverClass: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  size: PropTypes.string,
  maxFieldWidth: PropTypes.string,
  placeholder: PropTypes.string,
  activeItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ]),
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
  })),
};

SelectField.defaultProps = {
  size: 'small',
  className: '',
  popoverClass: '',
  name: '',
  list: [],
  activeItem: undefined,
  maxFieldWidth: undefined,
  placeholder: undefined,
  includeSearch: false,
  alignDropdownToFieldWidth: false,
};

export default SelectField;
