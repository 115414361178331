import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { DeleteOutlineRounded } from '@mui/icons-material';
import Tooltip from '../../../ApeUI/Tooltip/Tooltip';
import { Field, renderField as InputField, renderField } from '../../../../utils/formFieldHelper';
import { hexColor, required, composeValidators } from '../../../../utils/validation';
import { isColor } from './utils';

import './ChannelColors.scss';

const ColorInput = ({
  addColor,
  removeColor,
  label,
  value,
  readOnly,
  includeDeleteButton,
  includeSubmitButton,
  useFormField,
  name,
  submitLabel = 'Apply',
  clearOnSubmit = false,
}) => {
  const [color, setColor] = useState(value);
  const [isColorValid, setIsColorValid] = useState(true);

  useEffect(() => {
    setColor(value);
  }, [value]);

  const onChange = payload => {
    const newVal = typeof payload === 'string' ? payload : payload.target.value;
    setColor(newVal);
    setIsColorValid(isColor(newVal));
  };

  const onAddColor = () => {
    if (clearOnSubmit) {
      setColor('');
    }

    addColor(color);
  };

  const commonFieldProps = {
    name,
    readOnly,
    label,
    useLabelAsPlaceholder: false,
    prefix: '#',
  };

  return (
    <div className={`color-input-wrapper ${includeDeleteButton || includeSubmitButton ? 'actionable' : ''}`}>
      {useFormField ? (
        <Field
          {...commonFieldProps}
          onChange={onChange}
          component={renderField}
          validate={composeValidators(hexColor, required)}
        />
      ) : (
        <InputField
          {...commonFieldProps}
          input={{ value: color || '', onChange, name }}
          meta={{ touched: color !== value, error: !!color && hexColor(color) }}
        />
      )}

      <div
        className="color-preview"
        style={{ background: color && isColorValid ? `#${color}` : '', borderStyle: color?.length && isColorValid ? 'solid' : 'dashed' }}
      />
      {includeSubmitButton && (
        <Button
          type="button"
          className="outlined-secondary"
          onClick={onAddColor}
          disabled={!isColorValid || !color?.length || (value && color === value)}
        >
          {submitLabel}
        </Button>
      )}
      {includeDeleteButton && (
        <span>
          <Tooltip title="Delete" placement="top">
            <IconButton onClick={removeColor} className="delete-button"><DeleteOutlineRounded /></IconButton>
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default ColorInput;
