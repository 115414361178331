import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ApiRequest from '../../../../../helpers/ApiRequest';
import { CONFIG } from '../../../../../utils/constants';
import Flat from '../../../../ApeUI/Button/Flat/Flat';
import { copyToClipboard } from '../../../../../utils/utils';
import ApeSnackbar from '../../../../ApeUI/ApeSnackbar/ApeSnackbar';
import { StoresEnum } from '../../../../../stores';

const apiRequest = new ApiRequest(`${CONFIG.usersBaseUrl}`);

const ApiKeyContainer = ({ channelStore: { channel } }) => {
  const [decodedKey, setDecodedKey] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { publisherId } = channel;

  useEffect(() => {
    async function fetchData() {
      const result = await apiRequest.get(`/publisher/${publisherId}/key`);
      setDecodedKey(result.key);
    }
    fetchData();
  }, []);

  const generateKey = async () => {
    const generateConfirmMsg = 'Do you want to generate new API Key?\n(All secure APIs with old Key will not work)';
    /* eslint-disable no-alert */
    if (window.confirm(generateConfirmMsg)) {
      const data = await apiRequest.put(`/publisher/${publisherId}/key`);
      setDecodedKey(data.key);
    }
  };

  const onCopy = () => {
    copyToClipboard(decodedKey);
    setIsSnackbarOpen(true);
  };

  return (
    <div className="section">
      <div className="form-title">API Key</div>
      <div className="api-key-section">
        <Flat title="Generate" onClick={generateKey} />
        <div className="api-key">
          <span>{decodedKey}</span>
          <span
            className="ic icon-text-l copy-icon"
            onClick={onCopy}
          />
          <ApeSnackbar open={isSnackbarOpen} message="Copied!" onRequestClose={() => setIsSnackbarOpen(false)} />
        </div>
      </div>
    </div>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(ApiKeyContainer));
