import React from 'react';
import { init } from '@apester/image';
import { Route, Switch } from 'react-router-dom';
import RoutingService from '../../helpers/RoutingService';

import Settings from '../Settings/Settings';
import { CONFIG } from '../../utils/constants';

/** styles */
import './App.scss';

init(CONFIG.imagesUrl, CONFIG.staticBucket);

const App = () => (
  <div className="app-container">
    <Switch>
      <Route path={RoutingService.routes.settings.path} render={props => <Settings {...props} />} />
    </Switch>
  </div>
);

export default App;
