import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import routes from '../../../../utils/header.routes';
import { Field, renderSelect } from '../../../../utils/formFieldHelper';
import Popup from '../../../ApeUI/Popup/Popup';
import { popupContent, popupTopics } from '../../../ApeUI/Popup/PopupContent';
import { snackbarContent, snackbarTopics } from '../../../ApeUI/ApeSnackbar/SnackbarContent';
import UserProvider from "../../../../helpers/UserProvider";
import { StoresEnum } from '../../../../stores';

import './delete-group-popup.scss';

const REDIRECT_DELAY = 1000;

const DeleteGroupPopup = observer(({
  userStore: { sessionId }, shouldOpen, setShouldOpen, group, onGroupChange, routing,
}) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [groups, setGroups] = useState();
  const { _id: groupId } = group;

  const fetchGroups = async search => {
    const response = await UserProvider.getGroups({ search });
    const { payload } = response || [];

    return payload?.map(({ _id: id, name }) => ({ id, name })).filter(({ id }) => id !== groupId);
  };

  useEffect(() => {
    const getGroups = async () => {
      const groupsRes = await fetchGroups();
      setGroups(groupsRes);
    };
    if (groupId) {
      getGroups();
    }
  }, [groupId]);

  const handleDeleteGroup = async () => {
    const { name } = group;
    const response = await UserProvider.inactivateGroup(groupId, selectedGroup);

    if (response?.success) {
      setShouldOpen(false);
      const message = snackbarContent[snackbarTopics.GROUP_INACTIVATED](name)?.message;
      onGroupChange(true, message, response);
      setTimeout(() => {
        if (selectedGroup) {
          routing.push(`/groups/${selectedGroup}`);
        } else {
          window.location.href = `${routes.dashboard}?sId=${sessionId}`;
        }
      }, REDIRECT_DELAY);
    } else {
      const message = snackbarContent[snackbarTopics.GROUP_INACTIVATED_EXCEPTION](name)?.message;
      onGroupChange(false, message, response);
    }
  };

  return (
    <Popup
      open={shouldOpen}
      isAlert
      popupContent={popupContent[popupTopics.DELETED_GROUP](group?.name)}
      onCancel={() => setShouldOpen(false)}
      onApprove={handleDeleteGroup}
    >
      <div className="group-wrapper">
        <Field
          name="groupId"
          label=""
          placeholder="Pick a group"
          includeSearch
          searchLabel="group"
          component={renderSelect(groups, fetchGroups)}
          onChange={newValue => setSelectedGroup(newValue)}
        />
      </div>
    </Popup>
  );
});

export default inject(StoresEnum.USER, StoresEnum.ROUTING)(DeleteGroupPopup);
