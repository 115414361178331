import React from "react";
import './StoryItem.scss';
import { imageAdapter, Image } from '@apester/image';

const StoryItem = props => {
  const {
    id, textItem, onClick, image, readOnly,
  } = props;

  return (
    <div className={`story-item ${readOnly ? 'disabled' : ''}`} key={id}>
      <div className="ring-wrapper">
        <div className={`image-round ${image ? "solid" : "dashed"}`}>
          <div className="image-item-wrapper">
            <div className="image-item" onClick={onClick}>
              {!image
                ? <div className="item-no-image" />
                : (
                  <span className="item-image">
                    <span className="ic icon-edit-pencil" />
                    <Image className="image" shouldAnimateBackground={false} imageElement={imageAdapter(image)} />
                  </span>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {textItem && <div className={`text-item ${image && "selected"}`}>{textItem}</div>}
    </div>
  );
};

export default StoryItem;
