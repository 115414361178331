import React, { useState } from 'react';
import {
  TableCell, TableRow, IconButton, Menu, MenuItem,
} from '@mui/material';
import { MoreHorizRounded, LinkOffRounded, MailOutline } from '@mui/icons-material';
import Checkbox from '../../ApeUI/Checkbox/Checkbox';
import { getUserFullName } from '../../../utils/utils';
import EllipsisText from '../../EllipsisText/EllipsisText';
import ChannelsColumn from './ChannelsColumn';

import './MembersTable.scss';

const MemberRow = ({
  member,
  onSelectItem,
  isRowSelected,
  openUnassignPopup,
  resendEmail,
  displayPermission,
  includeChannelsColumn,
  onChangePermission,
  isEditable,
  disableActions,
  relatedChannels,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onResendClick = () => {
    handleClose();
    resendEmail(member);
  };

  const onUnassignClick = () => {
    handleClose();
    openUnassignPopup(member);
  };

  const renderEllipsisText = text => (
    <EllipsisText text={text} maxWidth="170px" />
  );

  const renderActionsColumn = () => (
    <>
      <IconButton className="more-actions-button" onClick={handleClick} disabled={!isEditable || disableActions}>
        <MoreHorizRounded />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} classes={{ list: 'more-actions-menu' }}>
        <MenuItem onClick={onUnassignClick} classes={{ root: 'list-item-button' }}>
          <LinkOffRounded />
          Unassign
        </MenuItem>
        {member.isPending && resendEmail && (
          <MenuItem onClick={onResendClick} classes={{ root: 'list-item-button' }}>
            <MailOutline />
            Resend invitation
          </MenuItem>
        )}
      </Menu>
    </>
  );

  return member ? (
    <TableRow hover className="body-row">
      {isEditable && (
        <TableCell padding="checkbox" classes={{ paddingCheckbox: 'checkbox-column' }} width="5%">
          <Checkbox onChange={onSelectItem} value={member.userId} checked={isRowSelected} />
        </TableCell>
      )}
      <TableCell className="name-column" width="40%">
        <div className="primary">{renderEllipsisText(getUserFullName(member, false))}</div>
        <div className="secondary">
          {renderEllipsisText(member.emails[0] || member.email)}
        </div>
      </TableCell>
      <TableCell width="25%">
        {member.isPending && resendEmail ? (
          <div className="resend-button" onClick={() => resendEmail(member)}>Resend email</div>
        ) : (
          <div className={`status-label ${member.isPending ? 'pending' : 'registered'}`}>
            {member.isPending ? 'Pending' : 'Registered'}
          </div>
        )}
      </TableCell>
      {includeChannelsColumn && (
        <TableCell width="25%">
          <ChannelsColumn member={member} isDisabled={disableActions} relatedChannels={relatedChannels} />
        </TableCell>
      )}
      {displayPermission && (
        <TableCell width="25%">
          {!member.isPending && (
            <Checkbox
              onChange={() => onChangePermission(member)}
              isDisabled={!isEditable || disableActions}
              checked={Boolean(member.isAdmin)}
              label="Admin"
              labelClass="role-label"
            />
          )}
        </TableCell>
      )}
      <TableCell width="5%">
        {renderActionsColumn()}
      </TableCell>
    </TableRow>
  ) : null;
};

export default MemberRow;
