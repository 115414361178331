import React from 'react';
import './SubscriptionView.scss';
import PaymentModal from '../../Modal/PaymentsModal/PaymentsModal';
import { CONFIG } from '../../../../../../utils/constants';

const successImg = require('../../../../../../theme/images/For-Dev.gif');
const incompleteIcon = require('../../../../../../theme/images/payment-incomplete.svg');

class SubscriptionView extends React.Component {
  state = {
    isPaymentMethodOpen: false,
    showBottomButton: true,
  };

  componentDidMount() {
    this.handle3d();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { status } = this.props;
    if (nextProps.status !== status) {
      this.setState(({ isPaymentMethodOpen: false }));
      return true;
    }
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  async handle3d() {
    const { subscription = {}, on3dRes } = this.props;
    if (subscription.status === 'incomplete') {
      const res = await window.Stripe(CONFIG.stripePK).handleCardPayment(subscription.clientSecret);
      on3dRes(res);
    }
  }

  goToLocation = () => {
    const {
      goTo, navigate, redirectToInteraction, selectedChannel,
    } = this.props;
    if (goTo === 'dashboard') {
      const win = window.open(`${CONFIG.editorBaseUrl}/dashboard?tab=media`, '_blank');
      win.focus();
    }
    if (redirectToInteraction) {
      window.location.href = `${CONFIG.editorBaseUrl}/editor/${redirectToInteraction}?isJourney=false&m=false`;
    }
    navigate(`/channels/${selectedChannel}`);
  };

  changePaymentMethodBoxStatus = () => {
    const { isPaymentMethodOpen } = this.state;
    const currentStatus = isPaymentMethodOpen;
    this.setState(({ isPaymentMethodOpen: !currentStatus, showBottomButton: false }));
  };

  displayImage() {
    const { status } = this.props;
    let src; let
      className;
    if (status === 'active') {
      src = successImg;
      className = "gif-container";
    } else {
      src = incompleteIcon;
      className = "image-container";
    }

    return (
      <div className={className}>
        <img src={src} alt="" />
      </div>
    );
  }

  createContentByStatus() {
    const { status } = this.props;

    switch (status) {
    case 'incomplete': {
      return {
        header: 'email payment confirmation',
        title: 'Hi, you’re almost there!',
        content:
  <span>
    You’re almost there. To complete your purchase please check your email
    <br />
    and confirm your payment.
  </span>,
      };
    }
    case 'active': {
      return {
        header: 'Payment completed',
        title: 'Your plan is on!',
        content:
  <span>
    you will receive a confirmation email within a few minutes,
    if you do not receive any confirmation,please email us at
    <span>
      {' '}
      <a href="mailto:support@apester.com">support@apester.com</a>
    </span>
  </span>,
      };
    }
    case 'past_due': {
      return {
        header: 'Update payment required',
        title: 'Your payment is past due, please enter a valid credit card.',
        content: '',
      };
    }
    case 'requires_update': {
      return {
        header: 'Update payment required',
        title: 'Your Payment method was declined.',
        content: 'please enter a valid credit card',
      };
    }
    default: {
      return {};
    }
    }
  }

  renderBottomButton() {
    const { status } = this.props;
    let onClick;
    let title;
    if (status === 'active') {
      onClick = () => this.goToLocation();
      title = 'done';
    } else {
      onClick = () => this.changePaymentMethodBoxStatus();
      title = 'Update';
    }

    return (
      <button
        type="button"
        className="btn-done"
        onClick={() => onClick()}
      >
        {title}
      </button>
    );
  }

  render() {
    const titleStyle = {};
    const { status, subscription, onSubmit } = this.props;
    const { showBottomButton, isPaymentMethodOpen } = this.state;

    if (status === 'active') {
      titleStyle.marginLeft = '8px';
    } else if (status === 'past_due') {
      titleStyle.fontWeight = 'normal';
    }

    const { header, title, content } = this.createContentByStatus();

    return (
      <div className="payments-box">
        <div className="payments-box-header">
          <span>
            {header}
          </span>
        </div>
        <div className="data-container">
          <div className="content">
            <div className="title">
              <div style={{ display: 'flex' }}>
                {status === 'active'
                  && <div className="success-icon" />}
                <span style={titleStyle}>{title}</span>
              </div>
              <div className="description">
                <div className="description-text">
                  <span>
                    {content}
                  </span>
                </div>
              </div>
            </div>
            <div className="btn-and-image-container">
              {status !== 'past_due'
                && (
                  <>
                    {this.displayImage()}
                  </>
                )}
              <div className="btn-container">
                {status !== 'incomplete' && showBottomButton
                  && (
                    <>
                      {this.renderBottomButton()}
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
        {isPaymentMethodOpen
          && (
            <PaymentModal
              close={this.changePaymentMethodBoxStatus}
              subscriptionId={subscription.id}
              onSubmit={onSubmit}
            />
          )}
      </div>
    );
  }
}

export default SubscriptionView;
