import React from 'react';

export const UncheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" fill="white" />
    <circle cx="8" cy="8" r="7.5" stroke="#1E1E37" strokeOpacity="0.9" />
  </svg>
);

export const CheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="6" stroke="#EB323C" strokeWidth="4" />
  </svg>
);
