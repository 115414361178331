import React from 'react';
import './ConfirmationModal.scss';

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  componentDidUpdate() {
    const { error } = this.state;
    const { errorAlert } = this.props;
    if (error !== errorAlert) {
      this.setState(({ error: errorAlert }));
    }
  }

  render() {
    const {
      close,
      children,
      title,
      subTitle,
      currentPlan,
      confirmBtnClick,
      confirmText,
      cancelText,
    } = this.props;
    const { error } = this.state;
    return (
      <div className="confirm-modal-container">
        <div onClick={close} />
        {children}
        <div className="confirm-modal-text">
          <div>
            {' '}
            {title}
            {' '}
          </div>
          <div className="sub-title">{subTitle}</div>
        </div>
        <div className="modal-actions">
          <div
            className="confirm"
            onClick={e => confirmBtnClick(currentPlan, e)}
          >
            <span>{confirmText}</span>
          </div>
          <div
            className="cancel"
            onClick={close}
          >
            <span>{cancelText}</span>
          </div>
        </div>
        {
          error && error
        }

      </div>
    );
  }
}

export default ConfirmationModal;
