import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import { Check, Close } from '@mui/icons-material';

import './ApeSnackbar.scss';

export const snackbarType = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
};

const iconProps = {
  style: {
    width: '20px',
    height: '20px',
  },
};

const ApeSnackbar = ({
  open, message, autoHideDuration = 5000, onRequestClose, iconType, includeCloseButton, className,
}) => {
  const renderIcon = () => {
    switch (iconType) {
    case snackbarType.LOADING:
      return (
        <CircularProgress
          mode="indeterminate"
          size={20}
          thickness={2}
          className="icon"
        />
      );
    case snackbarType.SUCCESS:
      return <Check {...iconProps} />;
    case snackbarType.ERROR:
      return <i className="ic icon-warning" />;
    default:
      return null;
    }
  };

  return (
    <Snackbar
      open={open}
      message={(
        <div className="message">
          {renderIcon(iconType)}
          <span dangerouslySetInnerHTML={{ __html: message }} />
          {includeCloseButton ? (
            <div className="close-button">
              <div className="vertical-separator" />
              <Close onClick={onRequestClose} {...iconProps} />
            </div>
          ) : null}
        </div>
      )}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      onClose={onRequestClose}
      className={`ape-snack-bar ${className} ${open ? 'open' : ''}`}
      TransitionComponent={Slide}
    />
  );
};

export default ApeSnackbar;
