import React, { useState } from 'react';
import LinkOffRounded from '@mui/icons-material/LinkOffRounded';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Fade,
} from '@mui/material';
import Popup from '../../ApeUI/Popup/Popup';
import UserProvider from '../../../helpers/UserProvider';
import useChannelsPlans from '../../../hooks/useChannelsPlans';
import { popupContent, popupTopics } from '../../ApeUI/Popup/PopupContent';
import TableActionsMenu from '../TableActionsMenu/TableActionsMenu';
import { channelsColumns } from '../../../utils/constants';
import ChannelRow from './ChannelRow';

import './ChannelsTable.scss';

const ChannelsTable = ({
  channels, selectedItems, setSelectedItems, onGroupChange, groupId,
}) => {
  const [popupDescription, setPopupDescription] = useState();
  const [popupCTA, setPopupCTA] = useState();
  const channelIds = channels?.map(channel => channel._id) || [];
  const { plansDictionary, blockableDictionary, refetchIsBlockable } = useChannelsPlans({ channelIds });

  const isAllSelected = selectedItems?.length === channels?.length;
  const isNoneSelected = !selectedItems?.length;

  const onSelectItem = ({ target }) => {
    if (!target.value?.length) {
      setSelectedItems(target.checked && isNoneSelected ? channels?.map(channel => channel._id) : []);
      return;
    }

    if (target.checked) {
      setSelectedItems([...selectedItems, target.value]);
    } else {
      setSelectedItems(selectedItems.filter(item => item !== target.value));
    }
  };

  const onSelectAll = ({
    target,
  }) => setSelectedItems(target.checked && isNoneSelected ? channels?.map(channel => channel._id) : []);

  const openPopup = (content, approveFunction) => {
    setPopupDescription(content);
    setPopupCTA(() => approveFunction);
  };

  const onApprovePopup = async () => {
    const response = await popupCTA();
    if (response) {
      onGroupChange(response.success);
      setPopupDescription();
    }
  };

  const openUnassignPopup = () => {
    const currentPopupContent = popupContent[popupTopics.UNASSIGN_CHANNELS](selectedItems.length);
    openPopup(currentPopupContent, () => (
      UserProvider.removeBulkPublishersFromGroup(groupId, selectedItems)
        .then(response => {
          setSelectedItems([]);
          return response;
        })
    ));
  };

  return (
    <Fade in={Boolean(channels?.length)}>
      <div className="table-wrapper">
        <TableActionsMenu
          name="channels"
          selectedItemsNumber={selectedItems.length}
          isAllSelected={isAllSelected}
          onSelect={onSelectAll}
          actions={[{ label: 'Unassign', icon: <LinkOffRounded />, function: openUnassignPopup }]}
        />
        <TableContainer padding="none" className="table">
          <Table padding="none" stickyHeader>
            <TableHead>
              <TableRow className="header-row">
                <TableCell />
                {channelsColumns.map(column => (
                  <TableCell className={column.field === 'revShare' ? 'rev-share-header-column' : ''} key={column.field}>{column.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {channels?.map(row => (
                <ChannelRow
                  key={row._id}
                  channel={row}
                  onSelectItem={onSelectItem}
                  isRowSelected={selectedItems.includes(row._id)}
                  isInitiallyBlockable={blockableDictionary?.[row._id]}
                  initialChannelPlan={plansDictionary?.[row._id]}
                  refetchIsBlockable={refetchIsBlockable}
                  openPopup={openPopup}
                  groupId={groupId}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Popup
          open={Boolean(popupDescription && popupCTA)}
          isAlert
          popupContent={popupDescription}
          onCancel={() => setPopupDescription()}
          onApprove={onApprovePopup}
        />
      </div>
    </Fade>
  );
};

export default ChannelsTable;
