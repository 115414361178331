/* exported CountriesService */
class CountriesService {
  // Array of country objects for the flag dropdown.
  // Each contains a name, country code (ISO 3166-1 alpha-2) and dial code.
  // Originally from https://github.com/mledoze/countries

  // So each country array has the following information:
  // [
  //    Country name,
  //    iso2 code,
  //    International dial code,
  //    Order (if >1 country with same dial code),
  //    Area codes (if >1 country with same dial code)
  // ]
  static countries = [{
    name: "Afghanistan (‫افغانستان‬‎)  +93",
    countryCode: "af",
    dialCode: "93",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Albania (Shqipëri)  +355",
    countryCode: "al",
    dialCode: "355",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Algeria (‫الجزائر‬‎)  +213",
    countryCode: "dz",
    dialCode: "213",
    priority: 0,
    areaCodes: null,
  }, {
    name: "American Samoa  +1684",
    countryCode: "as",
    dialCode: "1684",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Andorra  +376",
    countryCode: "ad",
    dialCode: "376",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Angola  +244",
    countryCode: "ao",
    dialCode: "244",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Anguilla  +1264",
    countryCode: "ai",
    dialCode: "1264",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Antigua and Barbuda  +1268",
    countryCode: "ag",
    dialCode: "1268",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Argentina  +54",
    countryCode: "ar",
    dialCode: "54",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Armenia (Հայաստան)  +374",
    countryCode: "am",
    dialCode: "374",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Aruba  +297",
    countryCode: "aw",
    dialCode: "297",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Australia  +61",
    countryCode: "au",
    dialCode: "61",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Austria (Österreich)  +43",
    countryCode: "at",
    dialCode: "43",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Azerbaijan (Azərbaycan)  +994",
    countryCode: "az",
    dialCode: "994",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bahamas  +1242",
    countryCode: "bs",
    dialCode: "1242",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bahrain (‫البحرين‬‎)  +973",
    countryCode: "bh",
    dialCode: "973",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bangladesh (বাংলাদেশ)  +880",
    countryCode: "bd",
    dialCode: "880",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Barbados  +1246",
    countryCode: "bb",
    dialCode: "1246",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Belarus (Беларусь)  +375",
    countryCode: "by",
    dialCode: "375",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Belgium (België)  +32",
    countryCode: "be",
    dialCode: "32",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Belize  +501",
    countryCode: "bz",
    dialCode: "501",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Benin (Bénin)  +229",
    countryCode: "bj",
    dialCode: "229",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bermuda  +1441",
    countryCode: "bm",
    dialCode: "1441",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bhutan (འབྲུག)  +975",
    countryCode: "bt",
    dialCode: "975",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bolivia  +591",
    countryCode: "bo",
    dialCode: "591",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)  +387",
    countryCode: "ba",
    dialCode: "387",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Botswana  +267",
    countryCode: "bw",
    dialCode: "267",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Brazil (Brasil)  +55",
    countryCode: "br",
    dialCode: "55",
    priority: 0,
    areaCodes: null,
  }, {
    name: "British Indian Ocean Territory  +246",
    countryCode: "io",
    dialCode: "246",
    priority: 0,
    areaCodes: null,
  }, {
    name: "British Virgin Islands  +1284",
    countryCode: "vg",
    dialCode: "1284",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Brunei  +673",
    countryCode: "bn",
    dialCode: "673",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Bulgaria (България)  +359",
    countryCode: "bg",
    dialCode: "359",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Burkina Faso  +226",
    countryCode: "bf",
    dialCode: "226",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Burundi (Uburundi)  +257",
    countryCode: "bi",
    dialCode: "257",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Cambodia (កម្ពុជា)  +855",
    countryCode: "kh",
    dialCode: "855",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Cameroon (Cameroun)  +237",
    countryCode: "cm",
    dialCode: "237",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Canada  +1",
    countryCode: "ca",
    dialCode: "1",
    priority: 1,
    areaCodes: ["204", "226", "236", "249", "250", "289", "306", "343", "365", "387", "403", "416", "418", "431", "437", "438", "450", "506", "514", "519", "548", "579", "581", "587", "604", "613", "639", "647", "672", "705", "709", "742", "778", "780", "782", "807", "819", "825", "867", "873", "902", "905"],
  }, {
    name: "Cape Verde (Kabu Verdi)  +238",
    countryCode: "cv",
    dialCode: "238",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Caribbean Netherlands  +599",
    countryCode: "bq",
    dialCode: "599",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Cayman Islands  +1345",
    countryCode: "ky",
    dialCode: "1345",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Central African Republic (République centrafricaine)  +236",
    countryCode: "cf",
    dialCode: "236",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Chad (Tchad)  +235",
    countryCode: "td",
    dialCode: "235",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Chile  +56",
    countryCode: "cl",
    dialCode: "56",
    priority: 0,
    areaCodes: null,
  }, {
    name: "China (中国)  +86",
    countryCode: "cn",
    dialCode: "86",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Christmas Island  +61",
    countryCode: "cx",
    dialCode: "61",
    priority: 2,
    areaCodes: null,
  }, {
    name: "Cocos (Keeling) Islands  +61",
    countryCode: "cc",
    dialCode: "61",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Colombia  +57",
    countryCode: "co",
    dialCode: "57",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Comoros (‫جزر القمر‬‎)  +269",
    countryCode: "km",
    dialCode: "269",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)  +243",
    countryCode: "cd",
    dialCode: "243",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Congo (Republic) (Congo-Brazzaville)  +242",
    countryCode: "cg",
    dialCode: "242",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Cook Islands  +682",
    countryCode: "ck",
    dialCode: "682",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Costa Rica  +506",
    countryCode: "cr",
    dialCode: "506",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Côte d’Ivoire  +225",
    countryCode: "ci",
    dialCode: "225",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Croatia (Hrvatska)  +385",
    countryCode: "hr",
    dialCode: "385",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Cuba  +53",
    countryCode: "cu",
    dialCode: "53",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Curaçao  +599",
    countryCode: "cw",
    dialCode: "599",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Cyprus (Κύπρος)  +357",
    countryCode: "cy",
    dialCode: "357",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Czech Republic (Česká republika)  +420",
    countryCode: "cz",
    dialCode: "420",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Denmark (Danmark)  +45",
    countryCode: "dk",
    dialCode: "45",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Djibouti  +253",
    countryCode: "dj",
    dialCode: "253",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Dominica  +1767",
    countryCode: "dm",
    dialCode: "1767",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Dominican Republic (República Dominicana)  +1",
    countryCode: "do",
    dialCode: "1",
    priority: 2,
    areaCodes: ["809", "829", "849"],
  }, {
    name: "Ecuador  +593",
    countryCode: "ec",
    dialCode: "593",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Egypt (‫مصر‬‎)  +20",
    countryCode: "eg",
    dialCode: "20",
    priority: 0,
    areaCodes: null,
  }, {
    name: "El Salvador  +503",
    countryCode: "sv",
    dialCode: "503",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Equatorial Guinea (Guinea Ecuatorial)  +240",
    countryCode: "gq",
    dialCode: "240",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Eritrea  +291",
    countryCode: "er",
    dialCode: "291",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Estonia (Eesti)  +372",
    countryCode: "ee",
    dialCode: "372",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Ethiopia  +251",
    countryCode: "et",
    dialCode: "251",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Falkland Islands (Islas Malvinas)  +500",
    countryCode: "fk",
    dialCode: "500",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Faroe Islands (Føroyar)  +298",
    countryCode: "fo",
    dialCode: "298",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Fiji  +679",
    countryCode: "fj",
    dialCode: "679",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Finland (Suomi)  +358",
    countryCode: "fi",
    dialCode: "358",
    priority: 0,
    areaCodes: null,
  }, {
    name: "France  +33",
    countryCode: "fr",
    dialCode: "33",
    priority: 0,
    areaCodes: null,
  }, {
    name: "French Guiana (Guyane française)  +594",
    countryCode: "gf",
    dialCode: "594",
    priority: 0,
    areaCodes: null,
  }, {
    name: "French Polynesia (Polynésie française)  +689",
    countryCode: "pf",
    dialCode: "689",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Gabon  +241",
    countryCode: "ga",
    dialCode: "241",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Gambia  +220",
    countryCode: "gm",
    dialCode: "220",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Georgia (საქართველო)  +995",
    countryCode: "ge",
    dialCode: "995",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Germany (Deutschland)  +49",
    countryCode: "de",
    dialCode: "49",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Ghana (Gaana)  +233",
    countryCode: "gh",
    dialCode: "233",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Gibraltar  +350",
    countryCode: "gi",
    dialCode: "350",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Greece (Ελλάδα)  +30",
    countryCode: "gr",
    dialCode: "30",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Greenland (Kalaallit Nunaat)  +299",
    countryCode: "gl",
    dialCode: "299",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Grenada  +1473",
    countryCode: "gd",
    dialCode: "1473",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Guadeloupe  +590",
    countryCode: "gp",
    dialCode: "590",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Guam  +1671",
    countryCode: "gu",
    dialCode: "1671",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Guatemala  +502",
    countryCode: "gt",
    dialCode: "502",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Guernsey  +44",
    countryCode: "gg",
    dialCode: "44",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Guinea (Guinée)  +224",
    countryCode: "gn",
    dialCode: "224",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Guinea-Bissau (Guiné Bissau)  +245",
    countryCode: "gw",
    dialCode: "245",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Guyana  +592",
    countryCode: "gy",
    dialCode: "592",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Haiti  +509",
    countryCode: "ht",
    dialCode: "509",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Honduras  +504",
    countryCode: "hn",
    dialCode: "504",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Hong Kong (香港)  +852",
    countryCode: "hk",
    dialCode: "852",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Hungary (Magyarország)  +36",
    countryCode: "hu",
    dialCode: "36",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Iceland (Ísland)  +354",
    countryCode: "is",
    dialCode: "354",
    priority: 0,
    areaCodes: null,
  }, {
    name: "India (भारत)  +91",
    countryCode: "in",
    dialCode: "91",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Indonesia  +62",
    countryCode: "id",
    dialCode: "62",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Iran (‫ایران‬‎)  +98",
    countryCode: "ir",
    dialCode: "98",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Iraq (‫العراق‬‎)  +964",
    countryCode: "iq",
    dialCode: "964",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Ireland  +353",
    countryCode: "ie",
    dialCode: "353",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Isle of Man  +44",
    countryCode: "im",
    dialCode: "44",
    priority: 2,
    areaCodes: null,
  }, {
    name: "Israel (‫ישראל‬‎)  +972",
    countryCode: "il",
    dialCode: "972",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Italy (Italia)  +39",
    countryCode: "it",
    dialCode: "39",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Jamaica  +1876",
    countryCode: "jm",
    dialCode: "1876",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Japan (日本)  +81",
    countryCode: "jp",
    dialCode: "81",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Jersey  +44",
    countryCode: "je",
    dialCode: "44",
    priority: 3,
    areaCodes: null,
  }, {
    name: "Jordan (‫الأردن‬‎)  +962",
    countryCode: "jo",
    dialCode: "962",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Kazakhstan (Казахстан)  +7",
    countryCode: "kz",
    dialCode: "7",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Kenya  +254",
    countryCode: "ke",
    dialCode: "254",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Kiribati  +686",
    countryCode: "ki",
    dialCode: "686",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Kuwait (‫الكويت‬‎)  +965",
    countryCode: "kw",
    dialCode: "965",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Kyrgyzstan (Кыргызстан)  +996",
    countryCode: "kg",
    dialCode: "996",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Laos (ລາວ)  +856",
    countryCode: "la",
    dialCode: "856",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Latvia (Latvija)  +371",
    countryCode: "lv",
    dialCode: "371",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Lebanon (‫لبنان‬‎)  +961",
    countryCode: "lb",
    dialCode: "961",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Lesotho  +266",
    countryCode: "ls",
    dialCode: "266",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Liberia  +231",
    countryCode: "lr",
    dialCode: "231",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Libya (‫ليبيا‬‎)  +218",
    countryCode: "ly",
    dialCode: "218",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Liechtenstein  +423",
    countryCode: "li",
    dialCode: "423",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Lithuania (Lietuva)  +370",
    countryCode: "lt",
    dialCode: "370",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Luxembourg  +352",
    countryCode: "lu",
    dialCode: "352",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Macau (澳門)  +853",
    countryCode: "mo",
    dialCode: "853",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Macedonia (FYROM) (Македонија)  +389",
    countryCode: "mk",
    dialCode: "389",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Madagascar (Madagasikara)  +261",
    countryCode: "mg",
    dialCode: "261",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Malawi  +265",
    countryCode: "mw",
    dialCode: "265",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Malaysia  +60",
    countryCode: "my",
    dialCode: "60",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Maldives  +960",
    countryCode: "mv",
    dialCode: "960",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Mali  +223",
    countryCode: "ml",
    dialCode: "223",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Malta  +356",
    countryCode: "mt",
    dialCode: "356",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Marshall Islands  +692",
    countryCode: "mh",
    dialCode: "692",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Martinique  +596",
    countryCode: "mq",
    dialCode: "596",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Mauritania (‫موريتانيا‬‎)  +222",
    countryCode: "mr",
    dialCode: "222",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Mauritius (Moris)  +230",
    countryCode: "mu",
    dialCode: "230",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Mayotte  +262",
    countryCode: "yt",
    dialCode: "262",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Mexico (México)  +52",
    countryCode: "mx",
    dialCode: "52",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Micronesia  +691",
    countryCode: "fm",
    dialCode: "691",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Moldova (Republica Moldova)  +373",
    countryCode: "md",
    dialCode: "373",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Monaco  +377",
    countryCode: "mc",
    dialCode: "377",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Mongolia (Монгол)  +976",
    countryCode: "mn",
    dialCode: "976",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Montenegro (Crna Gora)  +382",
    countryCode: "me",
    dialCode: "382",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Montserrat  +1664",
    countryCode: "ms",
    dialCode: "1664",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Morocco (‫المغرب‬‎)  +212",
    countryCode: "ma",
    dialCode: "212",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Mozambique (Moçambique)  +258",
    countryCode: "mz",
    dialCode: "258",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Myanmar (Burma) (မြန်မာ)  +95",
    countryCode: "mm",
    dialCode: "95",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Namibia (Namibië)  +264",
    countryCode: "na",
    dialCode: "264",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Nauru  +674",
    countryCode: "nr",
    dialCode: "674",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Nepal (नेपाल)  +977",
    countryCode: "np",
    dialCode: "977",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Netherlands (Nederland)  +31",
    countryCode: "nl",
    dialCode: "31",
    priority: 0,
    areaCodes: null,
  }, {
    name: "New Caledonia (Nouvelle-Calédonie)  +687",
    countryCode: "nc",
    dialCode: "687",
    priority: 0,
    areaCodes: null,
  }, {
    name: "New Zealand  +64",
    countryCode: "nz",
    dialCode: "64",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Nicaragua  +505",
    countryCode: "ni",
    dialCode: "505",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Niger (Nijar)  +227",
    countryCode: "ne",
    dialCode: "227",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Nigeria  +234",
    countryCode: "ng",
    dialCode: "234",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Niue  +683",
    countryCode: "nu",
    dialCode: "683",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Norfolk Island  +672",
    countryCode: "nf",
    dialCode: "672",
    priority: 0,
    areaCodes: null,
  }, {
    name: "North Korea (조선 민주주의 인민 공화국)  +850",
    countryCode: "kp",
    dialCode: "850",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Northern Mariana Islands  +1670",
    countryCode: "mp",
    dialCode: "1670",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Norway (Norge)  +47",
    countryCode: "no",
    dialCode: "47",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Oman (‫عُمان‬‎)  +968",
    countryCode: "om",
    dialCode: "968",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Pakistan (‫پاکستان‬‎)  +92",
    countryCode: "pk",
    dialCode: "92",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Palau  +680",
    countryCode: "pw",
    dialCode: "680",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Palestine (‫فلسطين‬‎)  +970",
    countryCode: "ps",
    dialCode: "970",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Panama (Panamá)  +507",
    countryCode: "pa",
    dialCode: "507",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Papua New Guinea  +675",
    countryCode: "pg",
    dialCode: "675",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Paraguay  +595",
    countryCode: "py",
    dialCode: "595",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Peru (Perú)  +51",
    countryCode: "pe",
    dialCode: "51",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Philippines  +63",
    countryCode: "ph",
    dialCode: "63",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Poland (Polska)  +48",
    countryCode: "pl",
    dialCode: "48",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Portugal  +351",
    countryCode: "pt",
    dialCode: "351",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Puerto Rico  +1",
    countryCode: "pr",
    dialCode: "1",
    priority: 3,
    areaCodes: ["787", "939"],
  }, {
    name: "Qatar (‫قطر‬‎)  +974",
    countryCode: "qa",
    dialCode: "974",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Réunion (La Réunion)  +262",
    countryCode: "re",
    dialCode: "262",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Romania (România)  +40",
    countryCode: "ro",
    dialCode: "40",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Russia (Россия)  +7",
    countryCode: "ru",
    dialCode: "7",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Rwanda  +250",
    countryCode: "rw",
    dialCode: "250",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Saint Barthélemy (Saint-Barthélemy)  +590",
    countryCode: "bl",
    dialCode: "590",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Saint Helena  +290",
    countryCode: "sh",
    dialCode: "290",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Saint Kitts and Nevis  +1869",
    countryCode: "kn",
    dialCode: "1869",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Saint Lucia  +1758",
    countryCode: "lc",
    dialCode: "1758",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Saint Martin (Saint-Martin (partie française))  +590",
    countryCode: "mf",
    dialCode: "590",
    priority: 2,
    areaCodes: null,
  }, {
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)  +508",
    countryCode: "pm",
    dialCode: "508",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Saint Vincent and the Grenadines  +1784",
    countryCode: "vc",
    dialCode: "1784",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Samoa  +685",
    countryCode: "ws",
    dialCode: "685",
    priority: 0,
    areaCodes: null,
  }, {
    name: "San Marino  +378",
    countryCode: "sm",
    dialCode: "378",
    priority: 0,
    areaCodes: null,
  }, {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)  +239",
    countryCode: "st",
    dialCode: "239",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)  +966",
    countryCode: "sa",
    dialCode: "966",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Senegal (Sénégal)  +221",
    countryCode: "sn",
    dialCode: "221",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Serbia (Србија)  +381",
    countryCode: "rs",
    dialCode: "381",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Seychelles  +248",
    countryCode: "sc",
    dialCode: "248",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Sierra Leone  +232",
    countryCode: "sl",
    dialCode: "232",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Singapore  +65",
    countryCode: "sg",
    dialCode: "65",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Sint Maarten  +1721",
    countryCode: "sx",
    dialCode: "1721",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Slovakia (Slovensko)  +421",
    countryCode: "sk",
    dialCode: "421",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Slovenia (Slovenija)  +386",
    countryCode: "si",
    dialCode: "386",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Solomon Islands  +677",
    countryCode: "sb",
    dialCode: "677",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Somalia (Soomaaliya)  +252",
    countryCode: "so",
    dialCode: "252",
    priority: 0,
    areaCodes: null,
  }, {
    name: "South Africa  +27",
    countryCode: "za",
    dialCode: "27",
    priority: 0,
    areaCodes: null,
  }, {
    name: "South Korea (대한민국)  +82",
    countryCode: "kr",
    dialCode: "82",
    priority: 0,
    areaCodes: null,
  }, {
    name: "South Sudan (‫جنوب السودان‬‎)  +211",
    countryCode: "ss",
    dialCode: "211",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Spain (España)  +34",
    countryCode: "es",
    dialCode: "34",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Sri Lanka (ශ්‍රී ලංකාව)  +94",
    countryCode: "lk",
    dialCode: "94",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Sudan (‫السودان‬‎)  +249",
    countryCode: "sd",
    dialCode: "249",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Suriname  +597",
    countryCode: "sr",
    dialCode: "597",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Svalbard and Jan Mayen  +47",
    countryCode: "sj",
    dialCode: "47",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Swaziland  +268",
    countryCode: "sz",
    dialCode: "268",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Sweden (Sverige)  +46",
    countryCode: "se",
    dialCode: "46",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Switzerland (Schweiz)  +41",
    countryCode: "ch",
    dialCode: "41",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Syria (‫سوريا‬‎)  +963",
    countryCode: "sy",
    dialCode: "963",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Taiwan (台灣)  +886",
    countryCode: "tw",
    dialCode: "886",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Tajikistan  +992",
    countryCode: "tj",
    dialCode: "992",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Tanzania  +255",
    countryCode: "tz",
    dialCode: "255",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Thailand (ไทย)  +66",
    countryCode: "th",
    dialCode: "66",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Timor-Leste  +670",
    countryCode: "tl",
    dialCode: "670",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Togo  +228",
    countryCode: "tg",
    dialCode: "228",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Tokelau  +690",
    countryCode: "tk",
    dialCode: "690",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Tonga  +676",
    countryCode: "to",
    dialCode: "676",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Trinidad and Tobago  +1868",
    countryCode: "tt",
    dialCode: "1868",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Tunisia (‫تونس‬‎)  +216",
    countryCode: "tn",
    dialCode: "216",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Turkey (Türkiye)  +90",
    countryCode: "tr",
    dialCode: "90",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Turkmenistan  +993",
    countryCode: "tm",
    dialCode: "993",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Turks and Caicos Islands  +1649",
    countryCode: "tc",
    dialCode: "1649",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Tuvalu  +688",
    countryCode: "tv",
    dialCode: "688",
    priority: 0,
    areaCodes: null,
  }, {
    name: "U.S. Virgin Islands  +1340",
    countryCode: "vi",
    dialCode: "1340",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Uganda  +256",
    countryCode: "ug",
    dialCode: "256",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Ukraine (Україна)  +380",
    countryCode: "ua",
    dialCode: "380",
    priority: 0,
    areaCodes: null,
  }, {
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)  +971",
    countryCode: "ae",
    dialCode: "971",
    priority: 0,
    areaCodes: null,
  }, {
    name: "United Kingdom  +44",
    countryCode: "gb",
    dialCode: "44",
    priority: 0,
    areaCodes: null,
  }, {
    name: "United States  +1",
    countryCode: "us",
    dialCode: "1",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Uruguay  +598",
    countryCode: "uy",
    dialCode: "598",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Uzbekistan (Oʻzbekiston)  +998",
    countryCode: "uz",
    dialCode: "998",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Vanuatu  +678",
    countryCode: "vu",
    dialCode: "678",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Vatican City (Città del Vaticano)  +39",
    countryCode: "va",
    dialCode: "39",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Venezuela  +58",
    countryCode: "ve",
    dialCode: "58",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Vietnam (Việt Nam)  +84",
    countryCode: "vn",
    dialCode: "84",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Wallis and Futuna  +681",
    countryCode: "wf",
    dialCode: "681",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Western Sahara (‫الصحراء الغربية‬‎)  +212",
    countryCode: "eh",
    dialCode: "212",
    priority: 1,
    areaCodes: null,
  }, {
    name: "Yemen (‫اليمن‬‎)  +967",
    countryCode: "ye",
    dialCode: "967",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Zambia  +260",
    countryCode: "zm",
    dialCode: "260",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Zimbabwe  +263",
    countryCode: "zw",
    dialCode: "263",
    priority: 0,
    areaCodes: null,
  }, {
    name: "Åland Islands  +358",
    countryCode: "ax",
    dialCode: "358",
    priority: 1,
    areaCodes: null,
  }];

  /**
   * Return country object by country code.
   * @param countryCode
   * @returns {*}
   */
  static getCountryPhonePrefix = countryCode => CountriesService.countries.find(country => country.countryCode === countryCode);
}

export default CountriesService;
