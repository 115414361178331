import React, { Component } from 'react';

import Modal from '../../../Modal/Modal';
import ConfirmPassword from './ConfirmPassword';
import ChangePassword from './ChangePassword';
import {
  confirmPasswordRequest,
  postNewPassword,
} from '../../Authentication/auth-requests';
import { FORM_ERROR_MESSAGES } from '../../../../utils/validation';

import './PasswordForm.scss';

class PasswordForm extends Component {
  state = {
    confirmed: false,
  };

  confirmPassword = values => (
    confirmPasswordRequest(values.password)
      .then(() => this.setState({ confirmed: true }))
      .catch(() => ({ password: FORM_ERROR_MESSAGES.INCORRECT_PASSWORD }))
  );

  handleSubmit = values => {
    const { newPassword, confirmNewPassword } = values;
    const { userStore: { user }, onClose } = this.props;

    if (newPassword === confirmNewPassword && user.hasPassword) {
      return postNewPassword({ ...user, password: values.newPassword })
        .then(res => onClose())
        .catch(error => ({ confirmNewPassword: error.err.message }));
    }

    return null;
  };

  render() {
    const { onClose } = this.props;
    const { confirmed } = this.state;
    return (
      <Modal width={606} height={392} onClose={onClose} title="CHANGE PASSWORD">
        <div className="form-fields">
          {!confirmed && <ConfirmPassword onSubmit={this.confirmPassword} />}
          {confirmed && <ChangePassword onSubmit={this.handleSubmit} />}
        </div>
      </Modal>
    );
  }
}
export default PasswordForm;
