import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CheckedCheckbox, UncheckedCheckbox, IndeterminateCheckbox } from './CheckboxIcons';

import './Checkbox.scss';

const CustomCheckbox = ({
  checked, onChange, name, value, formControlClass, label, checkboxClass, labelClass, labelPlacement = 'end', indeterminate, isDisabled = false,
}) => (
  <FormControlLabel
    className={`custom-checkbox ${formControlClass}`}
    label={label}
    labelPlacement={labelPlacement}
    classes={{ label: labelClass }}
    control={(
      <Checkbox
        checked={checked}
        onChange={onChange}
        name={name}
        disabled={isDisabled}
        classes={{ root: `${checkboxClass} ${labelPlacement} ${label ? 'has-label' : ''}` }}
        value={value}
        icon={indeterminate ? <IndeterminateCheckbox /> : <UncheckedCheckbox />}
        checkedIcon={<CheckedCheckbox />}
      />
    )}
  />
);

export default CustomCheckbox;
