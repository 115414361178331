import React from 'react';
import { inject, observer } from 'mobx-react';
import Section from '../../../Section/Section';
import Form from '../../../Settings/Form/Form';
import ColorInput from './ColorInput';
import { StoresEnum } from '../../../../stores';

import './ChannelColors.scss';

const ProgressBarColors = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const { visited, unvisited } = channel?.colorPalette?.progressBar || {};

  const renderFields = () => (
    <>
      <ColorInput readOnly={readOnly} useFormField name="colorPalette.progressBar.unvisited" value={unvisited} label="Unvisited slide color" />
      <ColorInput readOnly={readOnly} useFormField name="colorPalette.progressBar.visited" value={visited} label="Visited color" />
    </>
  );

  return (
    <Section title="Progress Bar Colors">
      <Form
        initialValues={channel}
        fields={renderFields()}
        onSubmit={updateChannel}
        hideCancelButton
        hideApproveButton={readOnly}
        approveLabel="Apply"
        approveClass="outlined-secondary"
        className="colors-form"
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(ProgressBarColors));
