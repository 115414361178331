import React from 'react';
import './GoBackBtn.scss';

const starBlue = require('../../../../../theme/images/go_back_arrow.svg');

const GoBackBtn = ({ onClick }) => (
  <div onClick={onClick} className="go-back-btn">
    <img src={starBlue} alt="star blue" />
    <span className="title">Go back</span>
  </div>
);

export default GoBackBtn;
