import React, { useState, useEffect } from "react";
import { Field, renderField, renderSelect } from "../../../utils/formFieldHelper";
import languages from "../../../utils/languages";
import Section from "../../Section/Section";
import Form from "../Form/Form";
import { composeValidators, email, required } from "../../../utils/validation";

const UserForm = ({ initialValues, onSubmit }) => {
  const [mappedLanguages, setMappedLanguages] = useState([]);
  const [mappedPublishers, setMappedPublishers] = useState([]);
  const { publishers } = initialValues;

  useEffect(() => {
    setMappedLanguages(languages.map(language => ({ name: language.name, id: language.value })));
    setMappedPublishers(publishers.map(publisher => ({ name: publisher.name, id: publisher._id || publisher.publisherId })));
  }, [publishers]);

  const renderFields = () => (
    <>
      <Field
        name="displayName"
        type="text"
        component={renderField}
        label="Display Name"
        validate={required}
      />
      <Field
        name="language"
        label="Language"
        component={renderSelect(mappedLanguages)}
      />
      <Field
        name="emails[0]"
        type="email"
        component={renderField}
        validate={composeValidators(required, email)}
        label="Email"
      />
      <Field
        name="defaultPublisher"
        label="Default Channel"
        component={renderSelect(mappedPublishers)}
      />
    </>
  );

  return (
    <Section title="BASIC INFO">
      <Form
        initialValues={initialValues}
        fields={renderFields()}
        onSubmit={onSubmit}
        hideCancelButton
      />
    </Section>
  );
};

export default UserForm;
