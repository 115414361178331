import { useQuery } from '@tanstack/react-query';
import PaymentsProvider from '../helpers/PaymentsProvider';
import { getAuthRestriction } from '../utils/utils';

const useChannelsPlans = ({ channelIds = [] }) => {
  const { data: blockableDictionary, refetch: refetchIsBlockable } = useQuery({
    queryKey: ['force-block', ...channelIds],
    enabled: Boolean(channelIds?.length),
    queryFn: () => getAuthRestriction(channelIds, 'force-block'),
  });

  const { data: plansDictionary } = useQuery({
    queryKey: ['get-channels-plans', ...channelIds],
    enabled: Boolean(channelIds?.length),
    queryFn: () => PaymentsProvider.getSubscriptionPerBulkPublishers(channelIds),
  });

  return { plansDictionary, blockableDictionary, refetchIsBlockable };
};

export default useChannelsPlans;
