import React from 'react';
import Loader from '../../../../Loader/Loader';
import "../Developers.scss";

const WebHookConnectionTester = ({ testWebhookEndPoint, testResult, processing }) => (
  <div className="webhooks-tester-block">
    <span className="webhook-main-ttl">Test your connection before applying:</span>
    <button type="button" className="webhook-test-btn" onClick={testWebhookEndPoint}>
      Test
      { processing && <Loader className="webhooks-test-loader" /> }
    </button>
    <div className="webhooks-msg-frame">
      {testResult.code && <i className={`ic ${testResult.code >= 200 && testResult.code < 300 ? 'icon-check' : 'icon-close-popup'}`} />}
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: testResult.message }} />
    </div>
  </div>
);

export default WebHookConnectionTester;
