import {
  makeObservable, action, observable, computed,
} from 'mobx';
import UserProvider from '../helpers/UserProvider';
import { isChannelAdmin as checkIsChannelAdmin } from '../utils/utils';
import EventDataBuilder from '../helpers/EventDataBuilder';
import EventTracker, { EVENT_TYPE } from '../helpers/EventTracker';

export default class ChannelStore {
  channel = {};

  fetched = false;

  get isChannelAdmin() {
    const { user } = this.userStore || {};
    const isNewChannel = !this.channel.publisherId && !this.channel._id;
    return isNewChannel || checkIsChannelAdmin(user, this.channel);
  }

  get readOnly() {
    const { isSuperAdmin } = this.userStore || {};
    return !this.isChannelAdmin && !isSuperAdmin;
  }

  constructor(userStore) {
    makeObservable(this, {
      channel: observable,
      fetched: observable,
      fetchChannel: action,
      clearChannel: action,
      updateChannel: action,
      setChannel: action,
      isChannelAdmin: computed,
      readOnly: computed,
    });

    this.userStore = userStore;
  }

  setChannel = (payload, overrideChannel = false) => {
    // Shouldn't store flat member's list (list without member's information) - happens when receiving update response.
    if (typeof payload?.admins?.[0] === 'string' || typeof payload?.editors?.[0] === 'string') {
      delete payload.admins;
      delete payload.editors;
    }
    this.channel = payload ? { ...(!overrideChannel && { ...this.channel }), ...payload } : { error: true };
    this.fetched = true;
  };

  clearChannel = () => {
    this.channel = {};
  };

  fetchChannel = async (channelId, shouldClearState) => {
    if (shouldClearState || !channelId) {
      this.clearChannel();
    }

    const channelPayload = await UserProvider.getPublisher(channelId);
    this.setChannel(channelPayload?.payload, true);
  };

  updateChannel = async updatedChannel => {
    const response = await UserProvider.updatePublisher({ ...this.channel, ...updatedChannel }, !this.readOnly);
    if (response?.channel?._id) {
      this.setChannel(response.channel);
      EventTracker.track(EVENT_TYPE.CHANNEL_UPDATED, EventDataBuilder.channelUpdated(response.channel, !this.readOnly));
    }

    return response;
  };
}
