import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import UserProvider from '../../../helpers/UserProvider';
import { Field, renderSelect } from '../../../utils/formFieldHelper';

import './ChannelForm.scss';

const GroupField = ({ selectedGroupId }) => {
  const [initialGroups, setInitialGroups] = useState();
  const [currentGroupId, setCurrentGroupId] = useState(selectedGroupId);

  const fetchGroups = useCallback(async (search, groupId = currentGroupId || selectedGroupId) => {
    const response = await UserProvider.getGroups({ search });
    const { payload } = response || [];
    const shouldRetrieveGroup = !search?.length && groupId && !payload.find(group => group._id === groupId);
    if (shouldRetrieveGroup) {
      const selectedGroup = await UserProvider.getGroup(groupId);
      payload.push(selectedGroup?.payload);
    }

    return payload?.map(group => ({ id: group._id, name: group.name }));
  }, [selectedGroupId, currentGroupId]);

  const setFirstInitialGroups = async groupId => {
    const groups = await fetchGroups('', groupId);
    setInitialGroups(groups);
  };

  useEffect(() => {
    setFirstInitialGroups();
  }, []);

  useEffect(() => {
    setCurrentGroupId(selectedGroupId);
  }, [selectedGroupId]);

  return initialGroups ? (
    <div className={`actionable-field ${!currentGroupId ? 'no-action' : ''}`}>
      <Field
        name="groupId"
        label="Group (optional)"
        placeholder="Pick a group"
        includeSearch
        searchLabel="group"
        component={renderSelect(initialGroups, fetchGroups)}
        onChange={newValue => {
          if (newValue !== currentGroupId) {
            setCurrentGroupId(newValue);
            setFirstInitialGroups(newValue);
          }
        }}
      />
      {currentGroupId && <Link className="link" to={`/groups/${currentGroupId}`}>Go to group</Link>}
    </div>
  ) : null;
};

export default GroupField;
