import React from 'react';
import { Tooltip as MuiTooltip, Zoom } from '@mui/material';

import './Tooltip.scss';

const Tooltip = ({
  title, placement = 'bottom', disableHoverListener, children, className = '',
}) => (
  <MuiTooltip
    arrow
    title={title || ''}
    placement={placement}
    disableHoverListener={disableHoverListener || !title}
    TransitionComponent={Zoom}
    classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow' }}
  >
    <div className={`custom-tooltip-children ${className}`}>
      {children}
    </div>
  </MuiTooltip>
);

export default Tooltip;
