import {
  makeObservable, observable, computed, action,
} from 'mobx';
import { HS } from '@apester/events';
import { isAdmin } from '../utils/utils';
import UserProvider from '../helpers/UserProvider';
import EventTracker, { EVENT_TYPE } from '../helpers/EventTracker';
import EventDataBuilder from '../helpers/EventDataBuilder';

export default class UserStore {
  user = {
    loggedIn: false,
    authorized: false,
    fetched: false,
  };

  get isSuperAdmin() {
    return isAdmin(this.user);
  }

  get sessionId() {
    if (!this.user.loggedIn) {
      return undefined;
    }
    EventTracker.identifyUser(this.user);
    const email = this.user.emails[0];
    HS.identifyUser(email);
    return EventTracker.getSessionId();
  }

  setUser(response = {}) {
    const { payload } = response;
    this.user = payload?.userId
      ? {
        ...payload,
        fetched: true,
        loggedIn: true,
        authorized: (payload.TOTPClient && payload.TOTPClient.active) || false,
      }
      : {
        loggedIn: false,
        authorized: false,
        fetched: true,
      };
  }

  constructor() {
    makeObservable(this, {
      user: observable,
      sessionId: computed,
      isSuperAdmin: computed,
      setUser: action,
      updateUser: action,
      fetchUser: action,
    });

    this.fetchUser();
  }

  updateUser = async newData => {
    const newUserObject = await UserProvider.updateUser({ ...this.user, ...newData });
    EventTracker.track(EVENT_TYPE.USER_UPDATED, EventDataBuilder.userUpdated(newUserObject));
    this.setUser(newUserObject);
    return newUserObject;
  };

  fetchUser = async () => {
    const data = await UserProvider.getUser("", true);
    this.setUser(data);
  };
}
