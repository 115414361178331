import React from 'react';
import { Form } from 'react-final-form';
import { sendMFASmsCode } from '../auth-requests';
import { Field, renderField } from '../../../../utils/formFieldHelper';
import { composeValidators, verificationCode } from '../../../../utils/validation';

const ConfirmVerificationCode = ({ onSubmit, isAppBarcode }) => (
  <>
    <Form onSubmit={onSubmit} className="create-form password-form">
      {({
        handleSubmit, pristine, submitting,
      }) => (
        <form onSubmit={handleSubmit} className="create-form password-form">
          <Field
            name="verificationCode"
            type="text"
            component={renderField}
            wrapperClassName="input-field-wrapper"
            placeholder="Enter 6 Digits Code"
            useLabelAsPlaceholder={false}
            validate={composeValidators(verificationCode)}
          />

          <div className="submit-container">
            <button
              className="submit-button"
              type="submit"
              disabled={pristine || submitting}
            >
              SUBMIT CODE
            </button>
          </div>
        </form>
      )}
    </Form>

    {!isAppBarcode && (
      <div className="submit-container">
        <button type="submit" onClick={() => sendMFASmsCode()} className="general-button">
          RESEND CODE
        </button>
      </div>
    )}
  </>
);

export default ConfirmVerificationCode;
