import React from 'react';
import { inject, observer } from 'mobx-react';
import WebHookForm from './WebHook/WebHookForm';
import ApiKeyForm from './ApiKey/ApiKeyForm';
import { StoresEnum } from '../../../../stores';

const ChannelDeveloper = ({ channelStore: { readOnly } }) => (
  <>
    {!readOnly && <ApiKeyForm />}
    <WebHookForm />
  </>
);

export default inject(StoresEnum.CHANNEL)(observer(ChannelDeveloper));
