import React from 'react';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import endpointImg from '../../../../../assets/images/endpoint.svg';

const WebHooListItemCell = ({ url = '', removeItem, readOnly }) => (
  <ListItem className="url-list-item">
    <div className="list-item-content">
      <Avatar size={50} src={endpointImg} />
      {url}
      {!readOnly && <span className="ic icon-cross delete-url-icon" onClick={removeItem} />}
    </div>
  </ListItem>
);

export default WebHooListItemCell;
