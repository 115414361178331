import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { StyledEngineProvider } from '@mui/material';

import { init } from '@apester/image';
import { HS } from '@apester/events';

import EventTracker from './helpers/EventTracker';
import App from './containers/App/App';
import { rootStore, routingStore } from './stores';

import './index.scss';
import './theme/fonts.scss';

const constants = require('./utils/constants');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      staleTime: 86400000,
    },
  },
});

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, routingStore);

init(constants.CONFIG.imagesNewUrl, constants.CONFIG.staticBucket);
EventTracker.init();
HS.init();
const target = document.getElementById('root');

const provider = (
  <QueryClientProvider client={queryClient}>
    <Provider {...rootStore}>
      <Router history={history}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </Router>
    </Provider>
  </QueryClientProvider>
);

const root = createRoot(target);
root.render(provider);
