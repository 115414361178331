import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import CountriesService from '../../../../helpers/CountriesService';
import confirmPhoneNumberImage from '../../../../theme/images/confirm-phone.svg';
import { Field, renderField, renderSelect } from '../../../../utils/formFieldHelper';
import { countryCode, phoneNumber } from '../../../../utils/validation';

const ConfirmPhoneNumber = ({ onSubmit, isBackup }) => {
  const [mappedCountries, setMappedCountries] = useState([]);

  useEffect(() => {
    setMappedCountries(CountriesService.countries.map(country => ({ ...country, id: country.countryCode })));
  }, []);

  return (
    <>
      <div className="description-section">
        <div className="description-title">
          {!isBackup
            ? `Apester will prompt you on one of your devices to verify it's you for sextra security measurement`
            : `If you lose your primary phone we will send you a code to a backup number`}
        </div>
        {!isBackup && <img alt="phone number" src={confirmPhoneNumberImage} />}
        <div />
      </div>
      <Form onSubmit={onSubmit}>
        {({
          handleSubmit, pristine, submitting, invalid,
        }) => (
          <form onSubmit={handleSubmit} className="create-form password-form">
            <Field
              name="countryCode"
              className="auth-select-field"
              popoverClass="auth-select-menu"
              placeholder="Country"
              component={renderSelect(mappedCountries)}
              validate={countryCode}
            />

            <Field
              name="phoneNumber"
              type="text"
              component={renderField}
              wrapperClassName="input-field-wrapper"
              placeholder="Enter Phone Number"
              useLabelAsPlaceholder={false}
              validate={phoneNumber}
            />

            <div className="submit-container">
              <button
                className="submit-button"
                type="submit"
                disabled={pristine || submitting || invalid}
              >
                {!isBackup ? `SEND SMS CODE` : `SAVE BACKUP NUMBER`}
              </button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

export default ConfirmPhoneNumber;
