import React from 'react';
import { inject, observer } from 'mobx-react';
import { Field, renderToggle } from "../../../../utils/formFieldHelper";
import Section from '../../../Section/Section';
import Form from '../../../Settings/Form/Form';
import { StoresEnum } from '../../../../stores';

const TrackingSettingsForm = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const renderFields = () => (
    <Field
      name="trackingSettings.shouldSendAllEvents"
      type="checkbox"
      component={renderToggle}
      readOnly={readOnly}
      label="Send all of the events"
      onChange={newValue => {
        updateChannel({
          ...channel,
          trackingSettings: {
            shouldSendAllEvents: newValue,
          },
        });
      }}
    />
  );

  return (
    <Section title="TRACKING">
      <Form
        initialValues={channel}
        fields={renderFields()}
        onSubmit={updateChannel}
        hideCancelButton
        hideApproveButton
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(TrackingSettingsForm));
