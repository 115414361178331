import { snackbarType } from './ApeSnackbar';

const snackbarTopics = {
  UNASSIGN_MEMBER: 'unassign_member',
  UNASSIGN_MEMBER_SINGLE_CHANNEL_EXCEPTION: 'unassign_member_single_channel_exception',
  UNASSIGN_MEMBER_SELF_REMOVAL_EXCEPTION: 'unassign_member_self_removal_exception',
  UNASSIGN_MEMBERS: 'unassign_members',
  GENERAL_MEMBERS_CHANGE: 'general_members_change',
  INVITE_EMAIL_SENT: 'invite_email_sent',
  INVITES_EMAIL_SENT: 'invites_email_sent',
  CHANNEL_BLOCKED: 'channel_blocked',
  CHANNEL_SAVED: 'channel_saved',
  GROUP_SAVED: 'group_saved',
  GROUP_INACTIVATED: 'group_inactivated',
  GROUP_INACTIVATED_EXCEPTION: 'group_inactivated_exception',
};

const snackbarContent = {
  [snackbarTopics.CHANNEL_BLOCKED]: channelName => ({
    message: `The channel <b>${channelName}</b> was blocked.`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.CHANNEL_SAVED]: (channelName, isNew) => ({
    message: `The channel <b>${channelName}</b> was ${isNew ? 'created' : 'updated'} successfully.`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.INVITE_EMAIL_SENT]: memberName => ({
    message: `An invitation was sent to <b>${memberName}</b> to join the channel.`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.INVITES_EMAIL_SENT]: membersNumber => ({
    message: `<b>${membersNumber}</b> invitations were sent to join the channel.`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.GROUP_SAVED]: (groupName, isNew) => ({
    message: `The group <b>${groupName}</b> was ${isNew ? 'created' : 'updated'} successfully`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.GROUP_INACTIVATED]: groupName => ({
    message: `The group <b>${groupName}</b> was inactivated successfully`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.GROUP_INACTIVATED_EXCEPTION]: groupName => ({
    message: `The group <b>${groupName}</b> was unable to be inactivated`,
    type: snackbarType.ERROR,
  }),
  [snackbarTopics.UNASSIGN_MEMBER]: (memberName, assignTo = 'group') => ({
    message: `The member <b>${memberName}</b> was unassigned from the ${assignTo}.`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.UNASSIGN_MEMBER_SINGLE_CHANNEL_EXCEPTION]: memberName => ({
    message: `Unassign ${memberName} was failed. Can't unassign user that has only one channel.`,
    type: snackbarType.ERROR,
  }),
  [snackbarTopics.UNASSIGN_MEMBER_SELF_REMOVAL_EXCEPTION]: () => ({
    message: `Can't remove yourself`,
    type: snackbarType.ERROR,
  }),
  [snackbarTopics.UNASSIGN_MEMBERS]: (membersNumber, assignTo = 'group') => ({
    message: `<b>${membersNumber} Members</b> were unassigned from the ${assignTo}.`,
    type: snackbarType.SUCCESS,
  }),
  [snackbarTopics.GENERAL_MEMBERS_CHANGE]: () => ({
    message: `The members list has been updated.`,
    type: snackbarType.SUCCESS,
  }),
};

export { snackbarTopics, snackbarContent };
