import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Fade,
} from '@mui/material';
import union from 'lodash/union';
import groupBy from 'lodash/groupBy';
import { LinkOffRounded, MailOutline } from '@mui/icons-material';
import Popup from '../../ApeUI/Popup/Popup';
import { popupContent, popupTopics } from '../../ApeUI/Popup/PopupContent';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';
import { getUserFullName, isSelfRemovalException, isSingleChannelException } from '../../../utils/utils';
import UserProvider from '../../../helpers/UserProvider';
import TableActionsMenu from '../TableActionsMenu/TableActionsMenu';
import MemberRow from './MemberRow';

import './MembersTable.scss';

const getRelatedChannels = async (registeredMembers, invitedMembers) => {
  const registeredMembersIds = registeredMembers?.map(member => member.userId);
  const registeredChannels = registeredMembersIds?.length ? await UserProvider.getBulkUsersPublishers(registeredMembersIds) : [];

  const invitedEmails = union(invitedMembers?.map(invited => invited.to) || []);
  const invitedChannels = invitedEmails.length ? await UserProvider.getPublisherPending(undefined, invitedEmails.join(','), true) : [];

  return { registeredChannels, invitedChannels };
};

const MembersTable = ({
  selectedRows,
  setSelectedRows,
  registeredMembers,
  invitedMembers,
  columns,
  resendEmail,
  unassignMembers,
  displayPermission,
  onChangePermission,
  includeChannelsColumn,
  isEditable,
  assignTo,
}) => {
  const [allRelatedMembers, setAllRelatedMembers] = useState();
  const [popupDescription, setPopupDescription] = useState();
  const [popupCTA, setPopupCTA] = useState();
  const [snackbarCurrentContent, setSnackbarCurrentContent] = useState();
  const [hideMainResendButton, setHideMainResendButton] = useState(true);
  const [invitedChannels, setInvitedChannels] = useState();
  const [registeredChannels, setRegisteredChannels] = useState();

  const isNoneSelected = !selectedRows?.length;
  const isAllSelected = !isNoneSelected && selectedRows?.length === allRelatedMembers?.length;

  const onSelectItem = ({ target }) => {
    if (target.checked) {
      setSelectedRows([...selectedRows, target.value]);
    } else {
      setSelectedRows(selectedRows.filter(item => item !== target.value));
    }
  };
  const onSelectAll = ({ target }) => {
    setSelectedRows(target.checked && isNoneSelected ? (allRelatedMembers || []).map(member => member.userId) : []);
  };

  useEffect(() => {
    if (registeredMembers) {
      const allMembers = [
        ...registeredMembers,
        ...(invitedMembers || []).map(invite => ({
          ...invite,
          isPending: true,
          publisherId: invite.publisher,
          userId: invite._id,
          emails: [invite.to],
        })),
      ];
      setAllRelatedMembers(allMembers);
    }
  }, [registeredMembers, invitedMembers]);

  useEffect(() => {
    const setChannels = async () => {
      const response = await getRelatedChannels(registeredMembers, invitedMembers);
      setInvitedChannels(response.invitedChannels);
      setRegisteredChannels(response.registeredChannels);
    };

    if (allRelatedMembers && includeChannelsColumn) {
      setChannels();
    }
  }, [allRelatedMembers]);

  useEffect(() => {
    // If one of the selected rows is registered member we should hide "resend email" button.
    const hasRegisteredMember = allRelatedMembers?.some(member => selectedRows.includes(member.userId) && !member.isPending);
    setHideMainResendButton(hasRegisteredMember);
  }, [allRelatedMembers, selectedRows]);

  const handleUnassignSuccess = payload => {
    const topic = typeof payload === 'number' ? snackbarTopics.UNASSIGN_MEMBERS : snackbarTopics.UNASSIGN_MEMBER;
    const messagePayload = typeof payload === 'number' ? payload : getUserFullName(payload);
    const content = snackbarContent[topic](messagePayload, assignTo);
    setSnackbarCurrentContent(content);
  };

  const handleUnassignError = ({ error }) => {
    if (isSingleChannelException(error)) {
      const foundMember = registeredMembers.find(member => member.userId);
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SINGLE_CHANNEL_EXCEPTION](getUserFullName(foundMember));
      setSnackbarCurrentContent(content);
    }

    if (isSelfRemovalException(error)) {
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SELF_REMOVAL_EXCEPTION]();
      setSnackbarCurrentContent(content);
    }
  };

  const approveUnassign = async member => {
    const invited = Array.isArray(member)
      ? invitedMembers?.filter(invite => selectedRows.includes(invite._id))
      : (member.isPending && [member]) || [];

    const registered = Array.isArray(member)
      ? registeredMembers?.filter(register => selectedRows.includes(register.userId))
      : (!member.isPending && [member]) || [];

    const numberOfDeletion = invited.length + registered.length;

    const response = await unassignMembers(registered, invited);
    if (response.error) {
      handleUnassignError(response);
      return;
    }

    if (numberOfDeletion > 0 && response.success) {
      handleUnassignSuccess(numberOfDeletion === 1 ? (registered[0] || invited[0]) : numberOfDeletion);
    }
  };

  const openUnassignPopup = member => {
    const currentPopupContent = Array.isArray(member)
      ? popupContent[popupTopics.UNASSIGN_MEMBERS](member.length, assignTo)
      : popupContent[popupTopics.UNASSIGN_MEMBER](getUserFullName(member), assignTo);
    setPopupDescription(currentPopupContent);

    setPopupCTA(() => async () => approveUnassign(member));
  };

  const renderRow = member => (
    <MemberRow
      key={member.userId || member._id}
      member={member}
      onSelectItem={onSelectItem}
      isRowSelected={selectedRows.includes(member.userId)}
      resendEmail={resendEmail}
      displayPermission={displayPermission}
      openUnassignPopup={openUnassignPopup}
      onChangePermission={onChangePermission}
      includeChannelsColumn={includeChannelsColumn}
      isEditable={isEditable}
      disableActions={!isNoneSelected}
      relatedChannels={member.isPending ? invitedChannels?.[member.to] : registeredChannels?.[member.userId]}
    />
  );

  return (
    <>
      <Fade in={Boolean(allRelatedMembers?.length)}>
        <div className={`table-wrapper ${!isEditable ? 'view-mode' : ''}`}>
          {isEditable && (
            <TableActionsMenu
              name="members"
              selectedItemsNumber={selectedRows.length}
              isAllSelected={isAllSelected}
              onSelect={onSelectAll}
              isDisabled={!isEditable}
              actions={[
                ...resendEmail && !hideMainResendButton ? [{ label: 'Resend invitation', icon: <MailOutline />, function: () => resendEmail(selectedRows) }] : [],
                { label: 'Unassign', icon: <LinkOffRounded />, function: () => openUnassignPopup(selectedRows) },
              ]}
            />
          )}

          <TableContainer padding="none" className="table">
            <Table padding="none" stickyHeader>
              <TableHead>
                <TableRow className="header-row">
                  {isEditable && <TableCell />}
                  {columns.map(column => (
                    <TableCell key={column.field}>{column.headerName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allRelatedMembers?.map(renderRow)}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fade>

      <Popup
        open={Boolean(popupDescription)}
        isAlert
        popupContent={popupDescription}
        onCancel={() => setPopupDescription()}
        onApprove={() => {
          setPopupDescription();
          popupCTA();
        }}
      />

      <ApeSnackbar
        open={Boolean(snackbarCurrentContent?.message)}
        message={snackbarCurrentContent?.message}
        onRequestClose={() => setSnackbarCurrentContent()}
        iconType={snackbarCurrentContent?.type}
      />
    </>
  );
};

MembersTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  isEditable: PropTypes.bool,
  includeChannelsColumn: PropTypes.bool,
};

MembersTable.defaultProps = {
  selectedRows: [],
  isEditable: false,
  includeChannelsColumn: false,
};

export default MembersTable;
