import _ from 'lodash';

import { CONFIG } from '../utils/constants';

import UserProvider from "./UserProvider";

export const interactionsMap = {};
const baseUrl = CONFIG.interactionBaseUrl;

export default class InteractionsHelper {
  static requestBrandedStripInteractionsData = async (channelId = '') => {
    const requestUrl = `${baseUrl}/publisher/${channelId}/strip`;
    const interactionsResp = await fetch(requestUrl, {
      credentials: 'include',
    });
    const { payload: interactionsJson } = await interactionsResp.json();

    if (!interactionsJson || interactionsJson.length === 0) {
      return Promise.resolve([]);
    }

    return Promise.resolve(interactionsJson);
  };

  static getChannelFonts = async channelId => {
    const requestUrl = `${baseUrl}/editor/backoffice/resource-filter?channelId=${channelId}&others=true&type=FONT`;
    try {
      const fontsResp = await fetch(requestUrl, { credentials: 'include' });
      const { payload } = await fontsResp.json();
      const fonts = payload[0]?.data?.FONT?.lists[0];
      return fonts ? fonts.resources : [];
    } catch (e) {
      return [];
    }
  };

  static updateInteraction = async (interactionId, props) => {
    const requestUrl = `${baseUrl}/interaction/${interactionId}`;

    const interactionsReq = await fetch(requestUrl, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...props }), // body data type must match "Content-Type" header
    });
    return interactionsReq.json();
  };

  static requestInteractionsData = async (interactionIds, slides) => {
    const data = slides ? `data` : `data.size`;
    const interactionIdsUrlParam = (interactionIds || []).join(',');
    // Filter invalid object ids. Remove after vremini fix.
    const requestUrl = `${baseUrl}/interaction/fields/?interactions=${interactionIdsUrlParam}&fields=title,layout,created,updated,creator,publisher,${data},state`;
    const interactionsResp = await fetch(requestUrl, {
      credentials: 'include',
    });
    const { payload: interactionsJson } = await interactionsResp.json();

    if (!interactionsJson || interactionsJson.length === 0) {
      return Promise.resolve([]);
    }

    const interactionCreatorIds = _.uniq(interactionsJson.map(i => i.creator));
    // Fetch creator data for all interactions.
    const { payload: creators } = await UserProvider.getUsersFields(interactionCreatorIds, ['displayName']);
    interactionsJson.forEach(interaction => {
      const userId = interaction.creator;
      interaction.creator = creators[userId];
    });

    return Promise.resolve(interactionsJson);
  };

  static async fetchInteractionData(interactionIds) {
    // Filter duplicate interaction Ids and interactions already requested
    const interactionIdsToRequest = _.without(_.uniq(interactionIds), ...Object.keys(interactionsMap));
    // We split interactionIds into chunks because of URL length restrictions
    const interactionIdsChunks = _.chunk(interactionIdsToRequest, 100);
    const promises = interactionIdsChunks.map(this.requestInteractionsData);
    await Promise.all(promises).then(responses => {
      responses.forEach(resp => {
        resp.forEach(interactionData => {
          interactionsMap[interactionData._id] = interactionData;
          interactionsMap[interactionData._id].title = interactionData.title
            .replace(/(<([^>]+)>)/gi, ' ')
            .replace(/\s\s+/g, ' ')
            .trim();
        });
      });
    });
  }

  static requestInteractionsWithoutChannel = async () => {
    const requestUrl = `${CONFIG.interactionBaseUrl}/interaction?list=true`;
    const interactionResp = await fetch(requestUrl, { credentials: 'include' });
    const { payload } = await interactionResp.json();
    return payload;
  };

  static postInteractionToChannel = async (publisherId = '', interactionIds = []) => {
    const requestUrl = `${CONFIG.interactionBaseUrl}/interaction/migrate-publisher`;
    const body = {
      interactionIds,
      publisherId,
    };
    const attachInteracion = await fetch(requestUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return attachInteracion.status === 200;
  };

  static getById(interactionId) {
    return interactionsMap[interactionId];
  }

  static getInteractions = async () => {
    const requestUrl = `${baseUrl}/interaction?count=true`;
    const interactionsResp = await fetch(requestUrl, {
      credentials: 'include',
    });
    const { payload: units } = await interactionsResp.json();
    if (!units) {
      return Promise.resolve([]);
    }

    return units;
  };
}
