import React, { Component } from 'react';

import Modal from '../../../Modal/Modal';
import ConfirmPassword from '../../Password/PasswordForm/ConfirmPassword';
import ConfirmVerificationCode from '../MFAAlert/ConfirmVerificationCode';
import {
  switchMFAMethod,
  getAuthAppBarCode,
  confirmPasswordRequest,
} from '../auth-requests';

import './AppAlert.scss';
import { FORM_ERROR_MESSAGES } from '../../../../utils/validation';

const stepDescription = (number, title) => (
  <div className="headers">
    <div className="step-number">{`Step ${number}`}</div>
    <div className="step-title">{title}</div>
  </div>
);

class AppAlert extends Component {
  state = {
    barcodeImage: '',
    confirmed: false,
    page: 0,
  };

  confirmPassword = values => {
    let err;
    confirmPasswordRequest(values.password)
      .then(res => this.setState({ confirmed: true }))
      .catch(error => { err = error.err.message; });

    getAuthAppBarCode().then(barcodeImage => this.setState({ barcodeImage }));
    return err ? { password: err } : {};
  };

  handleVerificationCode = values => {
    const { onClose, userStore: { user, fetchUser } } = this.props;
    const { verificationCode } = values;

    return switchMFAMethod(user, verificationCode).then(res => {
      if (res) {
        fetchUser();
        onClose();
      }
      return res ? undefined : { verificationCode: FORM_ERROR_MESSAGES.FAILED_VERIFICATION_CODE };
    });
  };

  renderBarcode = barcodeImage => (
    <div>
      <div className="headers-containers">
        {stepDescription(1, 'Download ‘Google Authenticator’ app')}
        {stepDescription(2, 'Scan this barcode with the app')}
      </div>
      <div className="icon-container">
        <img className="icon" src={barcodeImage} alt="" />
      </div>
      <div className="submit-container">
        <button
          type="button"
          onClick={() => this.setState({ page: 1 })}
          className="general-button black"
        >
          NEXT
        </button>
      </div>
    </div>
  );

  render() {
    const { onClose } = this.props;
    const {
      barcodeImage,
      page,
      confirmed,
    } = this.state;

    return (
      <Modal
        width={606}
        height={!confirmed ? 392 : 500}
        onClose={onClose}
        title="SWITCH TO APP"
      >
        <div className="form-fields">
          {!confirmed && <ConfirmPassword onSubmit={this.confirmPassword} />}
          {confirmed && page === 0 && this.renderBarcode(barcodeImage)}
          {confirmed && page === 1 && <ConfirmVerificationCode onSubmit={this.handleVerificationCode} isAppBarcode />}
        </div>
      </Modal>
    );
  }
}

export default AppAlert;
