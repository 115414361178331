import React from 'react';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './Popup.scss';

const Popup = ({
  open, onCancel, onApprove, isAlert, popupContent, errorAlert, children,
}) => (popupContent ? (
  <Dialog
    open={open}
    className="custom-popup"
    onClose={onCancel}
  >
    <div className="custom-popup-container">
      <DialogTitle className="custom-popup-title">{popupContent.title}</DialogTitle>
      <DialogContent className="custom-popup-content">
        <DialogContentText className="custom-popup-description" dangerouslySetInnerHTML={{ __html: popupContent.description }} />
        {children}
      </DialogContent>
      {isAlert && (
        <DialogActions className="custom-popup-actions">
          <Button onClick={onCancel} className="outlined-secondary cancel">
            {popupContent.cancel}
          </Button>
          <Button onClick={onApprove} className="contained-primary approve">
            {popupContent.approve}
          </Button>
        </DialogActions>
      )}
      <div className="custom-popup-error-message">
        <Fade in={Boolean(errorAlert)}><span>{errorAlert}</span></Fade>
      </div>
    </div>
  </Dialog>
) : null);

export default Popup;
