import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { AddRounded } from '@mui/icons-material';
import ChannelsTable from '../ChannelsTable/ChannelsTable';
import AddItem from '../AddItem/AddItem';
import MultiselectDropdown from '../../ApeUI/MultiselectDropdown/MultiselectDropdown';
import useChannels from '../../../hooks/useChannels';
import UserProvider from '../../../helpers/UserProvider';
import { popupContent, popupTopics } from '../../ApeUI/Popup/PopupContent';
import Popup from '../../ApeUI/Popup/Popup';
import { CONFIG } from '../../../utils/constants';

const DEBOUNCE_TIMEOUT = 500;

const setBulkPublishers = (groupId, publishers) => (
  UserProvider.addBulkPublishersToGroup(
    groupId,
    publishers.filter(channel => channel.isSelected).map(channel => channel.id),
  )
);

const unsetBulkPublishers = (groupId, publishers) => (
  UserProvider.removeBulkPublishersFromGroup(
    groupId,
    publishers.filter(channel => !channel.isSelected).map(channel => channel.id),
  )
);

const ChannelsSection = ({
  assignedChannels = [],
  groupId, onGroupChange,
  newChannelHandler,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchName, setSearchName] = useState('');
  const { channels, mapChannelsToSelectFormat } = useChannels({ searchName, selectedChannels: assignedChannels });
  const [anchorEl, setAnchorEl] = useState(null);
  const [mappedChannels, setMappedChannels] = useState([]);
  const [popupDescription, setPopupDescription] = useState();
  const [popupCTA, setPopupCTA] = useState();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const openAlreadyAssignedPopup = (channelName, channelId) => {
    const content = popupContent[popupTopics.CHANNEL_ALREADY_ASSIGNED](channelName);
    setPopupDescription(content);
    setPopupCTA(() => () => window.open(`${CONFIG.consoleBaseUrl}/channels/${channelId}`, '_blank'));
  };

  const onApprovePopup = () => {
    popupCTA();
    setPopupDescription();
  };

  const handleUnassignError = (unassignError, updatedList) => {
    const { payload } = unassignError.error;
    const channelName = updatedList.find(item => item.id === payload)?.label;
    openAlreadyAssignedPopup(channelName, payload);
  };

  const handleClose = async updatedList => {
    setAnchorEl(null);

    if (updatedList?.length) {
      setSearchName('');
      const [unsetPublishers, setPublishers] = await Promise.all([
        unsetBulkPublishers(groupId, updatedList),
        setBulkPublishers(groupId, updatedList),
      ]);

      onGroupChange(unsetPublishers.success || setPublishers.success);

      if (setPublishers.error?.message.includes('already assigned to group')) {
        handleUnassignError(setPublishers, updatedList);
      }
    }
  };

  useEffect(() => {
    setMappedChannels(mapChannelsToSelectFormat());
  }, [channels]);

  return (
    <>
      <div className="add-item-wrapper autocomplete-search-wrapper">
        <AddItem
          className="search-field"
          onChange={setSearchName}
          onInputClick={handleClick}
          inputValue={searchName}
          placeholder="Search channels to assign to the group"
          isSearchField
          debounceTimeout={DEBOUNCE_TIMEOUT}
          disabled={Boolean(selectedRows.length)}
        />
        <Button
          classes={{ label: 'button-label' }}
          className="outlined-secondary"
          startIcon={<AddRounded />}
          disabled={Boolean(selectedRows.length)}
          onClick={() => newChannelHandler(true)}
        >
          New channel
        </Button>
      </div>

      <MultiselectDropdown
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleClose}
        items={mappedChannels}
      />

      <ChannelsTable
        channels={assignedChannels}
        selectedItems={selectedRows}
        setSelectedItems={setSelectedRows}
        onGroupChange={onGroupChange}
        groupId={groupId}
      />

      <Popup
        open={Boolean(popupDescription)}
        isAlert
        popupContent={popupDescription}
        onCancel={() => setPopupDescription()}
        onApprove={onApprovePopup}
      />
    </>
  );
};

export default ChannelsSection;
