import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import './StripeElements.scss';

const StripeElements = () => {
  const style = {
    base: {
      color: '#4a4a4a',
      fontSize: '14px',
      fontFamily: 'LatoLatin, sans-serif',
      fontWeight: 'normal',
    },
  };

  return (
    <div className="elements-inline">
      <div className="stripe-group-elem">
        <CardNumberElement
          style={style}
          placeholder=""
          className="StripeElement"
          required
        />
        <span className="input-title">Card number</span>
      </div>
      <div className="stripe-group-elem">
        <CardExpiryElement style={style} placeholder="" className="strip-group-input" />
        <span className="input-title">Expiration</span>
      </div>
      <div style={style} className="stripe-group-elem">
        <CardCvcElement style={style} placeholder="" className="strip-group-input" />
        <span className="input-title">CVC</span>
      </div>
    </div>
  );
};

export default StripeElements;
