import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Field, renderToggle } from "../../../../utils/formFieldHelper";
import Section from '../../../Section/Section';
import Form from '../../../Settings/Form/Form';
import IncludeExcludeCountries from './IncludeExcludeCountries';
import { StoresEnum } from '../../../../stores';

const OnUnitAndPlaylistForm = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const [unitAndPlaylistValues, setUnitAndPlaylistValues] = useState();
  const { playlist, unitGeneralSettings } = channel;
  const { geoInclude = [], geoExclude = [] } = playlist || {};

  useEffect(() => {
    setUnitAndPlaylistValues({
      ...channel,
      shouldIncludeCountries: geoInclude.length > 0 ? "Include" : "Exclude",
    });
  }, [channel]);

  const renderFields = () => (
    <>
      <Field
        name="poweredBy.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Powered by Apester"
        onChange={newValue => {
          updateChannel({
            ...channel,
            poweredBy: { active: newValue },
          });
        }}
      />
      <div> Playlist Settings </div>
      <Field
        name="stripProps.timer.enabled"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Slide auto transition (Story)"
        onChange={newValue => {
          updateChannel({
            ...channel,
            stripProps: {
              timer: { enabled: newValue },
              taglineSource: channel.stripProps.taglineSource,
            },
          });
        }}
      />
      <Field
        name="playlist.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Add Playlist"
        onChange={newValue => {
          updateChannel({
            ...channel,
            playlist: {
              ...playlist,
              active: newValue,
            },
          });
        }}
      />
      <Field
        name="feed.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Unit Feed"
        onChange={newValue => {
          updateChannel({
            ...channel,
            feed: { active: newValue },
          });
        }}
      />
      <Field
        name="playlist.fallback"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Playlist Fallback"
        onChange={newValue => {
          updateChannel({
            ...channel,
            playlist: {
              ...playlist,
              fallback: newValue,
            },
          });
        }}
      />
      <Field
        name="leadGenForm.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Access to lead-gen form"
        onChange={newValue => {
          updateChannel({
            ...channel,
            leadGenForm: { active: newValue },
          });
        }}
      />
      <IncludeExcludeCountries
        initialValues={unitAndPlaylistValues}
        geoExclude={geoExclude}
        geoInclude={geoInclude}
        onSubmit={updateChannel}
        readOnly={readOnly}
      />
      <Field
        name="shareUnit.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Sharing a unit"
        onChange={newValue => {
          updateChannel({
            ...channel,
            shareUnit: { active: newValue },
          });
        }}
      />
      <Field
        name="FBShareOriginalUrl.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="FB share original url"
        onChange={newValue => {
          updateChannel({
            ...channel,
            FBShareOriginalUrl: { active: newValue },
          });
        }}
      />
      <Field
        name="lightAnimation.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Light animation (poll, quiz, countdown)"
        onChange={newValue => {
          updateChannel({
            ...channel,
            lightAnimation: { active: newValue },
          });
        }}
      />
      <Field
        name="allowMultiChannel.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Publish to multiple channels at once"
        onChange={newValue => {
          updateChannel({
            ...channel,
            allowMultiChannel: { active: newValue },
          });
        }}
      />
      <Field
        name="unitGeneralSettings.allowStoryFullScreenOnMobile"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Story full screen on mobile"
        onChange={newValue => {
          updateChannel({
            ...channel,
            unitGeneralSettings: {
              ...unitGeneralSettings,
              allowStoryFullScreenOnMobile: newValue,
            },
          });
        }}
      />
      <Field
        name="unitGeneralSettings.storyBottomAdInsideUnit"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Story BottomAd within the unit"
        onChange={newValue => {
          updateChannel({
            ...channel,
            unitGeneralSettings: {
              ...unitGeneralSettings,
              storyBottomAdInsideUnit: newValue,
            },
          });
        }}
      />
      <Field
        name="unitGeneralSettings.autoTagMixDetection"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Auto-URL mix detection"
        onChange={newValue => {
          updateChannel({
            ...channel,
            unitGeneralSettings: {
              ...unitGeneralSettings,
              autoTagMixDetection: newValue,
            },
          });
        }}
      />
    </>
  );

  return (
    <Section title="ON UNIT AND PLAYLIST">
      {unitAndPlaylistValues && (
        <Form
          initialValues={unitAndPlaylistValues}
          fields={renderFields()}
          onSubmit={updateChannel}
          hideCancelButton
          hideApproveButton
        />
      )}
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(OnUnitAndPlaylistForm));
