import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import Glider from 'react-glider';
import { cloneDeep } from 'lodash';

import InteractionsHelper from '../../../../../helpers/InteractionsHelper';
import StoryItem from './StoryItem/StoryItem';
// eslint-disable-next-line import/no-named-as-default
import AddInteractionPopup from './AddInteractionPopup/AddInteractionPopup';
import extractImageElement from './utils';
import { StoresEnum } from '../../../../../stores';
import 'glider-js/glider.min.css';
import './BrandedStoryStrip.scss';

const storyItemModel = {
  index: 0,
  interactionId: undefined,
  image: undefined,
};

const initialStoryItems = Array(16)
  .fill(storyItemModel)
  .map((item, i) => ({
    ...item,
    index: i,
  }));

const assignCleanModel = (mappingObject, itemToDuplicate) => {
  const clonedStory = cloneDeep(itemToDuplicate);
  clonedStory.image = undefined;
  clonedStory.interactionId = undefined;
  mappingObject[clonedStory.index] = clonedStory;
};

async function getStripItems(channelId) {
  const interaction = await InteractionsHelper.requestBrandedStripInteractionsData(channelId);
  return interaction;
}
export class BrandedStoryStripForm extends Component {
  state = {
    popupOpend: false,
    currentStoryItemIndex: undefined,
    stripItems: initialStoryItems,
  };

  channelStoreDisposer;

  async componentDidMount() {
    const { channelStore } = this.props;
    const { stripItems } = this.state;
    this.getStripByChannel(channelStore.channel.publisherId, stripItems);

    this.channelStoreDisposer = reaction(() => channelStore.channel.publisherId, (value, previousValue) => {
      if (value !== previousValue) {
        this.getStripByChannel(value, cloneDeep(initialStoryItems));
      }
    });
  }

  componentWillUnmount() {
    this.channelStoreDisposer();
  }

  getStripByChannel = (publisherId, stripItems) => {
    getStripItems(publisherId).then(fetchedStripItems => {
      const newStripItems = stripItems.slice();
      fetchedStripItems.forEach(interaction => {
        newStripItems[interaction.stripIndex].image = extractImageElement(interaction);
        newStripItems[interaction.stripIndex].interactionId = interaction._id;
      });

      this.setState({ popupOpend: false, stripItems: newStripItems }); // It's ok only because async.
    });
  };

  openPopup = itemIndex => {
    this.setState({
      popupOpend: true,
      currentStoryItemIndex: itemIndex,
    });
  };

  closePopup = () => {
    this.setState({
      popupOpend: false,
    });
  };

  removeInteraction = async storyItem => {
    const { stripItems } = this.state;

    const newStripItems = stripItems.slice();
    assignCleanModel(newStripItems, storyItem);
    this.setState({ stripItems: newStripItems });

    this.closePopup();

    try {
      await InteractionsHelper.updateInteraction(storyItem.interactionId, { stripIndex: null });
    } catch (e) {
      // Rollback.
      this.setState({ stripItems });
    }
  };

  saveInteractionToStrip = async storyItem => {
    const { stripItems } = this.state;

    const newStripItems = stripItems.slice();
    const duplicateItem = newStripItems.find(i => i.interactionId && i.interactionId === storyItem.interactionId);
    // in case it's an interaciton we already have in the strip, we want to remove it from the strip.
    if (duplicateItem) {
      // handles duplicate item added.
      assignCleanModel(newStripItems, duplicateItem);
    }
    newStripItems[storyItem.index] = cloneDeep(storyItem);

    this.setState({ stripItems: newStripItems });

    this.closePopup();

    try {
      await InteractionsHelper.updateInteraction(storyItem.interactionId, { stripIndex: storyItem.index });
    } catch (e) {
      // Rollback.
      this.setState({ stripItems });
    }
  };

  render() {
    const { channelStore: { channel, readOnly } } = this.props;
    const {
      popupOpend, stripItems, currentStoryItemIndex,
    } = this.state;
    const currentStoryItem = stripItems[currentStoryItemIndex];
    return (
      <div className="section">
        <div className="form-title">Branded Story Strip</div>
        <div className="strip-wrapper">
          <Glider hasArrows className="strip-element" draggable slidesToShow={5}>
            {stripItems.map(item => (
              <StoryItem
                key={item.index}
                id={item.index}
                textItem={item.index + 1}
                image={item.image}
                onClick={() => this.openPopup(item.index)}
                readOnly={readOnly}
              />
            ))}
          </Glider>
        </div>
        {popupOpend && (
          <AddInteractionPopup
            publisherId={channel.publisherId}
            storyItem={currentStoryItem}
            closePopup={this.closePopup}
            removeInteraction={this.removeInteraction}
            saveInteraction={this.saveInteractionToStrip}
          />
        )}
      </div>
    );
  }
}

export default inject(StoresEnum.CHANNEL)(observer(BrandedStoryStripForm));
