import React from 'react';
import './Loader.scss';

const loaderImage = require('../../theme/images/loader.gif');

const Loader = props => {
  const { className } = props;
  return (
    <div className={`loader-wrapper ${className}`}>
      <img className="loader" src={loaderImage} alt="" />
    </div>
  );
};

export default Loader;
