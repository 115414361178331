import React from 'react';
import { inject, observer } from 'mobx-react';
import Section from '../../../Section/Section';
import ColorInput from './ColorInput';
import { StoresEnum } from '../../../../stores';

import './ChannelColors.scss';

const MAX_CUSTOM_COLORS = 10;

const ChannelColors = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const addColor = newColor => {
    const clonedChannel = { ...channel };
    const { colors: currentColorList } = clonedChannel.colorPalette || {};

    if (!newColor || currentColorList?.length >= MAX_CUSTOM_COLORS || currentColorList?.includes(newColor)) {
      return;
    }

    if (!currentColorList) {
      clonedChannel.colorPalette = { colors: [newColor] };
    } else {
      clonedChannel.colorPalette.colors.push(newColor);
    }

    updateChannel(clonedChannel);
  };

  const removeColor = index => {
    if (channel.colorPalette?.colors) {
      channel.colorPalette.colors.splice(index, 1);
      updateChannel(channel);
    }
  };

  return (
    <Section title="CHANNEL PALETTE">
      <div className="create-form palette-grid colors-form">
        {!readOnly && (
          <div>
            <div className="colors-subtitle">{`Add HEX colors (max ${MAX_CUSTOM_COLORS}):`}</div>
            <div className="colors-wrapper">
              <ColorInput clearOnSubmit includeSubmitButton addColor={addColor} submitLabel="Add color" />
            </div>
          </div>
        )}

        {channel.colorPalette?.colors?.length ? (
          <div>
            <div className="colors-subtitle">Current colors:</div>
            <div className="colors-wrapper">
              {channel.colorPalette?.colors.map((color, index) => (
                <ColorInput
                  key={`color-wrapper-${color}`}
                  value={color}
                  readOnly
                  includeDeleteButton={!readOnly}
                  label={`Color ${index + 1}`}
                  removeColor={() => removeColor(index)}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(ChannelColors));
