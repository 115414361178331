import React, { useState, useEffect } from 'react';
import unionBy from 'lodash/unionBy';
import geoList from '../../../../utils/geoList';
import { Field, renderMultiSelect, renderSelect } from '../../../../utils/formFieldHelper';

const selectTypes = [
  { name: "Exclude", id: 'Exclude' },
  { name: "Include", id: 'Include' },
];

const convertToSelectFormat = (countries, isSelected) => (
  countries?.map(geo => ({ label: geo.name, id: geo.code, isSelected }))
);

const mergeLists = (oldList, newList, property = 'id') => {
  const joinedLists = [...newList, ...oldList];
  return unionBy(joinedLists, property);
};

const IncludeExcludeCountries = ({
  onSubmit,
  initialValues,
  geoExclude = [],
  geoInclude = [],
  readOnly,
}) => {
  const [allCountries, setAllCountries] = useState([]);
  const [mappedCountries, setMappedCountries] = useState([]);
  const [isInclude, setIsInclude] = useState(geoInclude.length > 0);

  const { playlist } = initialValues;
  const includeCountriesValue = isInclude ? "Include" : "Exclude";
  const playlistGeoList = isInclude ? geoInclude : geoExclude;

  const onChangeSelectType = newValue => {
    if (newValue !== includeCountriesValue) {
      const selectedType = newValue === "Include";
      setIsInclude(selectedType);
      if (playlistGeoList.length && !readOnly) {
        onSubmit({
          ...initialValues,
          playlist: {
            ...playlist,
            ...(isInclude ? { geoExclude: geoInclude, geoInclude: [] } : { geoInclude: geoExclude, geoExclude: [] }),
          },
        });
      }
    }
  };

  const onCountryListChange = list => {
    if (list) {
      const changedCountriesList = list.map(item => ({ name: item.label, code: item.id, isSelected: item.isSelected }));
      const mergedLists = mergeLists(playlistGeoList, changedCountriesList, 'code');
      const newSelectedItems = mergedLists.filter(item => item.isSelected || item.isSelected === undefined);

      onSubmit({
        ...initialValues,
        playlist: {
          ...playlist,
          ...(isInclude ? { geoInclude: newSelectedItems } : { geoExclude: newSelectedItems }),
        },
      });
    }
  };

  useEffect(() => {
    setAllCountries(convertToSelectFormat(geoList));
  }, []);

  useEffect(() => {
    if (allCountries?.length) {
      const selectedList = convertToSelectFormat(playlistGeoList, true);
      setMappedCountries(mergeLists(allCountries, selectedList));
    }
  }, [allCountries, playlistGeoList]);

  return (
    <div className="country-select-group">
      <Field
        name="shouldIncludeCountries"
        autoMenuWidth
        component={renderSelect(selectTypes)}
        onChange={onChangeSelectType}
      />
      <div>
        <Field
          name="countries"
          component={renderMultiSelect(mappedCountries)}
          readOnly={readOnly}
          includeSearch
          placeholder={`${includeCountriesValue} Countries`}
          label={`${includeCountriesValue} Countries`}
          savedSelectedItems={playlistGeoList?.map(({ name }) => name)}
          onClose={onCountryListChange}
        />
      </div>
    </div>
  );
};

export default IncludeExcludeCountries;
