import React from 'react';
import './PlanElement.scss';
import PlanIcon from './PlanIcon';

const PlanNameElement = props => {
  const { subscriptionName }= props;
  return (
    <div className="plan-name">
      <PlanIcon {...props} />
      <div>
        {subscriptionName[0].toUpperCase() + subscriptionName.substr(1)}
        {' '}
        plan
      </div>
    </div>
  );
};

export default PlanNameElement;
