import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { unionBy } from 'lodash';
import UserProvider from '../helpers/UserProvider';

const useChannels = ({
  from = 0, limit = 100, searchName = '', selectedChannels,
}) => {
  const [channels, setChannels] = useState();
  const [fetchedChannels, setFetchedChannels] = useState();

  const transform = response => response.payload || [];

  const { data } = useQuery({
    queryKey: ['search-channels', searchName, from, limit],
    queryFn: () => UserProvider.getPublishers(from, limit, searchName).then(transform),
  });

  const mapChannelsToSelectFormat = () => (
    (channels || fetchedChannels)?.map(channel => ({
      id: channel.publisherId || channel._id,
      label: channel.name,
      isSelected: channel.isSelected,
    })) || []
  );

  useEffect(() => {
    setFetchedChannels(data);
  }, [data]);

  useEffect(() => {
    if (selectedChannels && fetchedChannels) {
      const additionalChannels = selectedChannels
        .filter(channel => channel.name?.includes(searchName))
        .map(channel => ({ ...channel, publisherId: channel._id || channel.publisherId, isSelected: true }));

      const joinedLists = [...additionalChannels, ...fetchedChannels];
      setChannels(unionBy(joinedLists, 'publisherId'));
    }
  }, [selectedChannels, fetchedChannels]);

  return { channels: channels || fetchedChannels, mapChannelsToSelectFormat };
};

export default useChannels;
