/* eslint-disable */
import React from 'react'; import './Modal.scss';

const PlanModal = props => {
  return (
    <div>
      <div className="modal-container-text">
        <div className="text">
          <div id="page">
            <p>
              <strong>Master SaaS Agreement</strong>
            </p>
            <p>
              <em>[Last Updated: September 24, 2019] </em>
            </p>
            <p>
              <em></em>
              <br />
            </p>
            <p>
              This Master SaaS Agreement (“<strong>Agreement</strong>”) governs your engagement with <strong><em>Apester Ltd</em></strong>. (“ <strong>Apester</strong>”,” “<strong>we</strong>” “<strong>our</strong>”), for the purpose of using the platform developed by Apester which provides functionality for creating, embedding and sharing interactive content on websites and apps (“<strong>Digital Assets</strong>” and “ <strong>Platform</strong>” respectively). This Agreement is a legally binding and enforceable agreement between you (“<strong>Customer</strong>” or “<strong>you</strong>”) and Apester. Customer and Apester shall each be referred individually to as a “<strong>party</strong>” and collectively as the “<strong>parties</strong>”.
                  </p>
            <p>
              <strong>ACCEPTANCE OF THE TERMS: </strong> By signing up, filling in your information and checking <strong><em>”Accept" </em></strong>or by using our Platform or Services (as defined below) you acknowledge that you have read, understood and agreed to this Agreement.<strong> </strong>You agree to be bound by this Agreement and to comply with all applicable laws and regulations regarding your use of the Platform and Services. IF YOU DO NOT AGREE TO ALL OR PART OF THIS AGREEMENT HEREIN PLEASE DO NOT CHECK ‘ACCEPT’.
                  </p>
            <ul>
              <li>
                <strong>Registration and Account</strong>
              </li>
              <ul>
                <li>
                  In order to use the Platform and Services, you must first register and create an account (“<strong>Customer Account</strong>”). You hereby represent and warrants you will provide accurate and complete information. In the event of untrue or inaccurate information Apester may suspend or terminate the Customer Account. During the process of registering, you will designate personal and exclusive user name and password to access the Customer Account. You are solely and fully responsible for maintaining the confidentiality of the password and username of your Customer Account and for all activities that occur under the Customer Account. Unauthorized access or use of the Customer Account or Services must be immediately reported to the Apester. You may not assign or transfer your rights or delegate your duties under the Customer Account, including your user name and password, without the prior written consent of Apester.
                          </li>
                <li>
                  Apester will store, process and use the information you provide in the course of registration and through using your Customer Account pursuant to our privacy policy, available at: https://apester.com/privacy-policy/. Note that, we may use your email provided to us during your registration for the purpose of sending you marketing messages and materials as well as operational messages.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Scope of Service</strong>
              </li>
              <ul>
                <li>
                  Subject to the terms herein, Apester shall provide you with the services, which will include: (i) the applicable integration enabling implementation of the Platform within Customer’s Digital Assets; (ii) online dashboard enabling you to choose the applicable scope of services (“<strong>SOW</strong>”) and plans you wish to be provided with (according to the options offered by Apester), as well as view, monitor and obtain information, e.g., actions, statistics, etc. (collectively: “<strong>Services</strong>”).
                          </li>
                <li>
                  Subject to the terms herein, Apester hereby grant you with a limited, revocable, non-exclusive license to use the Platform solely during the Term (as defined below) and solely for the purpose of the Services (“<strong>License</strong>”).
                          </li>
                <li>
                  Apester, at its sole discretion, is entitled to: (i) determine the features, settings or other tools which are available as part of the Service; and (ii) modify, correct, amend, update, enhance, improve, remove, replace or make any other changes to, or discontinue, or cease, temporarily or permanently, the Service, without liability to you.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>License Restrictions </strong>
              </li>
              <ul>
                <li>
                  You hereby undertakes not to, and not to allow others to: (i) attempt to interfere with, compromise the system integrity or security or decipher any transmissions to or from the servers running the Platform or Services; (ii) take any action that imposes, or may impose at Apester’s sole discretion an unreasonable or disproportionately large load on the Services infrastructure; (iii) sell, license (or sub-license), lease, assign, transfer, pledge, or share rights under this Agreement with any third party except as permitted hereunder; (iv) disassemble, decompile, reverse engineer or attempt to discover the Platform’s or Services’ source code or underlying algorithms; (v) upload invalid data, viruses, worms, malicious code or other software agents through the Platform or Services; (vi) interfere with the proper working or security measures of the Services; (vii) bypass the measures Apester may use to prevent or restrict access to the Services; (ix) use the Services for any illegal or unauthorized purpose, or could give rise to civil liability or other lawsuit; (xi) modify the Services, or insert any code or product, or in any other way manipulate the Services in any way or create any derivative works of the Services; or (xiii) use the Services in a manner that violates or infringes any rights of any third party, including but not limited to, privacy rights, publicity rights or intellectual property rights.
                          </li>
                <li>
                  Furthermore, you undertake not to, and not to allow others to use, upload, transfer, post or make available through to the Platform or Services any materials and content, including text, image, logo, graphic, design, etc., visual or audio (collectively: “ <strong>Content</strong>”) that involves, facilitates, advocates or promotes one or more of the following: (i) false, misleading, deceptive, discrimination on the basis of race, ethnicity, gender, religion, sexual orientation, age or disability; (ii) libelous, defamatory, obscene, nudity, pornographic, adult content, sexually explicit or abusive activities; (iii) illegal gambling; (iv) illegal activities; (v) MP3, MPEG and/or copyrighted materials for download, sale or otherwise, in any case without the permission of the copyright owner or otherwise in violation of law; (vi) a conflict or violation of any law or any intellectual property or other rights of any person or entity (“ <strong>Prohibited Materials</strong>”).
                          </li>
                <li>
                  Failure to comply with the provisions set forth above may result herein (at Apester’s sole discretion) in the termination or suspension of access to the Service (or any part or feature thereof) as well as immediate termination of this Agreement, without derogating from any other remedy Apester is entitled to under this Agreement or applicable law.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Representations and Warranties</strong>
              </li>
              <ul>
                <li>
                  You represents and warrants that: (i) you have full legal authority to be engaged by this Agreement; (ii) you own or have the legal rights in the Customer’s Digital Assets used in connection with the Platform and any Content used by you through the Services or uploaded, transferred, posted or made available through to the Platform or Services. It is hereby agreed that you shall be fully and the sole liable for any such Content, and whether created or provided by you, or otherwise created by Apester on your behalf; (iii) Customer’s Digital Assets and Content are and shall be at all time compliant with all applicable laws, rules, regulations, policies and industry standards. Among others, you will be responsible to clearly and conspicuously identify or label all Content provided through the Platform in accordance with all applicable laws and regulations; (iv) Customer’s Digital Assets and Content do not infringe upon any Intellectual Property Right or privacy rights of any third party, do not include or rely on any Prohibited Materials.
                          </li>
                <li>
                  Apester represents and warrants that: (i) it has full legal authority to be engaged by this Agreement; (ii) it owns or has the legal rights in the Platform and Services; and (iii) it will provide the Services in a timely and professional manner.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Consideration</strong>
              </li>
              <ul>
                <li>
                  In consideration for the License and Services, the relevant fees to be paid in connection thereof (“<strong>Consideration</strong>”) shall be calculated and paid in accordance with the SOW chosen by you through your Account, including type of subscription and features.
                          </li>
                <li>
                  All amounts payable under this Agreement are exclusive of all taxes including, among others, sales, value-added and any other taxes, charges, levies and duties.
                          </li>
                <li>
                  Without derogating from the foregoing, Apester reserves the right to change prices of the Services, from time to time, subject to a 30 days prior written notice, as applicable to your subscription (i.e., 30 days prior to the automatic renewal).
                          </li>
                <li>
                  If Customer is late on payment for the Services, Apester may suspend the Services or terminate the Agreement for breach pursuant to Section <strong>6</strong> below<strong>.</strong>
                </li>
              </ul>
            </ul>
            <p>
              <strong></strong>
              <br />
            </p>
            <ul>
              <li>
                <strong>Term and Termination </strong>
              </li>
              <ul>
                <li>
                  This Agreement shall commence on the date you have accepted its terms through registration, and shall thereafter continue, as follows (collectively “<strong>Term</strong>”):
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong></strong>
                <strong>In the event of an Annual Subscription</strong> : this Agreement shall continue for successive periods of twelve (12) months each, that will be automatically renewed unless you will provide Apester with a thirty (30) days written notice (or cancellation in ‘manage plan’ in the Apester platform) prior to the automatic renewal of the annual subscription period.
                      </li>
              <li>
                <strong></strong>
                <strong>In the event of a Monthly Subscription</strong> : this Agreement shall continue for successive periods of one (1) month each, that will be automatically renewed, unless you will provide Apester with a thirty (30) days written notice (or cancellation in ‘manage plan’ in the Apester platform) prior to the automatic renewal of the monthly subscription period.
                      </li>
              <li>
                Notwithstanding the above, Apester may terminate this Agreement for any reason by providing you with a five (5) days prior written notice, or immediately in the event: (i) Customer has breached this Agreement, and such breach is uncured within 24 hours after a notice of the breach was provided by Apester; (ii) Customer files a petition for bankruptcy, is adjudicated bankrupt, is insolvent, makes an assignment for the benefit of creditors, or enters into an agreement with its creditors pursuant to other bankruptcy law.
                      </li>
            </ul>
            <ul>
              <ul>
                <li>
                  Upon the expiration or termination of this Agreement: (i) Each party will promptly return to the other party all Confidential Information of the other party in its possession or control; (ii) you will pay all accrued and unpaid fees and expenses to Apester, if and as applicable; (iii) all rights and licenses granted under this Agreement shall immediately terminate and you shall cease use of the Platform and Services. Notwithstanding the above, and unless otherwise determined by Apester at its sole discretion, following termination, the use of the Platform by you can continue solely on a free mode basis, without the ability to use any additional features, and subject to the free mode Terms of Service available at: https://apester.com/terms-of-service/.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Intellectual Property Rights </strong>
              </li>
              <ul>
                <li>
                  The Intellectual Property Rights and all other rights, title and interest of any nature in and to the Platform and Services or any related documentation made available by or on behalf of Apester hereunder (including all modifications, enhancements, upgrades, customizations and derivative works thereof, and excluding the Content) are and shall remain the exclusive property of Apester and its licensors.<strong> </strong>For the purpose of this Agreement <strong> </strong>“<strong>Intellectual Property Rights</strong>” shall mean all intellectual property rights of every kind and description, including without limitation: (i) rights in or to trademarks and service marks (whether or not registered), trade names and other designations of source of origin, together with all goodwill related to the foregoing, (ii) patents and patent applications, (iii) rights in or to copyrights, whether or not registered, (iv) rights in or to trade secrets and confidential information, including without limitation know-how, technology methods, ideas and inventions, (v) rights in software and computer code (whether in source code, object code or any other form) and (vi) all applications and registrations of any of the foregoing.
                          </li>
                <li>
                  If Apester receives any feedback (<em>e.g</em>., questions, comments, suggestions etc.) regarding any of the Services (the “ <strong>Feedback</strong>”), all rights, including Intellectual Property Rights in such Feedback shall belong exclusively to Apester and to the extent required by applicable law then you hereby irrevocably transfers and assigns to Apester all Intellectual Property Rights it has in such Feedback and waive any and all moral rights that you may have in respect thereto.
                          </li>
                <li>
                  To the extent applicable, you grant Apester the right during the Term to use the Content in accordance with the terms hereof, and you grant Apester the right to use Customer’s name and logo when referring to Apester’s customers in Apester marketing materials.
                          </li>
                <li>
                  Nothing in this Agreement shall be construed as transferring any right, title or interest to you or any third party, unless explicitly stated hereunder. Apester and its licensors reserve any and all rights not expressly granted in this Agreement. The provisions of this section shall remain in full force and effect after termination or expiration of this Agreement for whatever reason.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Indemnification </strong>
              </li>
            </ul>
            <p>
              You shall indemnify, defend and hold harmless, Apester and its respective affiliates, officers, directors, shareholders, or representatives (“ <strong>Indemnified Parties</strong>”) from any and all demands, judgments, awards, losses, damages, expenses, claims and liabilities, and all related costs, including reasonable legal fees (“<strong>Liabilities</strong>”) incurred by Indemnified Parties as a result of or arising out of a third party claim arising from: (i) a breach of this Agreement, including, among others, any representations or warranties made by you hereunder; (ii) a breach by you of any of its confidentiality or data protection obligations hereunder, including, among others, <strong>Exhibit A</strong>; (iii) your gross negligence, willful misconduct or fraud, or your employees, agents, or subcontractors; and (iv) any breach or violation of applicable law by you.
                  </p>
            <ul>
              <li>
                <strong>Limitation of Liability and Disclaimer </strong>
              </li>
            </ul>
            <p>
              Except as otherwise expressly stated hereunder, AND TO the maximum extent permitted by applicable law, THE PLATFORM, SERVICES, CONTENT (IF APPLICABLE AND PROVIDED BY APESTER) AND ANY RELATED DOCUMENTATION, SOFTWARE OR COMPONENT THEREIN are provided on an “as is” and “as available” basis without warranty of any kind. APESTER disclaims all warranties, either express or implied, and MAKES NO REPRESENTATION NOR DOES IT EXTENDS ANY WARRANTY OF ANY KIND, WITH RESPECT TO THE PLATFORM, SERVICES AND CONTENT, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. APESTER ASSUMES NO RESPONSIBILITY OR LIABILITY FOR: (I) ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICES; (II) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES; (III) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES; AND (IV) ANY LOSS OF DATA OR CONTENT. In no event shall apester be liable for any damages whatsoever including, without limitation, indirect, consequential, special, punitive or incidental damages, or damages for loss of business profits, business interruption, loss of business information, or other pecuniary loss, arising out of the use of the SERVICES, EVEN IF APESTER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, AND TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL APESTER’S MAXIMUM AGGREGATE LIABILITY FOR DAMAGES IN CONNECTION WITH THIS AGREEMENT EXCEED THE AMOUNT OF CONSIDERATION ACTUALLY PAID TO APESTER UNDER THIS AGREEMENT IN THE SIX (6) MONTHS PRECEDING SUCH LIABILITY.
                  </p>
            <ul>
              <li>
                <strong>Confidentiality </strong>
              </li>
            </ul>
            <p>
              In the context of the relationship under this Agreement, either party (“<strong>Disclosing Party</strong>”) may disclose to the other party (“ <strong>Receiving Party</strong>”) certain confidential information regarding its technology and business (“ <strong>Confidential Information</strong>”). The Receiving Party agrees to keep confidential and not disclose or use any Confidential Information except to support its use or provision of the Services. Confidential Information shall not include information that Receiving Party can show: (i) was already lawfully known to or independently developed by Receiving Party without access to or use of Confidential Information: (ii) was received by Receiving Party from any third party without restrictions; (iii) is publicly and generally available, free of confidentiality restrictions; or (iv) is required to be disclosed by law, regulation or is requested in the context of a law enforcement investigation, provided that Receiving Party provides Disclosing Party with prompt notice of such requirement and cooperates in order to minimize such requirement. Receiving Party shall restrict disclosure of Confidential Information to those of its employees and contractors with a reasonable need to know such information and which are bound by written confidentiality obligations no less restrictive than those set out herein. The non-disclosure and non-use obligations set forth in this Section 10 shall survive the termination or expiration of this Agreement for a period of 5 years.
                  </p>
            <ul>
              <li>
                <strong>
                  Privacy, Data Protection &amp; End User Disclosure and Consent
          </strong>
              </li>
              <ul>
                <li>
                  When using the Platform, you may grant access to data collection from end users as needed for the purpose of the Services. Hence, to the extent applicable to the parties, the parties shall comply with the terms and condition of the Data Processing Agreement attached hereto as <strong>Exhibit A</strong>.
                          </li>
                <li>
                  You further acknowledge and agree that Apester is solely a service provider, and has no contractual relationship or interaction with end users. You acknowledge that the data collection through the Service is designed to and based on permission and disclosures presented to the end users, as required under applicable laws. Thus, you shall be responsible to obtain the end users’ consent to the collection and processing of data through the Services, by displaying permissions, notices or consent mechanism, as well as disclose its use of Apester Platform and the sharing of data with Apester including a link to Apester privacy policy available at: https://apester.com/privacy-policy/. You will enable the end user to opt-out of data collection, as required under applicable laws. In the event requested by Apester, you will be able to demonstrate end users’ consent obtained by you.
                          </li>
                <li>
                  Apester will store, process and use Personal Data, including Personal Data provided by you (e.g. through Account registration, if applicable) pursuant to Apester’s privacy policy.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Amendments</strong>
              </li>
            </ul>
            <p>
              Apester reserves the right to modify, correct, or amend this Agreement at any time. The most current version of this Agreement will always be posted and any changes will be indicated under the “Last Updated” date above. It is your responsibility to ensure that you are familiar with the most current version of this Agreement. Your continued use of the Services following an updated version of this Agreement was posted, shall constitute your express agreement to be bound by the updated terms. In the event that, subject to Apester’s sole discretion, substantial changes were made, Apester may send or otherwise post an applicable notification.
                  </p>
            <ul>
              <li>
                <strong>Miscellaneous</strong>
              </li>
              <ul>
                <li>
                  Governing Law and Jurisdiction. This Agreement and any claim, controversy, or dispute arising under, related to, or otherwise in connection with this Agreement shall be interpreted, construed, and enforced in accordance with the laws of England, applied without giving effect to any conflicts of law principles. The Parties agree that any lawsuit that may be brought with respect to this Agreement shall be brought and tried exclusively in the competent courts located within London, England.
                          </li>
                <li>
                  Relationship of the Parties.<strong> </strong>Each party is an independent contractor and as such will not have any authority to bind or commit the other. Nothing herein shall be deemed or construed to create a joint venture, fiduciary or agency relationship between the parties for any purpose.
                          </li>
                <li>
                  Assignment. This Agreement may not be assigned or transferred by you without the prior written consent of Apester.
                          </li>
                <li>
                  Force Majeure. Neither party shall be liable for any delay or failure to perform if and to the extent that such delay or failure to perform is caused or otherwise brought about by circumstances beyond the non-performing Party’s reasonable control, including strikes, lockouts, labor troubles, restrictive government or judicial orders or decrees, riots, insurrection, war, terrorism, Acts of God, and/or inclement weather, which the non-performing party is unable to prevent by the exercise of reasonable due diligence, and provided that the non-performing party uses its best efforts to overcome any such circumstances.
                          </li>
                <li>
                  Entire Agreement. This Agreement, including all exhibits, contains the entire agreement of the parties, and supersedes any prior oral or written agreements or understanding between the parties.
                          </li>
                <li>
                  Severability. Should any one or more of the provisions of this Agreement be determined to be invalid, unlawful, or unenforceable in any respect, the validity, legality, and enforceability of the remaining provisions of this Agreement shall not in any way be affected or impaired by such determination and will remain in full force and effect, and the provision affected will be construed so as to be enforceable to the maximum extent permissible by law.
                          </li>
                <li>
                  Waiver. A delay or omission by either party to exercise any right under this Agreement shall not be construed to be a waiver of such right. A waiver by either party of any of the performance provisions of this Agreement shall not be construed to be a waiver of any succeeding performance or breach.
                          </li>
              </ul>
            </ul>
            <p>
              <strong>Exhibit A </strong> - <strong>Data Processing Addendum</strong>
            </p>
            <p>
              <strong></strong>
              <br />
            </p>
            <p>
              This Data Processing Addendum (“<strong>DPA</strong>”) only applies to the extent that EU Data Protection Law (as defined below) applies to the Processing of Personal Data under this Agreement, including if (a) the Processing is in the context of the activities of an establishment of either party in the European Economic Area (“<strong>EEA</strong>”) or (b) the Personal Data relates to Data Subjects who are in the EEA and the Processing relates to the offering to them of goods or services or the monitoring of their behavior in the EEA by or on behalf of a party. Capitalized terms not defined hereunder shall have the meaning ascribed to them in the Agreement.
                  </p>
            <ul>
              <li>
                <strong>DEFINITIONS</strong>
              </li>
              <ul>
                <li>
                  “<strong>Data Protection Law</strong>" means any and all applicable privacy and data protection laws and regulations (including, where applicable, EU Data Protection Law) as may be amended or superseded from time to time.
                          </li>
                <li>
                  "<strong>Controller</strong>", "<strong>Processor</strong>", "<strong>Data Subject</strong>", "<strong>Personal Data</strong>", "<strong>Processing</strong>" (and "<strong>Process</strong>"), “<strong>Personal Data Breach</strong>” and " <strong>Special Categories of Personal Data</strong>" shall all have the meanings given to them in EU Data Protection Law.
                          </li>
                <li>
                  "<strong>EU Data Protection Law</strong>" means the (i) EU General Data Protection Regulation (Regulation 2016/679) (“ <strong>GDPR</strong>”); (ii) the EU e-Privacy Directive (Directive 2002/58/EC), as amended (<strong>e-Privacy Law</strong>); (iii) any national data protection laws made under, pursuant to, replacing or succeeding (i) and (ii); and (iv) any legislation replacing or updating any of the foregoing.
                          </li>
                <li>
                  “<strong>Security Incident</strong>" means any accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Personal Data of the other party. For the avoidance of doubt, any Personal Data Breach of the other party’s Personal Data will comprise a Security Incident.
                          </li>
                <li>
                  “<strong>Users Data</strong>” means any and all end users’ Personal Data Processed through the Services by Apester on behalf of Customer.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>RELATIONSHIP OF THE PARTIES </strong>
              </li>
            </ul>
            <p>
              The parties acknowledge that in relation to all Users Data, as between the parties, Customer is the Controller of Customer Data, and Apester, in providing the Services is acting as a Processor on behalf of the Customer. Without derogating from the above, it is hereby clarified that in addition to the Apester’s capacity as a Processor of the User Data, Apester is also a Controller of certain Personal Data related to the Customer, such as Customer’s registration data, Customer’s personnel contact details, etc., and such Personal Data shall be used in accordance with Apester Privacy Policy available at: https://apester.com/privacy-policy/.
                  </p>
            <ul>
              <li>
                <strong>REPRESENTATIONS AND WARRANTIES </strong>
              </li>
            </ul>
            <p>
              The Customer represents and warrants that: (a) its Processing instructions shall comply with applicable Data Protection Law, and the Customer acknowledges that, taking into account the nature of the Processing, Apester is not in a position to determine whether the Customer’s instructions infringe applicable Data Protection Law; and (b) it will comply with EU Data Protection Law, specifically with the lawful basis for Processing Personal Data. Apester represents and warrants that it shall process Personal Data, as set forth under Article 28(3) of the GDPR, on behalf of the Customer, solely for the purpose of providing the Service. Notwithstanding the above, in the event required under applicable laws, Apester may Process Personal Data other than as instructed by Customer, in such event, Apester shall make best efforts to inform the Customer of such requirement unless prohibited under applicable law.
                  </p>
            <ul>
              <li>
                <strong>
                  PROCESSING OF PERSONAL DATA AND COMPLIANCE WITH DATA PROTECTION LAW
          </strong>
              </li>
              <ul>
                <li>
                  The Customer represents and warrants that Special Categories of data shall not be Processed or shared in connection with the performance of Apester obligations under the Agreement, unless agreed in writing by Apester and shared in accordance with applicable Data Protection Law.
                          </li>
                <li>
                  Unless otherwise agreed to in writing by the Parties, the Customer shall not share any Personal Data with Apester that contains Personal Data relating to children under 16 years old.
                          </li>
                <li>
                  As between the parties, the Customer undertakes, accepts and agrees that Apester and the Data Subject do not have a direct relationship. The Customer shall ensure that it obtains a proper affirmative act of consent from Data Subjects in the event required in accordance with applicable Data Protection Law and other relevant privacy requirements in order to Process Personal Data as set out herein.
                          </li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>
                  RIGHTS OF DATA SUBJECT AND PARTIES COOPERATION OBLIGATIONS
          </strong>
              </li>
            </ul>
            <p>
              It is agreed that where the Apester receives a request from a Data Subject or an applicable authority in respect of User Data Processed by Apester, where relevant, Apester will direct the Data Subject or the applicable authority to the Customer in order to enable the Customer to respond directly to the Data Subject’s or applicable authority’s request, unless otherwise required under applicable laws. Both Parties shall provide each other with commercially reasonable cooperation and assistance in relation to handling of a Data Subject’s or applicable authority’s request, to the extent permitted under Data Protection Law.
                  </p>
            <ul>
              <li>
                <strong>SUB-PROCESSOR</strong>
              </li>
            </ul>
            <p>
              The Customer acknowledges that Apester may transfer Personal Data to and otherwise interact with third party data processors (“ <strong>Sub-Processor</strong>”). The Customer hereby, authorizes Apester to engage and appoint such Sub-Processors to Process Personal Data, as well as permits each Sub-Processor to appoint a Sub-Processor on its behalf. Apester may, continue to use those Sub-Processors already engaged by Apester and Apester may, engage an additional or replace an existing Sub-Processor to process Personal Data provided that it provides an applicable notification. Apester shall, where it engages any Sub-Processor impose, through a legally binding contract between Apester and Sub-Processor, data protection obligations no less onerous than those set out in this DPA on the Sub-Processor, in particular providing sufficient guarantees to implement appropriate technical and organizational measures in such a manner that the processing will meet the requirements of the GDPR.
                  </p>
            <ul>
              <li>
                <strong>TECHNICAL AND ORGANIZATIONAL MEASURES </strong>
              </li>
            </ul>
            <p>
              Apester has implemented appropriate technical and organizational measures to protect the User Data, as required under Data Protection Law.
                  </p>
            <ul>
              <li>
                <strong>SECURITY INCIDENT</strong>
              </li>
            </ul>
            <p>
              Apester will notify the Customer upon becoming aware that an actual Security Incident involving the User Data in Apester’s possession or control has occurred, as Apester determines in its sole discretion. Apester’s notification of or response to a Security Incident under this Section 8 shall not be construed as an acknowledgment by Apester of any fault or liability with respect to the Security Incident. Apester will, in connection with any Security Incident affecting the User Data: (i) take such steps as are necessary to contain, remediate, minimize any effects of and investigate any Security Incident and to identify its cause; (ii) co-operate with the Customer and provide the Customer with such assistance and information as it may reasonably require in connection with the containment, investigation, remediation or mitigation of the Security Incident; and (iii) notify the Customer in writing of any request, inspection, audit or investigation by a supervisory authority or other authority.
                  </p>
            <ul>
              <li>
                <strong>AUDIT RIGHTS</strong>
              </li>
            </ul>
            <p>
              Apester shall make available, solely upon prior written notice and no more than once per year, to a reputable auditor nominated by the Customer, information necessary to reasonably demonstrate compliance with this DPA, and shall allow for audits, including inspections, by such reputable auditor solely in relation to the Processing of the User Data (“ <strong>Audit</strong>”) in accordance with the terms and conditions hereunder. The Audit shall be subject to the terms of this DPA and confidentiality obligations (including towards third parties). Apester may object to an auditor appointed by the Customer in the event Apester reasonably believes, the auditor is not suitably qualified or independent, a competitor of Apester or otherwise manifestly unsuitable (“ <strong>Objection Notice</strong>”). In the event of Objection Notice, Customer will appoint a different auditor or conduct the Audit itself. Customer shall bear all expenses related to the Audit and shall avoid causing any damage, injury or disruption to Apester’s premises, equipment, personnel and business while its personnel are on those premises in the course of such Audit. Any and all conclusions of such Audit shall be confidential and reported back to Apester immediately.
                  </p>
            <ul>
              <li>
                <strong>DATA TRANSFER</strong>
              </li>
            </ul>
            <p>
              Where EU Data Protection Law applies, neither party shall transfer Personal Data to a territory outside of the EEA unless it has taken such measures as are necessary to ensure the transfer is in compliance with EU Data Protection Law. Such measures may include (without limitation) transferring the Personal Data to a recipient in a country that the European Commission has decided provides adequate protection for Personal Data.
                  </p>
            <ul>
              <li>
                <strong>CONFLICT </strong>
              </li>
            </ul>
            <p>
              In the event of a conflict between the terms and conditions of this DPA and the Agreement, this DPA shall prevail. Except as set forth herein all of the terms and conditions of the Agreement shall remain in full force and effect.
                  </p>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="plan-term">
          <label className="terms">
            <input type="checkbox" name="termsConfirm" onChange={props.success} checked={props.termsChecked} required />
            <span className="checkmark-box"></span>
          </label>
          <span className="terms-title">I agree to Apester’s terms and conditions. </span>
        </div>

      </div>
    </div>)
}

export default PlanModal;
