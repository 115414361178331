import React from 'react';
import Divider from '@mui/material/Divider';
import CustomTextField from '../Input/CustomTextField/CustomTextField';

import './MultiselectDropdown.scss';

const DEBOUNCE_TIMEOUT = 500;

const SelectSearchField = ({ onSearch, name }) => (
  <>
    <div className="list-item-button search-item">
      <CustomTextField
        onChange={onSearch}
        includeSearchIcon
        placeholder={`Search ${name}`}
        className="select-search-field"
        debounceTimeout={DEBOUNCE_TIMEOUT}
      />
    </div>
    <Divider classes={{ root: 'divider' }} />
  </>
);

export default SelectSearchField;
