import React from 'react';
import Security from './Security';
import RoutingService from '../../helpers/RoutingService';

const SecurityWrapper = props => {
  const { userStore: { user }, history: { push } } = props;
  const securityUrl = RoutingService.routes.settings.routes.profile.url.replace(
    ':section?/:page?/:option?',
    '',
  );
  return (
    <Security
      onPresentPassword={shouldOpen => {
        const url = !shouldOpen
          ? securityUrl
          : RoutingService.routes.settings.routes.profile.routes.password.url;
        push(url);
      }}
      onPresentAuthMFA={shouldOpen => {
        const url = !shouldOpen || (user.authorized && user.TOTPClient.mfaType === 'sms')
          ? securityUrl
          : RoutingService.routes.settings.routes.profile.routes.mfa.routes.active.url;
        push(url);
      }}
      onPresentAuthApp={shouldOpen => {
        const url = !shouldOpen || !user.authorized
          ? securityUrl
          : RoutingService.routes.settings.routes.profile.routes.mfa.routes.app.url;
        if (user.authorized) {
          push(url);
        }
      }}
      onPresentAuthBackup={shouldOpen => {
        const backupUrl = RoutingService.routes.settings.routes.profile.routes.mfa.routes.backup.url.replace(
          '/:option?',
          '',
        );
        const url = !shouldOpen || !user.authorized ? securityUrl : backupUrl;
        if (user.authorized) {
          push(url);
        }
      }}
      {...props}
    />
  );
};

export default SecurityWrapper;
