import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { AddRounded } from '@mui/icons-material';
import AddItem from '../AddItem/AddItem';
import MultiselectDropdown from '../../ApeUI/MultiselectDropdown/MultiselectDropdown';
import useUsers from '../../../hooks/useUsers';
import { isSelfRemovalException, isSingleChannelException } from '../../../utils/utils';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';

const DEBOUNCE_TIMEOUT = 500;

const AddMember = ({
  newMemberHandler,
  isEditable,
  isSuperAdmin,
  handleMembersChange,
  assignedMembers,
  inviteMember,
  disableAddAction,
  assignTo,
}) => {
  const [searchName, setSearchName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [mappedMembers, setMappedMembers] = useState([]);
  const [snackbarCurrentContent, setSnackbarCurrentContent] = useState();

  const { users, mapUsersToSelectFormat } = useUsers({ searchName, selectedUsers: assignedMembers, enabled: isSuperAdmin });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMembersChangeError = error => {
    if (isSingleChannelException(error)) {
      const foundMember = mappedMembers.find(member => member.id === error.payload);
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SINGLE_CHANNEL_EXCEPTION](foundMember?.label);
      setSnackbarCurrentContent(content);
    }

    if (isSelfRemovalException(error)) {
      const content = snackbarContent[snackbarTopics.UNASSIGN_MEMBER_SELF_REMOVAL_EXCEPTION]();
      setSnackbarCurrentContent(content);
    }
  };

  const handleClose = async updatedList => {
    setAnchorEl(null);

    if (updatedList) {
      setSearchName('');
      const response = await handleMembersChange(updatedList);

      if (response?.error) {
        handleMembersChangeError(response.error);
      }

      if (response?.success && updatedList.length) {
        const content = snackbarContent[snackbarTopics.GENERAL_MEMBERS_CHANGE]();
        setSnackbarCurrentContent(content);
      }
    }
  };

  useEffect(() => {
    setMappedMembers(mapUsersToSelectFormat());
  }, [users]);

  return (
    <>
      {isEditable && (
        <div className={`add-item-wrapper ${isSuperAdmin ? 'autocomplete-search-wrapper' : ''}`}>
          {isSuperAdmin
            ? (
              <>
                <AddItem
                  className="search-field"
                  onChange={setSearchName}
                  onInputClick={handleClick}
                  inputValue={searchName}
                  placeholder={`Search a member to assign to the ${assignTo}`}
                  isSearchField
                  debounceTimeout={DEBOUNCE_TIMEOUT}
                  disabled={disableAddAction}
                />
                <Button
                  classes={{ label: 'button-label' }}
                  className="outlined-secondary"
                  startIcon={<AddRounded />}
                  disabled={disableAddAction}
                  onClick={newMemberHandler}
                >
                  New member
                </Button>
              </>
            )
            : <AddItem placeholder="Enter Email" onClick={inviteMember} />
          }
        </div>
      )}

      {isSuperAdmin && (
        <MultiselectDropdown
          isOpen={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handleClose={handleClose}
          items={mappedMembers}
        />
      )}

      <ApeSnackbar
        open={Boolean(snackbarCurrentContent?.message)}
        message={snackbarCurrentContent?.message}
        onRequestClose={() => setSnackbarCurrentContent()}
        iconType={snackbarCurrentContent?.type}
      />
    </>
  );
};

AddMember.propTypes = {
  newMemberHandler: PropTypes.func.isRequired,
  handleMembersChange: PropTypes.func.isRequired,
  inviteMember: PropTypes.func,
  isEditable: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  disableAddAction: PropTypes.bool,
  assignTo: PropTypes.string.isRequired,
};

AddMember.defaultProps = {
  isEditable: false,
  isSuperAdmin: false,
  disableAddAction: false,
  inviteMember: undefined,
};

export default AddMember;
