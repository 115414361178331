import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';
import DeleteGroupPopup from './DeleteGroupPopup/DeleteGroupPopup';
import Form from '../Form/Form';
import { SellerTypes } from '../../../utils/constants';
import {
  Field, renderField, renderSelect, renderCheckbox,
} from '../../../utils/formFieldHelper';
import {
  email, required, composeValidators, FORM_ERROR_MESSAGES,
} from '../../../utils/validation';
import geoList from '../../../utils/geoList';
import { isAlreadyExistException } from '../../../utils/utils';
import UserProvider from '../../../helpers/UserProvider';
import EventDataBuilder from '../../../helpers/EventDataBuilder';
import EventTracker, { EVENT_TYPE } from '../../../helpers/EventTracker';
import { StoresEnum } from '../../../stores';

const GroupForm = ({
  routing: { push }, initialValues, onGroupChange, updateNameOnTyping,
}) => {
  const [mappedCountries, setMappedCountries] = useState([]);
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const mappedSelerTypes = Object.values(SellerTypes).map(val => ({ name: val, id: val }));

  const handleResponse = (response, groupName) => {
    if (response.success) {
      const message = snackbarContent[snackbarTopics.GROUP_SAVED](groupName, !initialValues?._id)?.message;
      onGroupChange(true, message, response);
      const isNewGroup = !initialValues?._id && response._id;
      if (isNewGroup) {
        EventTracker.track(EVENT_TYPE.GROUP_CREATED, EventDataBuilder.groupCreated(response));
        push(`${response._id}`);
      }
    }

    return isAlreadyExistException(response.error) ? { name: FORM_ERROR_MESSAGES.NAME_ALREADY_EXIST } : undefined;
  };

  const handleDeleteGroup = async () => {
    setOpenDeletePopUp(true);
  };

  const submitGroup = async formProps => {
    const response = initialValues._id
      ? await UserProvider.updateGroup({ ...formProps, _id: initialValues._id })
      : await UserProvider.createGroup(formProps);

    return handleResponse(response, formProps.name);
  };

  useEffect(() => {
    setMappedCountries(geoList.map(({ name, code }) => ({ name, id: code })));
  }, []);

  const renderFields = () => (
    <>
      <Field
        name="name"
        type="text"
        component={renderField}
        label="Group name"
        validate={required}
        onChange={value => updateNameOnTyping(value)}
      />
      <Field
        name="companyDomain"
        type="text"
        component={renderField}
        label="Company domain"
        validate={required}
      />
      <Field
        name="geo"
        type="text"
        label="Country"
        placeholder="Pick a country"
        includeSearch
        searchLabel="country"
        component={renderSelect(mappedCountries)}
        validate={required}
      />
      <Field
        name="successManagerEmail"
        type="email"
        component={renderField}
        label="Success manager Email"
        validate={composeValidators(required, email)}
      />
      <Field
        name="salesManagerEmail"
        type="email"
        component={renderField}
        label="Sales manager Email"
        validate={email}
        allowEmpty
      />
      <Field
        name="isConfidential"
        type="checkbox"
        component={renderCheckbox}
        label="Is confidential"
      />
      <Field
        name="sellerType"
        type="text"
        component={renderSelect(mappedSelerTypes)}
        label="Seller type"
        validate={required}
      />
      <Field
        name="shouldExludeFromSellerJson"
        type="checkbox"
        component={renderCheckbox}
        label="Exclude from seller.json"
      />
    </>
  );

  return (
    <Form
      initialValues={initialValues}
      fields={renderFields()}
      onSubmit={submitGroup}
      approveLabel={initialValues?._id ? 'Update' : 'Create'}
      cancelLabel="Delete"
      cancelClass="outlined-secondary delete"
      disableCancelButton={initialValues?.status === 'inactive'}
      onCancel={handleDeleteGroup}
    >
      <DeleteGroupPopup
        group={initialValues}
        shouldOpen={openDeletePopUp}
        setShouldOpen={setOpenDeletePopUp}
        onGroupChange={onGroupChange}
      />
    </Form>
  );
};

GroupForm.propTypes = {
  onGroupChange: PropTypes.func.isRequired,
  updateNameOnTyping: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

GroupForm.defaultProps = {
  initialValues: undefined,
};

export default inject(StoresEnum.ROUTING)(observer(GroupForm));
