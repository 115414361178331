import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import GroupForm from '../../../components/Settings/GroupForm/GroupForm';
import Section from '../../../components/Section/Section';
import { AvatarContainer } from '../../../components/ApeUI/AvatarContainer/AvatarContainer';
import ChannelsSection from '../../../components/Settings/ChannelsSection/ChannelsSection';
import GroupMembersSection from '../../../components/Settings/MembersSection/GroupMembersSection';
import ApeSnackbar from '../../../components/ApeUI/ApeSnackbar/ApeSnackbar';
import ChannelForm, { FIELD_NAMES } from '../../../components/Settings/ChannelForm/ChannelForm';
import UserProvider from '../../../helpers/UserProvider';
import { snackbarContent, snackbarTopics } from '../../../components/ApeUI/ApeSnackbar/SnackbarContent';
import ProfileSkeleton from './ProfileSkeleton';
import CdnMediaProvider from '../../../helpers/CdnMediaProvider';
import EventTracker, { EVENT_TYPE } from '../../../helpers/EventTracker';
import EventDataBuilder from '../../../helpers/EventDataBuilder';
import { StoresEnum } from '../../../stores';

const DEFAULT_GROUP_NAME = 'Group Name';

class GroupsProfile extends Component {
  state = {
    showNewChannelSection: false,
    showNewMemberSection: false,
    snackbarMessage: undefined,
    snackbarType: undefined,
    groupName: undefined,
  };

  componentDidMount() {
    this.onIdParamChange();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { groupId } = match.params;
    const { groupId: prevId } = prevProps.match.params;
    if (groupId !== prevId) {
      this.onIdParamChange();
    }
  }

  componentWillUnmount() {
    const { groupStore: { clearGroup } } = this.props;
    clearGroup();
  }

  onIdParamChange = () => {
    const {
      match, isNewGroup, groupStore: { fetchGroup, group, clearGroup },
    } = this.props;
    const { groupId } = match.params;

    if (groupId && !isNewGroup && groupId !== group?._id) {
      fetchGroup(groupId);
    }

    if (!groupId) {
      clearGroup();
    }
  };

  onGroupChange = (hasSucceed, message, updatedGroup) => {
    const { groupStore: { fetchGroup, group, setGroup } } = this.props;
    if (message) {
      this.setState({ snackbarMessage: message, snackbarType: !!hasSucceed });
    }

    const isNewGroup = !group._id;
    if (isNewGroup || !hasSucceed) {
      return;
    }

    EventTracker.track(EVENT_TYPE.GROUP_UPDATED, EventDataBuilder.groupUpdated(group));

    if (updatedGroup) {
      setGroup(updatedGroup);
    } else {
      fetchGroup(group._id);
    }
  };

  handleChannelSubmit = async channel => {
    const { groupStore: { fetchGroup, group } } = this.props;
    const response = await UserProvider.newChannelToGroup(group._id, channel);
    if (response?.publisherId) {
      const content = snackbarContent[snackbarTopics.CHANNEL_SAVED](channel.name, true);
      this.setState({ snackbarMessage: content.message, snackbarType: content.type, showNewChannelSection: false });
      fetchGroup(group._id);
    }

    return response;
  };

  newChannelHandler = () => {
    const { showNewChannelSection } = this.state;
    this.setState({
      showNewChannelSection: !showNewChannelSection,
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  newMemberHandler = () => {
    const { showNewMemberSection } = this.state;
    this.setState({
      showNewMemberSection: !showNewMemberSection,
    });
  };

  updateNameOnTyping = newName => {
    this.setState({ groupName: newName?.length ? newName : DEFAULT_GROUP_NAME });
  };

  groupAvatarChange = async e => {
    const { groupStore: { group, setGroup } } = this.props;
    const profileImage = await CdnMediaProvider.uploadImage(e);
    if (profileImage) {
      const updatedGroup = await UserProvider.updateGroup({ ...group, profileImage });
      setGroup(updatedGroup);
    }
  };

  renderAvatarContainer = () => {
    const { groupStore: { group } } = this.props;
    const { groupName } = this.state;
    return (
      <AvatarContainer
        name={group._id ? group.name : groupName || DEFAULT_GROUP_NAME}
        picture={group.profileImage}
        handleAvatarChange={this.groupAvatarChange}
      >
        <span style={{ color: 'red' }}>{group.status === 'inactive' ? group.status : null}</span>
      </AvatarContainer>
    );
  };

  render() {
    const {
      groupStore: { group },
      userStore: { isSuperAdmin },
      paymentsStore: { defaultPlanId },
      isNewGroup,
    } = this.props;
    const {
      showNewChannelSection, snackbarMessage, snackbarType,
    } = this.state;

    return (
      <>
        <ProfileSkeleton avatar={this.renderAvatarContainer()}>
          <Section title="info">
            <GroupForm
              initialValues={group}
              onGroupChange={this.onGroupChange}
              updateNameOnTyping={this.updateNameOnTyping}
            />
          </Section>
          {!isNewGroup && group._id && isSuperAdmin && (
            <>
              <Section title="channels">
                {showNewChannelSection ? (
                  <ChannelForm
                    onSubmit={this.handleChannelSubmit}
                    innerTitle="CREATE NEW CHANNEL FOR THIS GROUP"
                    fieldsList={[FIELD_NAMES.BILLINGS, FIELD_NAMES.PLAN]}
                    channel={{ plan: defaultPlanId }}
                    isSuperAdmin
                    onCancel={this.newChannelHandler}
                  />
                ): (
                  <ChannelsSection
                    newChannelHandler={this.newChannelHandler}
                    onGroupChange={this.onGroupChange}
                    assignedChannels={group.channels}
                    groupId={group._id}
                  />
                )}
              </Section>

              {group?.channels?.length ? (
                <Section title="members">
                  <GroupMembersSection group={group} onGroupChange={this.onGroupChange} />
                </Section>
              ) : null}
            </>
          )}
        </ProfileSkeleton>

        <ApeSnackbar
          open={Boolean(snackbarMessage)}
          message={snackbarMessage}
          onRequestClose={() => this.setState({ snackbarMessage: undefined, snackbarType: undefined })}
          iconType={snackbarType}
        />
      </>
    );
  }
}

export default inject(StoresEnum.GROUP, StoresEnum.USER, StoresEnum.PAYMENTS)(observer(GroupsProfile));
