import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import { DoneRounded } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import EllipsisText from '../../EllipsisText/EllipsisText';
import SelectSearchField from './SelectSearchField';

import './MultiselectDropdown.scss';

const filterListBySearchName = (list, search) => (
  list.filter(item => item.name?.toLowerCase()?.includes(search))
);

const SingleSelectDropdown = ({
  isOpen, anchorEl, onClose, list, includeSearch, selectedId, name, fetchList, searchLabel, size, popoverClass, alignDropdownToFieldWidth,
}) => {
  const [filteredItems, setFilteredItems] = useState();

  const id = isOpen ? 'single-select-dropdown' : undefined;

  const handleClose = selected => {
    onClose(selected?.id);
    setFilteredItems(list);
  };

  // For lists that have static list we can avoid passing async function (fetchList) as prop and handle the filtering in this component
  const onSearch = async value => {
    if (fetchList) {
      const items = await fetchList(value);
      setFilteredItems(items);
      return;
    }

    setFilteredItems(!value?.length ? undefined : filterListBySearchName(list, value));
  };

  const renderList = () => (
    <List disablePadding>
      {(filteredItems || list)?.map(item => (
        <ListItem
          key={item.id}
          value={item.id}
          onClick={() => handleClose(item)}
          classes={{ root: `list-item-button ${selectedId === item.id ? 'selected' : ''} ${item.disabled ? 'disabled-item' : ''}` }}
        >
          <span>{selectedId === item.id && <DoneRounded />}</span>
          <EllipsisText className="primary-text" text={item.name} maxWidth="100%" />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      className={`custom-menu-popper custom-select-menu single-select ${popoverClass} ${size}`}
      placement="bottom-start"
      disableAutoFocus
      disableEnforceFocus
    >
      <div style={alignDropdownToFieldWidth ? { width: anchorEl?.clientWidth } : {}}>
        {includeSearch && <SelectSearchField name={searchLabel || name} onSearch={onSearch} />}

        <div className="options-list">
          {renderList()}
        </div>
      </div>
    </Popover>
  );
};

SingleSelectDropdown.propTypes = {
  alignDropdownToFieldWidth: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  includeSearch: PropTypes.bool,
  popoverClass: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  fetchList: PropTypes.func,
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  })),
};

SingleSelectDropdown.defaultProps = {
  alignDropdownToFieldWidth: false,
  includeSearch: false,
  anchorEl: null,
  selectedId: undefined,
  fetchList: undefined,
  popoverClass: '',
  list: [],
  name: '',
};

export default SingleSelectDropdown;
