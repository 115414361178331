import React from 'react';
import { Avatar } from "..";
import { getImageUrl } from '../../../utils/utils';

import './AvatarContainer.scss';

export const NumberOfMembers = channel => {
  const { admins, editors } = channel;
  const members = admins && editors && admins.length + editors.length;
  const content = `${members} ${parseInt(members) > 1 ? 'Members' : 'Member'}`;

  return (
    members
      ? (
        <div
          style={{
            color: "#4a4a4a",
            lineHeight: "1.14",
            fontSize: '14px',
            fontWeight: 'normal',
            marginTop: "8px",
            letterSpacing: '0.4px',
          }}
        >
          {content}
        </div>
      )
      : <div />
  );
};

export const AvatarContainer = props => {
  const {
    title, name, picture, handleAvatarChange, children, onLeftClick, onRightClick, avatarTitleStyle,
  } = props;
  return (
    <div className="avatar-container">
      <div className="avatar-title" style={avatarTitleStyle}>{title}</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {onLeftClick && <div className="navigation-icon ic icon-arrow-left" onClick={onLeftClick} />}
        <Avatar
          avatarSize={144}
          profileImage={getImageUrl(picture)}
          userAvatarChange={handleAvatarChange}
        />
        {onRightClick && <div className="navigation-icon ic icon-arrow-right" onClick={onRightClick} />}
      </div>
      <div className="avatar-data">
        <div className="avatar-name">{name}</div>
        {children}
      </div>
    </div>
  );
};
