import React, { Component } from "react";
import { inject, observer } from 'mobx-react';
import interactionHelper from "../../../helpers/InteractionsHelper";
import SecurityWrapper from "../../../components/Security/SecurityWrapper";
import UserForm from "../../../components/Settings/UserForm/UserForm";
import { AvatarContainer } from "../../../components/ApeUI/AvatarContainer/AvatarContainer";
import ProfileSkeleton from "./ProfileSkeleton";
import CdnMediaProvider from "../../../helpers/CdnMediaProvider";
import { StoresEnum } from "../../../stores";

class Profile extends Component {
  state = {
    numberOfUnits: "0",
  };

  componentDidMount() {
    interactionHelper.getInteractions().then(res => {
      this.setState({
        numberOfUnits: (typeof res !== "object" && res) || "0",
      });
    });
  }

  handleSubmit = values => {
    const { userStore: { updateUser } } = this.props;
    updateUser(values);
  };

  renderUserData = ({ numberOfUnits, numberOfChannels }) => (
    <div className="user-data">
      <div className="number line">{numberOfUnits}</div>
      <div className="data-title line">Units</div>
      <div className="number">{numberOfChannels}</div>
      <div className="data-title" style={{ marginLeft: '3px' }}>Channels</div>
    </div>
  );

  userAvatarChange = async e => {
    const profileImage = await CdnMediaProvider.uploadImage(e);
    if (profileImage) {
      const { userStore: { updateUser } } = this.props;
      updateUser({ profileImage });
    }
  };

  renderAvatarContainer = () => {
    const { numberOfUnits } = this.state;
    const { userStore: { user, isSuperAdmin } } = this.props;
    const {
      displayName, profileImage, picture, publishers,
    } = user;
    const numberOfChannels = user && publishers?.length;

    return (
      <AvatarContainer
        title="Profile"
        name={displayName}
        picture={profileImage || picture}
        handleAvatarChange={this.userAvatarChange}
      >
        {!isSuperAdmin && this.renderUserData({ numberOfUnits, numberOfChannels })}
      </AvatarContainer>
    );
  };

  render() {
    const { userStore: { user } } = this.props;

    return (
      <ProfileSkeleton avatar={this.renderAvatarContainer()}>
        {user.loggedIn && <UserForm initialValues={user} onSubmit={this.handleSubmit} />}
        <SecurityWrapper {...this.props} />
      </ProfileSkeleton>
    );
  }
}

export default inject(StoresEnum.USER)(observer(Profile));
