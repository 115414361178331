import {
  action, computed, makeObservable, observable, reaction,
} from 'mobx';
import PaymentsProvider from '../helpers/PaymentsProvider';
import { getAuthRestriction } from '../utils/utils';
import { checkPayingUserId, getDefaultPlan } from '../components/Channels/Channel/Payments/payments-util';

export default class PaymentsStore {
  currentSubscription = null;

  hasSubscriptionFetched = false;

  isBlockable = false;

  internalPlans = undefined;

  stripePlans = undefined;

  defaultPlanId = undefined;

  error = undefined;

  get isPayingUser() {
    return checkPayingUserId(this.userStore.user.userId, this.channelStore.channel);
  }

  constructor(userStore, channelStore) {
    makeObservable(this, {
      currentSubscription: observable,
      fetchSubscription: action,
      hasSubscriptionFetched: observable,
      defaultPlanId: observable,
      isBlockable: observable,
      setIsBlockable: action,
      checkIsBlockable: action,
      setSubscription: action,
      isPayingUser: computed,
      internalPlans: observable,
      setInternalPlans: action,
      fetchInternalPlans: false,
      stripePlans: observable,
      fetchStripePlans: false,
      setStripePlans: action,
      error: observable,
      setError: action,
      fetchPlans: action,
    });

    this.userStore = userStore;
    this.channelStore = channelStore;

    reaction(
      () => this.channelStore.channel?.publisherId,
      async publisherId => this.fetchSubscription(publisherId),
    );

    reaction(
      () => this.currentSubscription?.updatedAt,
      async () => this.checkIsBlockable(),
    );
  }

  setSubscription = subscription => {
    this.currentSubscription = { ...this.currentSubscription, ...subscription };
    this.hasSubscriptionFetched = true;
  };

  setIsBlockable = isBlockable => {
    this.isBlockable = isBlockable;
  };

  setInternalPlans = payload => {
    this.internalPlans = payload;
    this.defaultPlanId = getDefaultPlan(payload)?.id;
  };

  setStripePlans = stripePlans => {
    this.stripePlans = stripePlans;
  };

  setError = error => {
    this.error = error;
  };

  fetchInternalPlans = async () => {
    const internalPlans = await PaymentsProvider.fetchInternalPlans();
    this.setInternalPlans(internalPlans);
  };

  // Not sure we really need this list, we should address it on future ticket (POS-777)
  fetchStripePlans = async () => {
    const billingPlans = await PaymentsProvider.getBillingPlans();
    this.setStripePlans(billingPlans);
  };

  fetchPlans = () => {
    if (this.userStore.isSuperAdmin) {
      this.fetchInternalPlans();
    }

    this.fetchStripePlans();
  };

  fetchSubscription = async publisherId => {
    if (!publisherId || this.channelStore.readOnly) {
      return this.setSubscription();
    }

    let subscriptionData = await PaymentsProvider.getSubscriptionPerPublisher(publisherId);
    if (subscriptionData && subscriptionData.status === 'incomplete') {
      subscriptionData = await PaymentsProvider.getSubscriptionPerPublisher(publisherId, true);
    }

    return this.setSubscription(subscriptionData);
  };

  checkIsBlockable = async () => {
    if (this.currentSubscription.id && this.userStore.isSuperAdmin) {
      const isBlockable = await getAuthRestriction(this.channelStore.channel.publisherId, 'force-block');
      this.setIsBlockable(isBlockable);
    }
  };
}
