import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import uniqBy from 'lodash/uniqBy';
import UserProvider from '../../../helpers/UserProvider';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';
import MembersTable from '../MembersTable/MembersTable';
import { channelMembersColumns } from '../../../utils/constants';
import AddMember from './AddMember';
import MemberForm from './MemberForm';
import { StoresEnum } from '../../../stores';

const setBulkMembers = (publisherId, members) => (
  UserProvider.addBulkMembersToPublisher(
    publisherId,
    members.filter(member => member.isSelected).map(member => member.secondaryLabel),
  )
);

const unsetBulkInvited = (publisherId, members) => {
  const invites = members
    .map(member => ({ userEmail: member.to || member.emails?.[0], publisherId }));
  return UserProvider.deleteBulkPublisherInvitations(invites, publisherId);
};

const unsetBulkRegistered = (publisherId, members) => (
  UserProvider.removeBulkMembersFromPublisher(
    publisherId,
    members.map(member => member.emails?.[0] || member.secondaryLabel),
  )
);

const ChannelMembersSection = ({
  channelStore: { channel, readOnly, fetchChannel },
  userStore: { user: { userId }, isSuperAdmin },
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [shouldDisplayMemberForm, setShouldDisplayMemberForm] = useState(false);
  const [snackbarCurrentContent, setSnackbarCurrentContent] = useState();

  const { publisherId, admins = [], editors = [] } = channel || {};

  const handleMembersChange = async updatedList => {
    if (updatedList) {
      // Removing members before adding new ones (instead of using Promise.all) to avoid conflicts on list changes.
      const unsetMembers = await unsetBulkRegistered(publisherId, updatedList.filter(member => !member.isSelected));
      const setMembers = await setBulkMembers(publisherId, updatedList);

      if (unsetMembers.success || setMembers.success) {
        fetchChannel(publisherId, false);
      }

      if (unsetMembers.error || setMembers.error) {
        return unsetMembers.error ? unsetMembers : setMembers;
      }
    }

    return { success: true };
  };

  const retrieveInvites = async () => {
    const invites = await UserProvider.getPublisherPending(publisherId);
    setInvitedMembers(invites);
  };

  const onChangePermission = async member => {
    const updatedMember = await UserProvider.updateUserInPublisher(
      publisherId,
      member.emails[0],
      member.isAdmin,
    );

    if (updatedMember.payload) {
      setRegisteredMembers(registeredMembers.map(m => (m.userId === member.userId ? ({ ...m, isAdmin: !member.isAdmin }) : m)));
    }
  };

  const resendEmail = async member => {
    if (!member) {
      return;
    }

    const invites = Array.isArray(member)
      ? invitedMembers?.filter(invited => member.includes(invited._id))
      : [member];

    const response = await UserProvider.resendBulkInvitations(invites);
    if (response?.success && invites.length) {
      const topic = invites.length > 1 ? snackbarTopics.INVITES_EMAIL_SENT : snackbarTopics.INVITE_EMAIL_SENT;
      const messagePayload = invites.length > 1 ? invites.length : invites[0].to;
      const content = snackbarContent[topic](messagePayload);
      setSnackbarCurrentContent(content);
    }

    setSelectedRows([]);
  };

  useEffect(() => {
    if (publisherId && !readOnly) {
      retrieveInvites();
    }
  }, [publisherId, readOnly]);

  useEffect(() => {
    setRegisteredMembers([...admins.map(admin => ({ ...admin, isAdmin: true })), ...editors]);
  }, [admins, editors]);

  const unassignMembers = async (registered, invited) => {
    const [unsetRegistered, unsetInvited] = await Promise.all([
      unsetBulkRegistered(publisherId, registered),
      unsetBulkInvited(publisherId, invited),
    ]);

    if (unsetRegistered.success || unsetInvited.success) {
      fetchChannel(publisherId, false);
      setSelectedRows([]);
    }

    if (unsetInvited.success) {
      const deletedInviteEmails = invited?.map(invite => invite.to);
      setInvitedMembers(prev => prev?.filter(invite => !deletedInviteEmails.includes(invite.to)));
    }

    if (unsetRegistered.error || unsetInvited.error) {
      return unsetRegistered.error ? unsetRegistered : unsetInvited;
    }

    return { success: true };
  };

  const toggleNewMember = () => {
    setShouldDisplayMemberForm(prev => !prev);
  };

  const handleInviteSuccess = (email, responsePayload) => {
    const content = snackbarContent[snackbarTopics.INVITE_EMAIL_SENT](email);
    setSnackbarCurrentContent(content);
    if (responsePayload.userId) {
      fetchChannel(publisherId, false);
    } else {
      setInvitedMembers(prev => [responsePayload, ...prev]);
    }
  };

  const inviteMember = async email => {
    const invite = await UserProvider.sendInviteToPublisher(email, userId, publisherId);

    if (invite?.payload) {
      handleInviteSuccess(email, invite.payload);
    }
  };

  const onSubmitMember = async ({ to, displayName }) => {
    const invitesList = [{
      to,
      displayName,
      publisher: publisherId,
    }];

    const response = await UserProvider.sendBulkInvites(invitesList, false);

    if (response.success) {
      toggleNewMember();
      setInvitedMembers(prev => [...response.payload, ...prev]);
      const message = snackbarContent[snackbarTopics.INVITE_EMAIL_SENT](displayName);
      setSnackbarCurrentContent(message);
    }

    return response;
  };

  const renderMemberForm = () => (
    <MemberForm
      toggleFormDisplay={toggleNewMember}
      assignTo="channel"
      onSubmitMember={onSubmitMember}
    />
  );

  return (
    <>
      {shouldDisplayMemberForm ? renderMemberForm() : (
        <>
          <AddMember
            isEditable={!readOnly}
            isSuperAdmin={isSuperAdmin}
            disableAddAction={Boolean(selectedRows.length)}
            assignedMembers={registeredMembers}
            handleMembersChange={handleMembersChange}
            inviteMember={inviteMember}
            newMemberHandler={toggleNewMember}
            assignTo="channel"
          />

          <MembersTable
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            registeredMembers={registeredMembers}
            invitedMembers={invitedMembers}
            columns={channelMembersColumns}
            unassignMembers={unassignMembers}
            resendEmail={resendEmail}
            onChangePermission={onChangePermission}
            displayPermission
            isEditable={!readOnly}
            assignTo="channel"
          />
        </>
      )}

      <ApeSnackbar
        open={Boolean(snackbarCurrentContent?.message)}
        message={snackbarCurrentContent?.message}
        onRequestClose={() => setSnackbarCurrentContent()}
        iconType={snackbarCurrentContent?.type}
      />
    </>
  );
};

export default inject(StoresEnum.CHANNEL, StoresEnum.USER)(observer(ChannelMembersSection));
