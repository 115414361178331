import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Field, renderField, renderToggle, renderSelect,
} from '../../../../utils/formFieldHelper';
import Form from '../../../Settings/Form/Form';
import { UNSUPPORTED_FONTS, defaultFonts, taglineSourceOptions } from '../../../../utils/constants';
import Section from '../../../Section/Section';
import InteractionsHelper from '../../../../helpers/InteractionsHelper';
import { StoresEnum } from '../../../../stores';

const StoryStripForm = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const [mappedFonts, setMappedFonts] = useState();
  const [updatedStripProps, setUpdatedStripProps] = useState(channel?.stripProps);
  const initTaglineSource = taglineSourceOptions.find(tag => tag.id === channel.stripProps?.taglineSource);

  const addProviderToFont = (newVal, fieldName) => {
    const stripProps = updatedStripProps || channel.stripProps;
    const selectedFont = mappedFonts.find(font => font.id === newVal);
    stripProps.fontSettings[fieldName] = !selectedFont?.provider ? { name: '' } : { name: selectedFont.id, provider: selectedFont.provider };
    setUpdatedStripProps(stripProps);
  };

  const fetchFonts = async () => {
    const resourceList = await InteractionsHelper.getChannelFonts(channel.publisherId);
    const filteredFonts = resourceList.filter(font => !UNSUPPORTED_FONTS.includes(font.data.name.toLowerCase()));

    let fonts;
    if (filteredFonts.length) {
      fonts = filteredFonts.map(res => ({ name: res.name, value: res.data.css, provider: res.data.provider.name }));
      fonts.splice(0, 0, { name: "Default", value: '' });
    }

    const fontsArray = (fonts?.length && fonts.map(font => ({ ...font, id: font.value }))) || defaultFonts;
    setMappedFonts(fontsArray);
  };

  useEffect(() => {
    fetchFonts();
  }, [channel.publisherId]);

  const renderFields = () => (
    <>
      <Field name="autoStories.siteSection" type="text" component={renderField} label="Target or path domain" readOnly={readOnly} allowEmpty />
      <Field name="autoStories.enabled" type="checkbox" component={renderToggle} label="Add Auto-Create Story" readOnly={readOnly} />
      <Field name="autoStories.usePublishedTime" type="checkbox" component={renderToggle} label="Use article's published time" readOnly={readOnly} />
      <Field name="autoStories.viewsThreshold" type="number" component={renderField} label="Auto Creation Threshold" readOnly={readOnly} />

      <Field
        name="stripProps.fontSettings.titleFont.name"
        label="Title font type"
        defaultValue="Default"
        component={renderSelect(mappedFonts)}
        onChange={newValue => addProviderToFont(newValue, 'titleFont')}
        readOnly={readOnly}
      />

      <Field
        name="stripProps.taglineSource"
        label="Source for Tagline"
        defaultValue={initTaglineSource?.name}
        component={renderSelect(taglineSourceOptions)}
        readOnly={readOnly}
      />

      <Field
        name="stripProps.fontSettings.bodyFont.name"
        label="Description font type"
        defaultValue="Default"
        component={renderSelect(mappedFonts)}
        onChange={newValue => addProviderToFont(newValue, 'bodyFont')}
        readOnly={readOnly}
      />
    </>
  );

  return (
    <Section title="INSTANT STORY STRIP">
      <Form
        initialValues={channel}
        fields={renderFields()}
        onSubmit={data => updateChannel({
          ...data,
          stripProps: {
            ...data.stripProps,
            fontSettings: updatedStripProps.fontSettings,
          },
        })}
        hideCancelButton
        hideApproveButton={readOnly}
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(StoryStripForm));
