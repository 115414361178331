const languages = [{
  value: 'en',
  name: 'English',
}, {
  value: 'he',
  name: 'Hebrew',
}, {
  value: 'hu',
  name: 'Hungarian',
}, {
  value: 'fr',
  name: 'French',
}, {
  value: 'it',
  name: 'Italian',
}, {
  value: 'lt',
  name: 'Lithuanian',
}, {
  value: 'lv',
  name: 'Latvian',
}, {
  value: 'ml',
  name: 'Malay',
}, {
  value: 'pl',
  name: 'Polish',
}, {
  value: 'pt-pt',
  name: 'Portuguese',
}, {
  value: 'pt',
  name: 'Portuguese/br',
}, {
  value: 'tr',
  name: 'Turkish',
}, {
  value: 'da',
  name: 'Danish',
}, {
  value: 'es',
  name: 'Spanish',
}, {
  value: 'hi',
  name: 'Hindi',
}, {
  value: 'ro',
  name: 'Romanian',
}, {
  value: 'ru',
  name: 'Russian',
}, {
  value: 'de',
  name: 'German',
}, {
  value: 'ja',
  name: 'Japanese',
}, {
  value: 'sv',
  name: 'Swedish',
}, {
  value: 'zh-hans',
  name: 'Chinese',
}, {
  value: 'nl',
  name: 'Dutch',
}, {
  value: 'ar',
  name: 'Arabic',
}, {
  value: 'uk',
  name: 'Ukrainian',
}, {
  value: 'ka',
  name: 'Georgian',
}, {
  value: 'hr',
  name: 'Croatian',
}, {
  value: 'el',
  name: 'Greek',
}];

languages.sort((lanA, lanB) => {
  if (lanA.name < lanB.name) return -1;
  if (lanA.name > lanB.name) return 1;
  return 0;
});

export default languages;
