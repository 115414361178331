import React from 'react';
import migrationGif from '../../../../../assets/images/migration.gif';
import migrationJpg from '../../../../../assets/images/migration-done.jpg';

const Migration = ({
  total, finishedMigration, current, errorWithMigration,
}) => (
  <div className="no-channel-popup-container">
    <div className="modal modal-size-popup-migration">
      <div className="popup-migration__box">
        <p className="popup-migration__title">{finishedMigration ? <span>Done!</span> : "Transferring..."}</p>
        <img alt="" className="popup-migration__gif" src={finishedMigration ? migrationJpg : migrationGif} />
        <div className="popup-migration__trasfer-box">
          <p className="popup-migration__trasfer-box__current">{current}</p>
          <p className="popup-migration__trasfer-box__total">
            out of
            {total}
            {' '}
            units
          </p>
        </div>
        {errorWithMigration && <p className="popup-migration__error">There was an error with the migration, continues without it...</p>}
      </div>
    </div>
  </div>
);

export default Migration;
