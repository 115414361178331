import React from 'react';
import './CurrentPlan.scss';
import PlanNameElement from '../../PlanElement/PlanTitle/PlanElement';

const CurrentPayment = ({ planDetails, isCanceled, openModal }) => {
  const { startDate, nextBillingDate, billingPlan } = planDetails;
  return (
    <div className="current-payments-container">
      <div className="container-header"><span>YOUR PLAN</span></div>
      <div className="container-content">
        <div className="left-section">
          <PlanNameElement subscriptionName={billingPlan.display_name} />
        </div>
        <div className="right-section">
          <div className="payments-dates">
            <div>
              <span className="date-text">Plan start date</span>
              <span className="date">
                {new Date(startDate).toDateString().split(' ').slice(1)
                  .join(' ')}
              </span>
            </div>
            <div>
              <span className="date-text">
                {`Plan will automatically ${isCanceled ? 'cancel' : 'renew'} on`}
              </span>
              <span className="date">
                {new Date(nextBillingDate).toDateString().split(' ').slice(1)
                  .join(' ')}
              </span>
            </div>
          </div>
          <div className="plan-actions">
            <div className="action">
              <a href="https://apester.com/pricing" target="_blank" rel="noopener noreferrer">See other plans</a>
            </div>
            {!isCanceled ? <div className="action cancel-plan-danger" onClick={openModal}> Cancel plan</div> : <div />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPayment;
