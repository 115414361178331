import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AddRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import MultiselectDropdown from '../MultiselectDropdown/MultiselectDropdown';
import EllipsisText from '../../EllipsisText/EllipsisText';
import Tooltip from '../Tooltip/Tooltip';

import './SelectField.scss';

const MultiselectField = ({
  includeSearchField,
  includeSelectAll,
  items,
  size,
  className,
  handleClose,
  onSearchChange,
  name,
  savedSelectedItems,
  maxFieldWidth,
  label,
  placeholder,
  isDisabled,
  readOnly,
}) => {
  const [anchorEl, setAnchorEl] = useState();

  const hasSelected = Boolean(savedSelectedItems?.length);
  const hasMultipleSelected = savedSelectedItems?.length > 1;
  const selectedLabel = hasMultipleSelected ? `${savedSelectedItems.length} ${name}` : savedSelectedItems?.[0];

  const isOpen = Boolean(anchorEl);

  const onClose = updatedList => {
    setAnchorEl(null);
    if (handleClose) {
      handleClose(updatedList);
    }
  };

  const renderTooltipLine = itemName => <p key={itemName} className="multiselect-tooltip-line">{itemName}</p>;

  const renderExpandIcon = () => (isOpen ? <ExpandLessRounded className="custom-select-icon" /> : <ExpandMoreRounded className="custom-select-icon" />);

  const renderSelectCurrentValue = () => {
    if (hasMultipleSelected) {
      return <Tooltip title={savedSelectedItems.map(renderTooltipLine)}>{selectedLabel}</Tooltip>;
    }

    if (selectedLabel || placeholder) {
      return (
        <EllipsisText
          className={`current-value ${placeholder && !hasSelected ? 'empty' : ''}`}
          text={selectedLabel || placeholder}
          maxWidth={maxFieldWidth}
        />
      );
    }

    return (
      <div className="empty-field">
        <AddRounded />
        {`Add ${name}`}
      </div>
    );
  };

  return (
    <div className="custom-select">
      <div className={`custom-select-field multiselect ${className} ${size} ${isDisabled || readOnly ? 'disabled' : ''}`} onClick={event => setAnchorEl(event.currentTarget)}>
        {renderSelectCurrentValue()}
        {!readOnly && renderExpandIcon()}
      </div>
      {label && <span className="custom-select-label">{label}</span>}

      {!readOnly && !isDisabled && (
        <MultiselectDropdown
          isOpen={isOpen}
          anchorEl={anchorEl}
          handleClose={onClose}
          items={items}
          includeSearchField={includeSearchField}
          includeSelectAll={includeSelectAll}
          onSearchChange={onSearchChange}
          className="menu-base-position"
          name={name}
        />
      )}
    </div>
  );
};

MultiselectField.propTypes = {
  includeSearchField: PropTypes.bool,
  includeSelectAll: PropTypes.bool,
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  maxFieldWidth: PropTypes.number,
  name: PropTypes.string.isRequired,
  savedSelectedItems: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    secondaryLabel: PropTypes.string,
    isSelected: PropTypes.bool,
  })),
};

MultiselectField.defaultProps = {
  includeSearchField: false,
  includeSelectAll: false,
  size: 'small',
  className: '',
  items: [],
  savedSelectedItems: [],
  maxFieldWidth: undefined,
  label: undefined,
  placeholder: undefined,
};

export default MultiselectField;
