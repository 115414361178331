// state to field

export const newPlanState = {
  email: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  address: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  country: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  zip: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  city: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  selectedPlan: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  termsConfirm: {
    value: false,
    error: {
      value: false,
      message: undefined,
    },
  },
};

export const UpdatePaymentsState = {
  email: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  address: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  country: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  zip: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
  city: {
    value: '',
    error: {
      value: false,
      message: undefined,
    },
  },
};
