import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';

import ApiRequest from "./ApiRequest";
import PaymentsProvider from './PaymentsProvider';
import { appendProtocol, getChannelDomains } from '../utils/url';

class UserProvider {
  static request = new ApiRequest();

  static setTOTP = type => UserProvider.request.get(`/totp/${type}`);

  static totpLogin = code => UserProvider.request.post('/totp/auth', {
    data: { code },
  });

  static createBackupTOTPCodes = id => UserProvider.request.get(`/totp/${id}/backupCodes`);

  static confirmPass = async pass => {
    const isConfirmed = await UserProvider.request.post(`/user/validate/pass`, {
      data: { pass },
    });

    return isConfirmed;
  };

  static switchMFAMethod = (user, code) => UserProvider.request.post(`/user/${user.userId}/switch/totp/${code}`);

  static sendInviteToPublisher = (email, userId, publisherId) => UserProvider.request.post('/invites', {
    data: { to: email, from: userId, publisher: publisherId },
  })
    .then(({ payload }) => ({ success: true, payload }))
    .catch(e => ({ error: { message: e.message || e.err?.message, payload: e?.payload || e.err?.payload } }));

  /**
   * Send multiple invites - support both registered and unregistered users
   * @param invites - Array of invite objects contains `to` (string) and `publisher` (string).
   * @param updateIfExist - Boolean, if set to false - posting existing member will lead to error.
   */
  static sendBulkInvites = (invites, updateIfExist = true) => {
    if (!invites?.length) {
      return Promise.resolve({});
    }

    return UserProvider.request.post(`/invites/bulk-invites`, { data: { invites, updateIfExist } })
      .then(({ payload }) => ({ success: true, payload }))
      .catch(e => ({ error: { message: e.message || e.err?.message, payload: e?.payload || e.err?.payload } }));
  };

  static resendBulkInvitations = invites => UserProvider.request.put(`/invites/bulk-invites`, { data: { invites } })
    .then(() => ({ success: true }))
    .catch(e => ({ error: { message: e.message } }));

  /**
   * Delete multiple invites at once by email and list of publishers
   * @param invites - Array of invite objects contains userEmail (string) and publisherIds (array of strings).
   */
  static deleteBulkPublisherInvitations = (invites, publisher) => {
    if (!invites?.length) {
      return Promise.resolve({});
    }

    return UserProvider.request.delete(`/invites/bulk-invites`, { data: { invites, publisher } })
      .then(() => ({ success: true }))
      .catch(e => ({ error: { message: e.message } }));
  };

  /**
   * Get all invites of unregistered users by publishers list OR emails list.
   * @param publisherIds - List of publisher IDs. Optional.
   * @param emails - List of emails. Optional.
   * @param shouldPopulate - Flag indicates if response should contain publishers data.
   */
  static getPublisherPending = async (publisherIds, emails, shouldPopulate) => {
    try {
      const queryParam = publisherIds ? `publisher=${publisherIds}` : `to=${emails}`;
      const response = await UserProvider.request.get(`/invites${shouldPopulate ? '/populated' : ''}?${queryParam}`);
      const { payload } = response || {};
      if (emails && payload) {
        const channels = payload.map(({ publisher, to }) => ({ ...publisher, to }));
        return groupBy(channels, 'to');
      }

      return payload;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  static usersCache = {};

  static getUser = (userId = '', forceFetch = false) => {
    if (UserProvider.usersCache[userId] && !forceFetch) {
      return Promise.resolve(UserProvider.usersCache[userId]);
    }

    return UserProvider.request.get(`/user/${userId}`)
      .then(user => {
        UserProvider.usersCache[userId] = user;
        return user;
      })
      .catch(err => err);
  };

  static getUsersFields = (userIds = [], fields = []) => {
    const userIdsQuery = userIds.join(',');
    const fieldsQuery = fields.join(',');

    return UserProvider.request.get(`/user/fields?users=${userIdsQuery}&fields=${fieldsQuery}`);
  };

  static updateUser = user => {
    delete user.images;
    return UserProvider.request.put(`/user/${user.userId}`, {
      data: { ...user },
    })
      .then(res => res)
      .catch(err => err);
  };

  static getBulkUsersPublishers = userIds => UserProvider.request.get(`/user/bulk-users/publishers?userIds=${userIds.join(',')}`)
    .then(({ payload }) => payload)
    .catch(err => console.error(err));

  static getPublishers = (from, limit, search, ids) => {
    const encodedSearch = search ? encodeURIComponent(search) : '';
    const queryParams = ids ? `channelIds=${ids.join(',')}` : `from=${from || 0}&limit=${limit || 100}&searchName=${encodedSearch}`;
    return UserProvider.request.get(`/publisher/slim?${queryParams}`);
  };

  static getUsers = ({
    from, limit, searchName, userIds,
  }) => {
    const encodedSearch = searchName ? encodeURIComponent(searchName) : '';
    const params = userIds?.length
      ? `userIds=${userIds.join(',')}`
      : `from=${from || 0}&limit=${limit || 100}&searchName=${encodedSearch}`;
    return UserProvider.request.get(`/users/slim?${params}`);
  };

  static getGroups = ({ from = 0, limit = 100, search = '' }) => {
    const encodedSearch = search ? encodeURIComponent(search) : '';
    return UserProvider.request.get(`/groups?from=${from || 0}&limit=${limit || 100}&searchName=${encodedSearch}`);
  };

  static getGroup = id => UserProvider.request.get(`/groups/${id}`);

  /**
   * Remove single publisher from group.
   * @param groupId
   * @param publisherId
   */
  static removePublisherFromGroup = (groupId, publisherId) => (
    UserProvider.request.delete(`/groups/${groupId}/publishers/${publisherId}`)
      .then(() => ({ success: true }))
      .catch(e => ({ error: { message: e.message } }))
  );

  /**
   * Delete multiple publishers from group
   * @param groupId
   * @param publisherIds
   */
  static removeBulkPublishersFromGroup = async (groupId, publisherIds) => {
    if (!publisherIds?.length || !groupId) {
      return Promise.resolve({});
    }

    return (
      UserProvider.request.delete(`/groups/${groupId}/bulk-publishers`, {
        data: { publishers: publisherIds },
      })
        .then(() => ({ success: true }))
        .catch(e => ({ error: { message: e.message } }))
    );
  };

  static newChannelToGroup = async (groupId, channel) => {
    try {
      const payload = await UserProvider.request.post(`/publisher`, {
        data: {
          ...channel,
          urls: getChannelDomains(channel)?.map(appendProtocol),
        },
      });
      const { plan } = channel;
      const { publisherId } = payload.payload;
      await UserProvider.request.put(`/groups/${groupId}/bulk-publishers`, {
        data: { publishers: [publisherId] },
      });

      if (plan) {
        const subscriptionData = {
          billingPlanId: plan,
          entityId: publisherId,
          token: '',
          subscriptionId: undefined,
        };
        await PaymentsProvider.postSubscription(subscriptionData);
      }

      return payload.payload;
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  /**
   * Add multiple publishers to group
   * @param groupId
   * @param publisherIds
   */
  static addBulkPublishersToGroup = async (groupId, publishers) => {
    if (!publishers?.length || !groupId) {
      return Promise.resolve({});
    }

    return (
      UserProvider.request.put(`/groups/${groupId}/bulk-publishers`, {
        data: { publishers },
      })
        .then(() => ({ success: true }))
        .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }))
    );
  };

  /**
   * Add multiple members to group
   * @param groupId
   * @param publisherIds
   */
  static addBulkMembersToGroup = async (groupId, userEmails) => {
    if (!userEmails?.length || !groupId) {
      return Promise.resolve({});
    }

    return (
      UserProvider.request.put(`/groups/${groupId}/bulk-members`, {
        data: { userEmails },
      })
        .then(() => ({ success: true }))
        .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }))
    );
  };

  /**
   * Delete multiple registered members from group
   * @param groupId
   * @param userEmails - list of email addresses
   */
  static removeBulkMembersFromGroup = async (groupId, userEmails) => {
    if (!userEmails?.length || !groupId) {
      return Promise.resolve({});
    }

    return (
      UserProvider.request.delete(`/groups/${groupId}/bulk-members`, {
        data: { userEmails },
      })
        .then(() => ({ success: true }))
        .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }))
    );
  };

  static getPublisher = async id => (
    UserProvider.request.get(`/publisher/${id}`)
      .then(({ payload }) => (payload ? {
        payload: {
          ...payload,
          urls: getChannelDomains(payload),
        },
      } : { payload }))
      .catch(() => ({ payload: { error: true } }))
  );

  static getChannelAdmin = async id => {
    const channel = await UserProvider.getPublisher(id);
    return channel.payload.admins[0];
  };

  static createPublisher = channel => UserProvider.request.post('/publisher', {
    data: {
      ...channel,
      urls: getChannelDomains(channel)?.map(appendProtocol),
    },
  })
    .then(({ payload }) => payload)
    .catch(err => err);

  static createGroup = group => UserProvider.request.post('/groups', {
    data: group,
  })
    .then(({ payload }) => ({ ...payload, success: true }))
    .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }));

  static updatePublisher = async (channel, canEditChannels) => {
    let channelToSend = cloneDeep(channel);
    delete channelToSend.admins;
    delete channelToSend.editors;
    delete channelToSend.isBlocked; // Currently we are not supporting updating 'isBlocked' property from channel settings.

    const {
      name, vertical, geo, status, language, profileImage, colorPalette, whitelist, pixels, plan, publisherId, shouldTrack,
    } = channel;
    if (!canEditChannels) {
      channelToSend = {
        name,
        vertical,
        geo,
        status,
        language,
        profileImage,
        colorPalette: colorPalette || {},
        whitelist: whitelist || { urls: [], enable: true },
        pixels: pixels || {},
        shouldTrack,
      };
    }

    channelToSend.urls = getChannelDomains(channel)?.map(appendProtocol);

    try {
      const updatedChannel = await UserProvider.request.put(`/publisher/${publisherId}`, { data: channelToSend });
      let newSubscription;
      if (plan) {
        const subscriptionData = {
          billingPlanId: plan,
          entityId: publisherId,
          token: '',
          subscriptionId: undefined,
        };
        newSubscription = await PaymentsProvider.postSubscription(subscriptionData);
      }

      return { channel: updatedChannel?.payload, subscription: newSubscription };
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  static updateGroup = group => {
    const groupToSend = cloneDeep(group);
    // The following properties are too big to send to the server, and they're ignored anyways ).
    delete groupToSend.members;
    delete groupToSend.channels;

    return UserProvider.request.put(`/groups/${group._id}`, {
      data: { ...groupToSend },
    })
      .then(({ payload }) => ({ ...payload, success: true }))
      .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }));
  };

  static inactivateGroup = async (groupId, destinationGroupId) => {
    try {
      const response = await UserProvider.request.put(`/groups/${groupId}/inactive`, {
        data: { destinationGroupId },
      });

      return { ...response.payload, success: true };
    } catch (err) {
      return { error: { message: err?.message, payload: err?.payload } };
    }
  };

  static updateUserInPublisher = (id, email, isAdmin) => UserProvider.request.put(`/publisher-membership/${id}`, {
    data: {
      role: isAdmin ? 'editor' : 'admin',
      userName: email,
    },
  });

  /**
   * Delete single registered member from list of publishers
   * @param userId
   * @param publisherIds - list of publisher IDs
   */
  static removeUserFromBulkPublishers = (userId, publisherIds) => {
    if (!publisherIds?.length || !userId) {
      return Promise.resolve({});
    }

    return (
      UserProvider.request.delete(`/publisher-membership/${userId}/bulk-publishers`, { data: { publisherIds } })
        .then(() => ({ success: true }))
        .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }))
    );
  };

  /**
   * Add multiple registered members to single publisher
   * @param publisherId
   * @param emails - list of email addresses
   */
  static addBulkMembersToPublisher = (publisherId, emails) => {
    if (!emails?.length || !publisherId) {
      return Promise.resolve({});
    }

    return (
      UserProvider.request.post(`/publisher-membership/${publisherId}/bulk-members`, { data: { emails } })
        .then(() => ({ success: true }))
        .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }))
    );
  };

  /**
   * Remove multiple registered members from single publisher
   * @param publisherId
   * @param emails - list of email addresses
   */
  static removeBulkMembersFromPublisher = (publisherId, emails) => {
    if (!emails?.length || !publisherId) {
      return Promise.resolve({});
    }

    return (
      UserProvider.request.delete(`/publisher-membership/${publisherId}/bulk-members`, { data: { emails } })
        .then(() => ({ success: true }))
        .catch(({ err }) => ({ error: { message: err?.message, payload: err?.payload } }))
    );
  };

  static deleteMFA = userId => UserProvider.request.delete(`/user/${userId}/mfa`);

  static logout = () => UserProvider.request.get('/logout');
}

export default UserProvider;
