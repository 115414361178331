import { RouterStore } from 'mobx-react-router';
import UserStore from './User';
import ChannelStore from './Channel';
import GroupStore from './Group';
import PaymentsStore from './Payments';

const StoresEnum = {
  ROUTING: 'routing',
  USER: 'userStore',
  CHANNEL: 'channelStore',
  PAYMENTS: 'paymentsStore',
  GROUP: 'groupStore',
};

const routingStore = new RouterStore();
const userStore = new UserStore();
const channelStore = new ChannelStore(userStore);

const rootStore = {
  [StoresEnum.ROUTING]: routingStore,
  [StoresEnum.USER]: userStore,
  [StoresEnum.CHANNEL]: channelStore,
  [StoresEnum.PAYMENTS]: new PaymentsStore(userStore, channelStore),
  [StoresEnum.GROUP]: new GroupStore(),
};

export { rootStore, routingStore, StoresEnum };
