import React, { Component } from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import List from '@mui/material/List';

import UrlCell from './UrlCell';
import Section from '../../../Section/Section';
import AddItem from '../../../Settings/AddItem/AddItem';
import { StoresEnum } from '../../../../stores';

import './ContentAccess.scss';

class ContentAccess extends Component {
  state = {
    whitelist: {
      urls: [],
      enable: true,
    },
    error: '',
  };

  channelStoreDisposer;

  componentDidMount() {
    const { channelStore } = this.props;
    if (channelStore.channel.whitelist) {
      this.setState({ whitelist: channelStore.channel.whitelist });
    }

    this.channelStoreDisposer = reaction(() => channelStore.channel, (value, previousValue) => {
      if (value.publisherId !== previousValue.publisherId) {
        const hasWhitelist = value.whitelist && Object.keys(value.whitelist).length;
        const newWhitelist = hasWhitelist ? value.whitelist : { enable: true, urls: [] };
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ whitelist: newWhitelist });
      }
    });
  }

  componentWillUnmount() {
    this.channelStoreDisposer();
  }

  updateWhitelist = newWhitelist => {
    const { channelStore: { updateChannel } } = this.props;
    this.setState({ whitelist: newWhitelist, error: '' });
    updateChannel({ whitelist: newWhitelist });
  };

  validateUrl = url => {
    const validURL = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    const isLocalhost = url.includes('localhost');
    return isLocalhost || validURL.test(url);
  };

  sanitizeUrl = url => {
    const sanitizedUrl = url.slice(-1) === '/' ? url.slice(0, -1) : url;

    return sanitizedUrl;
  };

  addUrlToWhitelist = url => {
    if (!this.validateUrl(url)) {
      this.setState({ error: 'Invalid domain' });
    } else {
      const sanitizedUrl = this.sanitizeUrl(url);
      const { whitelist } = this.state;
      const newUrls = [...whitelist.urls, sanitizedUrl];
      const newWhitelist = { ...whitelist, urls: newUrls };
      this.updateWhitelist(newWhitelist);
    }
  };

  deleteUrl = deletedUrl => {
    const { whitelist: { urls }, whitelist } = this.state;
    const newUrls = urls.filter(url => url !== deletedUrl);
    const newWhitelist = { ...whitelist, urls: newUrls };
    this.updateWhitelist(newWhitelist);
  };

  handleToggleClicked = toggle => {
    const { whitelist } = this.state;
    const newWhitelist = { ...whitelist, enable: toggle };
    this.updateWhitelist(newWhitelist);
  };

  render() {
    const { channelStore: { channel = {}, readOnly } } = this.props;
    const { whitelist, error } = this.state;

    return !readOnly || whitelist.urls.length ? (
      <Section
        title="CONTENT ACCESS WHITELIST"
        includeToggleButton
        readOnly={readOnly}
        onToggle={this.handleToggleClicked}
        isChecked={whitelist.enable}
      >
        {!readOnly && (
          <div className="add-item-wrapper">
            <span className="input-label">Enter domains for content access</span>
            <AddItem placeholder="Enter Domain" onClick={this.addUrlToWhitelist} />
            {error && <span className="url-error">{error}</span>}
          </div>
        )}
        {channel.publisherId && (
          <List>
            {whitelist.urls.map(url => <UrlCell readOnly={readOnly} key={url} url={url} onDeleteClick={this.deleteUrl} isEnable={whitelist.enable} />)}
          </List>
        )}
      </Section>
    ): null;
  }
}

export default inject(StoresEnum.CHANNEL)(observer(ContentAccess));
