import React, { Component } from "react";
import { inject, observer } from 'mobx-react';

import ChannelForm, { FIELD_NAMES } from "../../Settings/ChannelForm/ChannelForm";
import { AvatarContainer } from "../../ApeUI/AvatarContainer/AvatarContainer";

import RoutingService from "../../../helpers/RoutingService";
import InteractionsHelper from '../../../helpers/InteractionsHelper';
import PopupMoveInteractions from './PopupMoveInteractions/PopupMoveInteractions';
import Section from "../../Section/Section";
import UserProvider from "../../../helpers/UserProvider";
import ProfileSkeleton from "../../../containers/Settings/Tabs/ProfileSkeleton";
import CdnMediaProvider from "../../../helpers/CdnMediaProvider";
import EventTracker, { EVENT_TYPE } from "../../../helpers/EventTracker";
import EventDataBuilder from "../../../helpers/EventDataBuilder";
import { StoresEnum } from "../../../stores";

const defaultChannelName = "Channel Name";

class CreateChannel extends Component {
  state = {
    channelName: defaultChannelName,
    showPopup: false,
    unattachedInteractions: null,
  };

  updateNameOnTyping = value => {
    this.setState({
      channelName: value || defaultChannelName,
    });
  };

  navigateToNewChannel = publisherId => {
    const { path } = RoutingService.routes.settings.routes.channels;
    window.location.href = `${window.location.origin}${path}/${publisherId}`;
  };

  handleSubmit = async values => {
    const { channelStore: { channel } } = this.props;
    const response = await UserProvider.createPublisher({ ...channel, ...values });
    if (response?.publisherId) {
      this.onSuccessfullyCreated(response);
    }

    return response;
  };

  onSuccessfullyCreated = async channel => {
    const { channelStore: { setChannel }, userStore: { user, updateUser } } = this.props;
    EventTracker.track(EVENT_TYPE.CHANNEL_CREATED, EventDataBuilder.channelCreated(channel));
    setChannel(channel);

    if (user?.userId && !user.publishers?.length) {
      await updateUser({ defaultPublisher: channel.publisherId });
    }

    const interactionsArray = await InteractionsHelper.requestInteractionsWithoutChannel();
    if (interactionsArray.count > 0) {
      this.setState({ showPopup: true, unattachedInteractions: interactionsArray });
    } else {
      this.navigateToNewChannel(channel.publisherId);
    }
  };

  closePopup = () => {
    const { channelStore: { channel } } = this.props;
    this.setState({ showPopup: false });
    this.navigateToNewChannel(channel.publisherId);
  };

  channelAvatarChange = async e => {
    const { channelStore: { setChannel } } = this.props;
    const profileImage = await CdnMediaProvider.uploadImage(e);
    if (profileImage) {
      setChannel({ profileImage });
    }
  };

  renderAvatarContainer = () => {
    const { channelStore: { channel } } = this.props;
    const { channelName } = this.state;
    return (
      <AvatarContainer
        name={channelName}
        picture={channel.profileImage}
        handleAvatarChange={this.channelAvatarChange}
      />
    );
  };

  render() {
    const { channelStore: { channel }, userStore: { isSuperAdmin } } = this.props;
    const { showPopup, unattachedInteractions } = this.state;
    const basicFieldsList = [FIELD_NAMES.COUNTRY, FIELD_NAMES.VERTICAL];

    return (
      <>
        <ProfileSkeleton avatar={this.renderAvatarContainer()}>
          <Section title="info">
            <ChannelForm
              fieldsList={isSuperAdmin ? [...basicFieldsList, FIELD_NAMES.GROUP] : basicFieldsList}
              channel={{}}
              updateNameOnTyping={this.updateNameOnTyping}
              onSubmit={this.handleSubmit}
              cancelLabel="Delete"
              // TODO: onCancel will be changed and disableCancelButton will be removed when delete functionality is implemented
              onCancel={() => console.log('should delete')}
              disableCancelButton
            />
          </Section>
        </ProfileSkeleton>

        {showPopup && (
          <PopupMoveInteractions
            channel={channel}
            interactions={unattachedInteractions}
            ocClose={() => this.closePopup()}
          />
        )}
      </>
    );
  }
}

export default inject(StoresEnum.ROUTING, StoresEnum.USER, StoresEnum.CHANNEL)(observer(CreateChannel));
