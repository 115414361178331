const verticalList = [
  {
    name: 'News',
  },
  {
    name: 'Sport',
  },
  {
    name: 'Entertainment',
  },
  {
    name: 'Business',
  },
  {
    name: 'Tech',
  },
  {
    name: 'Lifestyle',
  },
  {
    name: 'Agency',
  },
  {
    name: 'Blog',
  },
  {
    name: 'Brand',
  },
  {
    name: 'Cars',
  },
  {
    name: 'Dating',
  },
  {
    name: 'Education',
  },
  {
    name: 'Food',
  },
  {
    name: 'Gambling',
  },
  {
    name: 'Gaming',
  },
  {
    name: 'Gossip',
  },
  {
    name: 'Health',
  },
  {
    name: 'Media Groups',
  },
  {
    name: 'Shopping',
  },
  {
    name: 'Travel',
  },
  {
    name: 'Viral',
  },
  {
    name: 'Other',
  },
];

export default verticalList;
