import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { reaction } from "mobx";
import { inject, observer } from 'mobx-react';

import RoutingService from "../../helpers/RoutingService";
import routes from '../../utils/header.routes';

import Profile from "./Tabs/UserProfile";
import MainHeader from "../../components/MainHeader/Header";
import ChannelProfile from "./Tabs/ChannelProfile";
import GroupsProfile from "./Tabs/GroupsProfile";
import CreateChannel from "../../components/Channels/Create/CreateChannel";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { StoresEnum } from "../../stores";

import "./Settings.scss";

class Settings extends Component {
  // eslint-disable-next-line react/no-unused-class-component-methods
  userStoreDisposer;

  async componentDidMount() {
    const { paymentsStore, userStore } = this.props;

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.userStoreDisposer = reaction(() => userStore.isSuperAdmin, (value, previousValue) => {
      paymentsStore.fetchPlans();
    });
  }

  renderBreadcrumbs = () => {
    const { groupStore: { group }, channelStore: { channel } } = this.props;

    let links;
    if (channel?.name && group?.name) {
      links = [
        { label: group.name, path: `/groups/${group._id}` },
        { label: channel.name, path: `/groups/${group._id}/channels/${channel.publisherId}` },
      ];
    }
    return <Breadcrumbs links={links} maxItems={2} />;
  };

  render() {
    const { userStore: { user, isSuperAdmin, sessionId } } = this.props;

    if (user?.fetched && !user?.loggedIn) {
      window.location.href = `${routes.logout}?sId=${sessionId}`;
    }

    return user.loggedIn ? (
      <div className="settings-layout">
        <MainHeader selectedMenuOption="user" app="console" />
        {this.renderBreadcrumbs()}
        <Switch>
          <Route
            exact
            path={`${RoutingService.routes.settings.routes.channels.routes.payments.url}`}
            render={routeProps => (
              <ChannelProfile
                paymentMode
                {...this.props}
                {...routeProps}
              />
            )}
          />
          <Route
            path={`${RoutingService.routes.settings.routes.channels.routes.create.url}`}
            render={props => <CreateChannel {...props} />}
          />
          <Route
            exact
            path={`${RoutingService.routes.settings.routes.channels.url}`}
            render={routeProps => (
              <ChannelProfile {...this.props} {...routeProps} />
            )}
          />
          <Route
            exact
            path={`${
                RoutingService.routes.settings.routes.groups.routes.create.url
              }`}
            render={routeProps => (
              isSuperAdmin
                ? (
                  <GroupsProfile
                    isNewGroup
                    {...this.props}
                    {...routeProps}
                  />
                ) : <Redirect to="/" />
            )}
          />
          <Route
            exact
            path={`${RoutingService.routes.settings.routes.groups.url}`}
            render={routeProps => (
              isSuperAdmin
                ? <GroupsProfile {...this.props} {...routeProps} />
                : <Redirect to="/" />
            )}
          />
          <Route
            exact
            path={`${RoutingService.routes.settings.routes.groups.routes.channel.url}`}
            render={routeProps => (isSuperAdmin ? (
              <ChannelProfile {...this.props} {...routeProps} />
            ) : <Redirect to="/" />
            )}
          />
          <Route
            path={`${RoutingService.routes.settings.routes.profile.url}`}
            render={routeProps => (
              <Profile {...this.props} {...routeProps} />
            )}
          />
        </Switch>
      </div>
    ) : (
      ""
    );
  }
}

export default inject(StoresEnum.USER, StoresEnum.CHANNEL, StoresEnum.GROUP, StoresEnum.PAYMENTS)(observer(Settings));
