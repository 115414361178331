import {
  makeObservable, action, observable,
} from 'mobx';
import UserProvider from '../helpers/UserProvider';
import { SellerTypes } from '../utils/constants';

const DEFAULT_GROUP_VALUES = {
  shouldExludeFromSellerJson: false,
  sellerType: SellerTypes.publisher,
};

export default class GroupStore {
  group = { ...DEFAULT_GROUP_VALUES };

  constructor() {
    makeObservable(this, {
      group: observable,
      fetchGroup: action,
      setGroup: action,
      clearGroup: action,
    });
  }

  setGroup = payload => {
    this.group = payload ? { ...this.group, ...payload } : { error: true };
  };

  clearGroup = () => {
    this.group = { ...DEFAULT_GROUP_VALUES };
  };

  fetchGroup = async (groupId, shouldClearState) => {
    if (shouldClearState || !groupId) {
      this.clearGroup();
    }

    const groupPayload = await UserProvider.getGroup(groupId);
    this.setGroup(groupPayload?.payload);
  };
}
