import React from 'react';
import { inject, observer } from 'mobx-react';
import CreateSubscription from './CreatePlan/CreatePlan';
import ManagePlan from './ManagePlan/ManagePlan';
import SubscriptionView from './CreatePlan/SubscriptionView/SubscriptionView';
import EventTracker, { EVENT_TYPE } from '../../../../helpers/EventTracker';
import PaymentsProvider from '../../../../helpers/PaymentsProvider';
import loader from '../../../../theme/images/loader.gif';
import GoBackBtn from "./GoBackBtn/GoBackBtn";
import { StoresEnum } from '../../../../stores';

import './Payments.scss';

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionCompleted: false,
      isLoading: false,
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const redirectTo = match.params.locationTo;
    this.setState(({ redirectTo }));
  }

  onUpdatePaymentMethod = async data => {
    const { paymentsStore } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await PaymentsProvider.updateSubscription(data);
      paymentsStore.setSubscription(response);
    } catch (err) {
      // TODO: pass HTTP status from ApiRequest module (I don't want to test for regressions ATM)
      paymentsStore.error = err.err.message === "payment method rejected" ? 'card_rejected' : err.err.message;
    }
    this.setState({ isLoading: false });
  };

  onCreateNewSubscription = async subscriptionData => {
    const { paymentsStore } = this.props;
    this.setState({ isLoading: true });
    try {
      const subscriptionCreated = await PaymentsProvider.postSubscription(subscriptionData);
      paymentsStore.setSubscription({ ...subscriptionCreated, isNewSubscriptionCreated: true });
      EventTracker.track(EVENT_TYPE.PAYMENT_SUCCESS, { channelId: subscriptionData.entityId });
    } catch (err) {
      paymentsStore.error = err.err.message === "payment method rejected" ? 'card_rejected' : err.err.message;
      EventTracker.track(EVENT_TYPE.PAYMENT_FAILED, { channelId: subscriptionData.entityId });
    }

    this.setState({ isLoading: false });
  };

  onSubscriptionCanceled = async subscriptionId => {
    const { paymentsStore } = this.props;
    this.setState({ isLoading: true });
    try {
      const newSubscription = await PaymentsProvider.cancelSubscription(subscriptionId);
      paymentsStore.setSubscription({ ...newSubscription, isNewSubscriptionCreated: false });
    } catch (err) {
      paymentsStore.error = err;
    }
    this.setState({ isLoading: false });
  };

  clearError = () => {
    const { paymentsStore } = this.props;
    paymentsStore.error = undefined;
  };

  // todo can come from store?
  paymentsNavigation = path => {
    const { history: { push } } = this.props;
    push(path);
  };

  goBack = () => {
    const { history } = this.props;
    if (history) {
      history.goBack();
    } else {
      // should not ever happens
      console.error("not have go back funciton");
    }
  };

  showBackButton = () => {
    const { paymentsStore: { currentSubscription, stripePlans } } = this.props;

    const { status } = currentSubscription || false;
    if (!currentSubscription && stripePlans) {
      return true;
    }
    return currentSubscription && status && status === 'active' && !currentSubscription.isNewSubscriptionCreated;
  };

  // Choose which component to render to screen
  renderPaymentsView = () => {
    const {
      paymentsStore: {
        currentSubscription, setSubscription, error, stripePlans,
      },
      channelStore: { channel },
    } = this.props;
    const { redirectTo, subscriptionCompleted } = this.state;

    if (!currentSubscription?.id) {
      if (!stripePlans?.length) {
        return (
          <SubscriptionView
            header="Error ocurred"
            status="error"
            title="We are sorry"
            content="Please try again later."
          />
        );
      }

      return (
        <div>
          <CreateSubscription
            plans={stripePlans}
            onSubmit={subscriptionData => { this.onCreateNewSubscription(subscriptionData); }}
            selectedChannel={channel.publisherId}
            formError={error}
            clearError={this.clearError}
          />
        </div>
      );
    } else {
      const { status } = currentSubscription;
      let showSubscriptionView = true;
      const managePlanProps = {};
      const subscriptionViewProps = {};
      const url = new URL(window.location.href);
      const wipInteractionId = url.searchParams.get("interactionId");
      subscriptionViewProps.navigate = this.paymentsNavigation;
      subscriptionViewProps.selectedChannel = channel.publisherId;
      subscriptionViewProps.goTo = redirectTo;
      subscriptionViewProps.onSubmit = this.onUpdatePaymentMethod;
      subscriptionViewProps.subscription = currentSubscription;
      subscriptionViewProps.formError = error;
      subscriptionViewProps.redirectToInteraction = wipInteractionId;
      subscriptionViewProps.on3dRes = res => {
        setSubscription({ status: res.error ? 'requires_update' : 'active' });
      };
      if (status && status === 'active') {
        if (subscriptionCompleted || !currentSubscription.isNewSubscriptionCreated) {
          showSubscriptionView = false;
          managePlanProps.errorAlert = error;
        }
      }

      if (showSubscriptionView) {
        subscriptionViewProps.status = status;

        return <SubscriptionView {...subscriptionViewProps} />;
      } else {
        managePlanProps.unsubscribe = this.onSubscriptionCanceled;
        managePlanProps.onSubmit = this.onUpdatePaymentMethod;
        managePlanProps.subscription = currentSubscription;
        managePlanProps.selectedChannel = channel.publisherId;

        return (
          <ManagePlan {...managePlanProps} />
        );
      }
    }
  };

  render() {
    const { paymentsStore: { stripePlans, currentSubscription, hasSubscriptionFetched } } = this.props;

    const { isLoading } = this.state;

    const paymentContainerStyle = {};
    if (!currentSubscription && stripePlans) {
      paymentContainerStyle.width = '600px';
    }

    const showBackButton = this.showBackButton();
    return (
      hasSubscriptionFetched && (
        <div className="payments-container" style={paymentContainerStyle}>
          {showBackButton
          && <GoBackBtn onClick={this.goBack} />}
          {this.renderPaymentsView()}
          {
            isLoading && (
              <div className="payment-loader">
                <img src={loader} alt="" />
              </div>
            )
          }
        </div>
      )
    );
  }
}

export default inject(StoresEnum.CHANNEL, StoresEnum.PAYMENTS)(observer(Payments));
