import React, { useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { SearchRounded, ClearRounded } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import './CustomTextField.scss';

const CustomTextField = ({
  onChange, includeSearchIcon, placeholder, value, onKeyUp, variant, className, debounceTimeout = 0,
}) => {
  const inputRef = useRef();

  const clearText = () => {
    onChange('');

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const onChangeDebounced = debounce(e => onChange(e.target.value?.toLowerCase() || ''), debounceTimeout);

  const handleChange = e => {
    // Prevents React from resetting its properties (according to https://reactjs.org/docs/legacy-event-pooling.html):
    e.persist();
    onChangeDebounced(e);
  };

  useEffect(() => {
    if (value !== undefined && value !== inputRef.current.value) {
      inputRef.current.value = value;
    }
  }, [value]);

  return (
    <TextField
      autoFocus
      placeholder={placeholder}
      className={`custom-text-field ${className}`}
      variant={variant}
      margin="none"
      onChange={handleChange}
      onKeyUp={onKeyUp}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (inputRef?.current?.value?.length || includeSearchIcon) && (
          <InputAdornment position="end">
            {inputRef?.current?.value?.length
              ? <ClearRounded onClick={clearText} className="field-icon clear" />
              : (includeSearchIcon && <SearchRounded className="field-icon" />)
            }
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomTextField;
