import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import { Input, InputAdornment } from '@mui/material';
import SelectField from '../../ApeUI/SelectField/SelectField';
import {
  fixedFlatRateValue, fixedRevenueShareValue, getValidRevShareList, isValidRevShareList, updateCampaignBillingValue,
} from '../../../utils/utils';
import { BillingTypes } from '../../../utils/constants';

const getBillingFields = billings => (
  billings.map((billing, index) => ({
    index,
    id: billing.billingType,
    campaignType: billing.campaignType,
    name: startCase(billing.billingType),
    value: billing.value || 0,
    sign: billing.billingType === BillingTypes.revenueShare ? '%' : '$',
    isPrice: billing.billingType === BillingTypes.flatRate,
  }))
);

const RevenueShareSelect = ({
  saveRevShare, className, bottomLabel, campaignSettings,
}) => {
  const [billings, setBillings] = useState([]);
  const [billingFields, setBillingFields] = useState([]);
  const [activeBilling, setActiveBilling] = useState();
  const [billingValue, setBillingValue] = useState(0);

  // Since dot does not trigger onChange and calculation we should increase the size manually to make sure value won't cut
  const inputContentWidth = `calc(${billingValue?.toString()?.length}ch + 3px)`;

  const onSelectBillingType = value => {
    const selectedItem = billingFields.find(revShare => revShare.id === value);
    setActiveBilling(selectedItem || billingFields[0]);
  };

  const renderInputAdornment = position => (
    <InputAdornment position={position}>
      {activeBilling?.sign || ''}
    </InputAdornment>
  );

  const updateBillingValue = async () => {
    const newValue = activeBilling.id === BillingTypes.flatRate
      ? fixedFlatRateValue(billingValue)
      : fixedRevenueShareValue(billingValue);

    setBillingValue(+newValue);
    const updatedBillings = updateCampaignBillingValue(activeBilling.index, billings, +newValue);
    saveRevShare(updatedBillings);
  };

  useEffect(() => {
    const { billings: channelBillings } = campaignSettings || {};
    const hasBillingsChanged = (
      !billings?.length
      || channelBillings?.[0]?.value !== billings?.[0]?.value
      || channelBillings?.[1]?.value !== billings?.[1]?.value
    );

    if (!billingFields?.length || (channelBillings && hasBillingsChanged)) {
      const billingsList = isValidRevShareList(campaignSettings)
        ? channelBillings
        : getValidRevShareList(campaignSettings);

      setBillings(billingsList);
      const revShareData = getBillingFields(billingsList);
      setBillingFields(revShareData);
    }
  }, [campaignSettings]);

  useEffect(() => {
    const revShareWithValue = billingFields.find(rev => rev.value > 0);
    setActiveBilling(revShareWithValue || activeBilling || billingFields[0]);
  }, [billingFields]);

  useEffect(() => {
    setBillingValue(activeBilling?.value || 0);
  }, [activeBilling]);

  return (
    <div className={`rev-share ${className}`}>
      <div className="select-wrapper">
        <SelectField
          activeItem={activeBilling}
          list={billingFields}
          className="select-column-field"
          onSelect={onSelectBillingType}
          label={bottomLabel}
        />
      </div>

      <Input
        className="rev-share-field"
        inputProps={{
          step: activeBilling?.isPrice ? '.01' : 1,
          type: 'number',
          style: { width: inputContentWidth },
        }}
        value={billingValue}
        onChange={({ target }) => setBillingValue(target.value)}
        onBlur={updateBillingValue}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.target.blur();
          }
        }}
        onFocus={event => event.target.select()}
        endAdornment={!activeBilling?.isPrice && renderInputAdornment('end')}
        startAdornment={activeBilling?.isPrice && renderInputAdornment('start')}
      />
    </div>
  );
};

export default RevenueShareSelect;
