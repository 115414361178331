import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import './Toggle.scss';

const Toggle = ({
  toggled, onChange, label, size, typography, disabled, name,
}) => (
  <FormControlLabel
    className={`toggle-form-control ${size} ${!label ? 'no-label' : ''}`}
    label={label}
    classes={{ label: typography }}
    disabled={disabled}
    control={(
      <Switch
        checked={toggled}
        disableRipple
        name={name}
        onChange={event => onChange(event.target.checked)}
        classes={{
          root: 'switch-root', switchBase: 'switch-base', checked: 'switch-checked', thumb: 'thumb', track: 'track',
        }}
      />
    )}
  />
);

Toggle.propTypes = {
  label: PropTypes.string,
  typography: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  toggled: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  label: undefined,
  toggled: false,
  disabled: false,
  size: 'medium',
  typography: 'baseMediumExtraRegular',
  name: '',
};

export default Toggle;
