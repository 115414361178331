import UserProvider from '../../../helpers/UserProvider';
import EventTracker, { EVENT_TYPE } from '../../../helpers/EventTracker';
import EventDataBuilder from '../../../helpers/EventDataBuilder';

export const confirmPasswordRequest = async password => {
  const payload = await UserProvider.confirmPass(password);
};

export const postNewPassword = async user => {
  const payload = await UserProvider.updateUser(user);
};

export const sendMFASmsCode = async () => {
  try {
    const payload = await UserProvider.setTOTP('sms');
    return true;
  } catch (err) {
    return false;
  }
};

export const enableAuthMFALogin = async (code, user) => {
  try {
    const payload = await UserProvider.totpLogin(code);
    EventTracker.track(
      EVENT_TYPE.USER_MFA,
      EventDataBuilder.userMFA('sms'),
    );
    return true;
  } catch (errorObject) {
    return false;
  }
};

export const disableAuthMFALogin = async userId => {
  try {
    const payload = await UserProvider.deleteMFA(userId);
    return true;
  } catch (errorObject) {
    return false;
  }
};

export const getMFABackupCodes = async userId => {
  try {
    const codes = await UserProvider.createBackupTOTPCodes(userId);
    return codes.payload;
  } catch (errorObject) {
    return [];
  }
};

export const getAuthAppBarCode = async () => {
  try {
    const barCodePayload = await UserProvider.setTOTP('app');
    return barCodePayload.payload.url;
  } catch (errorObject) {
    return 'Failed to fetch Barcode';
  }
};

export const switchMFAMethod = async (user, code) => {
  try {
    const payload = await UserProvider.switchMFAMethod(user, code);
    EventTracker.track(
      EVENT_TYPE.USER_SWITCH_MFA,
      EventDataBuilder.userMFA('app'),
    );
    return true;
  } catch (errorObject) {
    return false;
  }
};
