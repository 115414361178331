import React from 'react';
import './PlanElement.scss';
import { getPlanImageOrColor } from '../../payments-util';

const PlanIcon = ({ subscriptionName }) => (
  <div className="plan-checked">
    <div>
      <img alt="" src={getPlanImageOrColor(subscriptionName)} />
    </div>
  </div>
);

export default PlanIcon;
