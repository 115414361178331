import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { Field, renderField } from '../../../utils/formFieldHelper';
import MultiselectField from '../../ApeUI/SelectField/MultiselectField';
import Form from '../Form/Form';
import {
  required, email, composeValidators, FORM_ERROR_MESSAGES,
} from '../../../utils/validation';
import { isAlreadyExistException } from '../../../utils/utils';

const MemberForm = ({
  toggleFormDisplay, groupChannels, includeChannelField, onSubmitMember, assignTo,
}) => {
  const [mappedChannels, setMappedChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState();

  const submitMember = async ({ email: to, displayName }) => {
    const response = await onSubmitMember({ selectedChannels, to, displayName });
    return isAlreadyExistException(response?.error) ? { email: FORM_ERROR_MESSAGES.EMAIL_ALREADY_EXIST } : undefined;
  };

  const handleClose = data => {
    if (data) {
      const selectedItems = data?.filter(item => item.isSelected);
      setSelectedChannels(selectedItems);
      const mergedChannels = uniqBy([...data, ...mappedChannels], 'id');
      setMappedChannels(mergedChannels);
    }
  };

  useEffect(() => {
    const channelItems = groupChannels?.map(channel => ({
      id: channel._id,
      label: channel.name,
    }));

    setMappedChannels(channelItems);
  }, [groupChannels]);

  const renderFields = () => (
    <>
      <Field
        name="displayName"
        type="text"
        component={renderField}
        label="Member name"
        validate={required}
      />
      <Field
        name="email"
        type="email"
        component={renderField}
        label="Member email"
        validate={composeValidators(required, email)}
      />

      {includeChannelField && (
        <MultiselectField
          handleClose={handleClose}
          items={mappedChannels}
          includeSelectAll
          savedSelectedItems={selectedChannels?.map(({ label }) => label)}
          placeholder="Pick a channel"
          includeSearchField
          name="channels"
          label="Channel"
        />
      )}
    </>
  );

  return (
    <Form
      fields={renderFields()}
      onSubmit={submitMember}
      onCancel={toggleFormDisplay}
      approveLabel="Create"
      innerTitle={`Create a new member for this ${assignTo}`}
      disableApproveButton={includeChannelField && !selectedChannels?.length}
    />
  );
};

MemberForm.propTypes = {
  groupChannels: PropTypes.arrayOf(PropTypes.shape({})),
  toggleFormDisplay: PropTypes.func.isRequired,
  onSubmitMember: PropTypes.func.isRequired,
  includeChannelField: PropTypes.bool,
  assignTo: PropTypes.string.isRequired,
};

MemberForm.defaultProps = {
  includeChannelField: false,
  groupChannels: [],
};

export default MemberForm;
