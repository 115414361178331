import React from 'react';
import { Toggle } from '../ApeUI';

const Section = ({
  style, title, children, includeToggleButton, onToggle, isChecked, readOnly,
}) => (
  <div style={style} className={`section ${readOnly ? 'readonly' : ''}`}>
    <div className="form-title">
      {title}
      {includeToggleButton && <Toggle toggled={isChecked} onChange={onToggle} disabled={readOnly} />}
    </div>
    <div className="section-content">
      {children}
    </div>
  </div>
);

export default Section;
