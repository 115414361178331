import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FieldArray } from 'react-final-form-arrays';
import ContentCopy from '@mui/icons-material/FileCopyOutlined';
import { IconButton } from '@mui/material';
import {
  Field, renderDomainsFieldArray, renderField, renderSelect, renderToggle,
} from '../../../utils/formFieldHelper';
import {
  maxLength, minLength, required, composeValidators, FORM_ERROR_MESSAGES,
} from '../../../utils/validation';
import geoList from '../../../utils/geoList';
import verticalList from '../../../utils/verticalList';
import { copyToClipboard, isAlreadyExistException } from '../../../utils/utils';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import Tooltip from '../../ApeUI/Tooltip/Tooltip';
import RevenueShareSelect from '../ChannelsSection/RevenueShareSelect';
import Form from '../Form/Form';
import PlanField from './PlanField';
import GroupField from './GroupField';
import { StoresEnum } from '../../../stores';

import './ChannelForm.scss';

export const FIELD_NAMES = {
  PLAN: 'plan',
  BILLINGS: 'billings',
  COUNTRY: 'country',
  VERTICAL: 'vertical',
  GROUP: 'group',
  ID: 'id',
  TOKEN: 'token',
  STATUS: 'status',
};

const ChannelForm = ({
  userStore: { isSuperAdmin },
  channelStore: { readOnly },
  updateNameOnTyping,
  onSubmit,
  innerTitle,
  fieldsList,
  channel,
  cancelLabel,
  onCancel,
  disableCancelButton,
}) => {
  const [fieldsDictionary, setFieldsDictionary] = useState({});
  const [billings, setBillings] = useState();
  const [mappedCountries, setMappedCountries] = useState([]);
  const [mappedVerticals, setMappedVerticals] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  useEffect(() => {
    setMappedCountries(geoList.map(geo => ({ name: geo.name, id: geo.code })));
    setMappedVerticals(verticalList.map(vertical => ({ name: vertical.name, id: vertical.name.toLowerCase() })));
  }, []);

  useEffect(() => {
    const dictionary = fieldsList.reduce((acc, field) => ({ ...acc, [field]: true }), {});
    setFieldsDictionary(dictionary);
  }, [fieldsList]);

  const handleSubmit = async data => {
    const {
      name, groupId, vertical, plan, urls, geo, isActive,
    } = data;

    // Adjust channel data for submission
    const channelData = {
      name,
      groupId,
      vertical,
      urls,
      geo: mappedCountries.find(country => country.id === geo)?.name,
      ...(isSuperAdmin && plan && plan !== channel.plan && { plan }),
      ...(fieldsDictionary[FIELD_NAMES.STATUS] && { status: isActive ? 'active' : 'inactive' }),
      ...(fieldsDictionary[FIELD_NAMES.BILLINGS] && billings && isSuperAdmin && {
        campaignSettings: {
          ...channel.campaignSettings,
          billings,
        },
      }),
    };

    const response = await onSubmit(channelData);
    return isAlreadyExistException(response?.err) ? { name: FORM_ERROR_MESSAGES.NAME_ALREADY_EXIST } : undefined;
  };

  const copyToken = () => {
    copyToClipboard(channel.authToken);
    setIsSnackbarOpen(true);
  };

  const renderFields = () => (
    <>
      <Field
        name="name"
        type="text"
        component={renderField}
        label="Channel name"
        validate={required}
        onChange={value => updateNameOnTyping && updateNameOnTyping(value)}
        readOnly={readOnly}
      />
      {fieldsDictionary[FIELD_NAMES.COUNTRY] && (
        <Field
          name="geo"
          searchLabel="country"
          type="text"
          label="Country"
          placeholder="Pick a country"
          includeSearch
          component={renderSelect(mappedCountries)}
          validate={required}
          readOnly={readOnly}
        />
      )}
      {fieldsDictionary[FIELD_NAMES.VERTICAL] && (
        <Field
          name="vertical"
          label="Vertical"
          placeholder="Pick a vertical"
          component={renderSelect(mappedVerticals)}
          validate={required}
          readOnly={readOnly}
        />
      )}
      {fieldsDictionary[FIELD_NAMES.GROUP] && isSuperAdmin && (
        <GroupField selectedGroupId={channel?.groupId} />
      )}
      {fieldsDictionary[FIELD_NAMES.PLAN] && <PlanField />}
      {fieldsDictionary[FIELD_NAMES.ID] && isSuperAdmin && (
        <Field
          name="publisherId"
          label="Channel ID"
          readOnly
          component={renderField}
        />
      )}
      {fieldsDictionary[FIELD_NAMES.TOKEN] && (
        <div className="actionable-field">
          <Field
            name="authToken"
            label="Channel token"
            readOnly
            component={renderField}
          />
          <Tooltip title="Copy" placement="top">
            <IconButton onClick={copyToken}><ContentCopy /></IconButton>
          </Tooltip>
        </div>
      )}
      {fieldsDictionary[FIELD_NAMES.STATUS] && isSuperAdmin && (
        <Field
          name="isActive"
          label="Active status"
          type="checkbox"
          component={renderToggle}
        />
      )}
      {fieldsDictionary[FIELD_NAMES.BILLINGS] && isSuperAdmin && (
        <RevenueShareSelect
          className="revenue-type-wrapper"
          bottomLabel="Rate type"
          saveRevShare={setBillings}
        />
      )}
    </>
  );

  return (
    <>
      <Form
        fields={renderFields()}
        onSubmit={handleSubmit}
        initialValues={channel}
        approveLabel={channel?.publisherId ? 'Update' : 'Create'}
        innerTitle={innerTitle}
        className="channel-form"
        cancelLabel={cancelLabel}
        onCancel={onCancel}
        disableCancelButton={disableCancelButton}
        hideCancelButton={!isSuperAdmin}
        hideApproveButton={readOnly}
        hasFieldArray
      >
        <div>
          <h3 className="domains-title">Domains</h3>
          <FieldArray initialValue={channel.urls || ['']} name="urls" component={renderDomainsFieldArray} validate={composeValidators(maxLength(20), minLength(1))} readOnly={readOnly} />
        </div>
      </Form>
      <ApeSnackbar open={isSnackbarOpen} message="Copied!" onRequestClose={() => setIsSnackbarOpen(false)} />
    </>
  );
};

export default inject(StoresEnum.USER, StoresEnum.CHANNEL)(observer(ChannelForm));
