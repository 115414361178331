import React from 'react';
import { inject, observer } from 'mobx-react';
import Section from '../../../Section/Section';
import ColorInput from './ColorInput';
import { StoresEnum } from '../../../../stores';

import './ChannelColors.scss';

const ChannelNameColor = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const { channelNameColor } = channel?.colorPalette || {};

  const submitColor = updatedColor => {
    const clonedChannel = { ...channel };
    if (!updatedColor) {
      return;
    }

    if (!clonedChannel.colorPalette) {
      clonedChannel.colorPalette = { channelNameColor: updatedColor };
    } else {
      clonedChannel.colorPalette.channelNameColor = updatedColor;
    }

    updateChannel(clonedChannel);
  };

  return (
    <Section title="Channel name color">
      <div className="create-form colors-form">
        <div className="fields-grid">
          <ColorInput
            value={channelNameColor}
            label="Channel name color"
            includeSubmitButton={!readOnly}
            addColor={submitColor}
            readOnly={readOnly}
          />
        </div>
      </div>
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(ChannelNameColor));
