/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const Intro = props => {
  const { props: { interactions, channel, ocClose } } = props;
  return (
    <div className="no-channel-popup-container">
      <div className="modal modal-size-popup-intro">
        <div className="popup-intro__box">
          <p className="popup-intro__title">YOU HAVE <span>{interactions.count}</span> UNITS</p>
          <p className="popup-intro__title second-title">UNATTACHED</p>
          <p className="popup-intro__sub-text">Would you like to attach the units to your new channel account?</p>
          <div className="popup-intro__channel-box">
            <img src={`https://img.apester.com/auto/plain/${encodeURIComponent(channel.profileImage?.path)}`} />
            <p className="channel-box channel-box__name">{channel.name}</p>
            <p className="channel-box channel-box__url">{channel.url}</p>
          </div>
          <div className="popup-intro__attach-to-channel-btn" onClick={props.showMigration}>
            <span>ATTACH TO CHANNEL</span>
          </div>
          <div className="popup-intro__skip-btn" onClick={ocClose}>
            <span>SKIP THIS PART</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
