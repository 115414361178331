import React, { useRef, useEffect, useState } from 'react';
import { Field as FinalFormField } from 'react-final-form';
import { Button, IconButton } from '@mui/material';
import { AddRounded, DeleteOutline } from '@mui/icons-material';
import { Toggle } from '../components/ApeUI';
import SelectField from '../components/ApeUI/SelectField/SelectField';
import { domain, required, composeValidators } from './validation';
import MultiselectField from '../components/ApeUI/SelectField/MultiselectField';
import Checkbox from '../components/ApeUI/Checkbox/Checkbox';
import Tooltip from '../components/ApeUI/Tooltip/Tooltip';

const standardFbEventName = 'ViewContent';

const keyPlaceholder = 'key';

const DOMAINS_LIMIT = 20;

const renderErrorMessage = ({
  error, submitError, dirtySinceLastSubmit,
}) => {
  let msg;

  if (submitError && !dirtySinceLastSubmit) {
    msg = submitError;
  }

  if (error) {
    msg = error;
  }

  return msg && <span className="invalid-data">{msg}</span>;
};

export const renderField = ({
  input,
  label,
  placeholder,
  type,
  wrapperClassName = '',
  readOnly,
  prefix,
  useLabelAsPlaceholder = true,
  onChange,
  meta,
}) => (
  <div className={`input-field ${wrapperClassName} ${prefix ? 'has-prefix' : ''}`}>
    <span className="prefix">{prefix}</span>
    <input
      {...input}
      placeholder={useLabelAsPlaceholder ? label : placeholder || ''}
      type={type}
      className={meta.error && (meta.touched || meta.dirty) && !readOnly ? 'invalid' : ''}
      readOnly={readOnly}
      onChange={value => {
        input.onChange(value);
        if (onChange) {
          onChange(value.target.value);
        }
      }}
    />
    <label className="helper-text">
      {label && <span>{label}</span>}
      {(meta.touched || meta.dirty) && !readOnly && ((renderErrorMessage(meta)) || (meta.warning && <span>{meta.warning}</span>))}
    </label>
  </div>
);

// Parse prop is currently the only way to allow empty fields, otherwise react-final-form removes these fields on submit.
// https://github.com/final-form/react-final-form/issues/130
export const Field = ({ allowEmpty, ...props }) => <FinalFormField {...props} {...(allowEmpty ? { parse: value => value } : {})} />;

export const renderDomainsFieldArray = ({
  fields, readOnly, meta: { invalid },
}) => (
  <div className="domains-wrapper">
    <div className="domains-list">
      {fields.map((url, index) => (
        <div key={url} className="domain-item">
          <Field
            name={url}
            validate={composeValidators(required, domain)}
            component={renderField}
            readOnly={readOnly}
          />
          {!readOnly && (
            <IconButton className="delete-domain" onClick={() => fields.remove(index)} disabled={fields?.length === 1}>
              <DeleteOutline />
            </IconButton>
          )}
        </div>
      ))}
    </div>
    {fields?.length === DOMAINS_LIMIT && <div className="limit-error">You have reached the limit of domains you can add.</div>}
    {!readOnly && (
      <div>
        <Tooltip title={`Limited until ${DOMAINS_LIMIT} domains`} placement="bottom">
          <Button
            disabled={invalid || fields?.length === DOMAINS_LIMIT}
            classes={{ label: 'button-label' }}
            className="outlined-secondary add-domain"
            startIcon={<AddRounded />}
            onClick={() => fields.push()}
            variant="outlined"
          >
            Add domain
          </Button>
        </Tooltip>
      </div>
    )}
  </div>
);

export const renderHubspotFieldArrayEvents = ({ fields, readOnly }) => (
  <div>
    <ul className="field-array">
      {!readOnly && (
        <div className="add-button" onClick={() => fields.push({})}>
          <span style={{ paddingRight: '5px' }}>Add Event</span>
          <i className="ic icon-plus add-icon" />
        </div>
      )}

      {fields.map((event, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className="pixel-event-row">
          <div className="fields-list">
            <Field readOnly={readOnly} wrapperClassName="event-field" name={`${event}.id`} type="text" component={renderField} label="Event ID" validate={required} />
            <Field readOnly={readOnly} wrapperClassName="event-field" name={`${event}.value`} type="text" component={renderField} label="Value" validate={required} />
          </div>
          {!readOnly && (
            <div className="remove-button">
              <i className="ic icon-trash" onClick={() => fields.remove(index)} />
            </div>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export const RenderFacebookFieldArrayEvents = ({ fields, readOnly }) => {
  const focusedKeyElement = useRef({});

  const [focusedKey, setFocusedKey] = useState({});

  const updateFocused = (eventIndex, propKey) => {
    setFocusedKey({ eventIndex, propKey });
  };

  const updateCurrentProp = (props, currentKey) => {
    const newKey = focusedKeyElement.current?.value;
    const newProps = { ...props };

    // This code snippet is ensuring we keep the same prop order as before.
    // We're mutating the original props Obj of given event, to support Redux Form integration.
    Object.keys(newProps).forEach(propKey => {
      delete props[propKey];
      if (propKey === currentKey) {
        props[newKey] = newProps[propKey];
      } else {
        props[propKey] = newProps[propKey];
      }
    });

    setFocusedKey({});
  };

  const addNewEvent = () => {
    fields.push({ type: standardFbEventName, props: { content_name: undefined } });
  };

  const addNewProperty = index => {
    const item = fields.value[index];
    const newPropIndex = Object.keys(item.props).length + 1;
    const newPropDefaultName = `prop${newPropIndex}`;
    item.props[newPropDefaultName] = undefined;
    setFocusedKey({ index, propKey: newPropDefaultName });
  };

  const renderInput = (index, propKey) => {
    const item = fields.value[index];
    if (focusedKey.eventIndex === index && focusedKey.propKey === propKey) {
      return (
        <input placeholder={keyPlaceholder} readOnly={readOnly} ref={focusedKeyElement} onBlur={e => updateCurrentProp(item.props, propKey, index)} />
      );
    }
    return <div className={`input ${propKey ? '' : 'empty'}`} onClick={e => updateFocused(index, propKey)}>{propKey || keyPlaceholder}</div>;
  };

  useEffect(() => {
    if (focusedKey.eventIndex === undefined) {
      return;
    }
    focusedKeyElement.current.value = focusedKey.propKey;
    focusedKeyElement.current.focus();
  }, [focusedKey]);

  return (
    <div>
      <ul className="field-array">
        {!readOnly && (
          <div className="add-button" onClick={addNewEvent}>
            <span style={{ paddingRight: '5px' }}>Add Event</span>
            <i className="ic icon-plus add-icon" />
          </div>
        )}

        {fields.map((event, index) => (
        // eslint-disable-next-line react/no-array-index-key
          <li key={index} className="pixel-event-row">
            <div className="fields-list">
              <div className="event-general">
                <Field wrapperClassName="event-field" name={`${event}.type`} type="text" component={renderField} label="Event Type" validate={required} readOnly={readOnly} />
                {!readOnly && <button type="button" className="add-button" onClick={() => addNewProperty(index)}>Add Prop</button>}
              </div>
              <div className="event-props">
                {Object.keys(fields.value[index]?.props || {}).map(propKey => (
                  <div key={propKey} className="event-prop">
                    <div className="event-field input-field">
                      {renderInput(index, propKey)}
                      <label className="helper-text">key</label>
                    </div>
                    <Field wrapperClassName="event-field" name={`${event}.props.${propKey}`} type="text" component={renderField} label="value" validate={required} readOnly={readOnly} />
                  </div>
                ))}
              </div>
            </div>
            {!readOnly && (
              <div className="remove-button">
                <i className="ic icon-trash" onClick={() => fields.remove(index)} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const renderSelect = (sources, fetchList) => ({
  input,
  label,
  meta,
  placeholder,
  className = '',
  popoverClass = '',
  includeSearch,
  searchLabel,
  autoMenuWidth,
  readOnly,
  onChange,
}) => (
  <div className={`select-field ${className}`}>
    <SelectField
      activeItem={input.value || ''}
      placeholder={placeholder}
      name={input.name}
      onClose={value => input.onBlur(value)}
      includeSearch={includeSearch}
      list={sources}
      className={meta.error && meta.touched ? 'invalid' : ''}
      popoverClass={popoverClass}
      onSelect={value => {
        input.onChange(value);
        if (onChange) {
          onChange(value);
        }
      }}
      searchLabel={searchLabel}
      fetchList={fetchList}
      alignDropdownToFieldWidth={!autoMenuWidth}
      size="medium"
      readOnly={readOnly}
    />
    <label className="helper-text">
      {label && <span>{label}</span>}
      {meta.touched && ((renderErrorMessage(meta)) || (meta.warning && <span>{meta.warning}</span>))}
    </label>
  </div>
);

export const renderMultiSelect = sources => ({
  input, label, meta, placeholder, savedSelectedItems, className = '', onClose, includeSearch, readOnly,
}) => (
  <div className={`select-field ${className}`}>
    <MultiselectField
      placeholder={placeholder}
      includeSearchField={includeSearch}
      name={input.name}
      onClose={value => input.onBlur(value)}
      items={sources}
      handleClose={onClose}
      savedSelectedItems={savedSelectedItems}
      size="medium"
      readOnly={readOnly}
    />
    <label className="helper-text">
      {label && <span>{label}</span>}
      {meta.touched && ((renderErrorMessage(meta)) || (meta.warning && <span>{meta.warning}</span>))}
    </label>
  </div>
);

export const renderToggle = ({
  input, label, size, readOnly, onChange,
}) => (
  <Toggle
    disabled={readOnly}
    label={label}
    name={input.name}
    toggled={Boolean(input.checked)}
    onChange={value => (onChange ? onChange(value) : input.onChange(value))}
    size={size}
  />
);

export const renderCheckbox = ({
  input, label, readOnly, onChange,
}) => (
  <Checkbox
    label={label}
    checked={input.checked}
    disabled={readOnly}
    name={input.name}
    labelPlacement="end"
    onChange={value => (onChange ? onChange(value) : input.onChange(value))}
  />
);
