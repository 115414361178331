import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import { Field, renderField, renderSelect } from "../../../../utils/formFieldHelper";
import languages from "../../../../utils/languages";
import { channelEmbedOptions, statusOptions } from "../../../../utils/constants";
import Form from "../../../Settings/Form/Form";
import { email, required } from "../../../../utils/validation";
import Section from "../../../Section/Section";
import { StoresEnum } from "../../../../stores";

const AdminGeneralForm = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const [mappedLanguages, setMappedLanguages] = useState([]);

  useEffect(() => {
    setMappedLanguages(languages.map(language => ({ name: language.name, id: language.value })));
  }, []);

  // keep before choosen publish type
  const publishSettingsType = channel.publishSettings.type;
  const embedOptions = channelEmbedOptions;
  const hasEmbed = embedOptions.some(({ id }) => publishSettingsType === id);
  if (!hasEmbed) {
    embedOptions.push({ name: publishSettingsType, id: publishSettingsType });
  }

  const renderFields = () => (
    <>
      <Field
        name="salesManagerEmail"
        type="email"
        component={renderField}
        label="Sales Manager Email"
        validate={email}
        readOnly={readOnly}
        allowEmpty
      />
      <Field
        name="successManagerEmail"
        type="email"
        component={renderField}
        label="Success Manager Email"
        validate={email}
        readOnly={readOnly}
        allowEmpty
      />
      <Field
        name="displayName"
        component={renderField}
        label="Channel Display Name"
        readOnly={readOnly}
        allowEmpty
      />
      <Field
        name="publishSettings.type"
        label="Default Embedding"
        placeholder="None"
        component={renderSelect(embedOptions)}
        readOnly={readOnly}
      />
      <Field
        name="status"
        label="Status"
        placeholder="None"
        component={renderSelect(statusOptions)}
        readOnly={readOnly}
      />
      <Field
        name="language"
        label="Auto Strip Language"
        component={renderSelect(mappedLanguages)}
        readOnly={readOnly}
        validate={required}
      />
      <Field
        name="avatarSettings.url"
        component={renderField}
        readOnly={readOnly}
        label="Avatar URL"
        allowEmpty
      />
      <Field
        name="campaignSettings.iosAppStoreUrl"
        component={renderField}
        readOnly={readOnly}
        label="App Store URL"
        allowEmpty
      />
      <Field
        name="campaignSettings.iosAppStoreId"
        component={renderField}
        readOnly={readOnly}
        label="App Store ID"
        allowEmpty
      />
      <Field
        name="campaignSettings.iosAppName"
        component={renderField}
        readOnly={readOnly}
        label="App Name"
        allowEmpty
      />
    </>
  );

  return (
    <Section title="ADMIN GENERAL">
      <Form
        initialValues={channel}
        fields={renderFields()}
        onSubmit={updateChannel}
        hideCancelButton
        hideApproveButton={readOnly}
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(AdminGeneralForm));
