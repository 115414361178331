import React from 'react';
import { Password, Authentication } from '.';
import Section from '../Section/Section';

import './Security.scss';

const Security = props => (
  <Section title="SECURITY">
    <div className="security-settings">
      <Password {...props} />
      <Authentication {...props} />
    </div>
  </Section>
);

export default Security;
