import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ContentCopy from '@mui/icons-material/FileCopyOutlined';
import CampaignProvider from '../../../../helpers/CampaignProvider';
import {
  copyToClipboard, downloadFile, fileTypes, executeFunctionWithDelay,
} from '../../../../utils/utils';
import ApeSnackbar, { snackbarType } from '../../../ApeUI/ApeSnackbar/ApeSnackbar';
import { StoresEnum } from '../../../../stores';

import './AdsTxt.scss';

const LOADING_TIMEOUT = 1000;
const FILE_NAME = 'ads.txt';

const ChannelAdsTxt = ({ channelStore: { channel } }) => {
  const [demandPartners, setDemandPartners] = useState([]);
  const [demandPartnersCsv, setDemandPartnersCsv] = useState('');
  const [updatedAt, setUpdatedAt] = useState(false);
  const [isMarked, setIsMarked] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setAdsTxtData = ads => {
    const demandPartnersList = ads.demandPartners.map(demandPartner => (
      demandPartner.domain.includes('apester') ? { ...demandPartner, identifier: channel.groupId } : demandPartner
    ));

    setDemandPartners(demandPartnersList);
    const csv = CampaignProvider.demandPartnersArrToCsv(demandPartnersList);
    setDemandPartnersCsv(csv);
    const date = ads.updatedAt && new Date(ads.updatedAt).toLocaleDateString().replaceAll('/', '-');
    setUpdatedAt(date);
  };

  const copyDemandPartners = async () => {
    setIsLoading(true);
    setIsSnackbarOpen(true);
    await executeFunctionWithDelay(LOADING_TIMEOUT, () => copyToClipboard(demandPartnersCsv));
    setIsLoading(false);
  };

  const downloadTextFile = () => {
    downloadFile(demandPartnersCsv, FILE_NAME, fileTypes.TEXT);
  };

  useEffect(() => {
    CampaignProvider.getAdsTxt().then(setAdsTxtData);
  }, []);

  return (
    <div className="section channel-adtsxt-container">
      <div className="form-title">Authorized Digital Sellers list</div>
      <div className="form-content">
        <h1 className="subtitle">{`ADS.txt  (${demandPartners?.length})`}</h1>
        <div className="information">{updatedAt ? `Last updated ${updatedAt}` : ''}</div>
        <ul className={`ad-list ${isLoading ? 'disabled' : ''}`}>
          {demandPartners.map(ad => (
            <li key={ad._id} className={`item ${isMarked ? 'marked' : ''}`}>
              {CampaignProvider.convertDemandPartnerToStr(ad)}
            </li>
          ))}
        </ul>
        <div className="actions-wrapper">
          <button type="button" className="outlined-settings" onClick={downloadTextFile} disabled={isLoading}>
            <i className="ic icon-file-download" />
            download
          </button>
          <button type="button" className="contained-settings" onClick={copyDemandPartners} disabled={isLoading}>
            <ContentCopy />
            copy
          </button>
        </div>
      </div>
      <ApeSnackbar
        open={isSnackbarOpen}
        message={isLoading? 'Copying' : 'Copied!'}
        onRequestClose={() => setIsSnackbarOpen(false)}
        iconType={isLoading? snackbarType.LOADING : snackbarType.SUCCESS}
      />
    </div>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(ChannelAdsTxt));
