import React from 'react';
import './ManagePlan.scss';
import ConfirmationModal from '../Modal/ConfirmationModal/ConfirmationModal';
import CurrentPayment from './CurrentPlan/CurrentPlan';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import Modal from '../../../../Modal/Modal';
import cancelStarImg from '../../../../../theme/images/plan-cancel-pop-up.svg';

class ManagePlan extends React.Component {
  state = {
    showModal: false,
  };

  openModal = () => {
    const { showModal } = this.state;
    this.setState(({ showModal: !showModal }));
  };

  handleUnsubscribe = () => {
    const { unsubscribe, subscription } = this.props;
    unsubscribe(subscription.id);
  };

  render() {
    const {
      subscription, onSubmit, selectedChannel, errorAlert,
    } = this.props;
    const { showModal } = this.state;
    if (subscription.cancelAtPeriodEnd && showModal) {
      this.setState(({ showModal: false }));
    }
    return (
      <div>
        <div className="manage-plan-container">
          <CurrentPayment planDetails={subscription} openModal={this.openModal} isCanceled={subscription.cancelAtPeriodEnd} />
          <PaymentMethod onSubmit={onSubmit} subscriptionId={subscription.id} subscription={subscription} selectedChannel={selectedChannel} />
        </div>
        {showModal && (
          <Modal
            modalStyle={{ alignItems: 'none' }}
            customModalBodyStyle={{ padding: '22px 0 50px 0' }}
            title="plan cancelation"
            height="374"
            width="606"
            onClose={this.openModal}
            modalHeaderTitleStyle={{
              fontSize: '13px', lineHeight: '1.62', letterSpacing: '1.72px', color: '#cacaca', textTransform: 'uppercase',
            }}
          >
            <ConfirmationModal
              confirmText="Yes, cancel plan"
              cancelText="No, keep plan"
              confirmBtnClick={this.handleUnsubscribe}
              close={this.openModal}
              selectedChannel={selectedChannel}
              title="Plan cancelation will take effect at the end of this billing cycle. You will not be charged again."
              subTitle="Are you sure you want to cancel your plan?"
              errorAlert={errorAlert}
            >
              <img src={cancelStarImg} alt=" " />
            </ConfirmationModal>
          </Modal>
        )}
      </div>
    );
  }
}

export default ManagePlan;
