import { GA, EventsTracker } from '@apester/events';
import { CONFIG } from '../utils/constants';

export const EVENT_TYPE = {
  USER_UPDATED: 'user_updated',
  USER_SWITCH_MFA: 'user_switch_mfa',
  USER_MFA: 'user_mfa',
  CHANNEL_CREATED: 'channel_created',
  CHANNEL_UPDATED: 'channel_updated',
  CHANNEL_UPGRADE_CLICKED: 'channel_upgrade_clicked',
  CHANNEL_MFA_SETUP: 'publisher_mfa_setup',
  CHANNEL_MFA_REMOVE: 'publisher_mfa_remove',
  GROUP_UPDATED: 'group_updated',
  GROUP_CREATED: 'group_created',
  PAYMENT_PAGE_VISITED: 'payment_page_visited',
  PAYMENT_SUCCESS: 'payment_success',
  PAYMENT_FAILED: 'payment_failed',
  PAYMENT_UPGRADE_CLICKED: 'payment_upgrade_clicked',
  SETTING_TAB_SELECTED: 'setting_tab_selected',
  CHANNEL_BLOCKED: 'channel_blocked',
  ANALYTICS_PAGE_LOADED: 'analytics_page_loaded',
  BUTTON_CLICKED: 'button_clicked',
  EXPORT_CSV_CLICKED: 'export_csv_clicked',
  DATE_PICKER_CLICKED: 'date_picker_clicked',
  TAB_CLICKED: 'tab_clicked',
};

class EventTracker {
  static init() {
    EventsTracker.Init({
      props: { release: CONFIG.release },
      eventsUrl: CONFIG.eventsPublicUrl,
    });
    GA.Init();
  }

  static identifyUser(user) {
    GA.Identify(user.userId);
    EventsTracker.IdentifyUser(user);
  }

  static track(type, eventData) {
    EventsTracker.Send(type, eventData);
  }

  static getSessionId() {
    return EventsTracker.GetSessionId();
  }
}

export default EventTracker;
