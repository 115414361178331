import React from 'react';
import { Form } from 'react-final-form';
import { Field, renderField } from '../../../../utils/formFieldHelper';

const ConfirmPassword = ({ onSubmit }) => (
  <Form onSubmit={onSubmit}>
    {({
      handleSubmit, pristine, submitting,
    }) => (
      <form onSubmit={handleSubmit} className="create-form password-form">
        <Field
          name="password"
          type="password"
          component={renderField}
          wrapperClassName="input-field-wrapper"
          placeholder="Enter Your Current Password"
          useLabelAsPlaceholder={false}
        />

        <div className="submit-container">
          <button
            className="submit-button"
            type="submit"
            disabled={pristine || submitting}
          >
            SUBMIT
          </button>
        </div>
      </form>
    )}
  </Form>
);

export default ConfirmPassword;
