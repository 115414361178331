import React from 'react';
import { inject, observer } from 'mobx-react';
import List from '@mui/material/List';
import AddItem from '../../../../Settings/AddItem/AddItem';

import useEndPoint from './hook/useEndPointFormReducer';
import WebHookListItem from './WebHookListItemWrapper';
import Section from '../../../../Section/Section';
import Form from '../../../../Settings/Form/Form';
import { StoresEnum } from '../../../../../stores';

const WebHookFormContainer = ({ channelStore: { readOnly, channel } }) => {
  const [endPoints, error, eventHandlers] = useEndPoint(channel.publisherId);
  const {
    addEndPointToList,
    removeEndPointFromList,
    updateExistsEndPointOnList,
    testWebhookEndPoint,
  } = eventHandlers;

  return (
    <Section title="WEBHOOK ENDPOINTS">
      <Form className="webhook-form" hideCancelButton hideApproveButton>
        {!readOnly && (
          <div className="add-item-wrapper url-exclude-list">
            <span className="input-label">
              Enter EndPoint URL
              <span>&nbsp;&#9679;&nbsp;</span>
              Event: utility_form_submission
            </span>
            <AddItem placeholder="Enter URL's" onClick={addEndPointToList} inputStyle={{ paddingRight: '50px' }} />
            {error !== '' && <span className="url-error">{error}</span>}
          </div>
        )}

        <List className="url-exclude-list">
          {endPoints.map(({
            _id: endpointId, url, transformData, basicAuthName, basicAuthPassword, tokenAuthToken,
          }) => (
            <WebHookListItem
              key={endpointId}
              readOnly={readOnly}
              id={endpointId}
              url={url}
              testWebhookEndPoint={testWebhookEndPoint}
              basicAuthName={basicAuthName}
              basicAuthPassword={basicAuthPassword}
              tokenAuthToken={tokenAuthToken}
              transformData={transformData}
              onDeleteClick={removeEndPointFromList}
              onApplyClick={updateExistsEndPointOnList}
            />
          ))}
        </List>
      </Form>
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(WebHookFormContainer));
