import { isColor } from "../components/Channels/Channel/Palette/utils";
import { EMAIL_REGEX } from "./constants";
import { validateDomain } from "./url";

const isEmpty = value => value === undefined || value === null || value === '';
const alreadyExistMsg = propName => `This ${propName} is already in use.`;
const generalInvalidMsg = propName => `Invalid ${propName}`;

export const FORM_ERROR_MESSAGES = {
  EMAIL_ALREADY_EXIST: alreadyExistMsg('email'),
  NAME_ALREADY_EXIST: alreadyExistMsg('name'),
  REQUIRED: 'Required',
  PASSWORDS_NOT_MATCH: 'Passwords do not match.',
  INVALID_PASSWORD: 'Password must contain 1 uppercase character, 1 lowercase character, a number and be at least 6 characters.',
  INVALID_PHONE_NUMBER: 'Enter Valid Phone Number',
  INVALID_VERIFICATION_CODE: 'Enter Valid Verification code',
  FAILED_VERIFICATION_CODE: 'Failed to verify',
  REQUIRED_COUNTRY: 'Please Select Country Code',
  INCORRECT_PASSWORD: 'Incorrect Password',
};

export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

export const countryCode = value => (!value?.length ? FORM_ERROR_MESSAGES.REQUIRED_COUNTRY : undefined);

export const password = value => {
  const isInvalidPassword = !isEmpty(value) && !/((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,20})/.test(value);
  return isInvalidPassword ? FORM_ERROR_MESSAGES.INVALID_PASSWORD : undefined;
};

export const phoneNumber = value => {
  const isInvalidNumber = !isEmpty(value) && value.length < 8;
  return isInvalidNumber ? FORM_ERROR_MESSAGES.INVALID_PHONE_NUMBER : undefined;
};

export const verificationCode = value => {
  const isInvalidNumber = !isEmpty(value) && value.length !== 6;
  return isInvalidNumber ? FORM_ERROR_MESSAGES.INVALID_VERIFICATION_CODE : undefined;
};

export const email = value => {
  const isInvalidEmail = !isEmpty(value) && (!isEmpty(value) && !EMAIL_REGEX.test(value));
  return isInvalidEmail ? generalInvalidMsg('email address') : undefined;
};

export const hexColor = value => {
  const isInvalidColor = value?.length && !isColor(value);
  return isInvalidColor ? generalInvalidMsg('color') : undefined;
};

export const publisherId = value => {
  const isInvalidPublisherId = isEmpty(value) || !/^[0-9a-fA-F]{24}$/i.test(value);
  return isInvalidPublisherId ? generalInvalidMsg('publisher id') : undefined;
};

export const required = value => (isEmpty(value) ? FORM_ERROR_MESSAGES.REQUIRED : undefined);

export const domain = value => (!validateDomain(value) ? generalInvalidMsg('domain') : undefined);

export const minLength = min => value => {
  const isInvalidLength = !isEmpty(value) && value.length < min;
  return isInvalidLength ? `Must be at least ${min} characters` : undefined;
};

export const maxLength = max => value => {
  const isInvalidLength = !isEmpty(value) && value.length > max;
  return isInvalidLength ? `Must be no more than ${max} characters` : undefined;
};

export const isNumber = value => {
  const isInvalidFormat = !isEmpty(value) && parseInt(value, 10) != value;
  return isInvalidFormat ? 'Please input number' : undefined;
};

export const minValue = min => value => {
  const isInvalidValue = !isEmpty(value) && parseInt(value, 10) < 300;
  return isInvalidValue ? `Must be greater then ${min}` : undefined;
};
