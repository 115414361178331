import ApiRequest from "./ApiRequest";

const constants = require('../utils/constants');

class PaymentsProvider {
  static request = new ApiRequest(constants.CONFIG.planApiUrl);

  static getSubscriptionPerPublisher = async (channelId, force = false) => PaymentsProvider.request.get(`/subscriptions/channel/${channelId}${force? '/force' : ''}`);

  static getSubscriptionPerBulkPublishers = async channelIds => PaymentsProvider.request.get(`/subscriptions/channel/bulk?ids=${channelIds.join(',')}`);

  static getBillingPlans = async () => PaymentsProvider.request.get(`/billing-plans`);

  static fetchInternalPlans = async () => PaymentsProvider.request.get(`/billing-plans/internal`);

  static postSubscription = async subscriptionData => PaymentsProvider.request.post('/subscriptions/', { data: subscriptionData });

  static cancelSubscription = async id => PaymentsProvider.request.delete(`/subscriptions/${id}`);

  static updateSubscription = async data => PaymentsProvider.request.put(`/subscriptions/${data.subscriptionId}`, { data });

  static fetchSubscriptions = async () => PaymentsProvider.request.get(`/subscriptions`);

  static resetTrialSubscription = async subscriptionId => PaymentsProvider.request.post(`/subscriptions/${subscriptionId}/reset`);

  static blockSubscription = async (subscriptionId, channelId) => PaymentsProvider.request.post(`/subscriptions/${subscriptionId}/${channelId}/block`);

  static createTrialPlan = async channelId => {
    const availablePlans = await PaymentsProvider.fetchInternalPlans();
    const trialPlan = availablePlans.find(plan => plan.name.toLowerCase() === constants.trialPlanName.toLowerCase());
    const subscriptionData = {
      billingPlanId: trialPlan.id,
      entityId: channelId,
      token: '',
    };
    await PaymentsProvider.postSubscription(subscriptionData);
  };
}

export default PaymentsProvider;
