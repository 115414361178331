class RoutingService {
  static routes = {
    settings: {
      path: '/',

      routes: {
        profile: {
          name: 'profile',
          path: '/',
          url: '/:section?/:page?/:option?',
          index: 0,
          routes: {
            default: {
              name: 'default',
              path: '',
              index: 0,
            },
            mfa: {
              name: 'mfa',
              path: '/mfa',
              url: '/mfa',
              index: 1,
              routes: {
                active: {
                  name: 'active',
                  path: '/active',
                  url: '/mfa/active',
                  routes: {},
                },
                app: {
                  name: 'app',
                  path: '/app',
                  url: '/mfa/app',
                  routes: {},
                },
                backup: {
                  name: 'backup',
                  path: '/backup',
                  url: '/mfa/backup/:option?',

                  routes: {
                    code: {
                      name: 'backup',
                      path: '/backup/code',
                      url: '/mfa/backup/code',
                      routes: {},
                    },
                  },
                },
              },
            },
            password: {
              name: 'password',
              path: '/password',
              url: '/password',
              index: 2,
              routes: {},
            },
          },
        },

        channels: {
          name: 'channels',
          path: '/channels',
          url: '/channels/:channelId?',
          index: 2,

          routes: {
            payments: {
              name: 'payments',
              path: '/payments',
              index: 3,
              url: '/channels/:channelId?/payments/:locationTo?',
              routes: {},
            },

            create: {
              name: 'create',
              path: '/create',
              index: 3,
              url: '/channels/create/:locationTo?',
              routes: {},
            },

            mfa: {
              name: 'mfa',
              path: '/mfa',
              url: '/channels/:channelId?/mfa',
              index: 4,
              routes: {},
            },
          },
        },

        groups: {
          name: 'groups',
          path: '/groups',
          url: '/groups/:groupId?',
          index: 3,

          routes: {
            channel: {
              name: 'Channel',
              path: '/channel',
              index: 3,
              url: '/groups/:groupId/channels/:channelId',
              routes: {},
            },

            create: {
              name: 'create',
              path: '/create',
              index: 2,
              url: '/groups/create',
              routes: {},
            },
          },
        },
      },
    },
  };

  static getRouteWithPathName = (routes, pathname) => {
    let initialRoute = {};
    Object.keys(routes).forEach(key => {
      const route = routes[key];
      if (route.path && pathname && pathname.indexOf(route.path) !== -1) {
        initialRoute = route;
      }
    });
    return initialRoute;
  };

  static getRouteForSectionName = (routes, section = '') => {
    let retRoute = {};
    Object.keys(routes).forEach(key => {
      const route = routes[key];
      if (
        (route.name && section.indexOf(route.name) !== -1)
        || route.name === section
      ) {
        retRoute = route;
      }
    });
    return retRoute;
  };
}

export default RoutingService;
