import React from 'react';
import Checkbox from '../../../../ApeUI/Checkbox/Checkbox';

const WebHookAction = ({ onClick, visible }) => (
  <Checkbox
    formControlClass="webhook-customize-control"
    labelClass="webhook-customize-label"
    isChecked={!visible}
    onChange={onClick}
    label="Customize Payload"
  />
);

export default WebHookAction;
