import React, { useState, useEffect } from 'react';
import CdnMediaProvider from '../../helpers/CdnMediaProvider';
import { getImageUrl } from '../../utils/utils';

import './ImageUpload.scss';

const GENERAL_ERROR = 'Image couldn\'t be uploaded. Try again';

const ImageUpload = ({
  imagePath, classNames, onChange, categoryId, error, readOnly,
}) => {
  const [imageUrl, setImageUrl] = useState();
  const [uploadError, setUploadError] = useState();

  useEffect(() => {
    if (error) {
      setImageUrl(null);
    }
  }, [error]);

  useEffect(() => {
    setImageUrl(imagePath && getImageUrl({ path: imagePath, type: 'cdn' }));
  }, [imagePath]);

  const uploadImage = file => {
    setUploadError(null);
    setImageUrl(null);

    CdnMediaProvider.uploadProfileImage(file)
      .then(mediaData => onChange(mediaData.payload, categoryId))
      .catch(() => setUploadError(true));
  };

  const deleteImage = () => {
    setImageUrl(null);
    onChange(null, categoryId);
  };

  return readOnly ? (
    <div className={`upload-image-container disabled ${classNames} ${imageUrl ? 'uploaded' : ''}`}>
      {imageUrl ? (
        <div className="displayed-image" style={{ backgroundImage: `url(${imageUrl})` }} />
      ) : (
        <div className="image-placeholder">
          <span className="ic icon-image" />
        </div>
      )}
    </div>
  ) : (
    <>
      <div className={`upload-image-container ${classNames} ${uploadError || error ? 'invalid' : ''} ${imageUrl ? 'uploaded' : ''}`}>
        <div className="shadow" />

        {imageUrl && (
          <>
            <span className="ic icon-close-popup icon" onClick={deleteImage} />
            <div className="displayed-image" style={{ backgroundImage: `url(${imageUrl})` }} />
          </>
        )}

        <div className="image-placeholder">
          <span className="ic icon-image" />
          {!categoryId && <span className="title">{imageUrl ? 'CHANGE IMAGE' : 'UPLOAD IMAGE BANNER'}</span>}
          <span className="subtitle">jpg, jpeg, png, gif, bmp</span>
        </div>

        <label className="input-wrapper">
          <input
            className="input-file"
            type="file"
            id="image-file"
            accept=".jpg, .jpeg, .png, .gif, .bmp"
            onChange={event => {
              uploadImage(event.target.files[0]);
              event.target.value = null;
            }}
          />
        </label>
      </div>

      <div className={`error-message ${uploadError || error ? 'show' : ''}`}>{GENERAL_ERROR}</div>
    </>
  );
};

export default ImageUpload;
