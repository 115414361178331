import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { RefreshRounded } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';
import EventTracker, { EVENT_TYPE } from '../../../helpers/EventTracker';
import { Field, renderField, renderSelect } from '../../../utils/formFieldHelper';
import PaymentsProvider from '../../../helpers/PaymentsProvider';
import { getChannelSubscriptionName, plans } from '../../Channels/Channel/Payments/payments-util';
import PaymentPopup from '../../Channels/Channel/Payments/PaymentPopup';
import { snackbarContent, snackbarTopics } from '../../ApeUI/ApeSnackbar/SnackbarContent';
import Tooltip from '../../ApeUI/Tooltip/Tooltip';
import ApeSnackbar from '../../ApeUI/ApeSnackbar/ApeSnackbar';
import { StoresEnum } from '../../../stores';

import './ChannelForm.scss';

const ACTION_BUTTON_TYPE = {
  UPGRADE: 'upgrade',
  TRIAL: 'trial',
};

const MANAGED_PLANS = [plans.PUBLISHER_PRO, plans.MARKETER_PRO];

const retrieveSuperAdminActionType = (channel, subscription) => {
  const subscriptionName = getChannelSubscriptionName(channel.publisherId, subscription);
  const isTrialPlan = subscriptionName?.toLowerCase() === plans.TRIAL;

  return isTrialPlan ? ACTION_BUTTON_TYPE.TRIAL : null;
};

const retrieveChannelAdminActionType = (isChannelAdmin, channel, subscription) => {
  const subscriptionName = getChannelSubscriptionName(channel.publisherId, subscription);

  if (!isChannelAdmin || MANAGED_PLANS.includes(subscriptionName?.toLowerCase())) {
    return null;
  }

  if (!subscription || subscriptionName?.toLowerCase() === plans.TRIAL) {
    return ACTION_BUTTON_TYPE.UPGRADE;
  }

  return null;
};

const PlanField = ({
  paymentsStore: {
    currentSubscription, setSubscription, isBlockable, internalPlans,
  },
  userStore: { isSuperAdmin, user },
  channelStore: { channel, isChannelAdmin },
  routing: { push },
}) => {
  const [actionButton, setActionButton] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [snackbarCurrentContent, setSnackbarCurrentContent] = useState();
  const [shouldShowPaymentPopup, setShowPaymentPopup] = useState(false);
  const [shouldFadeIn, setFadeInPopup] = useState(false);
  const [allPlans, setAllPlans] = useState([]);

  const { publisherId } = channel || {};
  const hasPlanChanged = selectedPlan && selectedPlan !== currentSubscription?.billingPlan?.id;

  const resetTrial = async () => {
    const newSubscription = currentSubscription?.id
      ? await PaymentsProvider.resetTrialSubscription(currentSubscription.id)
      : await PaymentsProvider.createTrialPlan(publisherId);

    if (newSubscription) {
      setSubscription(newSubscription);
    }
  };

  const blockChannel = async () => {
    const newSubscription = await PaymentsProvider.blockSubscription(currentSubscription?.id, channel.publisherId);
    if (newSubscription?.id) {
      setSubscription(newSubscription);
      const content = snackbarContent[snackbarTopics.CHANNEL_BLOCKED](channel.name);
      setSnackbarCurrentContent(content);
    }
    return newSubscription;
  };

  const trackUpgradeClick = () => {
    const { userId, emails } = user;
    EventTracker.track(EVENT_TYPE.PAYMENT_UPGRADE_CLICKED, {
      userId,
      publisherId,
      user_email: emails[0],
      eventCategory: 'manage',
      eventElement: 'button',
      eventAction: 'click',
      eventType: 'element_clicked',
      tabName: origin,
    });
  };

  const receiveMessage = event => {
    const { data, type } = event.data;
    if (type === 'close_payments') {
      setFadeInPopup(false);
      window.removeEventListener('message', receiveMessage);
      // time for animation to play
      setTimeout(() => {
        setShowPaymentPopup(false);
        if (data.shouldRefresh) {
          window.location.reload();
        }
      }, 1000);
    }
  };

  const onRegularUserAction = hasSubscription => {
    if (!hasSubscription) {
      trackUpgradeClick();
      setFadeInPopup(true);
      setShowPaymentPopup(true);
      window.addEventListener('message', receiveMessage, false);
    } else {
      push(`/channels/${channel.publisherId}/payments`);
    }
  };

  const renderActionButton = type => {
    switch (type) {
    case ACTION_BUTTON_TYPE.TRIAL:
      return (
        <div className="trial-actions">
          {isBlockable && (
            <Tooltip title="Finish trial plan" placement="top">
              <IconButton onClick={blockChannel}><i className="ic icon-expiration-date" /></IconButton>
            </Tooltip>
          )}
          <Tooltip title="Reset" placement="top">
            <IconButton onClick={resetTrial}><RefreshRounded /></IconButton>
          </Tooltip>
        </div>
      );
    case ACTION_BUTTON_TYPE.UPGRADE:
      return <Button className="contained-primary" onClick={() => onRegularUserAction()}>Upgrade</Button>;
    default:
      return null;
    }
  };

  useEffect(() => {
    if (!publisherId) {
      return;
    }

    const action = isSuperAdmin
      ? retrieveSuperAdminActionType(channel, currentSubscription)
      : retrieveChannelAdminActionType(isChannelAdmin, channel, currentSubscription);
    setActionButton(action);
  }, [currentSubscription]);

  useEffect(() => {
    if (!internalPlans?.length || !currentSubscription) {
      return;
    }

    const plansList = [...internalPlans];

    const { paymentProvider, id, name } = currentSubscription?.billingPlan || {};
    const planIds = internalPlans.map(({ planId }) => planId);
    if (paymentProvider === 'stripe' && !planIds.includes(id)) {
      plansList.unshift({
        id,
        name: `${name} (stripe plan)`,
        disabled: true,
      });
    }

    setAllPlans([...plansList]);
  }, [currentSubscription, internalPlans]);

  return (
    <>
      <div className={`plan-field actionable-field ${!actionButton || hasPlanChanged ? 'no-action' : ''} ${!isBlockable && actionButton === ACTION_BUTTON_TYPE.TRIAL ? 'trial-ended' : ''}`}>
        <Field
          name="plan"
          label="Plan"
          readOnly={!isSuperAdmin}
          onChange={value => setSelectedPlan(value)}
          component={isSuperAdmin ? renderSelect(allPlans) : renderField}
        />
        {channel?.publisherId && !hasPlanChanged && renderActionButton(actionButton)}
      </div>
      {shouldShowPaymentPopup && <PaymentPopup shouldFadeIn={shouldFadeIn} publisher={channel} user={user} />}

      <ApeSnackbar
        open={Boolean(snackbarCurrentContent?.message)}
        message={snackbarCurrentContent?.message}
        onRequestClose={() => setSnackbarCurrentContent()}
        iconType={snackbarCurrentContent?.type}
      />
    </>
  );
};

export default inject(StoresEnum.USER, StoresEnum.CHANNEL, StoresEnum.ROUTING, StoresEnum.PAYMENTS)(observer(PlanField));
