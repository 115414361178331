import ApiRequest from './ApiRequest';

const constants = require('../utils/constants');

class CampaignProvider {
  static request = new ApiRequest(constants.CONFIG.adsetApiPublicUrl);

  static getAdsTxt = async () => (
    CampaignProvider.request.get(`/crawlers/adsTxt/txt`)
      .then(res => res.payload)
      .catch(err => { console.error(err); })
  );

  static convertDemandPartnerToStr = adTxt => {
    let line = `${adTxt.domain}, ${adTxt.identifier}`;
    if (adTxt.inventory) {
      line += `, ${adTxt.inventory}`;
    }

    if (adTxt.vendorId) {
      line += `, ${adTxt.vendorId}`;
    }

    return line;
  };

  static demandPartnersArrToCsv = adsTxt => (
    (adsTxt || [])
      .map(this.convertDemandPartnerToStr)
      .join('\n')
  );
}

export default CampaignProvider;
