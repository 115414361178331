import React from 'react';
import PlanIcon from '../../PlanElement/PlanTitle/PlanIcon';
import './PlanFeatures.scss';
import { plans } from '../../payments-util';

const CheckMarkIcon = require('../../../../../../theme/images/checkmark-yellow.svg');

const starImage = require('../../../../../../theme/images/Star-yellow.svg');

const PlanFeature = () => {
  const details = [
    {
      title: 'Ads',
      features: ['Ads removed'],
    },
    {
      title: 'Creation Editor',
      features: ['Unlimited access to all content formats & libraries'],
    }, {
      title: 'CMS Roles',
      features: ['Unlimited number of editors'],
    }, {
      title: 'Distribution',
      features: ['Export to Instagram & Snapchat', 'Algorithm-based personal feed'],
    }, {
      title: 'Conversation',
      features: ['Swipe up frames', 'CTA buttons (purchase, download, etc.)'],
    }, {
      title: 'Branding',
      features: ['Your logo on content'],
    }, {
      title: 'Support',
      features: ['Tech support'],
    },
  ];

  return (
    <div className="plan-feature-container">
      <div className="feature-header">
        <div className="left">
          <PlanIcon subscriptionName={plans.ESSENTIAL} />
          <div className="feature-title" style={{ color: '#ffc300' }}>
            { plans.ESSENTIAL[0].toUpperCase() + plans.ESSENTIAL.substr(1) }
            {' '}
            plan
          </div>
        </div>
        <div className="views-limit">Up to 100K page views</div>
      </div>
      <div className="plan-details">
        {
          details.map((feature, key) => (
            <div className="feature-description-container" key={feature.title}>
              <div className="feature-title">{feature.title}</div>
              {
                feature.features.map((feat, i) => (
                  <div key={`${feature.title}_${feat}`} className={`feature-description ${i >0 ? 'break-line':'' }`}>
                    <span>{feat}</span>
                    <div className="checkmark-feature">
                      <img alt="" src={CheckMarkIcon} style={{ width: '22px', height: '22px', objectFit: 'contain' }} />
                    </div>
                  </div>
                ))

              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default PlanFeature;
