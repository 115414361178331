import { createPhotoObject } from "../utils/utils";
import ApiRequest from "./ApiRequest";

class CdnMediaProvider {
  static request = new ApiRequest();

  static uploadImage = async e => {
    const imageFile = e.target.files.length && e.target.files[0];
    if (/^image\//.test(imageFile.type)) {
      const response = await this.uploadProfileImage(imageFile);
      return createPhotoObject(response.payload);
    }

    return null;
  };

  static uploadProfileImage = fileImage => (
    CdnMediaProvider.request.post('/media/upload', {
      data: fileImage,
      file: { type: 'user-profile' },
    })
  );
}

export default CdnMediaProvider;
