import React, { useRef, useEffect } from 'react';
import { AddRounded, ClearRounded, SearchRounded } from '@mui/icons-material';

import './AddItem.scss';

const AddItem = ({
  placeholder,
  onClick,
  inputStyle,
  onChange,
  isSearchField,
  debounceTimeout = 0,
  className,
  onInputClick,
  includeClearButton,
  inputValue,
  disabled,
}) => {
  const inputRef = useRef();

  const renderIcon = () => {
    if (!isSearchField) {
      return <AddRounded />;
    }

    if (!includeClearButton) {
      return <SearchRounded />;
    }

    return inputRef?.current?.value.length ? <ClearRounded /> : <SearchRounded />;
  };

  const onClickIcon = e => {
    e.preventDefault();
    if (onClick) {
      onClick(inputRef?.current?.value);
    }

    if (inputRef.current) {
      inputRef.current.value = '';
    }

    if (onChange) {
      onChange('');
    }
  };

  const handleChange = e => {
    // Prevents React from resetting its properties (according to https://reactjs.org/docs/legacy-event-pooling.html):
    e.persist();

    setTimeout(() => {
      const { value } = e.target;
      if (onChange) {
        onChange(value);
      }
    }, debounceTimeout);
  };

  useEffect(() => {
    if (inputValue !== undefined && inputValue !== inputRef.current.value) {
      inputRef.current.value = inputValue;
    }
  }, [inputValue]);

  return (
    <div className={`add-item-container ${className} ${disabled ? 'disabled' : ''}`}>
      <input
        className="search-box"
        placeholder={placeholder}
        onChange={handleChange}
        onClick={onInputClick}
        ref={inputRef}
        style={inputStyle}
      />
      <span
        className={`icon-wrapper ${onClick || (includeClearButton && inputRef?.current?.value.length) ? 'clickable' : ''}`}
        onClick={onClickIcon}
      >
        {renderIcon()}
      </span>
    </div>
  );
};

export default AddItem;
