import React from 'react';
import { inject, observer } from 'mobx-react';
import CreateSubscription from '../../CreatePlan/CreatePlan';
import { StoresEnum } from '../../../../../../stores';
import './PaymentsModal.scss';

const PaymentModal = ({ paymentsStore, onSubmit, selectedChannel }) => (
  <div className="update-payment-modal">
    <CreateSubscription
      isEditMode
      clearError={() => { paymentsStore.error = undefined; }}
      subscriptionId={paymentsStore.currentSubscription.id}
      onSubmit={onSubmit}
      selectedChannel={selectedChannel}
    />
  </div>
);

export default inject(StoresEnum.PAYMENTS)(observer(PaymentModal));
