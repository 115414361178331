import React from 'react';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';

import './ContentAccess.scss';

import whitelistImage from '../../../../assets/images/whitelist.svg';

const UrlCell = ({
  url,
  onDeleteClick,
  isEnable,
  readOnly,
}) => (
  <ListItem className={`url-list-item ${isEnable ? '' : 'disabled'} ${readOnly ? 'readonly' : ''}`}>
    <div className="content">
      <Avatar src={whitelistImage} />
      {url}
      {!readOnly && (
        <span
          className="ic icon-cross delete-url-icon"
          onClick={() => {
            if (onDeleteClick) {
              onDeleteClick(url);
            }
          }}
        />
      )}
    </div>
  </ListItem>
);

export default UrlCell;
