/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import StoryItem from '../StoryItem/StoryItem';
import './AddInteractionPopup.scss';
import { CONFIG as config } from '../../../../../../utils/constants';
import InteractionsHelper from '../../../../../../helpers/InteractionsHelper';
import extractImageElement from '../utils';

export class AddInteractionPopup extends Component {
  state = {
    interactionId: '',
    isInteractionValid: false,
    interactionError: false,
  };

  constructor(props) {
    super(props);
    const { storyItem } = this.props;
    const clonedStoryItem = cloneDeep(storyItem);
    this.state = {
      interactionId: storyItem.interactionId,
      storyItem: clonedStoryItem,
      isInteractionValid: Boolean(storyItem.interactionId),
    };
  }

  onInputChange = e => {
    this.setState({ interactionId: e.target.value });
  };

  getInteractionData = async interactionId => {
    const { storyItem } = this.state;
    const { publisherId } = this.props;
    const validInteractionLength = interactionId.length === 24;
    if (!validInteractionLength) {
      return this.setState({ isInteractionValid: false, interactionError: true });
    }
    try {
      const interaction = (await InteractionsHelper.requestInteractionsData([interactionId], true))[0];
      const validInteractionType = interaction.layout.label === 'Story'
        && interaction.state === 'published'
        && interaction.publisher
        && publisherId === interaction.publisher._id;
      if (!validInteractionType) {
        return this.setState({ isInteractionValid: false, interactionError: true });
      }

      storyItem.image = extractImageElement(interaction);
      storyItem.interactionId = interactionId;
      return this.setState({
        storyItem,
        isInteractionValid: true,
        interactionError: false,
      });
    } catch (e) {
      return this.setState({ isInteractionValid: false, interactionError: true });
    }
  };

  render() {
    const { closePopup, removeInteraction, saveInteraction } = this.props;
    const {
      interactionId, storyItem, isInteractionValid, interactionError,
    } = this.state;
    return (
      <div className="add-branded-interaction-pop-up">
        <div className="modal">
          <button type="button" className="close-button" onClick={closePopup}>
            <i className="ic icon-cross" />
          </button>
          <div className="modal-interaction-form">
            <div className="interaction-image">
              <StoryItem image={storyItem.image} />
            </div>
            <div className="interaction-header">
              Story
              {' '}
              {storyItem.name}
            </div>
            <label className="interaction-label">Interaction ID</label>
            <div className="interaction-inbox">
              <div className="inbox-wrapper">
                <span className={`inbox-wrapper-container ${interactionError && 'interaction-error'}`}>
                  {interactionError && <span className="interaction-error-message">Invalid ID</span>}
                  <input disabled={isInteractionValid ? 'disabled' : ''} type="text" placeholder="Paste an interaction id..." value={interactionId || ''} onChange={this.onInputChange} />
                  {interactionId && !isInteractionValid && (
                    <a className="action-label-add" onClick={() => this.getInteractionData(interactionId)}>
                      Add
                    </a>
                  )}
                  {isInteractionValid && <span className="ic icon-trash" onClick={() => removeInteraction(storyItem)} />}
                </span>
              </div>
            </div>
            {isInteractionValid && interactionId && (
              <button type="button" className="preview-button">
                <span className="ic icon-view" />
                <a className="preview-text" href={`${config.playerBaseUrl}${interactionId}?preview=true&iframe_preview=true`} target="_blank" rel="noopener noreferrer">
                  Preview
                </a>
              </button>
            )}
            <button type="button" className={`interaction-save-button ${!isInteractionValid && 'disabled'}`} onClick={() => saveInteraction(storyItem)} disabled={!isInteractionValid && 'disabled'}>
              SAVE
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddInteractionPopup;
