import React from 'react';
import { Route } from 'react-router-dom';
import RoutingService from '../../../helpers/RoutingService';

import PasswordForm from './PasswordForm/PasswordForm';

const Password = ({
  onPresentPassword,
  ...otherProps
}) => (
  <div className="password-section">
    <label>Password</label>
    <div
      className="change-button"
      onClick={() => onPresentPassword(true)}
    >
      Change password
      <i className="ic icon icon-arrow-right" />
    </div>
    <Route
      path={`${
          RoutingService.routes.settings.routes.profile.routes.password.url
        }`}
      render={() => (
        <PasswordForm
          onClose={() => onPresentPassword(false)}
          {...otherProps}
        />
      )}
    />
  </div>
);

export default Password;
