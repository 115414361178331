// form validator
const paymentsValidationFields =(formData, field) => {
  if (field === 'email') {
    const re = /\S+@\S+\.\S+/;
    if (!re.test(formData.email.value)) {
      formData[field].error = {
        value: true,
        message: 'Email Is invalid',
      };
    } else {
      formData[field].error = {
        value: false,
        message: undefined,
      };
    }
  } else if (formData[field].value.length < 3) {
    formData[field].error = {
      value: true,
      message: 'Invalid',
    };
  } else {
    formData[field].error = {
      value: false,
      message: undefined,
    };
  }
  return formData;
};

export default paymentsValidationFields;
