import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '../ApeUI/Tooltip/Tooltip';

import './EllipsisText.scss';

const EllipsisText = ({
  text, className = '', maxWidth, tooltipPlacement = 'bottom',
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef(null);

  useEffect(() => {
    if (textElementRef?.current && maxWidth) {
      setIsOverflow(textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth);
    }
  }, [text, maxWidth]);

  return (
    <Tooltip
      title={text}
      disableHoverListener={!isOverflowed}
      placement={tooltipPlacement}
      className="ellipsis-tooltip"
    >
      <div className={`text-wrapper ${className}`} style={{ maxWidth }} ref={textElementRef}>
        {text}
      </div>
    </Tooltip>
  );
};

export default EllipsisText;
