import React from 'react';
import { inject, observer } from 'mobx-react';
import Header from '@apester/header';
import { getPublicUrlPath } from '@apester/image';

import UserProvider from '../../helpers/UserProvider';

import { CONFIG } from '../../utils/constants';
import { redirect, getHeaderRouter } from '../../utils/utils';
import route from '../../utils/header.routes';
import { StoresEnum } from '../../stores';

const MainHeader = observer(({
  userStore: { user, sessionId }, handleSwitchChannel, selectedMenuOption, app, routing,
}) => {
  const profileImageUrl = user.profileImage ? getPublicUrlPath(user.profileImage) : '';

  const openInternalLink = (menuOption, optionId) => {
    routing.push(`${route[menuOption]}${optionId || ''}`);
  };

  const openMenuLink = async (appName, menuOption, optionId) => {
    if (appName === 'console') {
      openInternalLink(menuOption, optionId);
      return;
    }

    if (appName === 'analytics') {
      window.location.href = route.analytics;
      return;
    }

    if (appName === 'portal') {
      window.location.href = `${route.templates}/${menuOption}?sId=${sessionId}&origin=console&inApp=false`;
      return;
    }

    if (appName === 'adset') {
      window.location.href = `${route[menuOption]}?sId=${sessionId}`;
      return;
    }

    if (menuOption === 'logout') {
      UserProvider.logout().then(() => {
        redirect(route[menuOption]);
      });
    } else {
      switch (menuOption) {
      case 'poll':
      case 'countdown':
      case 'videoPoll':
      case 'story':
      case 'custom':
      case 'gallery':
      case 'quiz':
      case 'personality':
      case 'videoQuiz':
      case 'videoPersonality':
        window.open(await getHeaderRouter(menuOption, sessionId), '_blank');
        break;
      default:
        window.location.href = `${route[menuOption]}sId=${sessionId}`;
        break;
      }
    }
  };

  return user?.loggedIn ? (
    <Header
      user={user}
      openMenuLink={openMenuLink}
      selectedMenuOption={selectedMenuOption}
      appName={app}
      profilePicUrl={profileImageUrl}
      planApi={CONFIG.planApiUrl}
      imagesUrl={CONFIG.imagesUrl}
      usersUrl={CONFIG.usersBaseUrl}
      wixIntegrationUrl={CONFIG.wixIntegrationUrl}
      jobsUrl={CONFIG.jobsUrl}
      portalUrl={CONFIG.portalUrl}
      eventsUrl={CONFIG.eventsPublicUrl}
      editorUrl={CONFIG.editorBaseUrl}
      paymentsUrl={CONFIG.paymentsUrl}
      callback={() => window.location.href}
      sessionId={sessionId}
      enableHeap={CONFIG.enableHeap}
      handleSwitchChannel={handleSwitchChannel}
      authUrl={CONFIG.authBaseUrl}
    />
  ) : null;
});

export default inject(StoresEnum.ROUTING, StoresEnum.USER)(MainHeader);
