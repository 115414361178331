import React from 'react';

import './ProfileSkeleton.scss';

const ProfileSkeleton = ({
  avatar, children, tabs, toggleTab, currentTab,
}) => {
  const renderTabs = () => (
    <div className="tabs-container">
      {tabs.map(tab => (
        <button
          type="button"
          key={tab.index}
          className={currentTab === tab.index ? 'active' : ''}
          onClick={() => toggleTab(tab.index, tab.label)}
          disabled={tab.disabled}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );

  return (
    <div className="skeleton-grid">
      <div className="screen-profile">
        {avatar}
      </div>
      <div className={`screen-settings ${tabs ? 'has-tabs' : ''}`}>
        {tabs && renderTabs()}
        <div className="profile-sections-wrapper">
          <div className="profile-sections">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
