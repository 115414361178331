import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import List from "@mui/material/List";
import { Button } from "@mui/material";
import { Form } from "react-final-form";

import UrlCell from '../ContentAccess/UrlCell';
import AddItem from '../../../Settings/AddItem/AddItem';
import { Field, renderToggle, renderField, renderSelect } from "../../../../utils/formFieldHelper";
import { sanitizeUrl, validateUrl } from '../../../../utils/url';
import ImageUpload from '../../../ImageUpload/ImageUpload';
import Section from "../../../Section/Section";
import { StoresEnum } from "../../../../stores";
import { fallbackSizesOptions } from '../../../../utils/constants';

import './AdsSettingsForm.scss';

const companionImageTypes = ['Below', 'Above'];

const AdsSettingsForm = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const [error, setError] = useState('');
  const [backfillUrlChanged, setBackfillUrlChanged] = useState(false);
  const [adsExcludeList, setAdsExcludeList] = useState([]);
  const [companionAds, setCompanionAds] = useState({});
  const { campaignSettings } = channel;

  const removeUrlFromExcludeList = url => {
    const filteredExcludeList = adsExcludeList.filter(u => u !== url);
    setAdsExcludeList([...filteredExcludeList]);
    updateChannel({ ...channel, adsUrlExcludeList: filteredExcludeList });
  };

  const addUrlToExcludeList = data => {
    const urls = data.split(' ');
    let errorMsg = '';
    const excludeList = [...new Set(urls.map(url => {
      if (validateUrl(url)) {
        return sanitizeUrl(url).replace(/\?.*/, '');
      } else {
        errorMsg = errorMsg ? `${errorMsg}, '${url}'` : `Invalid domains: '${url}'`;
        return undefined;
      }
    }).filter(item => item).concat(adsExcludeList))];
    if (errorMsg) {
      setError({ ...error, 'url-exclude-list': errorMsg });
    }
    if (excludeList.length > 0) {
      setAdsExcludeList(excludeList);
      updateChannel({ ...channel, adsUrlExcludeList: excludeList });
    }
  };

  const handleFallbackImageSize = (newVal, position) => {
    const sizes = campaignSettings?.companionAds.size || {};
    sizes[position] = newVal;

    updateChannel({
      ...channel,
      campaignSettings: {
        ...(campaignSettings || {}),
        companionAds: { ...(campaignSettings?.companionAds || {}), size: sizes },
      },
    });
  };

  const handleImageUpload = position => filePath => {
    updateChannel({
      ...channel,
      campaignSettings: {
        ...(campaignSettings || {}),
        companionAds: { ...(campaignSettings?.companionAds || {}), [position]: filePath },
      },
    });
    setCompanionAds(prevState => ({ ...prevState, [position]: filePath }));

    if (!campaignSettings?.companionAds?.size[position]) {
      handleFallbackImageSize(campaignSettings?.companionAds?.size[position], position);
    }
  };

  const onBackfillUrlApply = e => {
    e.preventDefault();
    if (companionAds.backfillUrl === '' || validateUrl(companionAds.backfillUrl)) {
      updateChannel({
        ...channel,
        campaignSettings: {
          ...(campaignSettings || {}),
          companionAds: {
            ...(campaignSettings?.companionAds || {}),
            backfillUrl: companionAds.backfillUrl,
          },
        },
      });
      setBackfillUrlChanged(false);
      setError({ ...error, backfillUrl: null });
    } else {
      setError({ ...error, backfillUrl: `Invalid adress: ${companionAds.backfillUrl}` });
    }
  };

  const renderExcludeAds = () => (
    <>
      {!readOnly ? (
        <div className="add-item-wrapper url-exclude-list">
          <span className="input-label">Exclude Ads from the following URLs</span>
          <AddItem placeholder="Enter URL's" onClick={addUrlToExcludeList} inputStyle={{ paddingRight: '50px' }} />
          {error['url-exclude-list'] !== '' && <span className="url-error">{error['url-exclude-list']}</span>}
        </div>
      ) : <div />}

      <div>
        {readOnly && <span className="exclude-ads-label">Exclude Ads from the following URLs</span>}
        <List className="url-exclude-list">
          {adsExcludeList.map(url => <UrlCell key={url} url={url} onDeleteClick={removeUrlFromExcludeList} isEnable readOnly={readOnly} />)}
        </List>
      </div>
    </>
  );

  useEffect(() => {
    const { adsUrlExcludeList = [], campaignSettings: { companionAds: companionAdsInitial } = {} } = channel;
    setAdsExcludeList(adsUrlExcludeList);
    setCompanionAds({ ...companionAdsInitial, backfillUrl: '' });
  }, [channel]);

  return (
    <Section title="ads">
      <div className="companion-settings">
        <Form onSubmit={updateChannel} initialValues={channel}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="create-form ads-settings-form">
              <h2 className="title">Companion Backfill Image</h2>
              <div className="field-wrapper companion-images">
                {companionImageTypes.map(type => (
                  <div className="companion-image" key={type}>
                    <label>{type}</label>
                    <ImageUpload imagePath={companionAds?.[type.toLowerCase()]} classNames="top-image" onChange={handleImageUpload(type.toLowerCase())} readOnly={readOnly} />
                    <Field
                      name={`campaignSettings.companionAds.size.${type.toLowerCase()}`}
                      label={`Size for ${type} Image`}
                      defaultValue={companionAds?.size?.[type.toLowerCase()]}
                      component={renderSelect(fallbackSizesOptions)}
                      onChange={newValue => handleFallbackImageSize(newValue, type.toLowerCase())}
                      readOnly={readOnly}
                    />
                  </div>
                ))}
              </div>
              <div className="field-wrapper">
                <label className="companion-backfill-link-description">Use the banner image to redirect users to the link address (optional.)</label>
                <Field
                  name="campaignSettings.companionAds.backfillUrl"
                  component={renderField}
                  wrapperClassName="companion-backfill-link-field"
                  readOnly={readOnly}
                  label="Image link"
                  useLabelAsPlaceholder={false}
                  placeholder="Enter URL"
                  meta={{ touched: backfillUrlChanged, error: error.backfillUrl }}
                  onChange={newValue => {
                    setBackfillUrlChanged(true);
                    setCompanionAds(prevState => ({ ...prevState, backfillUrl: newValue }));
                  }}
                />
                <div className="submit-button">
                  {!readOnly && (
                    <Button
                      classes={{ label: 'button-label' }}
                      className="outlined-secondary"
                      disabled={!backfillUrlChanged}
                      type="button"
                      onClick={onBackfillUrlApply}
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </div>
              <div className="field-wrapper">
                <Field
                  name="campaignSettings.companionAds.usePlaceholder"
                  type="checkbox"
                  component={renderToggle}
                  readOnly={readOnly}
                  label="Enable static companion display placeholder"
                  onChange={newValue => {
                    updateChannel({
                      ...channel,
                      campaignSettings: {
                        ...(campaignSettings || {}),
                        companionAds: { ...(campaignSettings?.companionAds || {}), usePlaceholder: newValue },
                      },
                    });
                  }}
                />
              </div>
              <div className="field-wrapper">
                <Field
                  name="campaignSettings.numberOfClicksBeforeFirstAd"
                  type="number"
                  readOnly={readOnly}
                  wrapperClassName="pubstack-tag-input"
                  component={renderField}
                  label="Number of clicks to show first ad"
                />
                <Field
                  name="campaignSettings.secondsBeforeFirstAd"
                  type="number"
                  readOnly={readOnly}
                  wrapperClassName="pubstack-tag-input"
                  component={renderField}
                  label="Number of seconds before loading first ad"
                />
                <div className="submit-button">
                  {!readOnly && (
                    <Button
                      classes={{ label: 'button-label' }}
                      className="outlined-secondary"
                      type="submit"
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </div>
              <div className="field-wrapper">
                <Field
                  name="trackingPixel.active"
                  type="checkbox"
                  component={renderToggle}
                  readOnly={readOnly}
                  label="Enable GTM"
                  onChange={newValue => {
                    updateChannel({
                      ...channel,
                      trackingPixel: {
                        active: newValue,
                      },
                    });
                  }}
                />
              </div>
              <div className="field-wrapper">
                <Field
                  name="campaignSettings.tools.assertiveYield.active"
                  type="checkbox"
                  component={renderToggle}
                  readOnly={readOnly}
                  label="Enable Assertive yield Tracker"
                  onChange={newValue => {
                    updateChannel({
                      ...channel,
                      campaignSettings: {
                        ...(campaignSettings || {}),
                        tools: { ...(campaignSettings?.tools || {}), assertiveYield: { active: newValue } },
                      },
                    });
                  }}
                />
              </div>
              <div className="field-wrapper">
                <Field
                  name="campaignSettings.tools.pubStack.active"
                  type="checkbox"
                  component={renderToggle}
                  readOnly={readOnly}
                  label="Enable Pubstack"
                />
                <Field
                  name="campaignSettings.tools.pubStack.tag"
                  component={renderField}
                  wrapperClassName="pubstack-tag-input"
                  readOnly={readOnly}
                  label="Pubstack TAG ID"
                />
                <div className="submit-button">
                  {!readOnly && (
                    <Button
                      classes={{ label: 'button-label' }}
                      className="outlined-secondary"
                      type="submit"
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </div>

              {(adsExcludeList?.length || !readOnly) && renderExcludeAds()}
            </form>
          )}
        </Form>
      </div>
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(AdsSettingsForm));
