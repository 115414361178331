import React from 'react';

export const UncheckedCheckbox = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="3.5" width="17" height="17" rx="3.5" stroke="#1E1E37" />
  </svg>
);

export const CheckedCheckbox = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="4" fill="#EB323C" />
    <path d="M16.59 7L18 8.42L10 16.42L6 12.43L7.42 11.02L10 13.59L16.59 7Z" fill="white" />
  </svg>
);

export const IndeterminateCheckbox = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="4" fill="#EB323C" />
    <rect x="3" y="7" width="10" height="2" rx="1" fill="white" />
  </svg>
);
