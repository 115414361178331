import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserProvider from '../../../helpers/UserProvider';
import RevenueShareSelect from '../ChannelsSection/RevenueShareSelect';

import './ChannelsTable.scss';

const RevShareColumn = ({ channel }) => {
  const [campaignSettings, setCampaignSettings] = useState();

  const saveRevShare = async updatedBillings => {
    const channelPayload = await UserProvider.updatePublisher(
      {
        ...channel,
        campaignSettings: {
          ...channel.campaignSettings,
          billings: updatedBillings,
        },
        publisherId: channel._id,
      },
      true,
    );

    if (channelPayload?.channel) {
      setCampaignSettings(channelPayload.payload?.campaignSettings);
    }
  };

  useEffect(() => {
    setCampaignSettings(channel?.campaignSettings);
  }, [channel]);

  return (
    <RevenueShareSelect saveRevShare={saveRevShare} campaignSettings={campaignSettings} />
  );
};

RevShareColumn.propTypes = {
  channel: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default RevShareColumn;
