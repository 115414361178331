import React from "react";
import { inject, observer } from 'mobx-react';
import { Field, renderField, renderToggle, renderSelect } from "../../../../utils/formFieldHelper";
import { composeValidators, required, minValue, isNumber } from "../../../../utils/validation";
import Section from "../../../Section/Section";
import Form from "../../../Settings/Form/Form";
import { qualityList } from "../../../../utils/constants";
import { StoresEnum } from "../../../../stores";

/** styles */
import "./UnitPerformance.scss";

const updateToggleField = (handler, propertyToUpdate, value, channel) => {
  handler({ ...channel, unitPerformance: { ...channel.unitPerformance, [propertyToUpdate]: { active: value } } }, true);
};

const updateSelectField = (handler, propertyToUpdate, value, channel) => {
  handler({ ...channel, unitPerformance: { ...channel.unitPerformance, [propertyToUpdate]: value } }, true);
};

const UnitPerformance = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const renderFields = () => (
    <>
      <Field
        name="unitPerformance.skipSlidePreload.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Skip slides preload"
        onChange={newValue => {
          updateToggleField(updateChannel, 'skipSlidePreload', newValue, channel);
        }}
      />
      <Field
        name="unitPerformance.preventGradualImageLoading.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Prevent gradual image loading"
        onChange={newValue => {
          updateToggleField(updateChannel, 'preventGradualImageLoading', newValue, channel);
        }}
      />
      <Field
        name="unitPerformance.imageQuality"
        label="Image quality"
        component={renderSelect(qualityList)}
        readOnly={readOnly}
        defaultValue={100}
        validate={required}
        onChange={newValue => {
          updateSelectField(updateChannel, 'imageQuality', newValue, channel);
        }}
      />
      <Field
        name="unitPerformance.lazyLoadMonetization.active"
        type="checkbox"
        component={renderToggle}
        readOnly={readOnly}
        label="Lazy load monetization"
        onChange={newValue => {
          updateToggleField(updateChannel, 'lazyLoadMonetization', newValue, channel);
        }}
      />
      <Field
        name="unitPerformance.minUnitSize.width"
        type="number"
        readOnly={readOnly}
        component={renderField}
        validate={composeValidators(isNumber, minValue(300))}
        label="Minimun unit width in px (min 300)"
      />
      <Field
        name="unitPerformance.minUnitSize.height"
        type="number"
        readOnly={readOnly}
        component={renderField}
        validate={composeValidators(isNumber, minValue(300))}
        label="Minimun unit height in px (min 300)"
      />
    </>
  );

  return (
    <Section title="UNIT PERFORMANCE">
      <Form
        initialValues={channel}
        fields={renderFields()}
        onSubmit={updateChannel}
        hideCancelButton
        hideApproveButton={readOnly}
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(UnitPerformance));
