export const sanitizeUrl = url => {
  const sanitizedUrl = url.slice(-1) === '/' ? url.slice(0, -1) : url;
  return sanitizedUrl;
};

export const validateUrl = url => {
  const validURL = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  const isLocalhost = url.includes('localhost');
  return isLocalhost || validURL.test(url);
};

export const appendProtocol = url => (!/^(?:f|ht)tps?:\/\//.test(url) ? `https://${url}` : url);

export const validateDomain = domain => {
  if (!domain) return false;
  const re = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
  return re.test(domain);
};

export const generateFilterUrlParams = filters => {
  let filtersString = '';
  Object.entries(filters).forEach(flt => {
    if (flt[1].length) {
      filtersString = `${filtersString}&filter[${flt[0]}]=${flt[1]}`;
    }
  });
  return filtersString;
};

export const getChannelDomains = channel => {
  const { urls, url } = channel || {};
  const hasUrls = urls?.filter(string => string?.length);

  if (hasUrls?.length) {
    return urls;
  }

  return url ? [url] : undefined;
};
