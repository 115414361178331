import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SelectField from '../../ApeUI/SelectField/SelectField';
import Popup from '../../ApeUI/Popup/Popup';
import { popupTopics, popupContent } from '../../ApeUI/Popup/PopupContent';
import { setInternalPlan } from '../../Channels/Channel/Payments/payments-util';
import { StoresEnum } from '../../../stores';

import './ChannelsTable.scss';

const PlanColumn = ({
  channel,
  onChangePlan,
  channelPlan,
  paymentsStore: { internalPlans },
}) => {
  const [trialPlan, setTrialPlan] = useState();
  const [newPlan, setNewPlan] = useState();
  const [popupDescription, setPopupDescription] = useState(0);

  const openPlanPopup = value => {
    const plan = internalPlans?.find(internalPlan => internalPlan.id === value);
    if (plan) {
      const currentPopupContent = popupContent[popupTopics.PLAN_CHANGE]({
        currentPlanName: channelPlan.billingPlan?.name || channelPlan.name,
        channelName: channel.name,
        newPlanName: plan.name,
      });
      setNewPlan(plan);
      setPopupDescription(currentPopupContent);
    }
  };

  const onSelectPlan = async () => {
    const updatedPlan = await setInternalPlan({
      channel,
      currentSubscription: channelPlan.name !== 'Trial' ? channelPlan : undefined,
      plan: newPlan,
    });

    if (updatedPlan) {
      onChangePlan(updatedPlan);
    }

    setPopupDescription();
  };

  useEffect(() => {
    if (internalPlans) {
      setTrialPlan(internalPlans.find(internalPlan => internalPlan.name === 'Trial'));
    }
  }, [internalPlans]);

  return (
    <>
      {internalPlans?.length && (
        <SelectField
          activeItem={channelPlan?.billingPlan || channelPlan || trialPlan}
          list={internalPlans}
          className="select-column-field"
          onSelect={openPlanPopup}
        />
      )}

      <Popup
        open={Boolean(popupDescription)}
        isAlert
        popupContent={popupDescription}
        onCancel={() => setPopupDescription()}
        onApprove={onSelectPlan}
      />
    </>
  );
};

PlanColumn.propTypes = {
  onChangePlan: PropTypes.func.isRequired,
  channel: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default inject(StoresEnum.PAYMENTS)(observer(PlanColumn));
