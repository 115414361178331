import React from 'react';
import { Form } from 'react-final-form';
import { Field, renderField } from '../../../../utils/formFieldHelper';
import {
  FORM_ERROR_MESSAGES, composeValidators, password, required,
} from '../../../../utils/validation';

const validateForm = values => {
  const errors = {};
  if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = FORM_ERROR_MESSAGES.PASSWORDS_NOT_MATCH;
  }
  return errors;
};

const ChangePassword = ({ onSubmit }) => (
  <Form onSubmit={onSubmit} validate={validateForm}>
    {({
      handleSubmit, pristine, submitting, errors,
    }) => (
      <form onSubmit={handleSubmit} className="create-form password-form">
        <Field
          name="newPassword"
          type="password"
          component={renderField}
          wrapperClassName="input-field-wrapper"
          placeholder="Enter Your New Password"
          useLabelAsPlaceholder={false}
          validate={password}
        />
        <Field
          name="confirmNewPassword"
          type="password"
          component={renderField}
          wrapperClassName="input-field-wrapper"
          placeholder="Confirm New Password"
          useLabelAsPlaceholder={false}
          validate={composeValidators(required, password)}
        />

        <div className="submit-container">
          <button
            className="submit-button"
            type="submit"
            disabled={pristine || submitting || !!Object.keys(errors).length}
          >
            UPDATE
          </button>
        </div>
      </form>
    )}
  </Form>
);

export default ChangePassword;
