import React from 'react';
import PaymentModal from '../../Modal/PaymentsModal/PaymentsModal';
import Modal from '../../../../../Modal/Modal';
import './PaymentMethod.scss';

const cardImage = require('../../../../../../theme/images/card.svg');

class PaymentMethod extends React.Component {
  state = {
    isPaymentMethodOpen: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { subscription } = this.props;
    if (subscription !== nextProps.subscription) {
      this.setState(({ isPaymentMethodOpen: false }));
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  changePaymentMethodBoxStatus = () => {
    const { isPaymentMethodOpen } = this.state;
    this.setState(({ isPaymentMethodOpen: !isPaymentMethodOpen }));
  };

  render() {
    const { subscription, onSubmit } = this.props;
    const { isPaymentMethodOpen } = this.state;
    return (
      <div className="method-container">
        <div className="container-header"><span>PAYMENT METHOD</span></div>
        <div className="method-content">
          <div className="credit-box" onClick={subscription.cancelAtPeriodEnd ? () => { } : this.changePaymentMethodBoxStatus}>
            <img src={cardImage} alt="" />
            <div className="credit-inner-box">
              <div className="card-number">
                <span>{subscription.cardBrand}</span>
                <span className="card-hidden-number">{`**** **** **** ${subscription.cardLast4 || ''}`}</span>
              </div>
              <div className="card-expiration">{`Expires ${subscription.cardExpMonth || ''}/${subscription.cardExpYear || ''}`}</div>
            </div>
          </div>
          <div
            className={`action ${subscription.cancelAtPeriodEnd ? 'btn-disabled' : ''}`}
            onClick={subscription.cancelAtPeriodEnd ? () => { } : this.changePaymentMethodBoxStatus}
          >
            Update details
          </div>
        </div>
        {isPaymentMethodOpen && (
          <Modal title="PAYMENT METHOD" height="520" width="606" onClose={this.changePaymentMethodBoxStatus}>
            <PaymentModal close={this.changePaymentMethodBoxStatus} subscriptionId={subscription.id} onSubmit={onSubmit} />
          </Modal>
        )}
      </div>
    );
  }
}

export default PaymentMethod;
