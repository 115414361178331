import React from 'react';
import { Route } from 'react-router-dom';
import RoutingService from '../../../helpers/RoutingService';

import MFAAlert from './MFAAlert/MFAAlert';
import BackupOptionsAlert from './BackupOptionsAlert/BackupOptionsAlert';
import AppAlert from './AppAlert/AppAlert';
import { disableAuthMFALogin } from './auth-requests';
import { Toggle } from '../../ApeUI';

import './Authentication.scss';

const Authentication = props => {
  const {
    onPresentAuthApp,
    onPresentAuthBackup,
    onPresentAuthMFA,
    userStore: { user, fetchUser },
  } = props;
  const authenticationEnabled = user.TOTPClient && user.TOTPClient.active; // need to use it to change to active css

  const switchToText = user.TOTPClient && user.TOTPClient.mfaType === 'app'
    ? 'Switch To Phone'
    : 'Switch To App';
  return (
    <div className="auth-section">
      <label>Two Factors Authentication</label>
      <Toggle
        toggleStyle={{ top: '0' }}
        label={authenticationEnabled ? 'Active' : 'Inactive'}
        toggled={authenticationEnabled}
        typography="baseMediumExtraBold"
        size="large"
        onChange={() => {
          if (!authenticationEnabled) {
            onPresentAuthMFA(true);
          } else if (!user.forceMFA) {
            disableAuthMFALogin(user.userId).then(fetchUser);
          }
        }}
      />
      {authenticationEnabled && (
        <div className="mfa-buttons">
          <div>
            <div
              className="mfa-button"
              onClick={() => onPresentAuthBackup(true)}
              style={{ paddingRight: '20px' }}
            >
              Edit backup options
              <i className="ic icon icon-arrow-right" />
            </div>
            <Route
              path={`${
                RoutingService.routes.settings.routes.profile.routes.mfa.routes
                  .backup.url
              }`}
              render={() => (
                <BackupOptionsAlert
                  onClose={() => onPresentAuthBackup(false)}
                  {...props}
                />
              )}
            />
          </div>
          <div>
            <div
              className="mfa-button"
              onClick={() => {
                if (user.TOTPClient.mfaType === 'sms') {
                  onPresentAuthApp(true);
                } else {
                  disableAuthMFALogin(user.userId).then(fetchUser);
                  onPresentAuthMFA(false);
                }
              }}
            >
              {switchToText}
              <i className="ic icon icon-arrow-right" />
            </div>
            <Route
              path={`${
                RoutingService.routes.settings.routes.profile.routes.mfa.routes
                  .app.url
              }`}
              render={() => (
                <AppAlert onClose={() => onPresentAuthApp(false)} {...props} />
              )}
            />
          </div>
        </div>
      )}
      <Route
        path={`${
          RoutingService.routes.settings.routes.profile.routes.mfa.routes.active
            .url
        }`}
        render={() => (
          <MFAAlert onClose={() => onPresentAuthMFA(false)} {...props} />
        )}
      />
    </div>
  );
};

Authentication.propTypes = {};

export default Authentication;
