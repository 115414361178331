import React from 'react';
import { CONFIG } from '../../../../utils/constants';

const getIframe = (user, publisher) => {
  const userJson = JSON.stringify({
    userId: user.userId, userName: user.displayName, userEmail: user.emails[0], isKeepoKappa: user.isKeepoKappa,
  });

  const userQueryParam = window.btoa(unescape(encodeURIComponent(userJson)));
  return (
    `<iframe id="payments-header" scrolling="yes" src="${CONFIG.paymentsUrl}/pricing?publisherId=${
      publisher.publisherId
    }&publisherName=${publisher.name}&inApp=true&user=${userQueryParam}" frameborder="no"></iframe>`
  );
};

const PaymentPopup = ({ user, publisher, shouldFadeIn }) => (
  <div
    className={`payments-wrapper-header ${shouldFadeIn ? 'fade-in' : 'fade-out'}`}
    dangerouslySetInnerHTML={{ __html: getIframe(user, publisher) || "" }}
  />
);

export default PaymentPopup;
