import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from '@mui/material/Button';

import './Flat.scss';

const Flat = props => {
  const { title, style } = props;
  const { onClick } = props;
  return (
    <div style={style} className="flat-button-wrapper">
      <FlatButton onClick={onClick}>
        {title}
      </FlatButton>
    </div>
  );
};

Flat.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

Flat.defaultProps = {
  style: {},
};

export default Flat;
