import React, { Component } from "react";
import { inject, observer } from 'mobx-react';
import { reaction } from "mobx";

import ChannelForm, { FIELD_NAMES } from "../../../components/Settings/ChannelForm/ChannelForm";
import ChannelColors from "../../../components/Channels/Channel/Palette/ChannelColors";
import UnitPerformance from "../../../components/Channels/Channel/UnitPerformance/UnitPerformance";
import ProgressBarColors from "../../../components/Channels/Channel/Palette/ProgressBarColors";
import ContentAccess from "../../../components/Channels/Channel/ContentAccess/ContentAccess";

import ChannelGeneral from "../../../components/Channels/Channel/General/ChannelGeneral";

import { AvatarContainer, NumberOfMembers } from "../../../components/ApeUI/AvatarContainer/AvatarContainer";
import Payment from '../../../components/Channels/Channel/Payments/Payments';

import WebhooksProvider from "../../../helpers/WebhooksProvider";
import EventTracker, { EVENT_TYPE } from "../../../helpers/EventTracker";
import CdnMediaProvider from "../../../helpers/CdnMediaProvider";

// eslint-disable-next-line import/no-named-as-default
import PixelsSettings from "../../../components/Channels/Channel/General/PixelsSettings";

import ChannelDeveloper from "../../../components/Channels/Channel/Developers/ChannelDeveloper";
import ChannelNameColor from '../../../components/Channels/Channel/Palette/ChannelNameColor';
import ChannelAdsTxt from "../../../components/Channels/Channel/AdsTxt/ChannelAdsTxt";
import Section from "../../../components/Section/Section";
import ApeSnackbar from "../../../components/ApeUI/ApeSnackbar/ApeSnackbar";
import ChannelMembersSection from "../../../components/Settings/MembersSection/ChannelMembersSection";
import { snackbarContent, snackbarTopics } from "../../../components/ApeUI/ApeSnackbar/SnackbarContent";
import ProfileSkeleton from "./ProfileSkeleton";
import { StoresEnum } from "../../../stores";

import "../Settings.scss";

const tabIndexes = {
  EXTERNAL: 0,
  INTERNAL: 1,
  MARKETING: 2,
  DEVELOPERS: 3,
  ADS_TXT: 4,
};

class ChannelProfile extends Component {
  state = {
    tabIndex: 0,
    snackbarMessage: undefined,
    snackbarType: undefined,
    hasWebhooks: false,
  };

  channelStoreDisposer;

  async componentDidMount() {
    const { match, channelStore } = this.props;
    const { params } = match;

    if (params?.channelId) {
      channelStore.fetchChannel(params.channelId);
    }

    this.channelStoreDisposer = reaction(() => channelStore.channel, (value, previousValue) => {
      if (channelStore.channel.readOnly && value.publisherId !== previousValue.publisherId) {
        this.checkIfChannelHasWebhooks(value.publisherId);
      }
    });
  }

  async componentDidUpdate(prevProps) {
    const { channelStore: { channel }, history } = this.props;

    if (channel?.error) {
      history.push('/channels/create');
    } else {
      this.handleParamsChange(prevProps);
    }
  }

  componentWillUnmount() {
    const { channelStore: { clearChannel } } = this.props;
    this.channelStoreDisposer();
    clearChannel();
  }

  handleParamsChange(prevProps) {
    const { match: { params: { channelId, groupId } }, groupStore: { group, fetchGroup, clearGroup } } = this.props;
    const { match: { params: { channelId: prevId } } } = prevProps;

    if (!groupId && group?._id) {
      clearGroup();
    }

    if (groupId && !group?._id) {
      fetchGroup(groupId);
    }

    if (channelId !== prevId) {
      const { channelStore } = this.props;
      channelStore.fetchChannel(channelId);
    }
  }

  getSelectedChannelIndex() {
    const { userStore: { user }, channelStore: { channel } } = this.props;
    const userPublishers = user.publishers;
    const selectedPublisherObject = userPublishers.find(
      pub => pub.publisherId === channel.publisherId,
    );
    if (!selectedPublisherObject) return -1;
    const selectedPublisherIndex = userPublishers.indexOf(
      selectedPublisherObject,
    );
    if (selectedPublisherIndex < 0) return -1;
    return selectedPublisherIndex;
  }

  handleChannelFormSubmit = async values => {
    const { paymentsStore: { setSubscription }, channelStore: { updateChannel } } = this.props;
    const response = await updateChannel(values);

    if (response?.subscription?.id) {
      setSubscription(response.subscription);
    }

    if (response?.channel?._id) {
      const content = snackbarContent[snackbarTopics.CHANNEL_SAVED](response.channel.name);
      this.setState({ snackbarMessage: content.message, snackbarType: content.type });
    }

    return response;
  };

  toggleSection = (tabIndex, tabName) => {
    const { channelStore: { channel } } = this.props;
    EventTracker.track(EVENT_TYPE.SETTING_TAB_SELECTED, {
      tabName: tabName?.toLowerCase(),
      channelId: channel.publisherId,
    });
    this.setState({ tabIndex });
  };

  changePrevOrNextChannel = isNext => {
    const {
      userStore: { user: { publishers } },
      history,
    } = this.props;

    const selectedChannelIndex = this.getSelectedChannelIndex();
    if (selectedChannelIndex < 0) return;

    let newPublisherId;

    if (isNext) {
      newPublisherId = selectedChannelIndex === publishers.length - 1
        ? publishers[0].publisherId
        : publishers[selectedChannelIndex + 1].publisherId;
    } else {
      newPublisherId = selectedChannelIndex === 0
        ? publishers[publishers.length - 1].publisherId
        : publishers[selectedChannelIndex - 1].publisherId;
    }

    history.push(`/channels/${newPublisherId}`);
  };

  getPrevChannel = () => {
    this.changePrevOrNextChannel();
  };

  getNextChannel = () => {
    this.changePrevOrNextChannel(true);
  };

  shouldHaveCarousel = () => {
    const { userStore: { user, isSuperAdmin } } = this.props;

    const userPublishers = user && user.publishers;

    return !isSuperAdmin
      && userPublishers
      && userPublishers.length > 1;
  };

  shouldRenderTab = (currentTabIndex, tabIndex) => {
    const { channelStore: { channel } } = this.props;
    const isAdmin = channel && channel.admins;
    switch (currentTabIndex) {
    case tabIndexes.EXTERNAL: {
      return channel && tabIndex === tabIndexes.EXTERNAL;
    }
    case tabIndexes.INTERNAL:
      return isAdmin && tabIndex === tabIndexes.INTERNAL;
    case tabIndexes.MARKETING: {
      return tabIndex === tabIndexes.MARKETING;
    }
    case tabIndexes.DEVELOPERS: {
      return tabIndex === tabIndexes.DEVELOPERS;
    }
    case tabIndexes.ADS_TXT: {
      return channel.groupId && tabIndex === tabIndexes.ADS_TXT;
    }
    default: return false;
    }
  };

  checkIfChannelHasWebhooks = async channelId => {
    if (!channelId) {
      this.setState({ hasWebhooks: false });
      return;
    }
    const webhooks = await WebhooksProvider.getWebhooks(channelId);
    this.setState({ hasWebhooks: webhooks?.length > 0 });
  };

  channelAvatarChange = async e => {
    const { channelStore: { updateChannel } } = this.props;
    const profileImage = await CdnMediaProvider.uploadImage(e);
    if (profileImage) {
      updateChannel({ profileImage });
    }
  };

  renderAvatarContainer = () => {
    const {
      channelStore: { channel },
      paymentMode,
      match,
    } = this.props;

    const { channelId } = match.params;

    const avatarProps = {};
    avatarProps.picture = channel.profileImage;

    avatarProps.name = channel.name;
    avatarProps.handleAvatarChange = this.channelAvatarChange;

    if (!paymentMode) {
      if (this.shouldHaveCarousel()) {
        avatarProps.onLeftClick = this.getPrevChannel;
        avatarProps.onRightClick = this.getNextChannel;
      }
    }

    return (
      <AvatarContainer {...avatarProps}>
        {channelId && <NumberOfMembers {...channel} />}
      </AvatarContainer>
    );
  };

  renderChannelInfo = () => {
    const {
      userStore: { isSuperAdmin },
      channelStore: { readOnly, channel },
      paymentsStore: { currentSubscription, hasSubscriptionFetched },
    } = this.props;

    const basicFieldsList = [FIELD_NAMES.COUNTRY, FIELD_NAMES.VERTICAL, FIELD_NAMES.TOKEN];
    if (!readOnly) {
      basicFieldsList.push(FIELD_NAMES.PLAN);
    }
    const plan = isSuperAdmin ? currentSubscription?.billingPlanId : currentSubscription?.billingPlan?.name;

    // Should make sure all relevant data are available to prevent unnecessary form reinitialize
    const isChannelDataReady = channel?.publisherId && hasSubscriptionFetched;

    const channelData = {
      ...channel,
      plan: plan || 'Trial',
      isActive: channel.status === 'active',
    };

    return (
      <>
        <Section title="info">
          {isChannelDataReady && (
            <ChannelForm
              channel={channelData}
              updateNameOnTyping={() => {}}
              onSubmit={this.handleChannelFormSubmit}
              fieldsList={isSuperAdmin ? [...basicFieldsList, FIELD_NAMES.GROUP, FIELD_NAMES.ID, FIELD_NAMES.STATUS] : basicFieldsList}
              cancelLabel="Delete"
              // TODO: onCancel will be changed and disableCancelButton will be removed when delete functionality is implemented
              onCancel={() => console.log('should delete')}
              disableCancelButton
            />
          )}
        </Section>

        <UnitPerformance />

        {(channel?.colorPalette?.colors?.length || !readOnly) && (
          <ChannelColors />
        )}

        <ProgressBarColors />
        <ChannelNameColor />

        {channel?.publisherId && (
          <Section title="members">
            <ChannelMembersSection />
          </Section>
        )}

        <ContentAccess />
      </>
    );
  };

  renderChannelData = () => {
    const { tabIndex } = this.state;

    return (
      <>
        {this.shouldRenderTab(tabIndexes.EXTERNAL, tabIndex) && this.renderChannelInfo()}
        {this.shouldRenderTab(tabIndexes.INTERNAL, tabIndex) && (
          <ChannelGeneral />
        )}
        {this.shouldRenderTab(tabIndexes.MARKETING, tabIndex) && (
          <PixelsSettings />
        )}
        {this.shouldRenderTab(tabIndexes.DEVELOPERS, tabIndex) && (
          <ChannelDeveloper />
        )}
        {this.shouldRenderTab(tabIndexes.ADS_TXT, tabIndex) && (
          <ChannelAdsTxt />
        )}
      </>
    );
  };

  render() {
    const { paymentMode, channelStore: { channel, readOnly }, userStore: { isSuperAdmin } } = this.props;
    const {
      snackbarMessage, snackbarType, tabIndex, hasWebhooks,
    } = this.state;

    const tabsProps = !paymentMode && {
      toggleTab: this.toggleSection,
      currentTab: tabIndex,
      tabs: [
        { label: 'General', index: tabIndexes.EXTERNAL },
        ...(isSuperAdmin ? [{ label: 'Internal', index: tabIndexes.INTERNAL }] : []),
        { label: 'Marketing', index: tabIndexes.MARKETING },
        { label: 'Developers', index: tabIndexes.DEVELOPERS, disabled: readOnly && !hasWebhooks },
        ...(channel?.groupId ? [{ label: 'Ads.txt', index: tabIndexes.ADS_TXT }] : []),
      ],
    };

    return !channel?.error && (
      <>
        <ProfileSkeleton {...tabsProps} avatar={this.renderAvatarContainer()}>
          {paymentMode
            ? <Payment {...this.props} />
            : this.renderChannelData()
          }
        </ProfileSkeleton>

        <ApeSnackbar
          open={Boolean(snackbarMessage)}
          message={snackbarMessage}
          onRequestClose={() => this.setState({ snackbarMessage: undefined, snackbarType: undefined })}
          iconType={snackbarType}
        />
      </>
    );
  }
}

export default inject(StoresEnum.GROUP, StoresEnum.CHANNEL, StoresEnum.USER, StoresEnum.PAYMENTS)(observer(ChannelProfile));
