const constants = require('../utils/constants');

const methods = ['get', 'post', 'put', 'patch', 'delete'];

class ApiRequest {
  constructor(url) {
    this.baseUrl = url || constants.CONFIG.usersBaseUrl;

    // eslint-disable-next-line no-return-assign
    methods.forEach(method => (this[method] = (path, {
      data = {},
      file,
      formData,
      include = true,
    // eslint-disable-next-line no-promise-executor-return
    } = {}) => new Promise((resolve, reject) => fetch(
      this.requestUrl(path),
      this.requestHeaders(method, data, file, formData, include),
    )
      .then(
        async response => {
          if (response.status >= 200 && response.status < 300) {
            const responseMessage = await response.text();
            return (responseMessage ? JSON.parse(responseMessage) : {});
          } else {
          // eslint-disable-next-line prefer-promise-reject-errors
            const responseMessage = await response.text();
            // eslint-disable-next-line prefer-promise-reject-errors
            return (reject({ err: responseMessage ? JSON.parse(responseMessage) : { err: 'error occurred' } }));
          }
        },
        err => {
        // eslint-disable-next-line no-console
          console.log(err);
          reject(err);
        },
      )
      .then(json => resolve(json), err => {
        // eslint-disable-next-line no-console
        console.log(err);

        reject(err);
      }))));
  }

  requestUrl = (path = '') => `${this.baseUrl}${path}`;

  requestHeaders = (method, data, file, formData, include) => {
    const res = {
      method,
    };
    if (include) {
      res.mode = 'cors';
      res.credentials = 'include';
    }
    if (formData) {
      res.body = formData;
    }
    if (file) {
      res['Content-Type'] = data.type;
      res.body = this.buildFormData(data, file);
    } else if (!formData && method !== 'get') {
      res.body = JSON.stringify(data);
      res.headers = {
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      };
    }
    return res;
  };

  buildFormData = (data, file) => {
    const formData = new FormData();
    formData.append('file', data);
    formData.append('type', file.type);
    formData.append('Content-Type', data.type);
    return formData;
  };
}

export default ApiRequest;
