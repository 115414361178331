import React from 'react';
import { Radio as MuiRadio, FormControlLabel } from '@mui/material';
import { CheckedIcon, UncheckedIcon } from './RadioIcons';

const Radio = ({
  selected,
  onChange,
  label = '',
  value,
  labelPlacement = 'end',
}) => (
  <FormControlLabel
    value={value}
    control={(
      <MuiRadio
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        checked={selected}
        onChange={onChange}
      />
    )}
    label={label}
    labelPlacement={labelPlacement}
  />
);

export default Radio;
