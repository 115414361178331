import React, { Component } from 'react';
import { fixedPhoneNumber } from '../../../../utils/utils';
import {
  sendMFASmsCode,
  enableAuthMFALogin,
  confirmPasswordRequest,
} from '../auth-requests';

import Modal from '../../../Modal/Modal';
import ConfirmPassword from '../../Password/PasswordForm/ConfirmPassword';
import ConfirmPhoneNumber from './ConfirmPhoneNumber';
import ConfirmVerificationCode from './ConfirmVerificationCode';
import successEnableMFAImage from '../../../../theme/images/success-enable-mfa.svg';
import { FORM_ERROR_MESSAGES } from '../../../../utils/validation';

import './MFAAlert.scss';

class MFAAlert extends Component {
  state = {
    page: 0,
    confirmed: false,
  };

  confirmPassword = values => (
    confirmPasswordRequest(values.password)
      .then(res => { this.setState({ confirmed: true }); })
      .catch(error => ({ password: error.err.message }))
  );

  handleConfirmPhoneNumber = values => {
    const { countryCode, phoneNumber } = values;
    const { userStore: { updateUser } } = this.props;
    const { page } = this.state;

    updateUser({
      primaryPhone: `+${countryCode.replace(/^\D+/g, '')}${fixedPhoneNumber(
        phoneNumber,
      )}`,
    });

    if (sendMFASmsCode()) {
      this.setState({ page: page + 1 });
    }
  };

  handleVerificationCode = values => {
    const { userStore: { user, fetchUser } } = this.props;
    const { verificationCode } = values;
    const { page } = this.state;
    return enableAuthMFALogin(verificationCode, user).then(res => {
      if (res) {
        fetchUser();
        this.setState({ page: page + 1 });
      }
      return res ? undefined : { verificationCode: FORM_ERROR_MESSAGES.FAILED_VERIFICATION_CODE };
    });
  };

  render() {
    const { onClose } = this.props;
    const { onPresentAuthBackup } = this.props;
    const { page, confirmed } = this.state;

    return (
      <Modal
        width={606}
        height={482}
        onClose={() => onClose(false)}
        title="2 Factors Authentication"
      >
        <div className="form-fields">
          {page === 0 && !confirmed && (
            <ConfirmPassword onSubmit={this.confirmPassword} />
          )}
          {page === 0 && confirmed && (
            <ConfirmPhoneNumber onSubmit={this.handleConfirmPhoneNumber} />
          )}

          {page === 1 && confirmed && (
            <ConfirmVerificationCode onSubmit={this.handleVerificationCode} />
          )}
          {page === 2 && confirmed && (
            <div className="description-section">
              <div className="description-title">
                Your account is now protected with an authentication code. we
                strongly recommend that you set up a backup option now
              </div>
              <div>
                <img src={successEnableMFAImage} alt="" />
              </div>
              <div className="submit-container">
                <button
                  type="button"
                  onClick={() => onPresentAuthBackup(true)}
                  className="general-button"
                >
                  SETUP BACKUP OPTIONS
                </button>
              </div>
              <div className="submit-container">
                <button type="button" onClick={onClose} className="general-button black">
                  I`LL DO IT LATER
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
export default MFAAlert;
